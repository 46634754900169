import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import AppRoutes from './AppRoutes';
import Navbar from './shared/Navbar';
import Sidebar from './shared/Sidebar';
import SettingsPanel from './shared/SettingsPanel';
import Footer from './shared/Footer';
import { withTranslation } from "react-i18next";

class App extends Component {
  state = {}
  closeBanner() {
    document.querySelector(".buy-now-banner").remove();
  }
  componentDidMount() {
    this.onRouteChanged();
  }
  render () 
  {
    let navbarComponent = !this.state.isFullPageLayout ? <Navbar data={this.props}/> : '';
    let sidebarComponent = !this.state.isFullPageLayout ? <Sidebar/> : '';
    let SettingsPanelComponent = !this.state.isFullPageLayout ? <SettingsPanel/> : '';
    let footerComponent = !this.state.isFullPageLayout ? <Footer/> : '';
    console.log(this.props)
    return (
      <div>
        {   this.props.location.pathname.match("/overview") ||this.props.location.pathname.match("/overview") ||this.props.location.pathname.match("/details")||this.props.location.pathname.match("/units")  ? 
        <AppRoutes/>
        :
        <div className="container-scroller">
          { navbarComponent  }
          <div className="container-fluid page-body-wrapper" style={{paddingTop:0}}>
            { sidebarComponent }
            <div className="main-panel" style={{marginLeft:258}}>
           
              <div className="content-wrapper" >
                <AppRoutes/>
                { SettingsPanelComponent }
              </div>
              { footerComponent }
            </div>
          </div>
        </div>
  }
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }
  
  onRouteChanged() {
    if(this.props.location.pathname.match("/overview") ||this.props.location.pathname.match("/details")||this.props.location.pathname.match("/units")){}else{ 
    console.log("ROUTE CHANGED");
    const { i18n } = this.props;
    const body = document.querySelector('body');
    if(this.props.location.pathname === '/layout/RtlLayout') {
      body.classList.add('rtl');
      i18n.changeLanguage('ar');
    }
    else {
      body.classList.remove('rtl')
      i18n.changeLanguage('en');
    }
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = ['/user-pages/login-1','/','/overview/','/units/', '/user-pages/login-2', '/user-pages/register-1', '/user-pages/register-2', '/user-pages/lockscreen', '/error-pages/error-404', '/error-pages/error-500', '/general-pages/landing-page'];
    for ( let i = 0; i < fullPageLayoutRoutes.length; i++ ) {
      if (this.props.location.pathname === fullPageLayoutRoutes[i]) {
        this.setState({
          isFullPageLayout: true
        })
        document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');
        document.querySelector('.main-panel').style.marginLeft='0';
        break;
      } else {
        this.setState({
          isFullPageLayout: false
        })
        document.querySelector('.page-body-wrapper').classList.remove('full-page-wrapper');
      }
    }
  }
}

}

export default withTranslation()(withRouter(App));
