import React, { Component, useState } from "react";
import { Button, Col, ProgressBar } from "react-bootstrap";
import { Dropdown, Tabs, Tab } from "react-bootstrap";
import { MutatingDots } from 'react-loader-spinner';
import Checkbox from "react-custom-checkbox";
import { parse, stringify, toJSON, fromJSON } from 'flatted';
import {
    Line,
    Bar,
    Doughnut,
    HorizontalBar,
    Pie,
    Radar
} from "react-chartjs-2";
import {
    API_URL,
    NESTED_SUBTOPIC_URL,
    SUBTOPICS_URL,
    MOSS_USER,
    MOSS_TITLE,
    TOPICS_URL,
    NESTED_TIER_THREE_URL,
    NESTED_TIER_FOUR_URL,
    TIER_THREE_URL,
    TIER_FOUR_URL,
} from "../constants";
import { exportCSV } from 'primereact/api';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "../App.scss";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import html2canvas from "html2canvas";
import Select from "react-select";
import axios from "axios";
import API from "../api";
import CB from "./checkBox";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import DatePicker from "react-datepicker";
import * as XLSX from "xlsx";
import { saveAs } from 'file-saver';
import "react-datepicker/dist/react-datepicker.css";
import { Chart } from "chart.js";
import exportAsImage from "../dashboard/exportAsImage";
import Swal from "sweetalert2";

import { writeFile } from 'xlsx';
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";

import 'primereact/resources/primereact.min.css';

import 'primereact/resources/themes/saga-blue/theme.css';


export class addittionalReport extends Component {
    constructor(props) {
        super(props);
        this.user = [];
        this.sectionList = [];
        this.tableRefs = Array.from({ length: 6 }, () => React.createRef());
        this.state = {
            company: [],
            png: false,
            radardataset: [{ data1: {}, data2: {}, data3: {}, data4: {} }],
            spinner: false,
            questionChartMaxSize: 0,
            usertopic1: [],
            usertopic2: [],
            role: "",
            selectedRole: "",
            assessment: [],
            selected1: "",
            activeDownload: "",
            characteristic: [],
            enableFilter: { filter1: true, filter2: true, filter3: true, filter4: true },
            response: [],
            // chartcolors: ['#131533', '#0F385C', '#005E80', '#00879C', '#33B0AF', '#74D9BA'],
            chartcolors: [
                "#bed8d4",
                "#78d5d7",
                "#63d2ff",
                "#2081c3",
                "#33B0AF",
                "#005E80",
            ],
            role_2: "",
            report: { roles: [], departments: [] },
            selectedRole_2: "",
            tiercountbytopic_2: [],
            tierlist_2: [],
            selectedtierlist_2: { t1: [], t2: [], t3: [], t4: [] },
            selectedtier_2: { t1: {}, t2: {}, t3: {}, t4: {} },
            topictitle_2: [],

            role_3: "",
            selectedRole_3: "",
            tiercountbytopic_3: [],
            selectedtierlist_3: { t1: [], t2: [], t3: [], t4: [] },
            selectedtier_3: { t1: {}, t2: {}, t3: {}, t4: {} },
            topictitle_3: [],

            role_4: "",
            selectedRole_4: "",
            tiercountbytopic_4: [],
            selectedtierlist_4: { t1: [], t2: [], t3: [], t4: [] },
            selectedtier_4: { t1: {}, t2: {}, t3: {}, t4: {} },
            topictitle_4: [],
            filteredRadargraph: {},

            tierresponsecount: [],
            tiercountbytopic: [],
            selectedtier: { t1: {}, t2: {}, t3: {}, t4: {} }, // selected tier from filter
            sectionchart: { a: [], b: [], c: [], d: [], e: [], f: [] }, // Average per question by categorywise
            questionChart: { a: [], b: [], c: [], d: [], e: [], f: [] }, // sum of each question
            questionlist: [], // List of questions
            section: [], // category wise average value for radar chart
            radargraph: [], // datasets by section values
            radargraph2: [], // datasets by section values for png default hidden
            tierlist: [], // unique tier by response
            selectedtierlist: { t1: [], t2: [], t3: [], t4: [] }, // selected tier filter list
            topictitle: [], // tier title list
            question: [], // total by section
            moss: [],
            selected: [],
            data: [],
            totalSurvey: 0,
            country: [],
            countryValue: "All",
            factoryValue: "All",
            groupValue: "All",
            typeValue: "All",
            jobValue: "All",
            attributesValue: "All",
            Loader: true,
            show: true,
            circle1: 60,
            circle2: 60,
            circle3: 70,
            maturity: 3,
            overall: 169,
            splice: [],
            PieData1: {
                datasets: [
                    {
                        data: [28, 27, 15, 20, 10],
                        backgroundColor: [
                            "#005100",
                            "#4F9F3F",
                            "#fbb11c",
                            "#C8442E",
                            "#940C07",
                        ],
                        borderWidth: 0,
                        label: "My dataset", // for legend
                    },
                ],
                labels: [
                    "Strongly Agree-28",
                    "Agree-27",
                    "Neutral-15",
                    "Disagree-20",
                    "Strongly Disagree-10",
                ],
            },
            datax: {
                // labels: ["Leadership", "Process", "People"],
                datasets: [
                    {
                        label: "Combined",
                        backgroundColor: "#a461d8",
                        data: [3],
                        borderWidth: 1,
                        fill: false,
                    },
                    {
                        label: "Manager/Supervisor",
                        backgroundColor: "#fc5a5a",
                        data: [4],
                        borderWidth: 1,
                        fill: false,
                    },
                    {
                        label: "Worker",
                        backgroundColor: "#197AFA",
                        data: [1],
                        borderWidth: 1,
                        fill: false,
                    },
                ],
            },
            factory1: {
                labels: ["Q1"],
                datasets: [
                    {
                        label: ["L1"],
                        data: [10],
                        backgroundColor: ["#a461d8"],
                        borderWidth: 1,
                        fill: false,
                    },
                    {
                        label: ["L2"],
                        data: [20],
                        backgroundColor: ["#fc5a5a"],
                        borderWidth: 1,
                        fill: false,
                    },
                    {
                        label: ["L3"],
                        data: [70],
                        backgroundColor: ["#197AFA"],
                        borderWidth: 1,
                        fill: false,
                    },
                    {
                        label: ["L4"],
                        data: [40],
                        backgroundColor: ["#796AD2"],
                        borderWidth: 1,
                        fill: false,
                    },
                    {
                        label: ["L5"],
                        data: [30],
                        backgroundColor: ["#44CE42"],
                        borderWidth: 1,
                        fill: false,
                    },
                ],
            },
            factoryopt: {
                tooltips: {
                    enabled: false,
                },
                responsive: true,

                legend: {
                    display: true,
                    position: "bottom",
                    fullWidth: false,
                    labels: {
                        boxWidth: 10,
                        padding: 5,
                    },
                },

                scales: {
                    yAxes: [
                        {
                            categoryPercentage: 1,
                            barPercentage: 0.7,
                            gridLines: {
                                display: true,
                                drawTicks: true,
                                drawOnChartArea: false,
                            },

                            ticks: {
                                min: 0,
                                fontColor: "#555759",
                                display: false,
                                fontSize: 12,
                            },
                        },
                    ],
                    xAxes: [
                        {
                            gridLines: {
                                display: true,
                                drawTicks: false,
                                tickMarkLength: 5,
                                drawBorder: false,
                            },
                            ticks: {
                                stepSize: 10,
                                padding: 5,
                                beginAtZero: true,
                                max: 100,

                                min: 0,
                                fontColor: "#555759",

                                fontSize: 11,
                                callback: function (label, index, labels) {
                                    return label;
                                },
                            },
                            scaleLabel: {
                                display: true,
                                padding: 5,

                                fontColor: "#555759",
                                fontSize: 16,
                                fontStyle: 700,
                                // labelString: 'L2'
                            },
                        },
                    ],
                },
            },
            bar1: {
                labels: ["Q1"],
                datasets: [
                    {
                        label: ["Combined"],
                        data: [3],
                        backgroundColor: ["#a461d8"],
                        borderWidth: 1,
                        fill: false,
                    },
                    {
                        label: ["Manager/Supervisor"],
                        data: [5],
                        backgroundColor: ["#fc5a5a"],
                        borderWidth: 1,
                        fill: false,
                    },
                    {
                        label: ["Worker"],
                        data: [1],
                        backgroundColor: ["#197AFA"],
                        borderWidth: 1,
                        fill: false,
                    },
                ],
            },
            data1: {
                labels: ["L1", "L2", "L3", "L4", "L5", "L6", "L7"],
                datasets: [
                    {
                        label: "",

                        data: [5, 8, 7, 6, 9, 10, 6],
                        backgroundColor: [
                            "rgb(255, 153, 0)",
                            "rgb(255, 153, 0)",
                            "rgb(255, 153, 0)",
                            "rgb(255, 153, 0)",
                            "rgb(255, 153, 0)",
                            "rgb(255, 153, 0)",
                            "rgb(255, 153, 0)",
                        ],
                    },
                ],
            },
            data2: {
                labels: [
                    "PR1",
                    "PR2",
                    "PR3",
                    "PR4",
                    "PR5",
                    "PR6",
                    "PR7",
                    "PR8",
                    "PR9",
                    "PE10",
                ],
                datasets: [
                    {
                        label: "Process",
                        data: [8, 7, 8, 7, 5, 10, 6, 5, 4, 7],
                        backgroundColor: [
                            "rgb(141, 180, 226)",
                            "rgb(141, 180, 226)",
                            "rgb(141, 180, 226)",
                            "rgb(141, 180, 226)",
                            "rgb(141, 180, 226)",
                            "rgb(141, 180, 226)",
                            "rgb(141, 180, 226)",
                            "rgb(141, 180, 226)",
                            "rgb(141, 180, 226)",
                            "rgb(141, 180, 226)",
                        ],
                    },
                ],
            },
            data3: {
                labels: [
                    "PE1",
                    "PE2",
                    "PE3",
                    "PE4",
                    "PE5",
                    "PE6",
                    "PE7",
                    "PE8",
                    "PE9",
                    "PE10",
                ],
                datasets: [
                    {
                        labels: [
                            "A1",
                            "A2",
                            "A3",
                            "A4",
                            "A5",
                            "A6",
                            "A7",
                            "A8",
                            "A9",
                            "A10",
                        ],

                        data: [6, 7, 10, 8, 5, 7, 5, 8, 9, 3],
                        backgroundColor: [
                            "rgb(146, 208, 80)",
                            "rgb(146, 208, 80)",
                            "rgb(146, 208, 80)",
                            "rgb(146, 208, 80)",
                            "rgb(146, 208, 80)",
                            "rgb(146, 208, 80)",
                            "rgb(146, 208, 80)",
                            "rgb(146, 208, 80)",
                            "rgb(146, 208, 80)",
                            "rgb(146, 208, 80)",
                        ],

                        borderWidth: 1,
                    },
                ],
            },
            data4: {
                labels: ["L1", "L2", "L3", "L4", "L5", "L6", "L7"],
                datasets: [
                    {
                        label: "",

                        data: [3, 5, 7, 6, 8, 7, 5],
                        backgroundColor: [
                            "#007d79",
                            "#007d79",
                            "#007d79",
                            "#007d79",
                            "#007d79",
                            "#007d79",
                            "#007d79",
                            "#007d79",
                        ],
                    },
                ],
            },
            data5: {
                labels: [
                    "PR1",
                    "PR2",
                    "PR3",
                    "PR4",
                    "PR5",
                    "PR6",
                    "PR7",
                    "PR8",
                    "PR9",
                    "PE10",
                ],
                datasets: [
                    {
                        label: "Process",
                        data: [5, 6, 8, 7, 5, 4, 3, 5, 4, 2],
                        backgroundColor: [
                            "rgb(141, 180, 226)",
                            "rgb(141, 180, 226)",
                            "rgb(141, 180, 226)",
                            "rgb(141, 180, 226)",
                            "rgb(141, 180, 226)",
                            "rgb(141, 180, 226)",
                            "rgb(141, 180, 226)",
                            "rgb(141, 180, 226)",
                            "rgb(141, 180, 226)",
                            "rgb(141, 180, 226)",
                        ],
                    },
                ],
            },
            data6: {
                labels: [
                    "PE1",
                    "PE2",
                    "PE3",
                    "PE4",
                    "PE5",
                    "PE6",
                    "PE7",
                    "PE8",
                    "PE9",
                    "PE10",
                ],
                datasets: [
                    {
                        labels: [
                            "A1",
                            "A2",
                            "A3",
                            "A4",
                            "A5",
                            "A6",
                            "A7",
                            "A8",
                            "A9",
                            "A10",
                        ],

                        data: [5, 7, 5, 8, 5, 7, 5, 8, 4, 3],
                        backgroundColor: [
                            "rgb(146, 208, 80)",
                            "rgb(146, 208, 80)",
                            "rgb(146, 208, 80)",
                            "rgb(146, 208, 80)",
                            "rgb(146, 208, 80)",
                            "rgb(146, 208, 80)",
                            "rgb(146, 208, 80)",
                            "rgb(146, 208, 80)",
                            "rgb(146, 208, 80)",
                            "rgb(146, 208, 80)",
                        ],

                        borderWidth: 1,
                    },
                ],
            },
        };

    }
    downloadGraph(id) {
        exportAsImage(document.getElementById(id), 'Response by Role');
    }
    downloadAll(id, data, tableid) {
        data.forEach((i, j) => {

            exportAsImage(document.getElementById(id + j), id + j);
        })


        const tempElement = this.tableRefs[tableid].current.getTable();

        const headers = Array.from(tempElement.querySelectorAll('thead th')).map(
            (header) => header.innerText.trim()
        );

        const values = Array.from(tempElement.querySelectorAll('tbody tr')).map((row) =>
            Array.from(row.querySelectorAll('td')).map((cell) => cell.innerText.trim())
        );

        const result = values.map((row) =>
            headers.reduce(
                (obj, header, index) => ({
                    ...obj,
                    [header]: row[index],
                }),
                {}
            )
        );

        this.export2Excel(result);

    }
    export2Excel(json) {

        const path = require('path')
        const os = require('os')
        const homedir = os.homedir()
        const downloadFolder = path.join(homedir, 'Downloads')
        const filename = path.join(downloadFolder, 'hellow.xlsx')
        const ws = XLSX.utils.json_to_sheet(json);
        const range = { s: { c: 0, r: 0 }, e: { c: this.state.report.departments.length + 2, r: 7 } }

        const wb = {
            Sheets: { [this.state.selectedtier.t1.label]: ws },
            SheetNames: [this.state.selectedtier.t1.label],
        };
        const worksheet = wb.Sheets[this.state.selectedtier.t1.label]

        const fill = { type: 'pattern', patternType: 'solid', fgColor: { rgb: 'FFFF0000' } }
        let style = []
        for (let row = range.s.r; row <= range.e.r; row++) {
            for (let col = range.s.c; col <= range.e.c; col++) {

                let cellAddress = XLSX.utils.encode_cell({ r: row, c: col })

                if (col > 2 && row > 1) {
                    let client_range = XLSX.utils.encode_cell({ r: row, c: 2 })
                    let client_value = parseFloat(worksheet[client_range].v.split('%')[0])
                    let output_value = parseFloat(worksheet[cellAddress].v.split('%')[0])
                    let result = client_value - output_value

                    if (result < 0) {
                        if (Math.abs(result) >= 10) {
                            style.push({ range: XLSX.utils.encode_range({ s: cellAddress, e: cellAddress }) + ':' + XLSX.utils.encode_range({ s: cellAddress, e: cellAddress }), color: '98fb98' })

                        }
                    } else {
                        if (result >= 10) {
                            style.push({ range: XLSX.utils.encode_range({ s: cellAddress, e: cellAddress }) + ':' + XLSX.utils.encode_range({ s: cellAddress, e: cellAddress }), color: 'FFCCCB' })

                        }
                    }

                }
                // if (worksheet[cellAddress].v === 'Unassigned') {
                //     style.push({ range: XLSX.utils.encode_range({ s: cellAddress, e: cellAddress }) + ':' + XLSX.utils.encode_range({ s: cellAddress, e: cellAddress }), color: 'FFFF0000' })

                // } else if (worksheet[cellAddress].v.toLowerCase().includes('justified -')) {
                //     style.push({ range: XLSX.utils.encode_range({ s: cellAddress, e: cellAddress }) + ':' + XLSX.utils.encode_range({ s: cellAddress, e: cellAddress }), color: '097969' })

                // }
            }
        }
        //  XLSX.writeFile(wb,filename)

        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

        const data = new Blob([excelBuffer], {
            type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        XlsxPopulate.fromDataAsync(data).then((workbook) => {
            workbook.sheets().forEach((sheet) => {

                sheet.range(`A1:${XLSX.utils.encode_col(this.state.report.departments.length + 3)}1`).style({

                    fontColor: 'ffffff',
                    fill: '6082B6'

                });
                style.forEach((cell) => {

                    sheet.range(cell.range).style({

                        fill: cell.color,
                        bold: cell.color === 'FFFF0000' ? false : true

                    });
                })


            })
            workbook
                .outputAsync()
                .then((workbookBlob) =>
                    saveAs(URL.createObjectURL(workbookBlob), this.state.selected1.label + '_' + this.state.selectedtier.t1.label + '_' + ".xlsx")
                )

        })

    }
    getSection(sec, ind) {
        ind = ind + 1;
        if (sec == 0) {
            return "G" + ind;
        } else if (sec == 1) {
            return "F" + ind;
        } else if (sec == 2) {
            return "S" + ind;
        } else if (sec == 3) {
            return "O" + ind;
        } else if (sec == 4) {
            return "R" + ind;
        } else if (sec == 5) {
            return "E" + ind;
        }
    }
    getLabel(sec) {

        if (sec == 0) {
            return "G"
        } else if (sec == 1) {
            return "F"
        } else if (sec == 2) {
            return "S"
        } else if (sec == 3) {
            return "O"
        } else if (sec == 4) {
            return "R"
        } else if (sec == 5) {
            return "E"
        }
    }

    convertToTable(data) {
        // Create table element
        const table = document.createElement('table');

        // Create table header row
        const thead = document.createElement('thead');
        const headerRow = document.createElement('tr');
        Object.keys(data[0]).forEach(key => {
            const th = document.createElement('th');
            th.textContent = key;
            headerRow.appendChild(th);
        });
        thead.appendChild(headerRow);
        table.appendChild(thead);

        // Create table body
        const tbody = document.createElement('tbody');
        data.forEach(item => {
            const row = document.createElement('tr');
            Object.values(item).forEach(value => {
                const td = document.createElement('td');
                td.textContent = value;
                row.appendChild(td);
            });
            tbody.appendChild(row);
        });
        table.appendChild(tbody);

        return table;
    };

    componentWillMount() {
        if (!localStorage.getItem("token")) {
            this.props.history.push("/");
        }




        let formData = new FormData();
        formData.append("userid", localStorage.getItem("token"));

        // https://api.acuizen.com/question-titles?filter={"include":[{"relation":"questions"}]}

        axios({
            method: "get",
            url: API.QuestionWithTitle,

            headers: {
                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            },
            mode: "no-cors",
        }).then((res) => {


            if (res.data.length !== 0) {
                let sectionName = [{ value: "All", label: "All" }];
                let questionlist = [];

                res.data.forEach((i) => {
                    let questionArray = JSON.parse(i.questions[0].value);


                    if (i.lang === "english") {
                        questionArray.forEach((k) => {
                            questionlist.push(
                                {
                                    question: k.label
                                        .replace(/(<([^>]+)>)/gi, "")
                                        .replace(/\n/g, " ")
                                        .replace(/&nbsp;/g, " "), value: i.id, label: i.name
                                }
                            );
                        });
                        sectionName.push({ value: i.id, label: i.name, count: JSON.parse(i.questions[0].value).length });
                    }
                });
                this.sectionList = sectionName;

                this.setState({ characteristic: sectionName, questionlist: this.groupArrayObject(questionlist, 'label') });
            }
        });

        axios({
            method: "get",
            url: "https://api.acuizen.com/companies",

            headers: {
                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            },
            mode: "no-cors",
        }).then((res) => {
            let check = {};
            if (res.data.length !== 0) {
                res.data.forEach((k) => {
                    if (k.roles.length !== 0 && Object.keys(check).length === 0) {
                        check = { value: k.id, label: k.company_name };
                    }
                });
            }

            if (Object.keys(check).length !== 0) {

                // this.showDashboard(check);
            }

            res.data.forEach((element) => {
                this.user.push({ value: element.id, label: element.company_name });
            });

            this.setState({
                moss: this.user

            });
        });
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.state.report.departments.length !== prevState.report.departments.length) {
            // State value has been updated, perform actions or trigger re-render

        }
    }
    groupArrayObject = (array, obj) => {
        return array.reduce((group, arr) => {

            let key = arr[obj];

            group[key] = group[key] ?? [];

            group[key].push(arr);

            return group;

        },

            {})
    }
    async showDashboard(id) {
        this.setState({
            selected1: id,
        });
        const response = await fetch(
            "https://api.acuizen.com/companies" + "/" + id.value + "/" + "moss-users"
        );
        let data = await response.json();
        let user = [];
        data.forEach((element) => {
            user.push({ value: element.id, label: element.assesment_title });
        });
        this.setState({
            assessment: user,
            value: undefined,
            selected: [],
            role: [],
            totalSurvey: 0,
        });


    }
    setSelected = (option) => {
        this.setState({
            selected: option,
        });
    };
    handleChange = async (selectedOption) => {
        this.setState({ spinner: true })
        this.setState({
            attributesValue: "All",
        });
        this.setSelected(selectedOption);
        if (selectedOption.value !== "All") {
            const response = await fetch(
                MOSS_USER + "/" + selectedOption.value + "/" + "moss-titles"
            );
            let data = await response.json();
            let filterdata = data.filter((i) => {
                return i.title !== "";
            });

            let topictitle = [];
            filterdata.forEach((i) => {
                topictitle[i.position - 1] = i.title;
            });

            this.setState({ topictitle, topictitle_2: topictitle });
        } else {
            let topictitle = ["Tier1", "Tier2", "Tier3"];
            this.setState({ topictitle });
        }
        let selectedtier = this.state.selectedtier;
        let selectedtierlist = this.state.selectedtierlist;
        selectedtier = { t1: {}, t2: {}, t3: {}, t4: {} };


        let url = 'https://api.acuizen.com/moss-users/' + selectedOption.value + '/topic-ones'
        let uriString = {
            "include": [{ "relation": "topicTwos" }]

        }
        const topicones = await axios.get(`${url}?filter=${encodeURIComponent(JSON.stringify(uriString))
            }`).then((a) => { return a.data })

        selectedtierlist = { t1: JSON.parse(JSON.stringify(topicones)).map((i) => { return { label: i.name, value: i.id, topics: i.topicTwos } }), t2: [], t3: [], t4: [] };
        selectedtierlist.t1.unshift({ label: 'All', value: 0, topics: JSON.parse(JSON.stringify(topicones)).flatMap((i) => { return i.topicTwos && i.topicTwos }).filter((l) => { return l !== undefined }) })
        this.setState({ selectedtierlist, selectedtier, role: [] });
        let topictwos = topicones.map((i) => {

            if (i.topicTwos !== undefined) {
                return i.topicTwos.map((j) => { return { name: j.name, id: j.id } })
            }



        })
        this.setState({ spinner: true, usertopic1: topicones.map((i) => { return { name: i.name, id: i.id } }), usertopic2: topictwos.reduce((a, b) => { return [...a, ...b] }) })

        //   this.setSelectedOption(selectedOption.value)
        setTimeout(() => {
            this.getMossTitle(selectedOption.value);

        }, 2000)

    };
    handleChange1 = async (selectedOption) => {
        this.setState({
            selected1: selectedOption,
        });
        this.setState({
            attributesValue: "All",
        });
        const response = await fetch(
            "https://api.acuizen.com/companies" +
            "/" +
            selectedOption.value +
            "/" +
            "moss-users"
        );
        let data = await response.json();
        let user = [];
        data.forEach((element) => {
            if (element.created === null || element.created === 'true') {
                user.push({ value: element.id, label: element.assesment_title });
            }
            // user.push({ value: element.id, label: element.assesment_title });
        });
        this.setState({
            assessment: user,
            value: undefined,
            selected: [],
            role: [],
            totalSurvey: 0,
        });
    };
    handleTierChange = (value) => {

        let tierselect = {}

        tierselect.t1 = value;
        this.renderGraph(value)
        this.setState({

            selectedtier: tierselect
        });

    };
    renderGraph = (id) => {
        let filteredData = this.state.response.filter((i) => { return (i.topic1 === id.value || id.value === 0) });
        let roles = [], departments = []

        filteredData.forEach((res) => {
            let roleIndex = roles.findIndex((i) => { return i.name === res.role })
            let depIndex = departments.findIndex((i) => { return i.name === res.topic2 })
            if (roleIndex === -1) {
                roles.push({ name: res.role, count: 1 })
            } else {
                roles[roleIndex].count = roles[roleIndex].count + 1
            }
            if (depIndex === -1) {
                let name = 'Not Found'
                let filtered = id.topics.filter((k) => { return k.id === res.topic2 })
                if (filtered.length !== 0) {
                    name = filtered[0].name
                }
                departments.push({ name: res.topic2, count: 1, title: name })
            } else {
                departments[depIndex].count = departments[depIndex].count + 1
            }
        })
        console.log(roles, departments)
        this.setState({ report: { roles, departments } })
    }
    getRole = (role) => {
        console.log(role)
        if (role === 'manager') {
            return 3
        } else if (role === 'supervisor') {
            return 2
        } else if (role === 'worker') {
            return 1
        } else {
            return null
        }
    }
    customLegend = (chart) => {
        console.log(chart)
        const { data } = chart;
        let legendHtml = '<ul class="custom-legend">';
        data.labels.forEach((label, index) => {
            const dataset = data.datasets[0];
            const backgroundColor = dataset.backgroundColor[index];
            legendHtml += `
            <li>
              <span class="legend-color" style="background-color: ${backgroundColor}"></span>
              <span class="legend-label">${label}as</span>
            </li>
          `;
        });
        legendHtml += '</ul>';
        return legendHtml;
    };
    getQuestionIndex = (initialValue, factor, limit) => {

        if (factor < 1 || factor > 6 || limit < 1) {
            return []; // Return an empty array for invalid input
        }

        const sequence = [];
        let currentValue = initialValue;

        while (currentValue <= limit) {
            sequence.push(currentValue);
            currentValue = currentValue + factor * 6;
        }

        return sequence;

    }
    getGroupChartDataOptions = (index1, index2, question, divid) => {
        let bgColor = { 'manager': '#A5A5A5', 'supervisor': '#EC7D31', 'worker': '#4271C3', 'average': '#06D9F0' }
        let roles = [], datasets = [], count = 0
        console.log(this.state.response.filter((i) => { return (i.topic1 === this.state.selectedtier.t1.value || this.state.selectedtier.t1.value === 0) }))
        this.state.response.filter((i) => { return (i.topic1 === this.state.selectedtier.t1.value || this.state.selectedtier.t1.value === 0) }).forEach((j) => {

            let roleIndex = roles.findIndex((l) => { return l.name === j.role })
            if (roleIndex === -1) {

                let qindex = j.input_json.findIndex((k) => { return (k.id === question.value || this.getQuestionIndex(question.value, 1, 48).includes(k.id)) })

                if (qindex !== -1) {

                    roles.push({ name: j.role, data: [parseFloat((j.input_json[qindex].questions[0].selectedValues[index2])) - 1], order: this.getRole(j.role.toLowerCase().trim()) })
                } else {
                    console.log(j.input_json, 'a')
                    count++
                }

            } else {
                console.log(this.getQuestionIndex(question.value, 1, 48))
                let qindex = j.input_json.findIndex((k) => { return (k.id === question.value || this.getQuestionIndex(question.value, 1, 48).includes(k.id)) })

                if (qindex !== -1) {

                    roles[roleIndex].data.push(parseFloat(j.input_json[qindex].questions[0].selectedValues[index2]) - 1)
                } else {
                    console.log(j.input_json, 'b')
                    count++


                }
            }

        })
        // let role = ['manager','supervisor','worker']
        // role.forEach((i)=>{
        //     if(roles.filter((j)=>{return j.name.trim().toLowerCase() === i }).length === 0){
        //         roles.push({ name: i, data: [] , order: this.getRole(i)})
        //     }
        // })
        let role = ['Average']
        console.log(question.question, roles)
        JSON.parse(JSON.stringify(roles)).sort((a, b) => { return a.order - b.order }).forEach((list, index) => {
            role.push(list.name)
            datasets.push({
                label: list.name, data: [(list.data.filter((val) => { return val === 0 }).length / list.data.length * 100).toFixed(2), (list.data.filter((val) => { return val === 1 }).length / list.data.length * 100).toFixed(2),
                (list.data.filter((val) => { return val === 2 }).length / list.data.length * 100).toFixed(2), (list.data.filter((val) => { return val === 3 }).length / list.data.length * 100).toFixed(2), (list.data.filter((val) => { return val === 4 }).length / list.data.length * 100).toFixed(2)]
                , backgroundColor: bgColor[list.name.trim().toLowerCase()]
            })
        })

        if (datasets.length === 1) {
            let element = document.getElementById(divid)
            console.log(element)
        } else if (datasets.length === 2) {
            let element = document.getElementById(divid)
            console.log(element)

        }
        return {
            data: {
                labels: ['Not True', 'Seldom True', 'Occasionally True', 'Mostly True', 'Always True'],
                datasets
            },


            options: {
                legend: {
                    display: true,
                    position: 'bottom',
                    labels: {
                        generateLabels: function (chart) {
                            const data = chart.data;
                            if (data.labels.length && data.datasets.length) {
                                return role.map(function (label, i) {
                                    const meta = chart.getDatasetMeta(0);
                                    const ds = data.datasets[0];
                                    const arc = meta.data[i];
                                    const value = ds.data[i];
                                    const customLabel = label;
                                    const arcOpts = chart.options.elements.arc;
                                    console.log(label)
                                    const fill = bgColor[label.toLowerCase().trim()];
                                    const stroke = bgColor[label.toLowerCase().trim()];
                                    const bw = arcOpts.borderWidth ? arcOpts.borderWidth : arc.borderWidth;

                                    return {
                                        text: customLabel,
                                        fillStyle: fill,
                                        strokeStyle: stroke,
                                        lineWidth: bw,
                                        hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
                                        index: i
                                    };
                                });
                            }
                            return [];
                        }
                    },

                    onClick: () => { }
                },
                animation: {
                    duration: 1,
                    onProgress: function () {
                        var chartInstance = this.chart,
                            ctx = chartInstance.ctx;

                        ctx.textAlign = "center";
                        ctx.fontSize = "12px";
                        ctx.textBaseline = "bottom";
                        let xaxis = [], yaxis = [], width = 0,textData = []
                        datasets.forEach(function (dataset, i) {
                            var meta = chartInstance.controller.getDatasetMeta(i);

                            meta.data.forEach(function (bar, index) {

                                var data = dataset.data[index];
                                width = bar._model.width

                                xaxis.push({ data, x: bar._model.x, y: bar._model.y, value: data })
                                if (data >= 90) {
                                    ctx.fillStyle = "white";
                                } else {
                                    ctx.fillStyle = "black";
                                }

                                ctx.fillText(
                                    data + ' %',
                                    bar._model.x,
                                    data >= 90 ? bar._model.y - -20 : bar._model.y
                                );

                            });
                        });
                        
                        const result = xaxis.reduce((acc, curr, index) => {
                            if (index % 5 === 0) {
                                acc.push([curr]);
                            } else {
                                acc[acc.length - 1].push(curr);
                            }
                            return acc;
                        }, []);

                        for (let j = 0; j < 5; j++) {
                            let x = 0, y = 0,val=0
                            result.forEach((i) => {
                                console.log(i)
                               val += parseFloat(i[j].value)
                                x = i[j].x + x
                                y = i[j].y + y
                            })
                            console.log(val/3, 'res')
                            ctx.beginPath();
                            ctx.moveTo((x / result.length) - (width * 1.58), (y / result.length));
                            ctx.lineTo((x / result.length) + (width * 1.58), (y / result.length));
                            ctx.fillStyle = "red"
                            ctx.fillText(
                                (val/3).toFixed(1) + '%',
                               ((x / result.length) + 20+ (width * 1.58)),(y / result.length) +5
                            );
                            ctx.strokeStyle = '#06D9F0'
                            ctx.lineWidth = 2;
                            ctx.stroke();
                        }

                    },
                },
                scales: {
                    y: {
                        beginAtZero: true,
                        min: 0, // Set the minimum value of the y-axis
                        max: 100,
                        ticks: {
                            stepSize: 10, // Set the interval for the y-axis ticks
                        }
                    },
                },
                plugins: {

                    legend: {
                        display: false,

                    },
                },
            }
        }
    }
    getPieChartOption = (role) => {
        let bgColor = ['#A5A5A5', '#EC7D31', '#4271C3']
        let bgColor_ = { 'manager': '#A5A5A5', 'supervisor': '#EC7D31', 'worker': '#4271C3' }
        console.log(role)
        let bgColors = JSON.parse(JSON.stringify(role)).sort((a, b) => {
            const nameA = a.name.toUpperCase();
            const nameB = b.name.toUpperCase();

            if (nameA < nameB) {
                return -1;
            }

            if (nameA > nameB) {
                return 1;
            }

            return 0;
        }).map((i) => { return bgColor_[i.name.toLowerCase().trim()] }).reverse()
        console.log(bgColors)
        return {
            data:
            {
                labels: JSON.parse(JSON.stringify(role)).map((i) => { return i.name }).sort().reverse(),
                datasets: [
                    {
                        data: JSON.parse(JSON.stringify(role)).sort((a, b) => {
                            const nameA = a.name.toUpperCase();
                            const nameB = b.name.toUpperCase();

                            if (nameA < nameB) {
                                return -1;
                            }

                            if (nameA > nameB) {
                                return 1;
                            }

                            return 0;
                        }).map((i) => { return i.count }).reverse(),
                        backgroundColor: bgColors,
                        hoverBackgroundColor: bgColors
                    }
                ]
            },
            options: {
                legend: {
                    display: true,
                    labels: {
                        generateLabels: function (chart) {
                            const data = chart.data;
                            if (data.labels.length && data.datasets.length) {
                                return data.labels.sort().reverse().map(function (label, i) {
                                    const meta = chart.getDatasetMeta(0);
                                    const ds = data.datasets[0];
                                    const arc = meta.data[i];
                                    const value = ds.data[i];
                                    const customLabel = label + ' (' + value + ')';
                                    const arcOpts = chart.options.elements.arc;
                                    console.log(label)
                                    const fill = bgColor_[label.toLowerCase().trim()];
                                    const stroke = bgColor_[label.toLowerCase().trim()];
                                    const bw = arcOpts.borderWidth ? arcOpts.borderWidth : arc.borderWidth;

                                    return {
                                        text: customLabel,
                                        fillStyle: fill,
                                        strokeStyle: stroke,
                                        lineWidth: bw,
                                        hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
                                        index: i
                                    };
                                });
                            }
                            return [];
                        }
                    }
                }
            }
        }
    }
    getMossTitle = (value) => {
        this.setState({
            value: value,
        });


        // const url =  'https://api.acuizen.com/custom-responses/' + value;
        const url =
            value === "All"
                ? "https://api.acuizen.com/custom-responses-all/"
                : "https://api.acuizen.com/custom-responses-beta/" + value;


        axios({
            method: "get",
            url: url,

            headers: {
                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            },
            mode: "no-cors",
        }).then((res) => {
            this.setState({ spinner: false })
            this.setState({ spinner: false, response: res.data })

        });
    };
    attributesValue(value) {
        this.setState({
            attributesValue: value,
        });
    }
    cellStyle(keys, tableIndex, cell, row) {

    }
    getResult(keys, tableIndex) {

        let filteredData = this.state.response.filter((i) => { return (i.topic1 === this.state.selectedtier.t1.value || this.state.selectedtier.t1.value === 0) });
        let data = [], data2 = []
        filteredData.forEach((i) => {
            let index = data.findIndex((k) => { return k.id === i.topic2 })
            if (index !== -1) {
                i.input_json.forEach((k, j) => {
                    k.questions[0].selectedValues.forEach((a, b) => {
                        let index2 = data.findIndex((k) => { return k.id === i.topic2 && k.section === this.getSection(j, b) })
                        if (index2 === -1) {
                            data.push({ id: i.topic2, section: this.getSection(j, b), count: parseFloat(a) === 5 ? 1 : 0, total: 1 })
                        } else {

                            data[index2].count = data[index2].count + (parseFloat(a) === 5 ? 1 : 0)
                            data[index2].total = data[index2].total + 1
                        }

                    })
                })

            } else {
                i.input_json.forEach((k, j) => {
                    k.questions[0].selectedValues.forEach((a, b) => {
                        let index2 = data.findIndex((k) => { return k.id === i.topic2 && k.section === this.getSection(j, b) })
                        if (index2 === -1) {
                            data.push({ id: i.topic2, section: this.getSection(j, b), count: parseFloat(a) === 5 ? 1 : 0, total: 1 })
                        } else {
                            data[index2].count = data[index2].count + (parseFloat(a) === 5 ? 1 : 0)
                            data[index2].total = data[index2].total + 1
                        }

                    })
                })
            }

        })
        this.state.response.forEach((i) => {
            let index = data2.findIndex((k) => { return k.id === i.topic2 })
            if (index !== -1) {
                i.input_json.forEach((k, j) => {
                    k.questions[0].selectedValues.forEach((a, b) => {
                        let index2 = data2.findIndex((k) => { return k.id === i.topic2 && k.section === this.getSection(j, b) })
                        if (index2 === -1) {
                            data2.push({ id: i.topic2, section: this.getSection(j, b), count: parseFloat(a) === 5 ? 1 : 0, total: 1 })
                        } else {

                            data2[index2].count = data2[index2].count + (parseFloat(a) === 5 ? 1 : 0)
                            data2[index2].total = data2[index2].total + 1
                        }

                    })
                })

            } else {
                i.input_json.forEach((k, j) => {
                    k.questions[0].selectedValues.forEach((a, b) => {
                        let index2 = data2.findIndex((k) => { return k.id === i.topic2 && k.section === this.getSection(j, b) })
                        if (index2 === -1) {
                            data2.push({ id: i.topic2, section: this.getSection(j, b), count: parseFloat(a) === 5 ? 1 : 0, total: 1 })
                        } else {
                            data2[index2].count = data2[index2].count + (parseFloat(a) === 5 ? 1 : 0)
                            data2[index2].total = data2[index2].total + 1
                        }

                    })
                })
            }

        })
        data.unshift({ section: '#', overall: this.state.response.length, actual: filteredData.length, count: 0 })
        // data.unshift({ id: 0, section: 'No of Response', overall: this.state.response.length, selected: filteredData.length })
        let groupData = this.groupArrayObject(data, 'section')
        let final = []
        Object.keys(groupData).forEach((key) => {
            if (key.includes(keys) || key.includes('#')) {
                // [parseFloat(key.match(/\d+/)[0])-1]: 
                final.push(groupData[key])
            }
        })

        let resultColumn = [
            {
                dataField: '0',
                text: 'Item',
                sort: false,
                formatter: (cell, row) => {

                    return (
                        <>{cell.section === '#' ? 'No of Response' : cell.section} </>
                    )
                }
            },
            {
                dataField: '0',
                text: 'Overall of ' + this.state.selected1.label,
                sort: false,
                formatter: (cell, row) => {
                    let r = data2.filter((i) => { return i.section === cell.section }).reduce((a, b) => a + b.count, 0)


                    return (
                        <>{cell.section === '#' ? this.state.response.length : ((r / this.state.response.length) * 100).toFixed(2) + `%`} </>
                    )
                }
            },
            , {
                dataField: '0',
                text: this.state.selectedtier.t1.label,
                sort: false,
                formatter: (cell, row) => {
                    let filter = data.filter((i) => { return i.section === cell.section })
                    let r = filter.reduce((a, b) => a + b.count, 0)


                    return (
                        <>{cell.section === '#' ? filteredData.length : ((r / filteredData.length) * 100).toFixed(2) + `% (${r})`}  </>
                    )
                }
            }
        ]

        this.state.report.departments.forEach((dep, index) => {

            resultColumn.push({
                dataField: '0',
                text: dep.title,
                sort: false,
                formatter: (cell, row) => {
                    let filter = data.filter((i) => { return i.section === cell.section && i.id === dep.name })
                    let r = filter.reduce((a, b) => a + b.count, 0)


                    return (
                        <>{cell.section === '#' ? dep.count : ((r / dep.count) * 100).toFixed(2) + `% (${r})`} </>
                    )
                }
            })
        })



        return { data: final, columns: resultColumn }
    }
    getMergedSection(array) {
        let mergedObject = array.reduce((result, obj) => {
            const { id, section, count, total } = obj;

            if (!result[section]) {
                result[section] = {};
            }

            result[section][id] = { count, total };

            return result;
        }, {});
        return mergedObject
    }
    getDataTable(keys, index) {

        let filteredData = this.state.response.filter((i) => { return (i.topic1 === this.state.selectedtier.t1.value || this.state.selectedtier.t1.value === 0) });
        let data = [], data2 = [], section = ['Item', 'Whole of ' + this.state.selected1.label, this.state.selectedtier.t1.label]
        filteredData.forEach((i) => {
            let index = data.findIndex((k) => { return k.id === i.topic2 })
            !section.includes(i.topic2) && section.push(i.topic2)
            if (index !== -1) {
                i.input_json.forEach((k, j) => {
                    k.questions[0].selectedValues.forEach((a, b) => {
                        let index2 = data.findIndex((k) => { return k.id === i.topic2 && k.section === this.getSection(j, b) })
                        if (index2 === -1) {
                            data.push({ id: i.topic2, section: this.getSection(j, b), count: parseFloat(a) === 5 ? 1 : 0, total: 1 })
                        } else {

                            data[index2].count = data[index2].count + (parseFloat(a) === 5 ? 1 : 0)
                            data[index2].total = data[index2].total + 1
                        }

                    })
                })

            } else {
                i.input_json.forEach((k, j) => {
                    k.questions[0].selectedValues.forEach((a, b) => {
                        let index2 = data.findIndex((k) => { return k.id === i.topic2 && k.section === this.getSection(j, b) })
                        if (index2 === -1) {
                            data.push({ id: i.topic2, section: this.getSection(j, b), count: parseFloat(a) === 5 ? 1 : 0, total: 1 })
                        } else {
                            data[index2].count = data[index2].count + (parseFloat(a) === 5 ? 1 : 0)
                            data[index2].total = data[index2].total + 1
                        }

                    })
                })
            }

        })
        this.state.response.forEach((i) => {
            let index = data2.findIndex((k) => { return k.id === i.topic2 })
            if (index !== -1) {
                i.input_json.forEach((k, j) => {
                    k.questions[0].selectedValues.forEach((a, b) => {
                        let index2 = data2.findIndex((k) => { return k.id === i.topic2 && k.section === this.getSection(j, b) })
                        if (index2 === -1) {
                            data2.push({ id: i.topic2, section: this.getSection(j, b), count: parseFloat(a) === 5 ? 1 : 0, total: 1 })
                        } else {

                            data2[index2].count = data2[index2].count + (parseFloat(a) === 5 ? 1 : 0)
                            data2[index2].total = data2[index2].total + 1
                        }

                    })
                })

            } else {
                i.input_json.forEach((k, j) => {
                    k.questions[0].selectedValues.forEach((a, b) => {
                        let index2 = data2.findIndex((k) => { return k.id === i.topic2 && k.section === this.getSection(j, b) })
                        if (index2 === -1) {
                            data2.push({ id: i.topic2, section: this.getSection(j, b), count: parseFloat(a) === 5 ? 1 : 0, total: 1 })
                        } else {
                            data2[index2].count = data2[index2].count + (parseFloat(a) === 5 ? 1 : 0)
                            data2[index2].total = data2[index2].total + 1
                        }

                    })
                })
            }

        })
        // data.unshift({ section: '#', overall: this.state.response.length, actual: filteredData.length, count: 0 })
        // data.unshift({ id: 0, section: 'No of Response', overall: this.state.response.length, selected: filteredData.length })
        let groupData = this.groupArrayObject(data, 'section')
        let groupData2 = this.groupArrayObject(data2, 'section')
        let final = [], finalResult = [{ '#': {} }]
        Object.keys(groupData).forEach((key) => {
            if (key.includes(keys) || key.includes('#')) {
                // [parseFloat(key.match(/\d+/)[0])-1]: 
                final.push(groupData[key])
            }
        })
        Object.values(final).forEach((k) => {
            finalResult.push(this.getMergedSection(k))
        })

        this.state.report.departments.map((dep) => {

            finalResult[0]["#"][dep.name] = { count: dep.count }
        })
        finalResult.forEach((i) => {

            Object.keys(i).forEach((item) => {
                if (item !== '#') {
                    i[item]['a'] = data2.filter((i) => { return i.section === item }).reduce((a, b) => a + b.count, 0)
                    i[item]['b'] = data.filter((i) => { return i.section === item }).reduce((a, b) => a + b.count, 0)
                } else {
                    i[item]['a'] = this.state.response.length
                    i[item]['b'] = filteredData.length
                }

            })
        })

        return (
            <DataTable value={finalResult} id={"section" + index} header={"Department Level Scores (Always True responses)"}
                showGridlines
                ref={this.tableRefs[index]}
                responsiveLayout="scroll"
            >
                <Column header={'Item'} body={(rowData) => { return <> {Object.keys(rowData)[0] === '#' ? 'No. of Responses' : Object.keys(rowData)}</> }} />
                <Column header={'Overall of ' + this.state.selected1.label} body={(rowData) => { let percent = ((Object.values(rowData)[0].a / this.state.response.length) * 100).toFixed(2); return <> {Object.keys(rowData)[0] !== '#' ? `${percent}%` : Object.values(rowData)[0].a}</> }} />
                <Column header={this.state.topictitle[0] + '-' + this.state.selectedtier.t1.label} body={(rowData) => { let total = data.filter((i) => { return i.section === Object.keys(rowData)[0] }).reduce((a, b) => a + b.count, 0); let percent = ((total / filteredData.length) * 100).toFixed(2); return <> {Object.keys(rowData)[0] !== '#' ? `${percent} %  (${total})` : Object.values(rowData)[0].b}</> }} />
                {this.state.report.departments.map((dep) => {
                    return (
                        <Column header={dep.title} body={(rowData) => { let total = ((Object.values(rowData)[0][dep.name].count / Object.values(rowData)[0][dep.name].total) * 100).toFixed(2); return <span > {Object.keys(rowData)[0] !== '#' && `${total} %`} ({Object.values(rowData)[0][dep.name].count})</span> }} />
                    )
                })

                }
            </DataTable>
        )
    }
    render() {


        return (
            <div id="test" className=" primary-color1">

                {this.state.Loader ? (
                    <div>
                        <div
                            className="row mb-5 primary-color1"
                            style={{ zIndex: 2, position: "relative" }}
                        >
                            <div className="col-md-4 mb-3">
                                <h4>Select Enterprise</h4>
                                <div className="card">
                                    <Select

                                        value={this.state.selected1}
                                        onChange={this.handleChange1}
                                        options={this.state.moss}
                                    />
                                </div>
                            </div>

                            {this.state.assessment.length !== 0 && (
                                <div className=" col-md-4 mb-3">
                                    <h4>Select Survey</h4>

                                    <div className="card">
                                        <Select

                                            value={this.state.selected}
                                            onChange={this.handleChange}
                                            options={this.state.assessment}
                                        />
                                    </div>
                                </div>
                            )}
                            {this.state.selectedtierlist.t1.length !== 0 && (
                                <div className="col-md-4 mb-3">
                                    <h4>
                                        Select {this.state.topictitle[0]}

                                    </h4>
                                    <div className="card">
                                        <Select
                                            isDisabled={this.state.spinner}
                                            value={this.state.selectedtier.t1}
                                            onChange={(e) => this.handleTierChange(e)}
                                            options={this.state.selectedtierlist.t1}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>

                        {this.state.totalSurvey !== 0 && (
                            <div>

                            </div>

                        )}

                        {this.state.selected1.label === undefined && (
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                Select Company from the list
                            </div>
                        )}
                        {this.state.selected1.label !== undefined &&
                            this.state.assessment.length === 0 && (
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    No survey configured for {this.state.selected1.label}
                                </div>
                            )}
                        {this.state.assessment.length !== 0 &&
                            this.state.selected.label === undefined && (
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    Select survey from the list
                                </div>
                            )}
                        {this.state.selected.label !== undefined && this.state.selectedtier.t1.label === undefined && !this.state.spinner &&
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                Select {this.state.topictitle[0]} from the list
                            </div>
                        }

                        {!this.state.spinner ?
                            <>

                                <div className="col-12" style={{ display: 'flex' }}>
                                    {this.state.report.departments.length !== 0 &&
                                        <>

                                            <div className="col-6" style={{ display: 'flex', flexDirection: 'column' }} >

                                                <div
                                                    className="d-sm-flex justify-content-center align-items-center mb-4"
                                                    style={{
                                                        background: "rgb(19, 21, 51)",
                                                        borderRadius: 5,
                                                        padding: 6,
                                                    }}
                                                >
                                                    <h4 className="text-white font-weight-bold m-0">
                                                        Departments Surveyed & Responses
                                                    </h4>
                                                </div>
                                                <div className="card chartcard" style={{ padding: '10px' }}>
                                                    {this.state.report.departments.map((dep, di) => {

                                                        return (
                                                            <label>
                                                                {di + 1}.  {dep.title} &nbsp; ( {dep.count} )
                                                            </label>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </>}
                                    {this.state.report.roles.length !== 0 &&
                                        <>

                                            <div className="col-6" style={{ display: 'flex', flexDirection: 'column' }} >
                                                <div
                                                    className="d-sm-flex justify-content-center align-items-center mb-4"
                                                    style={{
                                                        background: "rgb(19, 21, 51)",
                                                        borderRadius: 5,
                                                        padding: 6, position: 'relative'
                                                    }}
                                                >
                                                    <h4 className="text-white font-weight-bold m-0">
                                                        Participants by Job Role
                                                        <div
                                                            style={{
                                                                position: "absolute",
                                                                top: 5,
                                                                right: 7,
                                                            }}
                                                            onClick={() => {
                                                                this.downloadGraph('111')
                                                            }}
                                                        >
                                                            <i className="material-icons" style={{ cursor: "pointer" }}>
                                                                download_for_offline
                                                            </i>
                                                        </div>
                                                    </h4>
                                                </div>
                                                <div id="111" className="card chartcard" style={{
                                                    padding: '15px',
                                                    height: '100%',
                                                    justifyContent: 'center'
                                                }}>
                                                    <Pie  {...this.getPieChartOption(this.state.report.roles)} />
                                                </div>

                                            </div>
                                        </>}
                                </div>
                                {Object.keys(this.state.selectedtier.t1).length !== 0 &&
                                    <div className="col-12" style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div
                                            className="row col-12 filter "
                                            style={{ justifyContent: "space-between", marginTop: 20 }}
                                        >
                                            <div className="col-md-6 primary-color1">
                                                <h4>Select Characteristic</h4>
                                                <div className="card">
                                                    <Select
                                                        placeholder={"Characteristic"}
                                                        isSearchable
                                                        value={{ label: this.state.attributesValue }}
                                                        onChange={(value) => this.attributesValue(value.label)}
                                                        defaultValue={{ value: "All", label: "All" }}
                                                        options={this.state.characteristic}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            className="col-12  "
                                            style={{ justifyContent: "space-between", marginTop: 20 }}
                                        >{
                                                Object.keys(this.state.questionlist).map((key, ind) => {

                                                    if (this.state.attributesValue === 'All' || this.state.attributesValue === this.state.questionlist[key][0].label) {
                                                        return (
                                                            <>
                                                                <div
                                                                    className="d-sm-flex justify-content-center align-items-center mb-4"
                                                                    style={{
                                                                        background: "rgb(19, 21, 51)",
                                                                        borderRadius: 5,
                                                                        padding: 6, position: 'relative'
                                                                    }}
                                                                >
                                                                    <h4 className="text-white font-weight-bold m-0">
                                                                        {key}

                                                                        <div
                                                                            style={{
                                                                                position: "absolute",
                                                                                top: 5,
                                                                                right: 7,
                                                                            }}
                                                                            onClick={() => {
                                                                                this.downloadAll(this.getLabel(ind), this.state.questionlist[key], ind)
                                                                            }}
                                                                        >
                                                                            <i className="material-icons" style={{ cursor: "pointer" }}>
                                                                                download_for_offline
                                                                            </i>
                                                                        </div>
                                                                    </h4>
                                                                </div>
                                                                <div>
                                                                    {this.state.questionlist[key].map((q, ind1) => {
                                                                        return (
                                                                            <div>
                                                                                <div id={this.getLabel(ind) + ind1}
                                                                                    className="card chartcard " style={{ marginBottom: 10, padding: 15, position: 'relative' }} >
                                                                                    <h2 className="card-title ques-title">
                                                                                        {q.question}
                                                                                    </h2>

                                                                                    <Bar   {...this.getGroupChartDataOptions(ind, ind1, q, this.getLabel(ind) + 'div' + ind1)} />
                                                                                    {/* <div id={this.getLabel(ind) + 'div' + ind1} style={{
                                                                                        position: 'absolute',
                                                                                        left: '79px',
                                                                                        cursor: 'pointer',
                                                                                        bottom: '22px',
                                                                                        display: 'flex',
                                                                                        flexDirection: 'row',
                                                                                        alignItems: 'center',
                                                                                        color: 'darkolivegreen',
                                                                                        fontSize: '14px'
                                                                                    }}  >
                                                                                        <div style={{ width: 40, height: 12, marginRight: 5, backgroundColor: '#06D9F0' }} ></div> Average </div> */}
                                                                                </div>

                                                                                <div style={{ marginBottom: 10 }} >
                                                                                    {ind1 === this.state.questionlist[key].length - 1 &&
                                                                                        <div >
                                                                                            {this.getDataTable(this.getLabel(ind), ind)}

                                                                                        </div>


                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                })
                                            }

                                        </div >
                                    </div>}
                            </>
                            :
                            <MutatingDots
                                height="100"
                                width="100"
                                color="#00D8F0"
                                secondaryColor='rgb(19, 21, 51)'
                                radius='12.5'
                                ariaLabel="mutating-dots-loading"
                                wrapperStyle={{ justifyContent: 'center' }}
                                wrapperClass=""
                                visible={true}
                            />

                        }
                    </div>
                ) : (
                    <div >Check internet or contact admin team</div>
                )} </div>
        )
    }

}
export default addittionalReport;