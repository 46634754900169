import React, { Component } from "react";
import { Button, ProgressBar } from "react-bootstrap";
import { Dropdown, Tabs, Tab } from "react-bootstrap";
import { MutatingDots } from 'react-loader-spinner';
import {
    Line,
    Bar,
    Doughnut,
    HorizontalBar,
    Pie,
    Radar,
} from "react-chartjs-2";
import {
    API_URL,
    NESTED_SUBTOPIC_URL,
    SUBTOPICS_URL,
    MOSS_USER,
    MOSS_TITLE,
    TOPICS_URL,
    NESTED_TIER_THREE_URL,
    NESTED_TIER_FOUR_URL,
    TIER_THREE_URL,
    TIER_FOUR_URL,
} from "../constants";
import { responseData } from "./response";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "../App.scss";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Select from "react-select";
import axios from "axios";
import API from "../api";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import DatePicker from "react-datepicker";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import "react-datepicker/dist/react-datepicker.css";
import { Chart } from "chart.js";
import exportAsImage from "./exportAsImage";
import Swal from "sweetalert2";
const { SearchBar } = Search;
const defaultSorted = [
    {
        dataField: "id",
        order: "desc",
    },
];
const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: "1px dotted pink",
        color: state.isSelected ? "red" : "blue",
        padding: "5px 5px 5px 20px",
    }),
    // control: () => ({
    //   // none of react-select's styles are passed to <Control />
    //   width: 200,
    // }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = "opacity 300ms";

        return { ...provided, opacity, transition };
    },
};
export class DashboardTwo extends Component {
    constructor(props) {
        super(props);
        this.user = [];
        this.sectionList = [];

        this.quesyaxisstepsize = 0;
        this.state = {
            company: [],
            spinner: false,
            questionChartMaxSize: 0,
            usertopic1: [],
            usertopic2: [],
            role: "",
            selectedRole: "",
            assessment: [],
            selected1: "",
            activeDownload: "",
            characteristic: [],
            response: [],
            // chartcolors: ['#131533', '#0F385C', '#005E80', '#00879C', '#33B0AF', '#74D9BA'],
            chartcolors: [
                "#bed8d4",
                "#78d5d7",
                "#63d2ff",
                "#2081c3",
                "#33B0AF",
                "#005E80",
            ],
            tierresponsecount: [],
            tiercountbytopic: [],
            selectedtier: { t1: {}, t2: {}, t3: {}, t4: {} }, // selected tier from filter
            sectionchart: { a: [], b: [], c: [], d: [], e: [], f: [] }, // Average per question by categorywise
            questionChart: { a: [], b: [], c: [], d: [], e: [], f: [] }, // sum of each question
            questionlist: [], // List of questions
            section: [], // category wise average value for radar chart
            radargraph: [], // datasets by section values
            radargraph2: [], // datasets by section values for png default hidden
            tierlist: [], // unique tier by response
            selectedtierlist: { t1: [], t2: [], t3: [], t4: [] }, // selected tier filter list
            topictitle: [], // tier title list
            question: [], // total by section
            moss: [],
            selected: [],
            data: [],
            totalSurvey: 0,
            country: [],
            countryValue: "All",
            factoryValue: "All",
            groupValue: "All",
            typeValue: "All",
            jobValue: "All",
            attributesValue: "All",
            Loader: true,
            show: true,
            circle1: 60,
            circle2: 60,
            circle3: 70,
            maturity: 3,
            overall: 169,
            splice: [],
            PieData1: {
                datasets: [
                    {
                        data: [28, 27, 15, 20, 10],
                        backgroundColor: [
                            "#005100",
                            "#4F9F3F",
                            "#fbb11c",
                            "#C8442E",
                            "#940C07",
                        ],
                        borderWidth: 0,
                        label: "My dataset", // for legend
                    },
                ],
                labels: [
                    "Strongly Agree-28",
                    "Agree-27",
                    "Neutral-15",
                    "Disagree-20",
                    "Strongly Disagree-10",
                ],
            },
            datax: {
                // labels: ["Leadership", "Process", "People"],
                datasets: [
                    {
                        label: "Combined",
                        backgroundColor: "#a461d8",
                        data: [3],
                        borderWidth: 1,
                        fill: false,
                    },
                    {
                        label: "Manager/Supervisor",
                        backgroundColor: "#fc5a5a",
                        data: [4],
                        borderWidth: 1,
                        fill: false,
                    },
                    {
                        label: "Worker",
                        backgroundColor: "#197AFA",
                        data: [1],
                        borderWidth: 1,
                        fill: false,
                    },
                ],
            },
            factory1: {
                labels: ["Q1"],
                datasets: [
                    {
                        label: ["L1"],
                        data: [10],
                        backgroundColor: ["#a461d8"],
                        borderWidth: 1,
                        fill: false,
                    },
                    {
                        label: ["L2"],
                        data: [20],
                        backgroundColor: ["#fc5a5a"],
                        borderWidth: 1,
                        fill: false,
                    },
                    {
                        label: ["L3"],
                        data: [70],
                        backgroundColor: ["#197AFA"],
                        borderWidth: 1,
                        fill: false,
                    },
                    {
                        label: ["L4"],
                        data: [40],
                        backgroundColor: ["#796AD2"],
                        borderWidth: 1,
                        fill: false,
                    },
                    {
                        label: ["L5"],
                        data: [30],
                        backgroundColor: ["#44CE42"],
                        borderWidth: 1,
                        fill: false,
                    },
                ],
            },
            factoryopt: {
                tooltips: {
                    enabled: false,
                },
                responsive: true,

                legend: {
                    display: true,
                    position: "bottom",
                    fullWidth: false,
                    labels: {
                        boxWidth: 10,
                        padding: 5,
                    },
                },

                scales: {
                    yAxes: [
                        {
                            categoryPercentage: 1,
                            barPercentage: 0.7,
                            gridLines: {
                                display: true,
                                drawTicks: true,
                                drawOnChartArea: false,
                            },

                            ticks: {
                                min: 0,
                                fontColor: "#555759",
                                display: false,
                                fontSize: 12,
                            },
                        },
                    ],
                    xAxes: [
                        {
                            gridLines: {
                                display: true,
                                drawTicks: false,
                                tickMarkLength: 5,
                                drawBorder: false,
                            },
                            ticks: {
                                stepSize: 10,
                                padding: 5,
                                beginAtZero: true,
                                max: 100,

                                min: 0,
                                fontColor: "#555759",

                                fontSize: 11,
                                callback: function (label, index, labels) {
                                    return label;
                                },
                            },
                            scaleLabel: {
                                display: true,
                                padding: 5,

                                fontColor: "#555759",
                                fontSize: 16,
                                fontStyle: 700,
                                // labelString: 'L2'
                            },
                        },
                    ],
                },
            },
            bar1: {
                labels: ["Q1"],
                datasets: [
                    {
                        label: ["Combined"],
                        data: [3],
                        backgroundColor: ["#a461d8"],
                        borderWidth: 1,
                        fill: false,
                    },
                    {
                        label: ["Manager/Supervisor"],
                        data: [5],
                        backgroundColor: ["#fc5a5a"],
                        borderWidth: 1,
                        fill: false,
                    },
                    {
                        label: ["Worker"],
                        data: [1],
                        backgroundColor: ["#197AFA"],
                        borderWidth: 1,
                        fill: false,
                    },
                ],
            },
            data1: {
                labels: ["L1", "L2", "L3", "L4", "L5", "L6", "L7"],
                datasets: [
                    {
                        label: "",

                        data: [5, 8, 7, 6, 9, 10, 6],
                        backgroundColor: [
                            "rgb(255, 153, 0)",
                            "rgb(255, 153, 0)",
                            "rgb(255, 153, 0)",
                            "rgb(255, 153, 0)",
                            "rgb(255, 153, 0)",
                            "rgb(255, 153, 0)",
                            "rgb(255, 153, 0)",
                        ],
                    },
                ],
            },
            data2: {
                labels: [
                    "PR1",
                    "PR2",
                    "PR3",
                    "PR4",
                    "PR5",
                    "PR6",
                    "PR7",
                    "PR8",
                    "PR9",
                    "PE10",
                ],
                datasets: [
                    {
                        label: "Process",
                        data: [8, 7, 8, 7, 5, 10, 6, 5, 4, 7],
                        backgroundColor: [
                            "rgb(141, 180, 226)",
                            "rgb(141, 180, 226)",
                            "rgb(141, 180, 226)",
                            "rgb(141, 180, 226)",
                            "rgb(141, 180, 226)",
                            "rgb(141, 180, 226)",
                            "rgb(141, 180, 226)",
                            "rgb(141, 180, 226)",
                            "rgb(141, 180, 226)",
                            "rgb(141, 180, 226)",
                        ],
                    },
                ],
            },
            data3: {
                labels: [
                    "PE1",
                    "PE2",
                    "PE3",
                    "PE4",
                    "PE5",
                    "PE6",
                    "PE7",
                    "PE8",
                    "PE9",
                    "PE10",
                ],
                datasets: [
                    {
                        labels: [
                            "A1",
                            "A2",
                            "A3",
                            "A4",
                            "A5",
                            "A6",
                            "A7",
                            "A8",
                            "A9",
                            "A10",
                        ],

                        data: [6, 7, 10, 8, 5, 7, 5, 8, 9, 3],
                        backgroundColor: [
                            "rgb(146, 208, 80)",
                            "rgb(146, 208, 80)",
                            "rgb(146, 208, 80)",
                            "rgb(146, 208, 80)",
                            "rgb(146, 208, 80)",
                            "rgb(146, 208, 80)",
                            "rgb(146, 208, 80)",
                            "rgb(146, 208, 80)",
                            "rgb(146, 208, 80)",
                            "rgb(146, 208, 80)",
                        ],

                        borderWidth: 1,
                    },
                ],
            },
            data4: {
                labels: ["L1", "L2", "L3", "L4", "L5", "L6", "L7"],
                datasets: [
                    {
                        label: "",

                        data: [3, 5, 7, 6, 8, 7, 5],
                        backgroundColor: [
                            "#007d79",
                            "#007d79",
                            "#007d79",
                            "#007d79",
                            "#007d79",
                            "#007d79",
                            "#007d79",
                            "#007d79",
                        ],
                    },
                ],
            },
            data5: {
                labels: [
                    "PR1",
                    "PR2",
                    "PR3",
                    "PR4",
                    "PR5",
                    "PR6",
                    "PR7",
                    "PR8",
                    "PR9",
                    "PE10",
                ],
                datasets: [
                    {
                        label: "Process",
                        data: [5, 6, 8, 7, 5, 4, 3, 5, 4, 2],
                        backgroundColor: [
                            "rgb(141, 180, 226)",
                            "rgb(141, 180, 226)",
                            "rgb(141, 180, 226)",
                            "rgb(141, 180, 226)",
                            "rgb(141, 180, 226)",
                            "rgb(141, 180, 226)",
                            "rgb(141, 180, 226)",
                            "rgb(141, 180, 226)",
                            "rgb(141, 180, 226)",
                            "rgb(141, 180, 226)",
                        ],
                    },
                ],
            },
            data6: {
                labels: [
                    "PE1",
                    "PE2",
                    "PE3",
                    "PE4",
                    "PE5",
                    "PE6",
                    "PE7",
                    "PE8",
                    "PE9",
                    "PE10",
                ],
                datasets: [
                    {
                        labels: [
                            "A1",
                            "A2",
                            "A3",
                            "A4",
                            "A5",
                            "A6",
                            "A7",
                            "A8",
                            "A9",
                            "A10",
                        ],

                        data: [5, 7, 5, 8, 5, 7, 5, 8, 4, 3],
                        backgroundColor: [
                            "rgb(146, 208, 80)",
                            "rgb(146, 208, 80)",
                            "rgb(146, 208, 80)",
                            "rgb(146, 208, 80)",
                            "rgb(146, 208, 80)",
                            "rgb(146, 208, 80)",
                            "rgb(146, 208, 80)",
                            "rgb(146, 208, 80)",
                            "rgb(146, 208, 80)",
                            "rgb(146, 208, 80)",
                        ],

                        borderWidth: 1,
                    },
                ],
            },
        };

        this.columns = [
            {
                dataField: "country",
                text: "Country",
                sort: true,
            },
            {
                dataField: "factory_group",
                text: " Factory Group",
                sort: true,
            },
            {
                dataField: "factory_no",
                text: "Factory Code",
                sort: true,
            },
            {
                dataField: "value",
                text: "Level",
                sort: true,
            },
        ];
        this.country = [];
        this.factory_type = [];
        this.factory_group = [];
        this.factory = [];
        this.nested = [];
        this.nested1 = [];
    }

    componentWillMount() {
        if (!localStorage.getItem("token")) {
            this.props.history.push("/");
        }

        let formData = new FormData();
        formData.append("userid", localStorage.getItem("token"));

        // https://api.acuizen.com/question-titles?filter={"include":[{"relation":"questions"}]}

        axios({
            method: "get",
            url: API.QuestionWithTitle,

            headers: {
                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            },
            mode: "no-cors",
        }).then((res) => {

            if (res.data.length !== 0) {
                let sectionName = [{ value: "All", label: "All" }];
                let questionlist = [];

                res.data.forEach((i) => {
                    let questionArray = JSON.parse(i.questions[0].value);
                    questionArray.forEach((k) => {
                        questionlist.push(
                            k.label
                                .replace(/(<([^>]+)>)/gi, "")
                                .replace(/\n/g, " ")
                                .replace(/&nbsp;/g, " ")
                        );
                    });
                    if (i.lang === "english") {

                        sectionName.push({ value: i.id, label: i.name, count: JSON.parse(i.questions[0].value).length });
                    }
                });
                this.sectionList = sectionName;

                this.setState({ characteristic: sectionName, questionlist });
            }
        });

        axios({
            method: "get",
            url: "https://api.acuizen.com/companies",

            headers: {
                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            },
            mode: "no-cors",
        }).then((res) => {
            let check = {};
            if (res.data.length !== 0) {
                res.data.forEach((k) => {
                    if (k.roles.length !== 0 && Object.keys(check).length === 0) {
                        check = { value: k.id, label: k.company_name };
                    }
                });
            }

            if (Object.keys(check).length !== 0) {

                // this.showDashboard(check);
            }

            res.data.forEach((element) => {
                this.user.push({ value: element.id, label: element.company_name });
            });

            this.setState({
                moss: this.user

            });
        });
    }

    freezeFilter = () => {
        let stickyElem = document.querySelector(".sticky-div");
        let currStickyPos =
            stickyElem.getBoundingClientRect().top + window.pageYOffset;
        window.onscroll = function () {
            /* Check if the current Y offset
            is greater than the position of
            the element */
            if (window.pageYOffset > currStickyPos) {
                stickyElem.style.position = "fixed";
                stickyElem.style.top = "58px";

                stickyElem.style.width =
                    document.getElementById("divsize").clientWidth + "px";
                stickyElem.style.background = "white";
                stickyElem.style.zIndex = 999;
            } else {
                stickyElem.style.position = "relative";
                stickyElem.style.top = "initial";
                stickyElem.style.width = "100%";
                stickyElem.style.background = "transparent";
                stickyElem.style.zIndex = 1;
            }
        };
    };

    data1 = {
        labels: ["L1", "L2", "L3", "L4", "L5", "L6", "L7"],
        datasets: [
            {
                label: "",

                data: [5, 8, 7, 6, 9, 10, 6],
                backgroundColor: [
                    "rgb(255, 153, 0)",
                    "rgb(255, 153, 0)",
                    "rgb(255, 153, 0)",
                    "rgb(255, 153, 0)",
                    "rgb(255, 153, 0)",
                    "rgb(255, 153, 0)",
                    "rgb(255, 153, 0)",
                ],
                // borderColor: [
                // 'rgba(255, 165, 0, 0.8)',
                // 'rgba(255, 165, 0, 0.8)',
                // 'rgba(255, 165, 0, 0.8)',
                // 'rgba(255, 165, 0, 0.8)',
                // 'rgba(255, 165, 0, 0.8)',
                // 'rgba(255, 165, 0, 0.8)',
                // 'rgba(255, 165, 0, 0.8)',
                // ],
                // borderWidth: 1
            },
        ],
    };
    mycustomtooltipfunction(tooltip) {
        //  Here i want access to the virtual tooltip element and a way to add html to the body of the tooltip.
    }
    options1 = {
        animation: {
            duration: 1,
            onProgress: function () {
                var chartInstance = this.chart,
                    ctx = chartInstance.ctx;

                ctx.textAlign = "center";
                ctx.fontSize = "12px";
                ctx.textBaseline = "bottom";

                this.data.datasets.forEach(function (dataset, i) {
                    var meta = chartInstance.controller.getDatasetMeta(i);
                    meta.data.forEach(function (bar, index) {
                        var data = dataset.data[index];
                        if (data >= 90) {
                            ctx.fillStyle = "white";
                        } else {
                            ctx.fillStyle = "black";
                        }
                        ctx.fillText(
                            data + ' %',
                            bar._model.x,
                            data >= 90 ? bar._model.y - -20 : bar._model.y
                        );
                    });
                });
            },
        },
        hover: {
            mode: false,
        },
        tooltips: {
            displayColors: false,
            legend: false,
            callbacks: {
                //   afterBody: function(tti,data) {
                //     console.log(tti,data)
                //     var multistringText = ['first string'];
                //     // do some stuff
                //     multistringText.push('another string');

                //     return multistringText;
                // }
                label: function (tooltipItem, data) {
                    let str1 = [
                        data.datasets[0].label[tooltipItem.index].substr(
                            0,
                            data.datasets[0].label[tooltipItem.index].length / 2
                        ),
                    ];

                    str1.push(
                        data.datasets[0].label[tooltipItem.index].substr(
                            data.datasets[0].label[tooltipItem.index].length / 2,
                            data.datasets[0].label[tooltipItem.index].length
                        )
                    );

                    return str1;
                },
                title: function (tooltipItem, data) {
                    return;
                },
            },

            // enabled: true,
            // custom: this.mycustomtooltipfunction
        },
        legend: false,
        scales: {
            xAxes: [
                {
                    gridLines: {
                        display: false,
                    },
                    display: true,
                    scaleLabel: {
                        display: true,
                        labelString: "Question",
                    },
                },
            ],
            yAxes: [
                {
                    gridLines: {
                        display: false,
                    },
                    display: true,
                    scaleLabel: {
                        display: true,
                        labelString: "Score %",
                    },
                    ticks: {
                        beginAtZero: true,
                        stepSize: 100,
                        steps: 1,
                        stepValue: 1,
                        max: 100,
                    },
                },
            ],
        },
    };
    questionOption() {
        let data = {
            animation: {
                duration: 1,
                onProgress: function () {
                    var chartInstance = this.chart,
                        ctx = chartInstance.ctx;

                    ctx.textAlign = "center";
                    ctx.fontSize = "12px";
                    ctx.textBaseline = "bottom";

                    this.data.datasets.forEach(function (dataset, i) {
                        var meta = chartInstance.controller.getDatasetMeta(i);

                        meta.data.forEach(function (bar, index) {
                            var data = dataset.data[index];

                            let totalres = dataset.data.reduce((a, b) => {
                                return a + b;
                            });
                            // let per = Math.round(((data / totalres) * 100) * 10) / 10
                            let per = Math.round((data / totalres) * 100);

                            ctx.fillText(chartInstance.controller.controller.config.data.datasets[0].rawdata[index] + ` (${data} %) `, bar._model.x, bar._model.y);
                        });
                    });
                },
            },
            tooltips: {
                displayColors: false,

                enabled: false,
                // custom: this.mycustomtooltipfunction
            },
            responsive: true,
            maintainAspectRatio: true,
            legend: false,
            scales: {
                xAxes: [
                    {
                        gridLines: {
                            display: false,
                        },
                        display: true,
                        scaleLabel: {
                            display: false,
                            labelString: "Response category",
                        },
                    },
                ],
                yAxes: [
                    {
                        gridLines: {
                            display: false,
                        },
                        display: false,
                        scaleLabel: {
                            display: false,
                            labelString: "# of Responses",
                        },
                        ticks: {
                            beginAtZero: true,
                            max: 100,

                            stepSize: 100,
                        },
                    },
                ],
            },
        };
        return data;
    }
    questionOptions = {
        animation: {
            duration: 1,
            onProgress: function () {
                var chartInstance = this.chart,
                    ctx = chartInstance.ctx;

                ctx.textAlign = "center";
                ctx.fontSize = "12px";
                ctx.textBaseline = "bottom";

                this.data.datasets.forEach(function (dataset, i) {
                    var meta = chartInstance.controller.getDatasetMeta(i);
                    meta.data.forEach(function (bar, index) {
                        var data = dataset.data[index];

                        if (Math.max(...dataset.data) === data) {
                            ctx.fillStyle = "white";
                        } else {
                            ctx.fillStyle = "black";
                        }
                        ctx.fillText(
                            data,
                            bar._model.x,
                            data === Math.max(...dataset.data)
                                ? bar._model.y - -20
                                : bar._model.y
                        );
                    });
                });
            },
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    return data.datasets[0].label[tooltipItem.index];
                },
            },
            // enabled: true,
            // custom: this.mycustomtooltipfunction
        },
        legend: false,
        scales: {
            xAxes: [
                {
                    gridLines: {
                        display: false,
                    },
                    display: true,
                    scaleLabel: {
                        display: true,
                        labelString: "Response category",
                    },
                },
            ],
            yAxes: [
                {
                    gridLines: {
                        display: false,
                    },
                    display: false,
                    scaleLabel: {
                        display: true,
                        labelString: "# of Responses",
                    },
                    ticks: {
                        beginAtZero: true,
                        stepSize: this.quesyaxisstepsize,
                        steps: 1,
                        stepValue: 1,
                    },
                },
            ],
        },
    };
    setEndDate(date) {
        this.setState({
            endDate: date,
        });
    }
    setStartDate(date) {
        this.setState({
            startDate: date,
        });
    }
    removeDuplicates(data, key) {
        return [...new Map(data.map((item) => [key(item), item])).values()];
    }
    countryValue(value) {
        //     this.factory_type = []
        //     this.factory_group = []
        //     this.factory = []
        //     const jeepAutos = this.state.data.filter( (auto) => auto.country.includes(value))
        // jeepAutos.forEach(element => {
        //   this.factory.push({value:element.factory_name,label:element.factory_name})
        //   this.factory_group.push({value:element.factory_group,label:element.factory_group})
        //   this.factory_type.push({value:element.factory_type,label:element.factory_type})
        // });
        // this.factory=this.removeDuplicates(this.factory,item => item.value)
        // this.factory_type=this.removeDuplicates(this.factory_type,item => item.value)
        // this.factory_group=this.removeDuplicates(this.factory_group,item => item.value)
        this.setState({
            countryValue: value,
        });
    }
    factoryValue(value) {
        // this.country=[]
        // this.factory_type = []
        // this.factory_group = []
        //     const jeepAutos = this.state.data.filter( (auto) => auto.factory_name.includes(value))
        // jeepAutos.forEach(element => {
        //   this.country.push({value:element.country,label:element.country})
        //   this.factory_group.push({value:element.factory_group,label:element.factory_group})
        //   this.factory_type.push({value:element.factory_type,label:element.factory_type})
        // });
        // this.country=this.removeDuplicates(this.country,item => item.value)
        // this.factory_type=this.removeDuplicates(this.factory_type,item => item.value)
        // this.factory_group=this.removeDuplicates(this.factory_group,item => item.value)
        this.setState({
            factoryValue: value,
        });
    }
    typeValue(value) {
        //  this.country=[]
        // this.factory = []
        // this.factory_group = []
        //     const jeepAutos = this.state.data.filter( (auto) => auto.factory_name.includes(value))
        // jeepAutos.forEach(element => {
        //   this.country.push({value:element.country,label:element.country})
        //   this.factory_group.push({value:element.factory_group,label:element.factory_group})
        //   this.factory.push({value:element.factory_name,label:element.factory_name})
        // });
        // this.country=this.removeDuplicates(this.country,item => item.value)
        // this.factory=this.removeDuplicates(this.factory_name,item => item.value)
        // this.factory_group=this.removeDuplicates(this.factory_group,item => item.value)
        this.setState({
            typeValue: value,
        });
    }
    groupValue(value) {
        if (value == "Chang Shin") {
            this.setState({
                countryValue: "Vietnam",
            });
        } else if (value == "Ramatex") {
            this.setState({
                countryValue: "China",
            });
        } else {
            this.setState({
                countryValue: "All",
            });
        }
        //      this.country=[]
        //     this.factory = []
        //     this.factory_type = []
        //     const jeepAutos = this.state.data.filter( (auto) => auto.factory_name.includes(value))
        // jeepAutos.forEach(element => {
        //   this.country.push({value:element.country,label:element.country})
        //   this.factory_type.push({value:element.factory_type,label:element.factory_type})
        //   this.factory.push({value:element.factory_name,label:element.factory_name})
        // });
        // this.country=this.removeDuplicates(this.country,item => item.value)
        // this.factory=this.removeDuplicates(this.factory_name,item => item.value)
        // this.factory_type=this.removeDuplicates(this.factory_type,item => item.value)
        this.setState({
            groupValue: value,
        });
    }
    jobValue(value) {
        this.setState({
            jobValue: value,
        });
        if (value === "All") {
            this.setState({
                datax: {
                    datasets: [
                        {
                            label: "Combined",
                            backgroundColor: "#a461d8",
                            data: [3],
                            borderWidth: 1,
                            fill: false,
                        },
                        {
                            label: "Manager/Supervisor",
                            backgroundColor: "#fc5a5a",
                            data: [4],
                            borderWidth: 1,
                            fill: false,
                        },
                        {
                            label: "Worker",
                            backgroundColor: "#197AFA",
                            data: [2],
                            borderWidth: 1,
                            fill: false,
                        },
                    ],
                },
                bar1: {
                    labels: ["Q1"],
                    datasets: [
                        {
                            label: ["Combined"],
                            data: [3],
                            backgroundColor: ["#a461d8"],
                            borderWidth: 1,
                            fill: false,
                        },
                        {
                            label: ["Manager/Supervisor"],
                            data: [5],
                            backgroundColor: ["#fc5a5a"],
                            borderWidth: 1,
                            fill: false,
                        },
                        {
                            label: ["Worker"],
                            data: [1],
                            backgroundColor: ["#197AFA"],
                            borderWidth: 1,
                            fill: false,
                        },
                    ],
                },
            });
        } else if (value === "Worker") {
            this.setState({
                datax: {
                    datasets: [
                        {
                            label: "Combined",
                            backgroundColor: "#a461d8",
                            data: [0],
                            borderWidth: 1,
                            fill: false,
                        },
                        {
                            label: "Manager/Supervisor",
                            backgroundColor: "#fc5a5a",
                            data: [0],
                            borderWidth: 1,
                            fill: false,
                        },
                        {
                            label: "Worker",
                            backgroundColor: "#197AFA",
                            data: [1],
                            borderWidth: 1,
                            fill: false,
                        },
                    ],
                },
                bar1: {
                    labels: ["Q1"],
                    datasets: [
                        {
                            label: ["Combined"],
                            data: [0],
                            backgroundColor: ["#a461d8"],
                            borderWidth: 1,
                            fill: false,
                        },
                        {
                            label: ["Manager/Supervisor"],
                            data: [0],
                            backgroundColor: ["#fc5a5a"],
                            borderWidth: 1,
                            fill: false,
                        },
                        {
                            label: ["Worker"],
                            data: [7],
                            backgroundColor: ["#197AFA"],
                            borderWidth: 1,
                            fill: false,
                        },
                    ],
                },
            });
        } else if (value === "Manager/Supervisor") {
            this.setState({
                datax: {
                    datasets: [
                        {
                            label: "Combined",
                            backgroundColor: "#a461d8",
                            data: [0],
                            borderWidth: 1,
                            fill: false,
                        },
                        {
                            label: "Manager/Supervisor",
                            backgroundColor: "#fc5a5a",
                            data: [4],
                            borderWidth: 1,
                            fill: false,
                        },
                        {
                            label: "Worker",
                            backgroundColor: "#197AFA",
                            data: [0],
                            borderWidth: 1,
                            fill: false,
                        },
                    ],
                },
                bar1: {
                    labels: ["Q1"],
                    datasets: [
                        {
                            label: ["Combined"],
                            data: [0],
                            backgroundColor: ["#a461d8"],
                            borderWidth: 1,
                            fill: false,
                        },
                        {
                            label: ["Manager/Supervisor"],
                            data: [5],
                            backgroundColor: ["#fc5a5a"],
                            borderWidth: 1,
                            fill: false,
                        },
                        {
                            label: ["Worker"],
                            data: [0],
                            backgroundColor: ["#197AFA"],
                            borderWidth: 1,
                            fill: false,
                        },
                    ],
                },
            });
        }
    }
    attributesValue1(value) {
        if (value === "Worker") {
            this.setState({
                PieData1: {
                    datasets: [
                        {
                            data: [18, 17, 25, 10, 20],
                            backgroundColor: [
                                "#005100",
                                "#4F9F3F",
                                "#fbb11c",
                                "#C8442E",
                                "#940C07",
                            ],
                            borderWidth: 0,
                            label: "My dataset", // for legend
                        },
                    ],
                    labels: [
                        "Strongly Agree-18",
                        "Agree-17",
                        "Neutral-25",
                        "Disagree-10",
                        "Strongly Disagree-20",
                    ],
                },
            });
        } else if (value === "Manager/Supervisor") {
            this.setState({
                PieData1: {
                    datasets: [
                        {
                            data: [38, 37, 35, 10, 30],
                            backgroundColor: [
                                "#005100",
                                "#4F9F3F",
                                "#fbb11c",
                                "#C8442E",
                                "#940C07",
                            ],
                            borderWidth: 0,
                            label: "My dataset", // for legend
                        },
                    ],
                    labels: [
                        "Strongly Agree-38",
                        "Agree-37",
                        "Neutral-35",
                        "Disagree-10",
                        "Strongly Disagree-30",
                    ],
                },
            });
        } else if (value === "Combined") {
            this.setState({
                PieData1: {
                    datasets: [
                        {
                            data: [28, 27, 15, 20, 10],
                            backgroundColor: [
                                "#005100",
                                "#4F9F3F",
                                "#fbb11c",
                                "#C8442E",
                                "#940C07",
                            ],
                            borderWidth: 0,
                            label: "My dataset", // for legend
                        },
                    ],
                    labels: [
                        "Strongly Agree-28",
                        "Agree-27",
                        "Neutral-15",
                        "Disagree-20",
                        "Strongly Disagree-10",
                    ],
                },
            });
        }
    }
    attributesValue(value) {
        this.setState({
            attributesValue: value,
        });
    }
    search() {
        if (this.state.factoryValue !== "All") {
            this.setState({
                show: true,
            });
        }
        if (
            this.state.factoryValue === "VJ" &&
            this.state.jobValue === "All" &&
            this.state.attributesValue === "All"
        ) {
            this.setState({
                circle1: 60,
                circle2: 58,
                circle3: 70,
                maturity: 3,
                overall: 157,
                data1: {
                    labels: ["L1", "L2", "L3", "L4", "L5", "L6", "L7"],
                    datasets: [
                        {
                            label: "",

                            data: [3, 4, 5, 6, 7, 8, 9],
                            backgroundColor: [
                                "rgb(255, 153, 0)",
                                "rgb(255, 153, 0)",
                                "rgb(255, 153, 0)",
                                "rgb(255, 153, 0)",
                                "rgb(255, 153, 0)",
                                "rgb(255, 153, 0)",
                                "rgb(255, 153, 0)",
                            ],
                        },
                    ],
                },
                data2: {
                    labels: [
                        "PR1",
                        "PR2",
                        "PR3",
                        "PR4",
                        "PR5",
                        "PR6",
                        "PR7",
                        "PR8",
                        "PR9",
                        "PE10",
                    ],
                    datasets: [
                        {
                            label: "Process",
                            data: [2, 3, 3, 3, 3, 3, 3, 5, 4, 7],
                            backgroundColor: [
                                "rgb(141, 180, 226)",
                                "rgb(141, 180, 226)",
                                "rgb(141, 180, 226)",
                                "rgb(141, 180, 226)",
                                "rgb(141, 180, 226)",
                                "rgb(141, 180, 226)",
                                "rgb(141, 180, 226)",
                                "rgb(141, 180, 226)",
                                "rgb(141, 180, 226)",
                                "rgb(141, 180, 226)",
                            ],
                        },
                    ],
                },
                data3: {
                    labels: [
                        "PE1",
                        "PE2",
                        "PE3",
                        "PE4",
                        "PE5",
                        "PE6",
                        "PE7",
                        "PE8",
                        "PE9",
                        "PE10",
                    ],
                    datasets: [
                        {
                            labels: [
                                "A1",
                                "A2",
                                "A3",
                                "A4",
                                "A5",
                                "A6",
                                "A7",
                                "A8",
                                "A9",
                                "A10",
                            ],

                            data: [1, 3, 0, 4, 5, 7, 4, 8, 9, 3],
                            backgroundColor: [
                                "rgb(146, 208, 80)",
                                "rgb(146, 208, 80)",
                                "rgb(146, 208, 80)",
                                "rgb(146, 208, 80)",
                                "rgb(146, 208, 80)",
                                "rgb(146, 208, 80)",
                                "rgb(146, 208, 80)",
                                "rgb(146, 208, 80)",
                                "rgb(146, 208, 80)",
                                "rgb(146, 208, 80)",
                            ],

                            borderWidth: 1,
                        },
                    ],
                },
            });
        } else if (this.state.factoryValue === "QD") {
            this.setState({
                circle1: 30,
                circle2: 46,
                circle3: 50,
                maturity: 2,
                overall: 124,
                data1: {
                    labels: ["L1", "L2", "L3", "L4", "L5", "L6", "L7"],
                    datasets: [
                        {
                            label: "",

                            data: [1, 7, 8, 6, 4, 8, 9],
                            backgroundColor: [
                                "rgb(255, 153, 0)",
                                "rgb(255, 153, 0)",
                                "rgb(255, 153, 0)",
                                "rgb(255, 153, 0)",
                                "rgb(255, 153, 0)",
                                "rgb(255, 153, 0)",
                                "rgb(255, 153, 0)",
                            ],
                        },
                    ],
                },
                data2: {
                    labels: [
                        "PR1",
                        "PR2",
                        "PR3",
                        "PR4",
                        "PR5",
                        "PR6",
                        "PR7",
                        "PR8",
                        "PR9",
                        "PE10",
                    ],
                    datasets: [
                        {
                            label: "Process",
                            data: [8, 7, 3, 7, 5, 1, 6, 5, 4, 7],
                            backgroundColor: [
                                "rgb(141, 180, 226)",
                                "rgb(141, 180, 226)",
                                "rgb(141, 180, 226)",
                                "rgb(141, 180, 226)",
                                "rgb(141, 180, 226)",
                                "rgb(141, 180, 226)",
                                "rgb(141, 180, 226)",
                                "rgb(141, 180, 226)",
                                "rgb(141, 180, 226)",
                                "rgb(141, 180, 226)",
                            ],
                        },
                    ],
                },
                data3: {
                    labels: [
                        "PE1",
                        "PE2",
                        "PE3",
                        "PE4",
                        "PE5",
                        "PE6",
                        "PE7",
                        "PE8",
                        "PE9",
                        "PE10",
                    ],
                    datasets: [
                        {
                            labels: [
                                "A1",
                                "A2",
                                "A3",
                                "A4",
                                "A5",
                                "A6",
                                "A7",
                                "A8",
                                "A9",
                                "A10",
                            ],

                            data: [6, 2, 1, 8, 5, 7, 5, 8, 3, 3],
                            backgroundColor: [
                                "rgb(146, 208, 80)",
                                "rgb(146, 208, 80)",
                                "rgb(146, 208, 80)",
                                "rgb(146, 208, 80)",
                                "rgb(146, 208, 80)",
                                "rgb(146, 208, 80)",
                                "rgb(146, 208, 80)",
                                "rgb(146, 208, 80)",
                                "rgb(146, 208, 80)",
                                "rgb(146, 208, 80)",
                            ],

                            borderWidth: 1,
                        },
                    ],
                },
            });
        } else if (this.state.factoryValue === "JJ") {
            this.setState({
                circle1: 100,
                circle2: 100,
                circle3: 100,
                maturity: 5,
                overall: 270,
                data1: {
                    labels: ["L1", "L2", "L3", "L4", "L5", "L6", "L7"],
                    datasets: [
                        {
                            label: "",

                            data: [3, 4, 5, 6, 7, 8, 9],
                            backgroundColor: [
                                "rgb(255, 153, 0)",
                                "rgb(255, 153, 0)",
                                "rgb(255, 153, 0)",
                                "rgb(255, 153, 0)",
                                "rgb(255, 153, 0)",
                                "rgb(255, 153, 0)",
                                "rgb(255, 153, 0)",
                            ],
                        },
                    ],
                },
            });
        } else if (this.state.factoryValue === "TWK") {
            this.setState({
                circle1: 80,
                circle2: 70,
                circle3: 80,
                maturity: 4,
                overall: 200,
                data1: {
                    labels: ["L1", "L2", "L3", "L4", "L5", "L6", "L7"],
                    datasets: [
                        {
                            label: "",

                            data: [10, 9, 8, 6, 7, 8, 9],
                            backgroundColor: [
                                "rgb(255, 153, 0)",
                                "rgb(255, 153, 0)",
                                "rgb(255, 153, 0)",
                                "rgb(255, 153, 0)",
                                "rgb(255, 153, 0)",
                                "rgb(255, 153, 0)",
                                "rgb(255, 153, 0)",
                            ],
                        },
                    ],
                },
                data2: {
                    labels: [
                        "PR1",
                        "PR2",
                        "PR3",
                        "PR4",
                        "PR5",
                        "PR6",
                        "PR7",
                        "PR8",
                        "PR9",
                        "PE10",
                    ],
                    datasets: [
                        {
                            label: "Process",
                            data: [8, 7, 8, 7, 5, 10, 6, 5, 4, 7],
                            backgroundColor: [
                                "rgb(141, 180, 226)",
                                "rgb(141, 180, 226)",
                                "rgb(141, 180, 226)",
                                "rgb(141, 180, 226)",
                                "rgb(141, 180, 226)",
                                "rgb(141, 180, 226)",
                                "rgb(141, 180, 226)",
                                "rgb(141, 180, 226)",
                                "rgb(141, 180, 226)",
                                "rgb(141, 180, 226)",
                            ],
                        },
                    ],
                },
                data3: {
                    labels: [
                        "PE1",
                        "PE2",
                        "PE3",
                        "PE4",
                        "PE5",
                        "PE6",
                        "PE7",
                        "PE8",
                        "PE9",
                        "PE10",
                    ],
                    datasets: [
                        {
                            labels: [
                                "A1",
                                "A2",
                                "A3",
                                "A4",
                                "A5",
                                "A6",
                                "A7",
                                "A8",
                                "A9",
                                "A10",
                            ],

                            data: [6, 7, 10, 8, 5, 7, 5, 8, 9, 3],
                            backgroundColor: [
                                "rgb(146, 208, 80)",
                                "rgb(146, 208, 80)",
                                "rgb(146, 208, 80)",
                                "rgb(146, 208, 80)",
                                "rgb(146, 208, 80)",
                                "rgb(146, 208, 80)",
                                "rgb(146, 208, 80)",
                                "rgb(146, 208, 80)",
                                "rgb(146, 208, 80)",
                                "rgb(146, 208, 80)",
                            ],

                            borderWidth: 1,
                        },
                    ],
                },
            });
        } else if (
            this.state.countryValue &&
            this.state.groupValue &&
            this.state.factoryValue &&
            this.state.jobValue &&
            this.state.attributesValue &&
            this.state.typeValue
        ) {
            this.setState({
                circle1: 10,
                circle2: 60,
                circle3: 10,
                maturity: 3,
                overall: 180,
                data1: {
                    labels: ["L1", "L2", "L3", "L4", "L5", "L6", "L7"],
                    datasets: [
                        {
                            label: "",

                            data: [3, 4, 5, 6, 7, 8, 9],
                            backgroundColor: [
                                "rgb(255, 153, 0)",
                                "rgb(255, 153, 0)",
                                "rgb(255, 153, 0)",
                                "rgb(255, 153, 0)",
                                "rgb(255, 153, 0)",
                                "rgb(255, 153, 0)",
                                "rgb(255, 153, 0)",
                            ],
                        },
                    ],
                },
                data2: {
                    labels: [
                        "PR1",
                        "PR2",
                        "PR3",
                        "PR4",
                        "PR5",
                        "PR6",
                        "PR7",
                        "PR8",
                        "PR9",
                        "PE10",
                    ],
                    datasets: [
                        {
                            label: "Process",
                            data: [8, 7, 8, 7, 5, 10, 6, 5, 4, 7],
                            backgroundColor: [
                                "rgb(141, 180, 226)",
                                "rgb(141, 180, 226)",
                                "rgb(141, 180, 226)",
                                "rgb(141, 180, 226)",
                                "rgb(141, 180, 226)",
                                "rgb(141, 180, 226)",
                                "rgb(141, 180, 226)",
                                "rgb(141, 180, 226)",
                                "rgb(141, 180, 226)",
                                "rgb(141, 180, 226)",
                            ],
                        },
                    ],
                },
                data3: {
                    labels: [
                        "PE1",
                        "PE2",
                        "PE3",
                        "PE4",
                        "PE5",
                        "PE6",
                        "PE7",
                        "PE8",
                        "PE9",
                        "PE10",
                    ],
                    datasets: [
                        {
                            labels: [
                                "A1",
                                "A2",
                                "A3",
                                "A4",
                                "A5",
                                "A6",
                                "A7",
                                "A8",
                                "A9",
                                "A10",
                            ],

                            data: [6, 7, 10, 8, 5, 7, 5, 8, 9, 3],
                            backgroundColor: [
                                "rgb(146, 208, 80)",
                                "rgb(146, 208, 80)",
                                "rgb(146, 208, 80)",
                                "rgb(146, 208, 80)",
                                "rgb(146, 208, 80)",
                                "rgb(146, 208, 80)",
                                "rgb(146, 208, 80)",
                                "rgb(146, 208, 80)",
                                "rgb(146, 208, 80)",
                                "rgb(146, 208, 80)",
                            ],

                            borderWidth: 1,
                        },
                    ],
                },
            });
        }
    }
    _exportPdf() {
        html2canvas(document.querySelector("#test")).then((canvas) => {
            document.body.appendChild(canvas); // if you want see your screenshot in body.
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF();
            pdf.addImage(imgData, "PNG", 0, 0);
            pdf.save("download.pdf");
        });
    }
    handleChange = async (selectedOption) => {
        console.log(selectedOption)
        this.setState({ spinner: true })
        this.setState({
            attributesValue: "All",
        });
        this.setSelected(selectedOption);
        if (selectedOption.value !== "All") {
            const response = await fetch(
                MOSS_USER + "/" + selectedOption.value + "/" + "moss-titles"
            );
            let data = await response.json();
            let filterdata = data.filter((i) => {
                return i.title !== "";
            });

            let topictitle = [];
            filterdata.forEach((i) => {
                topictitle[i.position - 1] = i.title;
            });

            this.setState({ topictitle });
        } else {
            let topictitle = ["Tier1", "Tier2", "Tier3"];
            this.setState({ topictitle });
        }
        let selectedtier = this.state.selectedtier;
        let selectedtierlist = this.state.selectedtierlist;
        selectedtier = { t1: {}, t2: {}, t3: {}, t4: {} };
        selectedtierlist = { t1: [], t2: [], t3: [], t4: [] };
        this.setState({ selectedtierlist, selectedtier, role: [] });
        let url = 'https://api.acuizen.com/moss-users/' + selectedOption.value + '/topic-ones'
        let uriString = {
            "include": [{ "relation": "topicTwos" }]

        }
        const topicones = await axios.get(`${url}?filter=${encodeURIComponent(JSON.stringify(uriString))
            }`).then((a) => { return a.data })
        // console.log(topicones.map((i) => { return { name: i.name, id: i.id } }))

        let topictwos = topicones.map((i) => {

            if (i.topicTwos !== undefined) {
                return i.topicTwos.map((j) => { return { name: j.name, id: j.id } })
            }



        })
        this.setState({ usertopic1: topicones.map((i) => { return { name: i.name, id: i.id } }), usertopic2: topictwos.reduce((a, b) => { return [...a, ...b] }) })

        //   this.setSelectedOption(selectedOption.value)
        setTimeout(() => {
            this.getMossTitle(selectedOption.value);
        }, 2000)

    };
    handleChange1 = async (selectedOption) => {
        this.setState({
            selected1: selectedOption,
        });
        this.setState({
            attributesValue: "All",
        });
        const response = await fetch(
            "https://api.acuizen.com/companies" +
            "/" +
            selectedOption.value +
            "/" +
            "moss-users"
        );
        let data = await response.json();
        let user = [];
        data.forEach((element) => {
            if (element.created === null || element.created === 'true') {
                user.push({ value: element.id, label: element.assesment_title });
            }

        });
        this.setState({
            assessment: user,
            value: undefined,
            selected: [],
            role: [],
            totalSurvey: 0,
        });
    };
    handleChangeCompany = async (selectedOption) => { };
    handleTierChange = (obj, value) => {
        let tierlist = this.state.tierlist;
        let tierselect = this.state.selectedtier;
        let tierselectlist = this.state.selectedtierlist;
        let topiccount = this.state.tiercountbytopic;
        let count = 0;

        if (obj === "tier1") {
            let tier2 = [{ label: "All", value: 0 }];
            tierlist.forEach((i, j) => {
                if (i.topic1 === value.label || value.label === "All") {
                    if (
                        tier2.findIndex((a) => {
                            return a.label === i.topic2;
                        }) === -1
                    ) {
                        tier2.push({ label: i.topic2, value: j + 1 });
                    }
                }
            });

            this.state.tierresponsecount.forEach((res) => {
                if (res.topic1 === value.label || value.label === "All") {
                    count = count + 1;
                }
            });
            topiccount.t1 = count;
            topiccount.t2 = count;
            topiccount.t3 = count;
            topiccount.t4 = count;
            tierselect.t1 = value;
            tierselect.t2 = { label: "All", value: 0 };
            tierselect.t3 = { label: "All", value: 0 };
            tierselect.t4 = { label: "All", value: 0 };
            tierselectlist.t4 = [];
            tierselectlist.t3 = [];
            tierselectlist.t2 = tier2;
            this.renderFilter({
                topic1: value.label,
                topic2: "All",
                topic3: "All",
                topic4: "All",
            });

            this.setState({
                tierlist,
                selectedtier: tierselect,
                selectedtierlist: tierselectlist,
                tiercountbytopic: topiccount,
            });
        } else if (obj === "tier2") {
            let tier3 = [{ label: "All", value: 0 }];
            tierlist.forEach((i, j) => {
                if (
                    (i.topic2 === value.label || value.label === "All") &&
                    (i.topic1 === this.state.selectedtier.t1.label ||
                        "All" === this.state.selectedtier.t1.label)
                ) {
                    if (
                        tier3.findIndex((a) => {
                            return a.label === i.topic3;
                        }) === -1
                    ) {
                        if (i.topic3 !== undefined) {
                            tier3.push({ label: i.topic3, value: j + 1 });
                        }
                    }
                }
            });
            this.state.tierresponsecount.forEach((res) => {
                if (
                    (res.topic1 === this.state.selectedtier.t1.label ||
                        this.state.selectedtier.t1.label === "All") &&
                    (res.topic2 === value.label || value.label === "All")
                ) {
                    count = count + 1;
                }
            });

            topiccount.t2 = count;
            topiccount.t3 = count;
            topiccount.t4 = count;
            tierselect.t2 = value;

            tierselect.t4 = { label: "All", value: 0 };
            tierselectlist.t4 = [];
            if (tier3.length === 1) {
                tierselectlist.t3 = [];
            } else {
                tierselect.t3 = { label: "All", value: 0 };
                tierselectlist.t3 = tier3;
            }


            this.setState({
                tierlist,
                selectedtier: tierselect,
                selectedtierlist: tierselectlist,
                tiercountbytopic: topiccount,
            });
            this.renderFilter({
                topic1: this.state.selectedtier.t1.label,
                topic2: value.label,
                topic3: "All",
                topic4: "All",
            });
        } else if (obj === "tier3") {
            let tier4 = [{ label: "All", value: 0 }];
            tierlist.forEach((i, j) => {
                if (
                    (i.topic3 === value.label || value.label === "All") &&
                    (i.topic1 === this.state.selectedtier.t1.label ||
                        this.state.selectedtier.t1.label === "All") &&
                    (i.topic2 === this.state.selectedtier.t2.label ||
                        "All" === this.state.selectedtier.t2.label)
                ) {
                    if (
                        tier4.findIndex((a) => {
                            return a.label === i.topic4;
                        }) === -1
                    ) {
                        tier4.push({ label: i.topic4, value: j + 1 });
                    }
                }
            });
            this.state.tierresponsecount.forEach((res) => {
                if (
                    (res.topic1 === this.state.selectedtier.t1.label ||
                        this.state.selectedtier.t1.label === "All") &&
                    (res.topic2 === this.state.selectedtier.t2.label ||
                        this.state.selectedtier.t2.label === "All") &&
                    (res.topic3 === value.label || value.label === "All")
                ) {
                    count = count + 1;
                }
            });

            topiccount.t3 = count;
            topiccount.t4 = count;
            this.renderFilter({
                topic1: this.state.selectedtier.t1.label,
                topic2: this.state.selectedtier.t2.label,
                topic3: value.label,
                topic4: "All",
            });

            tierselect.t3 = value;
            tierselect.t4 = { label: "All", value: 0 };
            tierselectlist.t4 = tier4;

            this.setState({
                tierlist,
                selectedtier: tierselect,
                selectedtierlist: tierselectlist,
                tiercountbytopic: topiccount,
            });
        } else if (obj === "tier4") {
            tierselect.t4 = value;
            this.state.tierresponsecount.forEach((res) => {
                if (
                    (res.topic1 === this.state.selectedtier.t1.label ||
                        this.state.selectedtier.t1.label === "All") &&
                    (res.topic2 === this.state.selectedtier.t2.label ||
                        this.state.selectedtier.t2.label === "All") &&
                    (res.topic3 === this.state.selectedtier.t3.label ||
                        this.state.selectedtier.t3.label === "All") &&
                    (res.topic4 === value.label || value.label === "All")
                ) {
                    count = count + 1;
                }
            });

            topiccount.t4 = count;
            this.renderFilter({
                topic1: this.state.selectedtier.t1.label,
                topic2: this.state.selectedtier.t2.label,
                topic3: this.state.selectedtier.t3.label,
                topic4: value.label,
            });
            this.setState({
                tierlist,
                selectedtier: tierselect,
                selectedtierlist: tierselectlist,
                tiercountbytopic: topiccount,
            });
        }
    };
    renderRole(data) {
        //editrenderfilter
        this.setState({ spinner: true })
        let tier1 = [],
            tier2 = [],
            tier3 = [],
            tier4 = [];
        let sec = { a: 0, b: 0, c: 0, d: 0, e: 0, f: 0 };
        let sets = ["a", "b", "c", "d", "e", "f", "g", "h", "j", "i"];
        let que = {};
        let secCut = [];

        let s1Q = [[], [], [], [], [], [], [], []];
        let s2Q = [[], [], [], [], [], [], [], []];
        let s3Q = [[], [], [], [], [], [], [], []];
        let s4Q = [[], [], [], [], [], [], [], []];
        let s5Q = [[], [], [], [], [], [], [], []];
        let s6Q = [[], [], [], [], [], [], [], []];

        let graphlabel = [];
        let secgraphlabel = [];
        const url = "https://api.acuizen.com/custom-responses-beta/" + this.state.value;
        axios({
            method: "get",
            url: url,

            headers: {
                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            },
            mode: "no-cors",
        }).then((res) => {
            if (res.data.length !== 0) {
                let tierTopicCount = [];
                let roles = [];
                let roleData = [];
                let sectionwisequestioncount = [];
                let secDatalabel = { a: [], b: [], c: [], d: [], e: [], f: [] };
                let roleCount = 0;
                sectionwisequestioncount = JSON.parse(JSON.stringify(this.state.characteristic))
                sectionwisequestioncount.splice(0, 1)
                sectionwisequestioncount.forEach((k, ind) => {


                    if (ind === 0) {
                        secCut.push(k.count);
                    } else {
                        secCut.push(secCut[ind - 1] + k.count);
                    }

                    for (var is = 0; is < k.count; is++) {
                        que[sets[ind] + is] = 0;
                        if (secDatalabel[sets[ind]] !== undefined) {
                            secDatalabel[sets[ind]].push(this.getSection(ind, is));
                        }
                    }

                });

                let ccc = []
                res.data.forEach((i, ind) => {

                    if ((i.role === data || data === "All") && (this.state.selectedtier.t1.label === this.state.usertopic1.filter((k) => { if (k.id === i.topic1) { return k.name } })[0].name || this.state.selectedtier.t1.label === "All") &&
                        (this.state.selectedtier.t2.label === this.state.usertopic2.filter((k) => { if (k.id === i.topic2) { return k.name } })[0].name || this.state.selectedtier.t2.label === "All")) {
                        roleData.push(i);
                        roleCount++;
                       

                        i.input_json.forEach((section, sind) => {
                            if (graphlabel.findIndex((i) => i === section.name) === -1) {
                                graphlabel.push(section.name);
                            }

                            section.questions[0].selectedValues.forEach((question, qind) => {
                                if (
                                    secgraphlabel.findIndex(
                                        (i) =>
                                            i ===
                                            question.label
                                                .replace(/(<([^>]+)>)/gi, "")
                                                .replace(/\n/g, " ")
                                                .replace(/&nbsp;/g, " ")
                                    ) === -1
                                ) {
                                    // secgraphlabel.push(question.label.replace(/(<([^>]+)>)/gi, "").replace(/\n/g, ' ').replace(/&nbsp;/g, ' '))
                                }
                                if (sind === 0) {
                                    s1Q[qind].push(
                                        (parseInt(question.trim()) - 1)
                                    );
                                    que[`a${qind}`] =
                                        que[`a${qind}`] +
                                        (parseInt(question.trim()) - 1);
                                    //  que[qind].push(question.values.findIndex((i)=> i.selected === true))
                                    sec.a =
                                        sec.a + (parseInt(question.trim()) - 1);
                                } else if (sind === 1) {
                                    s2Q[qind].push(
                                        (parseInt(question.trim()) - 1)
                                    );
                                    que[`b${qind}`] =
                                        que[`b${qind}`] +
                                        (parseInt(question.trim()) - 1);
                                    sec.b =
                                        sec.b + (parseInt(question.trim()) - 1);
                                } else if (sind === 2) {
                                    s3Q[qind].push(
                                        (parseInt(question.trim()) - 1)
                                    );
                                    que[`c${qind}`] =
                                        que[`c${qind}`] +
                                        (parseInt(question.trim()) - 1)
                                    sec.c =
                                        sec.c + (parseInt(question.trim()) - 1)
                                } else if (sind === 3) {
                                    s4Q[qind].push(
                                        (parseInt(question.trim()) - 1)
                                    );
                                    que[`d${qind}`] =
                                        que[`d${qind}`] +
                                        (parseInt(question.trim()) - 1);
                                    sec.d =
                                        sec.d + (parseInt(question.trim()) - 1)
                                } else if (sind === 4) {
                                    if(qind === 0){
 ccc.push(parseInt(question.trim()) - 1)
                                    }
                                   
                                    s5Q[qind].push(
                                        (parseInt(question.trim()) - 1)
                                    );
                                    que[`e${qind}`] =
                                        que[`e${qind}`] +
                                        (parseInt(question.trim()) - 1)
                                    sec.e =
                                        sec.e + (parseInt(question.trim()) - 1)
                                } else if (sind === 5) {
                                    s6Q[qind].push(
                                        (parseInt(question.trim()) - 1)
                                    );
                                    que[`f${qind}`] =
                                        que[`f${qind}`] +
                                        (parseInt(question.trim()) - 1)
                                    sec.f =
                                        sec.f + (parseInt(question.trim()) - 1)
                                }
                            });
                        });
                    }
                });
                console.log(ccc)
                let graphlabel2 = [];
                let apiSectionName = this.state.characteristic;
                graphlabel.forEach((k, ind) => {
                    if (ind === 0) {
                        graphlabel2.push(
                            apiSectionName[ind + 1].label +
                            " (" +
                            parseInt((sec.a / (roleCount * 24)) * 100) +
                            " %)"
                        );
                    } else if (ind === 1) {
                        graphlabel2.push(
                            apiSectionName[ind + 1].label +
                            " (" +
                            parseInt((sec.b / (roleCount * 24)) * 100) +
                            " %)"
                        );
                    } else if (ind === 2) {
                        graphlabel2.push(
                            apiSectionName[ind + 1].label +
                            " (" +
                            parseInt((sec.c / (roleCount * 24)) * 100) +
                            " %)"
                        );
                    } else if (ind === 3) {
                        graphlabel2.push(
                            apiSectionName[ind + 1].label +
                            " (" +
                            parseInt((sec.d / (roleCount * 24)) * 100) +
                            " %)"
                        );
                    } else if (ind === 4) {
                        graphlabel2.push(
                            apiSectionName[ind + 1].label +
                            " (" +
                            parseInt((sec.e / (roleCount * 24)) * 100) +
                            " %)"
                        );
                    } else if (ind === 5) {
                        graphlabel2.push(
                            apiSectionName[ind + 1].label +
                            " (" +
                            parseInt((sec.f / (roleCount * 24)) * 100) +
                            " %)"
                        );
                    }
                });

                let config = {
                    type: "radar",
                    data: {
                        labels: graphlabel2,
                        datasets: [
                            {
                                label: this.state.selected.label,
                                data: [
                                    parseInt((sec.a / (roleCount * 24)) * 100),
                                    parseInt((sec.b / (roleCount * 24)) * 100),
                                    parseInt((sec.c / (roleCount * 24)) * 100),
                                    parseInt((sec.d / (roleCount * 24)) * 100),
                                    parseInt((sec.e / (roleCount * 24)) * 100),
                                    parseInt((sec.f / (roleCount * 24)) * 100),
                                ],
                                fill: true,
                                backgroundColor: "rgb(0, 216, 240,0.2)",
                                borderColor: "rgb(0, 216, 240,0.2)",
                                pointBackgroundColor: "rgb(19, 21, 51,0.1)",
                                pointBorderColor: "rgb(0, 216, 240,0)",
                                pointHoverBackgroundColor: "#fff",
                                pointHoverBorderColor: "rgb(0, 216, 240)",
                            },
                        ],
                    },
                    options: {
                        legend: {
                            display: false,
                        },
                        scale: {
                            ticks: {
                                min: 0,
                                max: 100,
                                stepSize: 20,
                            },
                            pointLabels: {
                                fontColor: "rgb(19, 21, 51)",
                                fontSize: 20,
                            },
                        },
                        elements: {
                            line: {
                                borderWidth: 3,
                            },
                        },
                        tooltips: {
                            callbacks: {
                                label: function (tooltipItem, data) {
                                    var data = "";
                                    switch (tooltipItem.index) {
                                        case 0:
                                            data = apiSectionName[1].label + tooltipItem.yLabel + " %";
                                            break;
                                        case 1:
                                            data = apiSectionName[2].label + tooltipItem.yLabel + " %";
                                            break;
                                        case 2:
                                            data = apiSectionName[3].label + tooltipItem.yLabel + " %";
                                            break;
                                        case 3:
                                            data = apiSectionName[4].label + tooltipItem.yLabel + " %";
                                            break;
                                        case 4:
                                            data = apiSectionName[5].label + tooltipItem.yLabel + " %";
                                            break;

                                        case 5:
                                            data = apiSectionName[6].label + tooltipItem.yLabel + " %";
                                            break;
                                    }
                                    return data;
                                },
                                title: function () {
                                    return null;
                                },
                            },
                            // enabled: true,
                            // custom: this.mycustomtooltipfunction
                        },
                    },
                };
                let pngconfig = {
                    type: "radar",
                    data: {
                        labels: graphlabel2,
                        datasets: [
                            {
                                label: this.state.selected.label,
                                data: [
                                    parseInt((sec.a / (roleCount * 24)) * 100),
                                    parseInt((sec.b / (roleCount * 24)) * 100),
                                    parseInt((sec.c / (roleCount * 24)) * 100),
                                    parseInt((sec.d / (roleCount * 24)) * 100),
                                    parseInt((sec.e / (roleCount * 24)) * 100),
                                    parseInt((sec.f / (roleCount * 24)) * 100),
                                ],
                                fill: false,
                                backgroundColor: "rgb(0, 216, 240,0.2)",
                                borderColor: "rgb(0, 216, 240,0.2)",
                                pointBackgroundColor: "rgb(19, 21, 51,0.1)",
                                pointBorderColor: "rgb(0, 216, 240,0)",
                                pointHoverBackgroundColor: "#fff",
                                pointHoverBorderColor: "rgb(0, 216, 240)",
                            },
                        ],
                    },
                    options: {
                        legend: {
                            display: false,
                        },
                        scale: {
                            ticks: {
                                display: false,
                                min: 0,
                                max: 100,
                                stepSize: 20,
                            },
                            pointLabels: {
                                fontColor: "rgb(19, 21, 51)",
                                fontSize: 0,
                            },
                        },
                        elements: {
                            line: {
                                borderWidth: 3,
                            },
                        },
                        tooltips: {
                            callbacks: {
                                title: function () {
                                    return null;
                                },
                            },
                            // enabled: true,
                            // custom: this.mycustomtooltipfunction
                        },
                    },
                };
                let data1 = {
                    labels: secDatalabel.a,
                    datasets: [
                        {
                            label: this.state.questionlist.slice(0, secCut[0]),

                            data: [
                                parseInt((que.a0 / (4 * roleCount)) * 100),
                                parseInt((que.a1 / (4 * roleCount)) * 100),
                                parseInt((que.a2 / (4 * roleCount)) * 100),
                                parseInt((que.a3 / (4 * roleCount)) * 100),
                                parseInt((que.a4 / (4 * roleCount)) * 100),
                                parseInt((que.a5 / (4 * roleCount)) * 100),
                                parseInt((que.a6 / (4 * roleCount)) * 100),
                                parseInt((que.a7 / (4 * roleCount)) * 100),
                            ],
                            backgroundColor: [
                                this.state.chartcolors[0],
                                this.state.chartcolors[0],
                                this.state.chartcolors[0],
                                this.state.chartcolors[0],
                                this.state.chartcolors[0],
                                this.state.chartcolors[0],
                                this.state.chartcolors[0],
                                this.state.chartcolors[0],
                            ],
                        },
                    ],
                };
                let data2 = {
                    labels: secDatalabel.b,
                    datasets: [
                        {
                            label: this.state.questionlist.slice(secCut[0], secCut[1]),
                            data: [
                                parseInt((que.b0 / (4 * roleCount)) * 100),
                                parseInt((que.b1 / (4 * roleCount)) * 100),
                                parseInt((que.b2 / (4 * roleCount)) * 100),
                                parseInt((que.b3 / (4 * roleCount)) * 100),
                                parseInt((que.b4 / (4 * roleCount)) * 100),
                                parseInt((que.b5 / (4 * roleCount)) * 100),
                                parseInt((que.b6 / (4 * roleCount)) * 100),
                                parseInt((que.b7 / (4 * roleCount)) * 100),
                            ],
                            backgroundColor: [
                                this.state.chartcolors[1],
                                this.state.chartcolors[1],
                                this.state.chartcolors[1],
                                this.state.chartcolors[1],
                                this.state.chartcolors[1],
                                this.state.chartcolors[1],
                                this.state.chartcolors[1],
                                this.state.chartcolors[1],
                            ],
                        },
                    ],
                };
                let data3 = {
                    labels: secDatalabel.c,
                    datasets: [
                        {
                            label: this.state.questionlist.slice(secCut[2], secCut[3]),

                            data: [
                                parseInt((que.c0 / (4 * roleCount)) * 100),
                                parseInt((que.c1 / (4 * roleCount)) * 100),
                                parseInt((que.c2 / (4 * roleCount)) * 100),
                                parseInt((que.c3 / (4 * roleCount)) * 100),
                                parseInt((que.c4 / (4 * roleCount)) * 100),
                                parseInt((que.c5 / (4 * roleCount)) * 100),
                                parseInt((que.c6 / (4 * roleCount)) * 100),
                                parseInt((que.c7 / (4 * roleCount)) * 100),
                            ],
                            backgroundColor: [
                                this.state.chartcolors[2],
                                this.state.chartcolors[2],
                                this.state.chartcolors[2],
                                this.state.chartcolors[2],
                                this.state.chartcolors[2],
                                this.state.chartcolors[2],
                                this.state.chartcolors[2],
                                this.state.chartcolors[2],
                            ],

                            borderWidth: 1,
                        },
                    ],
                };

                let data4 = {
                    labels: secDatalabel.d,
                    datasets: [
                        {
                            label: this.state.questionlist.slice(secCut[2], secCut[3]),

                            data: [
                                parseInt((que.d0 / (4 * roleCount)) * 100),
                                parseInt((que.d1 / (4 * roleCount)) * 100),
                                parseInt((que.d2 / (4 * roleCount)) * 100),
                                parseInt((que.d3 / (4 * roleCount)) * 100),
                                parseInt((que.d4 / (4 * roleCount)) * 100),
                                parseInt((que.d5 / (4 * roleCount)) * 100),
                                parseInt((que.d6 / (4 * roleCount)) * 100),
                                parseInt((que.d7 / (4 * roleCount)) * 100),
                            ],
                            backgroundColor: [
                                this.state.chartcolors[3],
                                this.state.chartcolors[3],
                                this.state.chartcolors[3],
                                this.state.chartcolors[3],
                                this.state.chartcolors[3],
                                this.state.chartcolors[3],
                                this.state.chartcolors[3],
                                this.state.chartcolors[3],
                            ],

                            borderWidth: 1,
                        },
                    ],
                };
                let data5 = {
                    labels: secDatalabel.e,
                    datasets: [
                        {
                            label: this.state.questionlist.slice(secCut[3], secCut[4]),

                            data: [
                                parseInt((que.e0 / (4 * roleCount)) * 100),
                                parseInt((que.e1 / (4 * roleCount)) * 100),
                                parseInt((que.e2 / (4 * roleCount)) * 100),
                                parseInt((que.e3 / (4 * roleCount)) * 100),
                                parseInt((que.e4 / (4 * roleCount)) * 100),
                                parseInt((que.e5 / (4 * roleCount)) * 100),
                                parseInt((que.e6 / (4 * roleCount)) * 100),
                                parseInt((que.e7 / (4 * roleCount)) * 100),
                            ],
                            backgroundColor: [
                                this.state.chartcolors[4],
                                this.state.chartcolors[4],
                                this.state.chartcolors[4],
                                this.state.chartcolors[4],
                                this.state.chartcolors[4],
                                this.state.chartcolors[4],
                                this.state.chartcolors[4],
                                this.state.chartcolors[4],
                            ],

                            borderWidth: 1,
                        },
                    ],
                };
                let data6 = {
                    labels: secDatalabel.f,
                    datasets: [
                        {
                            label: this.state.questionlist.slice(secCut[4], secCut[5]),

                            data: [
                                parseInt((que.f0 / (4 * roleCount)) * 100),
                                parseInt((que.f1 / (4 * roleCount)) * 100),
                                parseInt((que.f2 / (4 * roleCount)) * 100),
                                parseInt((que.f3 / (4 * roleCount)) * 100),
                                parseInt((que.f4 / (4 * roleCount)) * 100),
                                parseInt((que.f5 / (4 * roleCount)) * 100),
                                parseInt((que.f6 / (4 * roleCount)) * 100),
                                parseInt((que.f7 / (4 * roleCount)) * 100),
                            ],
                            backgroundColor: [
                                this.state.chartcolors[5],
                                this.state.chartcolors[5],
                                this.state.chartcolors[5],
                                this.state.chartcolors[5],
                                this.state.chartcolors[5],
                                this.state.chartcolors[5],
                                this.state.chartcolors[5],
                                this.state.chartcolors[5],
                            ],

                            borderWidth: 1,
                        },
                    ],
                };

                let secchart = this.state.sectionchart;
                secchart["a"] = data1;
                secchart["b"] = data2;
                secchart["c"] = data3;
                secchart["d"] = data4;
                secchart["e"] = data5;
                secchart["f"] = data6;
                let quechart = this.state.questionChart;
                console.log(s5Q)
                quechart["a"] = s1Q;
                quechart["b"] = s2Q;
                quechart["c"] = s3Q;
                quechart["d"] = s4Q;
                quechart["e"] = s5Q;
                quechart["f"] = s6Q;
                let as = []
                Object.values(quechart).forEach((a, i) => {

                    a.forEach((b) => {
                        if (b.length !== 0) {
                            as.push(b.filter((j) => { return j === 0 }).length)
                            as.push(b.filter((j) => { return j === 1 }).length)

                            as.push(b.filter((j) => { return j === 2 }).length)
                            as.push(b.filter((j) => { return j === 3 }).length)
                            as.push(b.filter((j) => { return j === 4 }).length)

                        }
                    })

                })
                this.setState({ questionChart: quechart, questionChartMaxSize: Math.max(...as) + 50 });



                this.quesyaxisstepsize = roleCount;
                let roletiercount = this.state.tiercountbytopic
                roletiercount.t4 = roleCount
                this.setState({
                    response: roleData,
                    tiercountbytopic: roletiercount
                });

                this.setState({
                    totalSurvey: roleCount,

                    section: sec,
                    question: que,
                    radargraph: config,
                    radargraph2: pngconfig,
                    sectionchart: secchart,

                });
                this.setState({ spinner: false })
            } else {
                this.setState({ spinner: false })
            }
        });
    }
    renderFilter(data) {
        this.setState({ spinner: true })
        let sec = { a: 0, b: 0, c: 0, d: 0, e: 0, f: 0 };
        let que = {};
        let role = [{ label: "All", value: 'r15' }]
        let sets = ["a", "b", "c", "d", "e", "f", "g", "h", "j", "i"];
        let secCut = [];
        let filterResData = [];
        let s1Q = [[], [], [], [], [], [], [], []];
        let s2Q = [[], [], [], [], [], [], [], []];
        let s3Q = [[], [], [], [], [], [], [], []];
        let s4Q = [[], [], [], [], [], [], [], []];
        let s5Q = [[], [], [], [], [], [], [], []];
        let s6Q = [[], [], [], [], [], [], [], []];
        let count = 0;
        let graphlabel = [];
        let secgraphlabel = [];
        const url =
            this.state.value === "All"
                ? "https://api.acuizen.com/custom-responses-all/"
                : "https://api.acuizen.com/custom-responses-beta/" + this.state.value;
        axios({
            method: "get",
            url: url,

            headers: {
                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            },
            mode: "no-cors",
        }).then((res) => {
            if (res.data.length) {
                let sectionwisequestioncount = [];
                let secDatalabel = { a: [], b: [], c: [], d: [], e: [], f: [] };
                sectionwisequestioncount = JSON.parse(JSON.stringify(this.state.characteristic))
                sectionwisequestioncount.splice(0, 1)
                sectionwisequestioncount.forEach((k, ind) => {


                    if (ind === 0) {
                        secCut.push(k.count);
                    } else {
                        secCut.push(secCut[ind - 1] + k.count);
                    }

                    for (var is = 0; is < k.count; is++) {
                        que[sets[ind] + is] = 0;
                        if (secDatalabel[sets[ind]] !== undefined) {
                            secDatalabel[sets[ind]].push(this.getSection(ind, is));
                        }
                    }

                });

                res.data.forEach((i, filind) => {
                    if (

                        (data.topic1 === this.state.usertopic1.filter((k) => { if (k.id === i.topic1) { return k.name } })[0].name || data.topic1 === "All") &&
                        (data.topic2 === this.state.usertopic2.filter((k) => { if (k.id === i.topic2) { return k.name } })[0].name || data.topic2 === "All")
                    ) {
                        if (role.findIndex((l) => { return l.label === i.role }) === -1) {

                            role.push({ label: i.role, value: i.role })
                        }

                        filterResData.push(i);
                        count = count + 1;
                        i.input_json.forEach((section, sind) => {
                            if (graphlabel.findIndex((i) => i === section.name) === -1) {
                                graphlabel.push(section.name);
                            }
                            section.questions[0].selectedValues.forEach((question, qind) => {
                                if (
                                    secgraphlabel.findIndex(
                                        (i) =>
                                            i ===
                                            question.label
                                                .replace(/(<([^>]+)>)/gi, "")
                                                .replace(/\n/g, " ")
                                                .replace(/&nbsp;/g, " ")
                                    ) === -1
                                ) {
                                    // secgraphlabel.push(question.label.replace(/(<([^>]+)>)/gi, "").replace(/\n/g, ' ').replace(/&nbsp;/g, ' '))
                                }
                                if (sind === 0) {
                                    s1Q[qind].push(
                                        (parseInt(question.trim()) - 1)
                                    );
                                    que[`a${qind}`] =
                                        que[`a${qind}`] +
                                        (parseInt(question.trim()) - 1);
                                    //  que[qind].push(question.values.findIndex((i)=> i.selected === true))
                                    sec.a =
                                        sec.a + (parseInt(question.trim()) - 1);
                                } else if (sind === 1) {
                                    s2Q[qind].push(
                                        (parseInt(question.trim()) - 1)
                                    );
                                    que[`b${qind}`] =
                                        que[`b${qind}`] +
                                        (parseInt(question.trim()) - 1);
                                    sec.b =
                                        sec.b + (parseInt(question.trim()) - 1);
                                } else if (sind === 2) {
                                    s3Q[qind].push(
                                        (parseInt(question.trim()) - 1)
                                    );
                                    que[`c${qind}`] =
                                        que[`c${qind}`] +
                                        (parseInt(question.trim()) - 1)
                                    sec.c =
                                        sec.c + (parseInt(question.trim()) - 1)
                                } else if (sind === 3) {
                                    s4Q[qind].push(
                                        (parseInt(question.trim()) - 1)
                                    );
                                    que[`d${qind}`] =
                                        que[`d${qind}`] +
                                        (parseInt(question.trim()) - 1);
                                    sec.d =
                                        sec.d + (parseInt(question.trim()) - 1)
                                } else if (sind === 4) {
                                    s5Q[qind].push(
                                        (parseInt(question.trim()) - 1)
                                    );
                                    que[`e${qind}`] =
                                        que[`e${qind}`] +
                                        (parseInt(question.trim()) - 1)
                                    sec.e =
                                        sec.e + (parseInt(question.trim()) - 1)
                                } else if (sind === 5) {
                                    s6Q[qind].push(
                                        (parseInt(question.trim()) - 1)
                                    );
                                    que[`f${qind}`] =
                                        que[`f${qind}`] +
                                        (parseInt(question.trim()) - 1)
                                    sec.f =
                                        sec.f + (parseInt(question.trim()) - 1)
                                }
                            });
                        });
                    }
                });

                let graphlabel2 = [];
                let apiSectionName = this.state.characteristic;
                graphlabel.forEach((k, ind) => {
                    if (ind === 0) {
                        graphlabel2.push(
                            apiSectionName[ind + 1].label +
                            " (" +
                            parseInt((sec.a / (count * 24)) * 100) +
                            " %)"
                        );
                    } else if (ind === 1) {
                        graphlabel2.push(
                            apiSectionName[ind + 1].label +
                            " (" +
                            parseInt((sec.b / (count * 24)) * 100) +
                            " %)"
                        );
                    } else if (ind === 2) {
                        graphlabel2.push(
                            apiSectionName[ind + 1].label +
                            " (" +
                            parseInt((sec.c / (count * 24)) * 100) +
                            " %)"
                        );
                    } else if (ind === 3) {
                        graphlabel2.push(
                            apiSectionName[ind + 1].label +
                            " (" +
                            parseInt((sec.d / (count * 24)) * 100) +
                            " %)"
                        );
                    } else if (ind === 4) {
                        graphlabel2.push(
                            apiSectionName[ind + 1].label +
                            " (" +
                            parseInt((sec.e / (count * 24)) * 100) +
                            " %)"
                        );
                    } else if (ind === 5) {
                        graphlabel2.push(
                            apiSectionName[ind + 1].label +
                            " (" +
                            parseInt((sec.f / (count * 24)) * 100) +
                            " %)"
                        );
                    }
                });

                let config = {
                    type: "radar",
                    data: {
                        labels: graphlabel2,
                        datasets: [
                            {
                                label: this.state.selected.label,
                                data: [
                                    parseInt((sec.a / (count * 24)) * 100),
                                    parseInt((sec.b / (count * 24)) * 100),
                                    parseInt((sec.c / (count * 24)) * 100),
                                    parseInt((sec.d / (count * 24)) * 100),
                                    parseInt((sec.e / (count * 24)) * 100),
                                    parseInt((sec.f / (count * 24)) * 100),
                                ],
                                fill: true,
                                backgroundColor: "rgb(0, 216, 240,0.2)",
                                borderColor: "rgb(0, 216, 240,0.2)",
                                pointBackgroundColor: "rgb(19, 21, 51,0.1)",
                                pointBorderColor: "rgb(0, 216, 240,0)",
                                pointHoverBackgroundColor: "#fff",
                                pointHoverBorderColor: "rgb(0, 216, 240)",
                            },
                        ],
                    },
                    options: {
                        legend: {
                            display: false,
                        },
                        scale: {
                            ticks: {
                                min: 0,
                                max: 100,
                                stepSize: 20,
                            },
                            pointLabels: {
                                fontColor: "rgb(19, 21, 51)",
                                fontSize: 20,
                            },
                        },
                        elements: {
                            line: {
                                borderWidth: 3,
                            },
                        },
                        tooltips: {
                            callbacks: {
                                label: function (tooltipItem, data) {
                                    var data = "";
                                    switch (tooltipItem.index) {
                                        case 0:
                                            data = apiSectionName[1].label + tooltipItem.yLabel + " %";
                                            break;
                                        case 1:
                                            data = apiSectionName[2].label + tooltipItem.yLabel + " %";
                                            break;
                                        case 2:
                                            data = apiSectionName[3].label + tooltipItem.yLabel + " %";
                                            break;
                                        case 3:
                                            data = apiSectionName[4].label + tooltipItem.yLabel + " %";
                                            break;
                                        case 4:
                                            data = apiSectionName[5].label + tooltipItem.yLabel + " %";
                                            break;

                                        case 5:
                                            data = apiSectionName[6].label + tooltipItem.yLabel + " %";
                                            break;
                                    }

                                    return data;
                                },
                                title: function () {
                                    return null;
                                },
                            },
                        },
                    },
                };
                let pngconfig = {
                    type: "radar",
                    data: {
                        labels: graphlabel2,
                        datasets: [
                            {
                                label: this.state.selected.label,
                                data: [
                                    parseInt((sec.a / (count * 24)) * 100),
                                    parseInt((sec.b / (count * 24)) * 100),
                                    parseInt((sec.c / (count * 24)) * 100),
                                    parseInt((sec.d / (count * 24)) * 100),
                                    parseInt((sec.e / (count * 24)) * 100),
                                    parseInt((sec.f / (count * 24)) * 100),
                                ],
                                fill: false,
                                backgroundColor: "rgb(0, 216, 240,0.2)",
                                borderColor: "rgb(0, 216, 240,0.2)",
                                pointBackgroundColor: "rgb(19, 21, 51,0.1)",
                                pointBorderColor: "rgb(0, 216, 240,0)",
                                pointHoverBackgroundColor: "#fff",
                                pointHoverBorderColor: "rgb(0, 216, 240)",
                            },
                        ],
                    },
                    options: {
                        legend: {
                            display: false,
                        },
                        scale: {
                            ticks: {
                                display: false,
                                min: 0,
                                max: 100,
                                stepSize: 20,
                            },
                            pointLabels: {
                                fontColor: "rgb(19, 21, 51)",
                                fontSize: 0,
                            },
                        },
                        elements: {
                            line: {
                                borderWidth: 3,
                            },
                        },
                        tooltips: {
                            callbacks: {
                                title: function () {
                                    return null;
                                },
                            },
                            // enabled: true,
                            // custom: this.mycustomtooltipfunction
                        },
                    },
                };
                let data1 = {
                    labels: secDatalabel.a,
                    datasets: [
                        {
                            label: this.state.questionlist.slice(0, secCut[0]),

                            data: [
                                parseInt((que.a0 / (4 * count)) * 100),
                                parseInt((que.a1 / (4 * count)) * 100),
                                parseInt((que.a2 / (4 * count)) * 100),
                                parseInt((que.a3 / (4 * count)) * 100),
                                parseInt((que.a4 / (4 * count)) * 100),
                                parseInt((que.a5 / (4 * count)) * 100),
                                parseInt((que.a6 / (4 * count)) * 100),
                                parseInt((que.a7 / (4 * count)) * 100),
                            ],
                            backgroundColor: [
                                this.state.chartcolors[0],
                                this.state.chartcolors[0],
                                this.state.chartcolors[0],
                                this.state.chartcolors[0],
                                this.state.chartcolors[0],
                                this.state.chartcolors[0],
                                this.state.chartcolors[0],
                                this.state.chartcolors[0],
                            ],
                        },
                    ],
                };
                let data2 = {
                    labels: secDatalabel.b,
                    datasets: [
                        {
                            label: this.state.questionlist.slice(secCut[0], secCut[1]),
                            data: [
                                parseInt((que.b0 / (4 * count)) * 100),
                                parseInt((que.b1 / (4 * count)) * 100),
                                parseInt((que.b2 / (4 * count)) * 100),
                                parseInt((que.b3 / (4 * count)) * 100),
                                parseInt((que.b4 / (4 * count)) * 100),
                                parseInt((que.b5 / (4 * count)) * 100),
                                parseInt((que.b6 / (4 * count)) * 100),
                                parseInt((que.b7 / (4 * count)) * 100),
                            ],
                            backgroundColor: [
                                this.state.chartcolors[1],
                                this.state.chartcolors[1],
                                this.state.chartcolors[1],
                                this.state.chartcolors[1],
                                this.state.chartcolors[1],
                                this.state.chartcolors[1],
                                this.state.chartcolors[1],
                                this.state.chartcolors[1],
                            ],
                        },
                    ],
                };
                let data3 = {
                    labels: secDatalabel.c,
                    datasets: [
                        {
                            label: this.state.questionlist.slice(secCut[1], secCut[2]),

                            data: [
                                parseInt((que.c0 / (4 * count)) * 100),
                                parseInt((que.c1 / (4 * count)) * 100),
                                parseInt((que.c2 / (4 * count)) * 100),
                                parseInt((que.c3 / (4 * count)) * 100),
                                parseInt((que.c4 / (4 * count)) * 100),
                                parseInt((que.c5 / (4 * count)) * 100),
                                parseInt((que.c6 / (4 * count)) * 100),
                                parseInt((que.c7 / (4 * count)) * 100),
                            ],
                            backgroundColor: [
                                this.state.chartcolors[2],
                                this.state.chartcolors[2],
                                this.state.chartcolors[2],
                                this.state.chartcolors[2],
                                this.state.chartcolors[2],
                                this.state.chartcolors[2],
                                this.state.chartcolors[2],
                                this.state.chartcolors[2],
                            ],

                            borderWidth: 1,
                        },
                    ],
                };
                let data4 = {
                    labels: secDatalabel.d,
                    datasets: [
                        {
                            label: this.state.questionlist.slice(secCut[2], secCut[3]),

                            data: [
                                parseInt((que.d0 / (4 * count)) * 100),
                                parseInt((que.d1 / (4 * count)) * 100),
                                parseInt((que.d2 / (4 * count)) * 100),
                                parseInt((que.d3 / (4 * count)) * 100),
                                parseInt((que.d4 / (4 * count)) * 100),
                                parseInt((que.d5 / (4 * count)) * 100),
                                parseInt((que.d6 / (4 * count)) * 100),
                                parseInt((que.d7 / (4 * count)) * 100),
                            ],
                            backgroundColor: [
                                this.state.chartcolors[3],
                                this.state.chartcolors[3],
                                this.state.chartcolors[3],
                                this.state.chartcolors[3],
                                this.state.chartcolors[3],
                                this.state.chartcolors[3],
                                this.state.chartcolors[3],
                                this.state.chartcolors[3],
                            ],

                            borderWidth: 1,
                        },
                    ],
                };
                let data5 = {
                    labels: secDatalabel.e,
                    datasets: [
                        {
                            label: this.state.questionlist.slice(secCut[3], secCut[4]),

                            data: [
                                parseInt((que.e0 / (4 * count)) * 100),
                                parseInt((que.e1 / (4 * count)) * 100),
                                parseInt((que.e2 / (4 * count)) * 100),
                                parseInt((que.e3 / (4 * count)) * 100),
                                parseInt((que.e4 / (4 * count)) * 100),
                                parseInt((que.e5 / (4 * count)) * 100),
                                parseInt((que.e6 / (4 * count)) * 100),
                                parseInt((que.e7 / (4 * count)) * 100),
                            ],
                            backgroundColor: [
                                this.state.chartcolors[4],
                                this.state.chartcolors[4],
                                this.state.chartcolors[4],
                                this.state.chartcolors[4],
                                this.state.chartcolors[4],
                                this.state.chartcolors[4],
                                this.state.chartcolors[4],
                                this.state.chartcolors[4],
                            ],

                            borderWidth: 1,
                        },
                    ],
                };
                let data6 = {
                    labels: secDatalabel.f,
                    datasets: [
                        {
                            label: this.state.questionlist.slice(secCut[4], secCut[5]),

                            data: [
                                parseInt((que.f0 / (4 * count)) * 100),
                                parseInt((que.f1 / (4 * count)) * 100),
                                parseInt((que.f2 / (4 * count)) * 100),
                                parseInt((que.f3 / (4 * count)) * 100),
                                parseInt((que.f4 / (4 * count)) * 100),
                                parseInt((que.f5 / (4 * count)) * 100),
                                parseInt((que.f6 / (4 * count)) * 100),
                                parseInt((que.f7 / (4 * count)) * 100),
                            ],
                            backgroundColor: [
                                this.state.chartcolors[5],
                                this.state.chartcolors[5],
                                this.state.chartcolors[5],
                                this.state.chartcolors[5],
                                this.state.chartcolors[5],
                                this.state.chartcolors[5],
                                this.state.chartcolors[5],
                                this.state.chartcolors[5],
                            ],

                            borderWidth: 1,
                        },
                    ],
                };

                let secchart = this.state.sectionchart;
                secchart["a"] = data1;
                secchart["b"] = data2;
                secchart["c"] = data3;
                secchart["d"] = data4;
                secchart["e"] = data5;
                secchart["f"] = data6;
                let quechart = this.state.questionChart;

                quechart["a"] = s1Q;
                quechart["b"] = s2Q;
                quechart["c"] = s3Q;
                quechart["d"] = s4Q;
                quechart["e"] = s5Q;
                quechart["f"] = s6Q;
                let as = []
                Object.values(quechart).forEach((a, i) => {

                    a.forEach((b) => {
                        if (b.length !== 0) {
                            as.push(b.filter((j) => { return j === 0 }).length)
                            as.push(b.filter((j) => { return j === 1 }).length)

                            as.push(b.filter((j) => { return j === 2 }).length)
                            as.push(b.filter((j) => { return j === 3 }).length)
                            as.push(b.filter((j) => { return j === 4 }).length)

                        }
                    })

                })
                this.setState({ questionChart: quechart, questionChartMaxSize: Math.max(...as) + 50 });

                this.setState({
                    response: filterResData,
                    radargraph: config, role, selectedRole: role[0],
                    radargraph2: pngconfig,
                    sectionchart: secchart,
                });
                this.setState({ spinner: false })
            } else {
                this.setState({ spinner: false })
            }
        });
    }
    async showDashboard(id) {
        this.setState({
            selected1: id,
        });
        const response = await fetch(
            "https://api.acuizen.com/companies" + "/" + id.value + "/" + "moss-users"
        );
        let data = await response.json();
        let user = [];
        data.forEach((element) => {
            user.push({ value: element.id, label: element.assesment_title });
        });
        this.setState({
            assessment: user,
            value: undefined,
            selected: [],
            role: [],
            totalSurvey: 0,
        });

        if (user.length !== 0) {
            this.handleChange(user[0]);
        }
    }
    setSelected = (option) => {
        this.setState({
            selected: option,
        });
    };
    getPercentage = () => {

    }
    getQuestionChartDatasets(type, sets) {

        let bg = [
            this.state.chartcolors[type - 1],
            this.state.chartcolors[type - 1],
            this.state.chartcolors[type - 1],
            this.state.chartcolors[type - 1],
            this.state.chartcolors[type - 1],
            this.state.chartcolors[type - 1],
            this.state.chartcolors[type - 1],
            this.state.chartcolors[type - 1],
        ];
        let loc = (sets.length / 100)
        let data = {
            labels: [
                "Not True",
                "Seldom True",
                "Occasionally True",
                "Mostly True",
                "Always True",
            ],
            datasets: [
                {
                    label: [
                        "Not True",
                        "Seldom True",
                        "Occasionally True",
                        "Mostly True",
                        "Always True",
                    ],
                    rawdata: [
                        (sets.filter((i) => i === 0).length),
                        (sets.filter((i) => i === 1).length),
                        (sets.filter((i) => i === 2).length),
                        (sets.filter((i) => i === 3).length),
                        (sets.filter((i) => i === 4).length)
                    ],
                    data: [
                        ((sets.filter((i) => i === 0).length) / loc).toFixed(2),
                        ((sets.filter((i) => i === 1).length) / loc).toFixed(2),
                        ((sets.filter((i) => i === 2).length) / loc).toFixed(2),
                        ((sets.filter((i) => i === 3).length) / loc).toFixed(2),
                        ((sets.filter((i) => i === 4).length) / loc).toFixed(2)
                    ],
                    backgroundColor: bg,
                },
            ],
        };
        return data;
    }
    onSelectRole = (e) => {
        this.setState({ selectedRole: e });


        this.renderRole(e.label);
        this.forceUpdate();
    };

    getUniqueDataCount(objArr, propName) {
        var data = [];
        if (Array.isArray(propName)) {
            propName.forEach(prop => {
                objArr.forEach(function (d, index) {
                    if (d[prop]) {
                        data.push(d[prop]);
                    }
                });
            });
        } else {
            objArr.forEach(function (d, index) {
                if (d[propName]) {
                    data.push(d[propName]);
                }
            });
        }

        var uniqueList = [...new Set(data)];

        var dataSet = {};
        for (var i = 0; i < uniqueList.length; i++) {
            dataSet[uniqueList[i]] = data.filter(x => x == uniqueList[i]).length;
        }

        return dataSet;
    }
    getMossTitle = (value) => {
        this.setState({
            value: value,
        });
        let tier1 = [],
            tier2 = [],
            tier3 = [],
            tier4 = [];
        let sec = { a: 0, b: 0, c: 0, d: 0, e: 0, f: 0 };
        let sets = ["a", "b", "c", "d", "e", "f", "g", "h", "j", "i"];
        let que = {};
        let secCut = [];
        let secgraphlabel = [];
        let graphlabel = [];
        let role = [{ label: 'All' }]
        let s1Q = [[], [], [], [], [], [], [], []];
        let s2Q = [[], [], [], [], [], [], [], []];
        let s3Q = [[], [], [], [], [], [], [], []];
        let s4Q = [[], [], [], [], [], [], [], []];
        let s5Q = [[], [], [], [], [], [], [], []];
        let s6Q = [[], [], [], [], [], [], [], []];
        let sectionname = [{ label: "All", value: "All" }];
        const counts = { topic1: 0, topic2: 0, topic3: 0, topic4: 0 };

        // const url =  'https://api.acuizen.com/custom-responses/' + value;
        const url =
            value === "All"
                ? "https://api.acuizen.com/custom-responses-all/"
                : "https://api.acuizen.com/custom-responses-beta/" + value;

        axios({
            method: "get",
            url: url,

            headers: {
                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            },
            mode: "no-cors",
        }).then((res) => {



            if (res.data.length !== 0) {
                //editrenderfilter
                let tierTopicCount = [];

                let sectionwisequestioncount = [];
                let secDatalabel = { a: [], b: [], c: [], d: [], e: [], f: [] };
                res.data.forEach((i) => {


                    let index = tierTopicCount.findIndex((t) => t.name === this.state.usertopic1.filter((k) => { if (k.id === i.topic1) { return k.name } })[0].name);
                    if (index === -1) {
                        tierTopicCount.push({
                            name: this.state.usertopic1.filter((k) => { if (k.id === i.topic1) { return k.name } })[0].name,
                            count: 1,
                            topic: "tier1",
                        });
                    } else {
                        tierTopicCount[index] = {
                            name: this.state.usertopic1.filter((k) => { if (k.id === i.topic1) { return k.name } })[0].name,
                            count: tierTopicCount[index].count + 1,
                            topic: "tier1",
                        };
                    }
                    let index2 = tierTopicCount.findIndex(
                        (t) => t.name === this.state.usertopic2.filter((k) => { if (k.id === i.topic2) { return k.name } })[0].name
                    );
                    if (index2 === -1) {
                        tierTopicCount.push({
                            name: this.state.usertopic2.filter((k) => { if (k.id === i.topic2) { return k.name } })[0].name,
                            count: 1,
                            topic: "tier2",
                        });
                    } else {
                        tierTopicCount[index2] = {
                            name: this.state.usertopic2.filter((k) => { if (k.id === i.topic2) { return k.name } })[0].name,
                            count: tierTopicCount[index2].count + 1,
                            topic: "tier2",
                        };
                    }


                });
                sectionwisequestioncount = JSON.parse(JSON.stringify(this.state.characteristic))
                sectionwisequestioncount.splice(0, 1)
                sectionwisequestioncount.forEach((k, ind) => {


                    if (ind === 0) {
                        secCut.push(k.count);
                    } else {
                        secCut.push(secCut[ind - 1] + k.count);
                    }

                    for (var is = 0; is < k.count; is++) {
                        que[sets[ind] + is] = 0;
                        if (secDatalabel[sets[ind]] !== undefined) {
                            secDatalabel[sets[ind]].push(this.getSection(ind, is));
                        }
                    }

                });


                this.setState({
                    response: res.data,
                    tiercountbytopic: {
                        t1: res.data.length,
                        t2: res.data.length,
                        t3: res.data.length,
                        t4: res.data.length,
                    },
                });
                res.data.forEach((i, ind) => {
                    if (role.findIndex((k) => { return k.label === i.role }) === -1) {
                        role.push({ label: i.role, value: i.role })
                    }
                    tier1.push({
                        topic1: this.state.usertopic1.filter((k) => { if (k.id === i.topic1) { return k.name } })[0].name,
                        topic2: this.state.usertopic2.filter((k) => { if (k.id === i.topic2) { return k.name } })[0].name,
                        topic3: i.topic3,
                        topic4: i.topic4,
                    });


                    i.input_json.forEach((section, sind) => {
                        if (graphlabel.findIndex((i) => i === section.name) === -1) {
                            graphlabel.push(section.name);
                        }

                        section.questions[0].selectedValues.forEach((question, qind) => {
                            if (
                                secgraphlabel.findIndex(
                                    (i) =>
                                        i ===
                                        question.label
                                            .replace(/(<([^>]+)>)/gi, "")
                                            .replace(/\n/g, " ")
                                            .replace(/&nbsp;/g, " ")
                                ) === -1
                            ) {
                                // secgraphlabel.push(question.label.replace(/(<([^>]+)>)/gi, "").replace(/\n/g, ' ').replace(/&nbsp;/g, ' '))
                            }
                            if (sind === 0) {
                                console.log(question)
                                s1Q[qind].push(
                                    (parseInt(question.trim()) - 1)
                                );
                                que[`a${qind}`] =
                                    que[`a${qind}`] +
                                    (parseInt(question.trim()) - 1);
                                //  que[qind].push(question.values.findIndex((i)=> i.selected === true))
                                sec.a =
                                    sec.a + (parseInt(question.trim()) - 1);
                            } else if (sind === 1) {
                                s2Q[qind].push(
                                    (parseInt(question.trim()) - 1)
                                );
                                que[`b${qind}`] =
                                    que[`b${qind}`] +
                                    (parseInt(question.trim()) - 1);
                                sec.b =
                                    sec.b + (parseInt(question.trim()) - 1);
                            } else if (sind === 2) {
                                s3Q[qind].push(
                                    (parseInt(question.trim()) - 1)
                                );
                                que[`c${qind}`] =
                                    que[`c${qind}`] +
                                    (parseInt(question.trim()) - 1)
                                sec.c =
                                    sec.c + (parseInt(question.trim()) - 1)
                            } else if (sind === 3) {
                                s4Q[qind].push(
                                    (parseInt(question.trim()) - 1)
                                );
                                que[`d${qind}`] =
                                    que[`d${qind}`] +
                                    (parseInt(question.trim()) - 1);
                                sec.d =
                                    sec.d + (parseInt(question.trim()) - 1)
                            } else if (sind === 4) {
                                s5Q[qind].push(
                                    (parseInt(question.trim()) - 1)
                                );
                                que[`e${qind}`] =
                                    que[`e${qind}`] +
                                    (parseInt(question.trim()) - 1)
                                sec.e =
                                    sec.e + (parseInt(question.trim()) - 1)
                            } else if (sind === 5) {
                                s6Q[qind].push(
                                    (parseInt(question.trim()) - 1)
                                );
                                que[`f${qind}`] =
                                    que[`f${qind}`] +
                                    (parseInt(question.trim()) - 1)
                                sec.f =
                                    sec.f + (parseInt(question.trim()) - 1)
                            }
                        });
                    });
                });


                //newnew
                this.setState({ selectedRole: role[0] });
                let graphlabel2 = [];
                let apiSectionName = this.state.characteristic;
                graphlabel.forEach((k, ind) => {
                    if (ind === 0) {
                        graphlabel2.push(
                            apiSectionName[ind + 1].label +
                            " (" +
                            parseInt((sec.a / (res.data.length * 24)) * 100) +
                            " %)"
                        );
                    } else if (ind === 1) {
                        graphlabel2.push(
                            apiSectionName[ind + 1].label +
                            " (" +
                            parseInt((sec.b / (res.data.length * 24)) * 100) +
                            " %)"
                        );
                    } else if (ind === 2) {
                        graphlabel2.push(
                            apiSectionName[ind + 1].label +
                            " (" +
                            parseInt((sec.c / (res.data.length * 24)) * 100) +
                            " %)"
                        );
                    } else if (ind === 3) {
                        graphlabel2.push(
                            apiSectionName[ind + 1].label +
                            " (" +
                            parseInt((sec.d / (res.data.length * 24)) * 100) +
                            " %)"
                        );
                    } else if (ind === 4) {
                        graphlabel2.push(
                            apiSectionName[ind + 1].label +
                            " (" +
                            parseInt((sec.e / (res.data.length * 24)) * 100) +
                            " %)"
                        );
                    } else if (ind === 5) {
                        graphlabel2.push(
                            apiSectionName[ind + 1].label +
                            " (" +
                            parseInt((sec.f / (res.data.length * 24)) * 100) +
                            " %)"
                        );
                    }
                });

                let config = {
                    type: "radar",
                    data: {
                        labels: graphlabel2,
                        datasets: [
                            {
                                label: this.state.selected.label,
                                data: [
                                    parseInt((sec.a / (res.data.length * 24)) * 100),
                                    parseInt((sec.b / (res.data.length * 24)) * 100),
                                    parseInt((sec.c / (res.data.length * 24)) * 100),
                                    parseInt((sec.d / (res.data.length * 24)) * 100),
                                    parseInt((sec.e / (res.data.length * 24)) * 100),
                                    parseInt((sec.f / (res.data.length * 24)) * 100),
                                ],
                                fill: true,
                                backgroundColor: "rgb(0, 216, 240,0.2)",
                                borderColor: "rgb(0, 216, 240,0.2)",
                                pointBackgroundColor: "rgb(19, 21, 51,0.1)",
                                pointBorderColor: "rgb(0, 216, 240,0)",
                                pointHoverBackgroundColor: "#fff",
                                pointHoverBorderColor: "rgb(0, 216, 240)",
                            },
                        ],
                    },
                    options: {
                        legend: {
                            display: false,
                        },

                        scale: {
                            ticks: {
                                min: 0,
                                max: 100,
                                stepSize: 20,

                                userCallback: function (value, index, values) {
                                    return value;
                                },
                            },

                            pointLabels: {
                                fontColor: "rgb(19, 21, 51)",
                                fontSize: 20,
                            },
                        },
                        elements: {
                            line: {
                                borderWidth: 3,
                            },
                        },
                        tooltips: {
                            callbacks: {
                                label: function (tooltipItem, data) {
                                    var data = "";
                                    switch (tooltipItem.index) {
                                        case 0:
                                            data =
                                                apiSectionName[1].label + tooltipItem.yLabel + " %";
                                            break;
                                        case 1:
                                            data =
                                                apiSectionName[2].label + tooltipItem.yLabel + " %";
                                            break;
                                        case 2:
                                            data =
                                                apiSectionName[3].label + tooltipItem.yLabel + " %";
                                            break;
                                        case 3:
                                            data =
                                                apiSectionName[4].label + tooltipItem.yLabel + " %";
                                            break;
                                        case 4:
                                            data =
                                                apiSectionName[5].label + tooltipItem.yLabel + " %";
                                            break;

                                        case 5:
                                            data =
                                                apiSectionName[6].label + tooltipItem.yLabel + " %";
                                            break;
                                    }

                                    return data;
                                },
                                title: function () {
                                    return null;
                                },
                            },
                            // enabled: true,
                            // custom: this.mycustomtooltipfunction
                        },
                    },
                };

                let pngconfig = {
                    type: "radar",
                    data: {
                        labels: graphlabel2,
                        datasets: [
                            {
                                label: this.state.selected.label,
                                data: [
                                    parseInt((sec.a / (res.data.length * 24)) * 100),
                                    parseInt((sec.b / (res.data.length * 24)) * 100),
                                    parseInt((sec.c / (res.data.length * 24)) * 100),
                                    parseInt((sec.d / (res.data.length * 24)) * 100),
                                    parseInt((sec.e / (res.data.length * 24)) * 100),
                                    parseInt((sec.f / (res.data.length * 24)) * 100),
                                ],
                                fill: false,
                                backgroundColor: "rgb(0, 216, 240,0.2)",
                                borderColor: "rgb(0, 216, 240,0.2)",
                                pointBackgroundColor: "rgb(19, 21, 51,0.1)",
                                pointBorderColor: "rgb(0, 216, 240,0)",
                                pointHoverBackgroundColor: "#fff",
                                pointHoverBorderColor: "rgb(0, 216, 240)",
                            },
                        ],
                    },
                    options: {
                        legend: {
                            display: false,
                        },
                        scale: {
                            ticks: {
                                display: false,
                                min: 0,
                                max: 100,
                                stepSize: 20,
                            },
                            pointLabels: {
                                fontColor: "rgb(19, 21, 51)",
                                fontSize: 0,
                            },
                        },
                        elements: {
                            line: {
                                borderWidth: 3,
                            },
                        },
                        tooltips: {
                            callbacks: {
                                title: function () {
                                    return null;
                                },
                            },
                            // enabled: true,
                            // custom: this.mycustomtooltipfunction
                        },
                    },
                };

                let data1 = {
                    labels: secDatalabel.a,
                    datasets: [
                        {
                            label: this.state.questionlist.slice(0, secCut[0]),

                            data: [
                                parseInt((que.a0 / (4 * res.data.length)) * 100),
                                parseInt((que.a1 / (4 * res.data.length)) * 100),
                                parseInt((que.a2 / (4 * res.data.length)) * 100),
                                parseInt((que.a3 / (4 * res.data.length)) * 100),
                                parseInt((que.a4 / (4 * res.data.length)) * 100),
                                parseInt((que.a5 / (4 * res.data.length)) * 100),
                                parseInt((que.a6 / (4 * res.data.length)) * 100),
                                parseInt((que.a7 / (4 * res.data.length)) * 100),
                            ],
                            backgroundColor: [
                                this.state.chartcolors[0],
                                this.state.chartcolors[0],
                                this.state.chartcolors[0],
                                this.state.chartcolors[0],
                                this.state.chartcolors[0],
                                this.state.chartcolors[0],
                                this.state.chartcolors[0],
                                this.state.chartcolors[0],
                            ],
                        },
                    ],
                };

                let data2 = {
                    labels: secDatalabel.b,
                    datasets: [
                        {
                            label: this.state.questionlist.slice(secCut[0], secCut[1]),
                            data: [
                                parseInt((que.b0 / (4 * res.data.length)) * 100),
                                parseInt((que.b1 / (4 * res.data.length)) * 100),
                                parseInt((que.b2 / (4 * res.data.length)) * 100),
                                parseInt((que.b3 / (4 * res.data.length)) * 100),
                                parseInt((que.b4 / (4 * res.data.length)) * 100),
                                parseInt((que.b5 / (4 * res.data.length)) * 100),
                                parseInt((que.b6 / (4 * res.data.length)) * 100),
                                parseInt((que.b7 / (4 * res.data.length)) * 100),
                            ],
                            backgroundColor: [
                                this.state.chartcolors[1],
                                this.state.chartcolors[1],
                                this.state.chartcolors[1],
                                this.state.chartcolors[1],
                                this.state.chartcolors[1],
                                this.state.chartcolors[1],
                                this.state.chartcolors[1],
                                this.state.chartcolors[1],
                            ],
                        },
                    ],
                };
                let data3 = {
                    labels: secDatalabel.c,
                    datasets: [
                        {
                            label: this.state.questionlist.slice(secCut[1], secCut[2]),

                            data: [
                                parseInt((que.c0 / (4 * res.data.length)) * 100),
                                parseInt((que.c1 / (4 * res.data.length)) * 100),
                                parseInt((que.c2 / (4 * res.data.length)) * 100),
                                parseInt((que.c3 / (4 * res.data.length)) * 100),
                                parseInt((que.c4 / (4 * res.data.length)) * 100),
                                parseInt((que.c5 / (4 * res.data.length)) * 100),
                                parseInt((que.c6 / (4 * res.data.length)) * 100),
                                parseInt((que.c7 / (4 * res.data.length)) * 100),
                            ],
                            backgroundColor: [
                                this.state.chartcolors[2],
                                this.state.chartcolors[2],
                                this.state.chartcolors[2],
                                this.state.chartcolors[2],
                                this.state.chartcolors[2],
                                this.state.chartcolors[2],
                                this.state.chartcolors[2],
                                this.state.chartcolors[2],
                            ],

                            borderWidth: 1,
                        },
                    ],
                };

                let data4 = {
                    labels: secDatalabel.d,
                    datasets: [
                        {
                            label: this.state.questionlist.slice(secCut[2], secCut[3]),

                            data: [
                                parseInt((que.d0 / (4 * res.data.length)) * 100),
                                parseInt((que.d1 / (4 * res.data.length)) * 100),
                                parseInt((que.d2 / (4 * res.data.length)) * 100),
                                parseInt((que.d3 / (4 * res.data.length)) * 100),
                                parseInt((que.d4 / (4 * res.data.length)) * 100),
                                parseInt((que.d5 / (4 * res.data.length)) * 100),
                                parseInt((que.d6 / (4 * res.data.length)) * 100),
                                parseInt((que.d7 / (4 * res.data.length)) * 100),
                            ],
                            backgroundColor: [
                                this.state.chartcolors[3],
                                this.state.chartcolors[3],
                                this.state.chartcolors[3],
                                this.state.chartcolors[3],
                                this.state.chartcolors[3],
                                this.state.chartcolors[3],
                                this.state.chartcolors[3],
                                this.state.chartcolors[3],
                            ],

                            borderWidth: 1,
                        },
                    ],
                };
                let data5 = {
                    labels: secDatalabel.e,
                    datasets: [
                        {
                            label: this.state.questionlist.slice(secCut[3], secCut[4]),

                            data: [
                                parseInt((que.e0 / (4 * res.data.length)) * 100),
                                parseInt((que.e1 / (4 * res.data.length)) * 100),
                                parseInt((que.e2 / (4 * res.data.length)) * 100),
                                parseInt((que.e3 / (4 * res.data.length)) * 100),
                                parseInt((que.e4 / (4 * res.data.length)) * 100),
                                parseInt((que.e5 / (4 * res.data.length)) * 100),
                                parseInt((que.e6 / (4 * res.data.length)) * 100),
                                parseInt((que.e7 / (4 * res.data.length)) * 100),
                            ],
                            backgroundColor: [
                                this.state.chartcolors[4],
                                this.state.chartcolors[4],
                                this.state.chartcolors[4],
                                this.state.chartcolors[4],
                                this.state.chartcolors[4],
                                this.state.chartcolors[4],
                                this.state.chartcolors[4],
                                this.state.chartcolors[4],
                            ],

                            borderWidth: 1,
                        },
                    ],
                };
                let data6 = {
                    labels: secDatalabel.f,
                    datasets: [
                        {
                            label: this.state.questionlist.slice(secCut[4], secCut[5]),

                            data: [
                                parseInt((que.f0 / (4 * res.data.length)) * 100),
                                parseInt((que.f1 / (4 * res.data.length)) * 100),
                                parseInt((que.f2 / (4 * res.data.length)) * 100),
                                parseInt((que.f3 / (4 * res.data.length)) * 100),
                                parseInt((que.f4 / (4 * res.data.length)) * 100),
                                parseInt((que.f5 / (4 * res.data.length)) * 100),
                                parseInt((que.f6 / (4 * res.data.length)) * 100),
                                parseInt((que.f7 / (4 * res.data.length)) * 100),
                            ],
                            backgroundColor: [
                                this.state.chartcolors[5],
                                this.state.chartcolors[5],
                                this.state.chartcolors[5],
                                this.state.chartcolors[5],
                                this.state.chartcolors[5],
                                this.state.chartcolors[5],
                                this.state.chartcolors[5],
                                this.state.chartcolors[5],
                            ],

                            borderWidth: 1,
                        },
                    ],
                };

                let secchart = this.state.sectionchart;
                secchart["a"] = data1;
                secchart["b"] = data2;
                secchart["c"] = data3;
                secchart["d"] = data4;
                secchart["e"] = data5;
                secchart["f"] = data6;

                let quechart = this.state.questionChart;
                console.log(s5Q)
                quechart["a"] = s1Q;
                quechart["b"] = s2Q;
                quechart["c"] = s3Q;
                quechart["d"] = s4Q;
                quechart["e"] = s5Q;
                quechart["f"] = s6Q;
                let as = []
                Object.values(quechart).forEach((a, i) => {

                    a.forEach((b) => {
                        if (b.length !== 0) {
                            as.push(b.filter((j) => { return j === 0 }).length)
                            as.push(b.filter((j) => { return j === 1 }).length)

                            as.push(b.filter((j) => { return j === 2 }).length)
                            as.push(b.filter((j) => { return j === 3 }).length)
                            as.push(b.filter((j) => { return j === 4 }).length)

                            // as.push({[Object.keys(data)]:b.filter((j)=> {return j === 1}).length})
                            // as.push({[Object.keys(data)]:b.filter((j)=> {return j === 2}).length})
                            // as.push({[Object.keys(data)]:b.filter((j)=> {return j === 3}).length})
                            // as.push({[Object.keys(data)]:b.filter((j)=> {return j === 4}).length})
                        }
                    })

                })

                this.setState({ questionChart: quechart, questionChartMaxSize: Math.max(...as) + 50 });

                let tierlist = tier1.filter(
                    (value, index, self) =>
                        index ===
                        self.findIndex(
                            (t) =>
                                t.topic1 === value.topic1 &&
                                t.topic2 === value.topic2 &&
                                t.topic3 === value.topic3 &&
                                t.topic4 === value.topic4
                        )
                );
                let selectedList = this.state.selectedtierlist;
                let tier1list = [{ label: "All", value: 0 }];
                let tier2list = [{ label: "All", value: 0 }];

                tierlist.forEach((i, j) => {
                    if (
                        tier1list.findIndex((a) => {
                            return a.label === i.topic1;
                        }) === -1
                    ) {
                        if (i.topic1 !== undefined) {
                            tier1list.push({ label: i.topic1, value: j + 1 });
                        }
                    }
                    if (
                        tier2list.findIndex((a) => {
                            return a.label === i.topic2;
                        }) === -1
                    ) {
                        if (i.topic1 !== undefined) {
                            tier2list.push({ label: i.topic2, value: j + 1 });
                        }
                    }
                });
                selectedList.t1 = tier1list;
                selectedList.t2 = tier2list
                setTimeout(() => {
                    if (res.data.length !== 0) {
                        this.freezeFilter();
                    }
                });

                this.quesyaxisstepsize = res.data.length;
                let loc = this.state.selectedtier;
                loc.t1 = { label: "All", value: 0 }
                loc.t2 = { label: "All", value: 0 }
                this.setState({
                    totalSurvey: res.data.length,
                    tierresponsecount: tier1,
                    section: sec,
                    selectedtier: loc,
                    loader: true,
                    question: que,
                    radargraph: config,
                    radargraph2: pngconfig,
                    sectionchart: secchart,
                    tierlist,
                    selectedList: tier1list,
                    role
                });

                this.setState({ spinner: false })
            } else {
                this.setState({ spinner: false })
                Swal.fire({
                    icon: "error",
                    titleText: "No reponse found",
                    showConfirmButton: false,
                    showCancelButton: false,
                    timer: 1500,
                });
            }

        });
    };
    renderResponse = () => {
        let tier1 = [],
            tier2 = [],
            tier3 = [],
            tier4 = [];
        let sec = { a: 0, b: 0, c: 0, d: 0, e: 0, f: 0 };
        let sets = ["a", "b", "c", "d", "e", "f", "g", "h", "j", "i"];
        let que = {};
        let secCut = [];
        let secgraphlabel = [];
        let graphlabel = [];

        let s1Q = [[], [], [], [], [], [], [], []];
        let s2Q = [[], [], [], [], [], [], [], []];
        let s3Q = [[], [], [], [], [], [], [], []];
        let s4Q = [[], [], [], [], [], [], [], []];
        let s5Q = [[], [], [], [], [], [], [], []];
        let s6Q = [[], [], [], [], [], [], [], []];
        let sectionname = [{ label: "All", value: "All" }];
        let res = { data: responseData }
        if (res.data.length !== 0) {
            //editrenderfilter
            let tierTopicCount = [];
            let roles = [{ value: res.data.length + 1, label: "All" }];
            let sectionwisequestioncount = [];
            let secDatalabel = { a: [], b: [], c: [], d: [], e: [], f: [] };
            res.data.forEach((i) => {
                i.input_json.forEach((k) => {
                    if (
                        sectionname.findIndex((ia) => {
                            return ia.label === k.name;
                        }) === -1
                    ) {
                        sectionname.push({ label: k.name, value: k.name });
                    }
                    if (
                        sectionwisequestioncount.findIndex((ia) => {
                            return ia.name === k.name;
                        }) === -1
                    ) {
                        sectionwisequestioncount.push({
                            name: k.name,
                            count: k.questions[0].value.length,
                        });
                    }
                });

                let index = tierTopicCount.findIndex((t) => t.name === i.topic1.name);
                if (index === -1) {
                    tierTopicCount.push({
                        name: i.topic1.name,
                        count: 1,
                        topic: "tier1",
                    });
                } else {
                    tierTopicCount[index] = {
                        name: i.topic1.name,
                        count: tierTopicCount[index].count + 1,
                        topic: "tier1",
                    };
                }
                let index2 = tierTopicCount.findIndex(
                    (t) => t.name === i.topic2.name
                );
                if (index2 === -1) {
                    tierTopicCount.push({
                        name: i.topic2.name,
                        count: 1,
                        topic: "tier2",
                    });
                } else {
                    tierTopicCount[index2] = {
                        name: i.topic2.name,
                        count: tierTopicCount[index2].count + 1,
                        topic: "tier2",
                    };
                }
                let index3 = tierTopicCount.findIndex(
                    (t) => t.name === i.topic3.name
                );
                if (index3 === -1) {
                    tierTopicCount.push({
                        name: i.topic3.name,
                        count: 1,
                        topic: "tier3",
                    });
                } else {
                    tierTopicCount[index3] = {
                        name: i.topic3.name,
                        count: tierTopicCount[index3].count + 1,
                        topic: "tier3",
                    };
                }
            });
            sectionwisequestioncount.forEach((k, ind) => {
                if (ind === 0) {
                    secCut.push(k.count);
                } else {
                    secCut.push(secCut[ind - 1] + k.count);
                }

                for (var is = 0; is < k.count; is++) {
                    que[sets[ind] + is] = 0;
                    if (secDatalabel[sets[ind]] !== undefined) {
                        secDatalabel[sets[ind]].push(this.getSection(ind, is));
                    }
                }
            });

            this.setState({
                response: res.data,
                tiercountbytopic: {
                    t1: res.data.length,
                    t2: res.data.length,
                    t3: res.data.length,
                    t4: res.data.length,
                },
            });
            res.data.forEach((i, ind) => {
                // tier1.push({
                //     topic1: i.topic1.name,
                //     topic2: i.topic2.name,
                //     topic3: i.topic3.name,
                //     topic4: i.topic4.name,
                // });

                if (
                    roles.findIndex((k) => {
                        return k.label === i.role;
                    }) === -1
                ) {
                    roles.push({ value: i.role, label: i.role });
                }
                i.input_json.forEach((section, sind) => {
                    if (graphlabel.findIndex((i) => i === section.name) === -1) {
                        graphlabel.push(section.name);
                    }

                    section.questions[0].value.forEach((question, qind) => {
                        if (
                            secgraphlabel.findIndex(
                                (i) =>
                                    i ===
                                    question.label
                                        .replace(/(<([^>]+)>)/gi, "")
                                        .replace(/\n/g, " ")
                                        .replace(/&nbsp;/g, " ")
                            ) === -1
                        ) {
                            // secgraphlabel.push(question.label.replace(/(<([^>]+)>)/gi, "").replace(/\n/g, ' ').replace(/&nbsp;/g, ' '))
                        }
                        if (sind === 0) {
                            s1Q[qind].push(
                                question.values.findIndex((i) => i.selected === true)
                            );
                            que[`a${qind}`] =
                                que[`a${qind}`] +
                                question.values.findIndex((i) => i.selected === true);
                            //  que[qind].push(question.values.findIndex((i)=> i.selected === true))
                            sec.a =
                                sec.a + question.values.findIndex((i) => i.selected === true);
                        } else if (sind === 1) {
                            s2Q[qind].push(
                                question.values.findIndex((i) => i.selected === true)
                            );
                            que[`b${qind}`] =
                                que[`b${qind}`] +
                                question.values.findIndex((i) => i.selected === true);
                            sec.b =
                                sec.b + question.values.findIndex((i) => i.selected === true);
                        } else if (sind === 2) {
                            s3Q[qind].push(
                                question.values.findIndex((i) => i.selected === true)
                            );
                            que[`c${qind}`] =
                                que[`c${qind}`] +
                                question.values.findIndex((i) => i.selected === true);
                            sec.c =
                                sec.c + question.values.findIndex((i) => i.selected === true);
                        } else if (sind === 3) {
                            s4Q[qind].push(
                                question.values.findIndex((i) => i.selected === true)
                            );
                            que[`d${qind}`] =
                                que[`d${qind}`] +
                                question.values.findIndex((i) => i.selected === true);
                            sec.d =
                                sec.d + question.values.findIndex((i) => i.selected === true);
                        } else if (sind === 4) {
                            s5Q[qind].push(
                                question.values.findIndex((i) => i.selected === true)
                            );
                            que[`e${qind}`] =
                                que[`e${qind}`] +
                                question.values.findIndex((i) => i.selected === true);
                            sec.e =
                                sec.e + question.values.findIndex((i) => i.selected === true);
                        } else if (sind === 5) {
                            s6Q[qind].push(
                                question.values.findIndex((i) => i.selected === true)
                            );
                            que[`f${qind}`] =
                                que[`f${qind}`] +
                                question.values.findIndex((i) => i.selected === true);
                            sec.f =
                                sec.f + question.values.findIndex((i) => i.selected === true);
                        }
                    });
                });
            });
            //newnew
            this.setState({ role: roles, selectedRole: roles[0] });
            let graphlabel2 = [];
            let apiSectionName = this.state.characteristic;
            graphlabel.forEach((k, ind) => {
                if (ind === 0) {
                    graphlabel2.push(
                        apiSectionName[ind + 1].label +
                        " (" +
                        parseInt((sec.a / (res.data.length * 24)) * 100) +
                        " %)"
                    );
                } else if (ind === 1) {
                    graphlabel2.push(
                        apiSectionName[ind + 1].label +
                        " (" +
                        parseInt((sec.b / (res.data.length * 24)) * 100) +
                        " %)"
                    );
                } else if (ind === 2) {
                    graphlabel2.push(
                        apiSectionName[ind + 1].label +
                        " (" +
                        parseInt((sec.c / (res.data.length * 24)) * 100) +
                        " %)"
                    );
                } else if (ind === 3) {
                    graphlabel2.push(
                        apiSectionName[ind + 1].label +
                        " (" +
                        parseInt((sec.d / (res.data.length * 24)) * 100) +
                        " %)"
                    );
                } else if (ind === 4) {
                    graphlabel2.push(
                        apiSectionName[ind + 1].label +
                        " (" +
                        parseInt((sec.e / (res.data.length * 24)) * 100) +
                        " %)"
                    );
                } else if (ind === 5) {
                    graphlabel2.push(
                        apiSectionName[ind + 1].label +
                        " (" +
                        parseInt((sec.f / (res.data.length * 24)) * 100) +
                        " %)"
                    );
                }
            });

            let config = {
                type: "radar",
                data: {
                    labels: graphlabel2,
                    datasets: [
                        {
                            label: this.state.selected.label,
                            data: [
                                parseInt((sec.a / (res.data.length * 24)) * 100),
                                parseInt((sec.b / (res.data.length * 24)) * 100),
                                parseInt((sec.c / (res.data.length * 24)) * 100),
                                parseInt((sec.d / (res.data.length * 24)) * 100),
                                parseInt((sec.e / (res.data.length * 24)) * 100),
                                parseInt((sec.f / (res.data.length * 24)) * 100),
                            ],
                            fill: true,
                            backgroundColor: "rgb(0, 216, 240,0.2)",
                            borderColor: "rgb(0, 216, 240,0.2)",
                            pointBackgroundColor: "rgb(19, 21, 51,0.1)",
                            pointBorderColor: "rgb(0, 216, 240,0)",
                            pointHoverBackgroundColor: "#fff",
                            pointHoverBorderColor: "rgb(0, 216, 240)",
                        },
                    ],
                },
                options: {
                    legend: {
                        display: false,
                    },

                    scale: {
                        ticks: {
                            min: 0,
                            max: 100,
                            stepSize: 20,

                            userCallback: function (value, index, values) {
                                return value;
                            },
                        },

                        pointLabels: {
                            fontColor: "rgb(19, 21, 51)",
                            fontSize: 20,
                        },
                    },
                    elements: {
                        line: {
                            borderWidth: 3,
                        },
                    },
                    tooltips: {
                        callbacks: {
                            label: function (tooltipItem, data) {
                                var data = "";
                                switch (tooltipItem.index) {
                                    case 0:
                                        data =
                                            apiSectionName[1].label + tooltipItem.yLabel + " %";
                                        break;
                                    case 1:
                                        data =
                                            apiSectionName[2].label + tooltipItem.yLabel + " %";
                                        break;
                                    case 2:
                                        data =
                                            apiSectionName[3].label + tooltipItem.yLabel + " %";
                                        break;
                                    case 3:
                                        data =
                                            apiSectionName[4].label + tooltipItem.yLabel + " %";
                                        break;
                                    case 4:
                                        data =
                                            apiSectionName[5].label + tooltipItem.yLabel + " %";
                                        break;

                                    case 5:
                                        data =
                                            apiSectionName[6].label + tooltipItem.yLabel + " %";
                                        break;
                                }

                                return data;
                            },
                            title: function () {
                                return null;
                            },
                        },
                        // enabled: true,
                        // custom: this.mycustomtooltipfunction
                    },
                },
            };

            let pngconfig = {
                type: "radar",
                data: {
                    labels: graphlabel2,
                    datasets: [
                        {
                            label: this.state.selected.label,
                            data: [
                                parseInt((sec.a / (res.data.length * 24)) * 100),
                                parseInt((sec.b / (res.data.length * 24)) * 100),
                                parseInt((sec.c / (res.data.length * 24)) * 100),
                                parseInt((sec.d / (res.data.length * 24)) * 100),
                                parseInt((sec.e / (res.data.length * 24)) * 100),
                                parseInt((sec.f / (res.data.length * 24)) * 100),
                            ],
                            fill: false,
                            backgroundColor: "rgb(0, 216, 240,0.2)",
                            borderColor: "rgb(0, 216, 240,0.2)",
                            pointBackgroundColor: "rgb(19, 21, 51,0.1)",
                            pointBorderColor: "rgb(0, 216, 240,0)",
                            pointHoverBackgroundColor: "#fff",
                            pointHoverBorderColor: "rgb(0, 216, 240)",
                        },
                    ],
                },
                options: {
                    legend: {
                        display: false,
                    },
                    scale: {
                        ticks: {
                            display: false,
                            min: 0,
                            max: 100,
                            stepSize: 20,
                        },
                        pointLabels: {
                            fontColor: "rgb(19, 21, 51)",
                            fontSize: 0,
                        },
                    },
                    elements: {
                        line: {
                            borderWidth: 3,
                        },
                    },
                    tooltips: {
                        callbacks: {
                            title: function () {
                                return null;
                            },
                        },
                        // enabled: true,
                        // custom: this.mycustomtooltipfunction
                    },
                },
            };
            let data1 = {
                labels: secDatalabel.a,
                datasets: [
                    {
                        label: this.state.questionlist.slice(0, secCut[0]),

                        data: [
                            parseInt((que.a0 / (4 * res.data.length)) * 100),
                            parseInt((que.a1 / (4 * res.data.length)) * 100),
                            parseInt((que.a2 / (4 * res.data.length)) * 100),
                            parseInt((que.a3 / (4 * res.data.length)) * 100),
                            parseInt((que.a4 / (4 * res.data.length)) * 100),
                            parseInt((que.a5 / (4 * res.data.length)) * 100),
                            parseInt((que.a6 / (4 * res.data.length)) * 100),
                            parseInt((que.a7 / (4 * res.data.length)) * 100),
                        ],
                        backgroundColor: [
                            this.state.chartcolors[0],
                            this.state.chartcolors[0],
                            this.state.chartcolors[0],
                            this.state.chartcolors[0],
                            this.state.chartcolors[0],
                            this.state.chartcolors[0],
                            this.state.chartcolors[0],
                            this.state.chartcolors[0],
                        ],
                    },
                ],
            };
            let data2 = {
                labels: secDatalabel.b,
                datasets: [
                    {
                        label: this.state.questionlist.slice(secCut[0], secCut[1]),
                        data: [
                            parseInt((que.b0 / (4 * res.data.length)) * 100),
                            parseInt((que.b1 / (4 * res.data.length)) * 100),
                            parseInt((que.b2 / (4 * res.data.length)) * 100),
                            parseInt((que.b3 / (4 * res.data.length)) * 100),
                            parseInt((que.b4 / (4 * res.data.length)) * 100),
                            parseInt((que.b5 / (4 * res.data.length)) * 100),
                            parseInt((que.b6 / (4 * res.data.length)) * 100),
                            parseInt((que.b7 / (4 * res.data.length)) * 100),
                        ],
                        backgroundColor: [
                            this.state.chartcolors[1],
                            this.state.chartcolors[1],
                            this.state.chartcolors[1],
                            this.state.chartcolors[1],
                            this.state.chartcolors[1],
                            this.state.chartcolors[1],
                            this.state.chartcolors[1],
                            this.state.chartcolors[1],
                        ],
                    },
                ],
            };
            let data3 = {
                labels: secDatalabel.c,
                datasets: [
                    {
                        label: this.state.questionlist.slice(secCut[1], secCut[2]),

                        data: [
                            parseInt((que.c0 / (4 * res.data.length)) * 100),
                            parseInt((que.c1 / (4 * res.data.length)) * 100),
                            parseInt((que.c2 / (4 * res.data.length)) * 100),
                            parseInt((que.c3 / (4 * res.data.length)) * 100),
                            parseInt((que.c4 / (4 * res.data.length)) * 100),
                            parseInt((que.c5 / (4 * res.data.length)) * 100),
                            parseInt((que.c6 / (4 * res.data.length)) * 100),
                            parseInt((que.c7 / (4 * res.data.length)) * 100),
                        ],
                        backgroundColor: [
                            this.state.chartcolors[2],
                            this.state.chartcolors[2],
                            this.state.chartcolors[2],
                            this.state.chartcolors[2],
                            this.state.chartcolors[2],
                            this.state.chartcolors[2],
                            this.state.chartcolors[2],
                            this.state.chartcolors[2],
                        ],

                        borderWidth: 1,
                    },
                ],
            };

            let data4 = {
                labels: secDatalabel.d,
                datasets: [
                    {
                        label: this.state.questionlist.slice(secCut[2], secCut[3]),

                        data: [
                            parseInt((que.d0 / (4 * res.data.length)) * 100),
                            parseInt((que.d1 / (4 * res.data.length)) * 100),
                            parseInt((que.d2 / (4 * res.data.length)) * 100),
                            parseInt((que.d3 / (4 * res.data.length)) * 100),
                            parseInt((que.d4 / (4 * res.data.length)) * 100),
                            parseInt((que.d5 / (4 * res.data.length)) * 100),
                            parseInt((que.d6 / (4 * res.data.length)) * 100),
                            parseInt((que.d7 / (4 * res.data.length)) * 100),
                        ],
                        backgroundColor: [
                            this.state.chartcolors[3],
                            this.state.chartcolors[3],
                            this.state.chartcolors[3],
                            this.state.chartcolors[3],
                            this.state.chartcolors[3],
                            this.state.chartcolors[3],
                            this.state.chartcolors[3],
                            this.state.chartcolors[3],
                        ],

                        borderWidth: 1,
                    },
                ],
            };
            let data5 = {
                labels: secDatalabel.e,
                datasets: [
                    {
                        label: this.state.questionlist.slice(secCut[3], secCut[4]),

                        data: [
                            parseInt((que.e0 / (4 * res.data.length)) * 100),
                            parseInt((que.e1 / (4 * res.data.length)) * 100),
                            parseInt((que.e2 / (4 * res.data.length)) * 100),
                            parseInt((que.e3 / (4 * res.data.length)) * 100),
                            parseInt((que.e4 / (4 * res.data.length)) * 100),
                            parseInt((que.e5 / (4 * res.data.length)) * 100),
                            parseInt((que.e6 / (4 * res.data.length)) * 100),
                            parseInt((que.e7 / (4 * res.data.length)) * 100),
                        ],
                        backgroundColor: [
                            this.state.chartcolors[4],
                            this.state.chartcolors[4],
                            this.state.chartcolors[4],
                            this.state.chartcolors[4],
                            this.state.chartcolors[4],
                            this.state.chartcolors[4],
                            this.state.chartcolors[4],
                            this.state.chartcolors[4],
                        ],

                        borderWidth: 1,
                    },
                ],
            };
            let data6 = {
                labels: secDatalabel.f,
                datasets: [
                    {
                        label: this.state.questionlist.slice(secCut[4], secCut[5]),

                        data: [
                            parseInt((que.f0 / (4 * res.data.length)) * 100),
                            parseInt((que.f1 / (4 * res.data.length)) * 100),
                            parseInt((que.f2 / (4 * res.data.length)) * 100),
                            parseInt((que.f3 / (4 * res.data.length)) * 100),
                            parseInt((que.f4 / (4 * res.data.length)) * 100),
                            parseInt((que.f5 / (4 * res.data.length)) * 100),
                            parseInt((que.f6 / (4 * res.data.length)) * 100),
                            parseInt((que.f7 / (4 * res.data.length)) * 100),
                        ],
                        backgroundColor: [
                            this.state.chartcolors[5],
                            this.state.chartcolors[5],
                            this.state.chartcolors[5],
                            this.state.chartcolors[5],
                            this.state.chartcolors[5],
                            this.state.chartcolors[5],
                            this.state.chartcolors[5],
                            this.state.chartcolors[5],
                        ],

                        borderWidth: 1,
                    },
                ],
            };

            let secchart = this.state.sectionchart;
            secchart["a"] = data1;
            secchart["b"] = data2;
            secchart["c"] = data3;
            secchart["d"] = data4;
            secchart["e"] = data5;
            secchart["f"] = data6;

            let quechart = this.state.questionChart;

            quechart["a"] = s1Q;
            quechart["b"] = s2Q;
            quechart["c"] = s3Q;
            quechart["d"] = s4Q;
            quechart["e"] = s5Q;
            quechart["f"] = s6Q;
            this.setState({ questionChart: quechart });

            // let tierlist = tier1.filter(
            //     (value, index, self) =>
            //         index ===
            //         self.findIndex(
            //             (t) =>
            //                 t.topic1 === value.topic1 &&
            //                 t.topic2 === value.topic2 &&
            //                 t.topic3 === value.topic3 &&
            //                 t.topic4 === value.topic4
            //         )
            // );
            let selectedList = this.state.selectedtierlist;
            let tier1list = [{ label: "All", value: 0 }];
            // tierlist.forEach((i, j) => {
            //     if (
            //         tier1list.findIndex((a) => {
            //             return a.label === i.topic1;
            //         }) === -1
            //     ) {
            //         if (i.topic1 !== undefined) {
            //             tier1list.push({ label: i.topic1, value: j + 1 });
            //         }
            //     }
            // });
            selectedList.t1 = tier1list;
            setTimeout(() => {
                if (res.data.length !== 0) {
                    this.freezeFilter();
                }
            });

            this.quesyaxisstepsize = res.data.length;
            let loc = this.state.selectedtier;
            loc.t1 = { label: "All", value: 0 }
            loc.t2 = { label: "All", value: 0 }

            this.setState({
                totalSurvey: res.data.length,
                tierresponsecount: tier1,
                section: sec,
                question: que,
                selectedtier: loc,
                radargraph: config,
                radargraph2: pngconfig,
                sectionchart: secchart,
                tierlist: [],
                selectedList: tier1list,

            });
        } else {
            Swal.fire({
                icon: "error",
                titleText: "No reponse found",
                showConfirmButton: false,
                showCancelButton: false,
                timer: 1500,
            });
        }
    }
    renderResponsebyRole = (data) => {

        //editrenderfilter
        let res = { data: responseData }
        let tier1 = [],
            tier2 = [],
            tier3 = [],
            tier4 = [];
        let sec = { a: 0, b: 0, c: 0, d: 0, e: 0, f: 0 };
        let sets = ["a", "b", "c", "d", "e", "f", "g", "h", "j", "i"];
        let que = {};
        let secCut = [];

        let s1Q = [[], [], [], [], [], [], [], []];
        let s2Q = [[], [], [], [], [], [], [], []];
        let s3Q = [[], [], [], [], [], [], [], []];
        let s4Q = [[], [], [], [], [], [], [], []];
        let s5Q = [[], [], [], [], [], [], [], []];
        let s6Q = [[], [], [], [], [], [], [], []];

        let graphlabel = [];
        let secgraphlabel = [];
        const url = "https://api.acuizen.com/custom-responses/" + this.state.value;

        let tierTopicCount = [];
        let roles = [];
        let roleData = [];
        let sectionwisequestioncount = [];
        let secDatalabel = { a: [], b: [], c: [], d: [], e: [], f: [] };
        let roleCount = 0;
        res.data.forEach((i) => {
            i.input_json.forEach((k) => {
                if (
                    sectionwisequestioncount.findIndex((ia) => {
                        return ia.name === k.name;
                    }) === -1
                ) {
                    sectionwisequestioncount.push({
                        name: k.name,
                        count: k.questions[0].value.length,
                    });
                }
            });
            if (i.role === data || data === "All") {
                roleData.push(i);
                roleCount++;
                let index = tierTopicCount.findIndex((t) => t.name === i.topic1.name);
                if (index === -1) {
                    tierTopicCount.push({
                        name: i.topic1.name,
                        count: 1,
                        topic: "tier1",
                    });
                } else {
                    tierTopicCount[index] = {
                        name: i.topic1.name,
                        count: tierTopicCount[index].count + 1,
                        topic: "tier1",
                    };
                }
                let index2 = tierTopicCount.findIndex(
                    (t) => t.name === i.topic2.name
                );
                if (index2 === -1) {
                    tierTopicCount.push({
                        name: i.topic2.name,
                        count: 1,
                        topic: "tier2",
                    });
                } else {
                    tierTopicCount[index2] = {
                        name: i.topic2.name,
                        count: tierTopicCount[index2].count + 1,
                        topic: "tier2",
                    };
                }
                let index3 = tierTopicCount.findIndex(
                    (t) => t.name === i.topic3.name
                );
                if (index3 === -1) {
                    tierTopicCount.push({
                        name: i.topic3.name,
                        count: 1,
                        topic: "tier3",
                    });
                } else {
                    tierTopicCount[index3] = {
                        name: i.topic3.name,
                        count: tierTopicCount[index3].count + 1,
                        topic: "tier3",
                    };
                }
            }
        });
        sectionwisequestioncount.forEach((k, ind) => {
            if (ind === 0) {
                secCut.push(k.count);
            } else {
                secCut.push(secCut[ind - 1] + k.count);
            }

            for (var is = 0; is < k.count; is++) {
                que[sets[ind] + is] = 0;
                if (secDatalabel[sets[ind]] !== undefined) {
                    secDatalabel[sets[ind]].push(this.getSection(ind, is));
                }
            }
        });

        res.data.forEach((i, ind) => {
            if (i.role === data || data === "All") {
                // tier1.push({
                //     topic1: i.topic1.name,
                //     topic2: i.topic2.name,
                //     topic3: i.topic3.name,
                //     topic4: i.topic4.name,
                // });

                if (
                    roles.findIndex((k) => {
                        return k.label === i.role;
                    }) === -1
                ) {
                    roles.push({ value: i.role, label: i.role });

                }
                i.input_json.forEach((section, sind) => {
                    if (graphlabel.findIndex((i) => i === section.name) === -1) {
                        graphlabel.push(section.name);
                    }

                    section.questions[0].value.forEach((question, qind) => {
                        if (
                            secgraphlabel.findIndex(
                                (i) =>
                                    i ===
                                    question.label
                                        .replace(/(<([^>]+)>)/gi, "")
                                        .replace(/\n/g, " ")
                                        .replace(/&nbsp;/g, " ")
                            ) === -1
                        ) {
                            secgraphlabel.push(
                                question.label
                                    .replace(/(<([^>]+)>)/gi, "")
                                    .replace(/\n/g, " ")
                                    .replace(/&nbsp;/g, " ")
                            );
                        }
                        if (sind === 0) {
                            s1Q[qind].push(
                                question.values.findIndex((i) => i.selected === true)
                            );
                            que[`a${qind}`] =
                                que[`a${qind}`] +
                                question.values.findIndex((i) => i.selected === true);
                            //  que[qind].push(question.values.findIndex((i)=> i.selected === true))
                            sec.a =
                                sec.a + question.values.findIndex((i) => i.selected === true);
                        } else if (sind === 1) {
                            s2Q[qind].push(
                                question.values.findIndex((i) => i.selected === true)
                            );
                            que[`b${qind}`] =
                                que[`b${qind}`] +
                                question.values.findIndex((i) => i.selected === true);
                            sec.b =
                                sec.b + question.values.findIndex((i) => i.selected === true);
                        } else if (sind === 2) {
                            s3Q[qind].push(
                                question.values.findIndex((i) => i.selected === true)
                            );
                            que[`c${qind}`] =
                                que[`c${qind}`] +
                                question.values.findIndex((i) => i.selected === true);
                            sec.c =
                                sec.c + question.values.findIndex((i) => i.selected === true);
                        } else if (sind === 3) {
                            s4Q[qind].push(
                                question.values.findIndex((i) => i.selected === true)
                            );
                            que[`d${qind}`] =
                                que[`d${qind}`] +
                                question.values.findIndex((i) => i.selected === true);
                            sec.d =
                                sec.d + question.values.findIndex((i) => i.selected === true);
                        } else if (sind === 4) {
                            s5Q[qind].push(
                                question.values.findIndex((i) => i.selected === true)
                            );
                            que[`e${qind}`] =
                                que[`e${qind}`] +
                                question.values.findIndex((i) => i.selected === true);
                            sec.e =
                                sec.e + question.values.findIndex((i) => i.selected === true);
                        } else if (sind === 5) {
                            s6Q[qind].push(
                                question.values.findIndex((i) => i.selected === true)
                            );
                            que[`f${qind}`] =
                                que[`f${qind}`] +
                                question.values.findIndex((i) => i.selected === true);
                            sec.f =
                                sec.f + question.values.findIndex((i) => i.selected === true);
                        }
                    });
                });
            }
        });
        let graphlabel2 = [];
        let apiSectionName = this.state.characteristic;
        graphlabel.forEach((k, ind) => {
            if (ind === 0) {
                graphlabel2.push(
                    apiSectionName[ind + 1].label +
                    " (" +
                    parseInt((sec.a / (roleCount * 24)) * 100) +
                    " %)"
                );
            } else if (ind === 1) {
                graphlabel2.push(
                    apiSectionName[ind + 1].label +
                    " (" +
                    parseInt((sec.b / (roleCount * 24)) * 100) +
                    " %)"
                );
            } else if (ind === 2) {
                graphlabel2.push(
                    apiSectionName[ind + 1].label +
                    " (" +
                    parseInt((sec.c / (roleCount * 24)) * 100) +
                    " %)"
                );
            } else if (ind === 3) {
                graphlabel2.push(
                    apiSectionName[ind + 1].label +
                    " (" +
                    parseInt((sec.d / (roleCount * 24)) * 100) +
                    " %)"
                );
            } else if (ind === 4) {
                graphlabel2.push(
                    apiSectionName[ind + 1].label +
                    " (" +
                    parseInt((sec.e / (roleCount * 24)) * 100) +
                    " %)"
                );
            } else if (ind === 5) {
                graphlabel2.push(
                    apiSectionName[ind + 1].label +
                    " (" +
                    parseInt((sec.f / (roleCount * 24)) * 100) +
                    " %)"
                );
            }
        });

        let config = {
            type: "radar",
            data: {
                labels: graphlabel2,
                datasets: [
                    {
                        label: this.state.selected.label,
                        data: [
                            parseInt((sec.a / (roleCount * 24)) * 100),
                            parseInt((sec.b / (roleCount * 24)) * 100),
                            parseInt((sec.c / (roleCount * 24)) * 100),
                            parseInt((sec.d / (roleCount * 24)) * 100),
                            parseInt((sec.e / (roleCount * 24)) * 100),
                            parseInt((sec.f / (roleCount * 24)) * 100),
                        ],
                        fill: true,
                        backgroundColor: "rgb(0, 216, 240,0.2)",
                        borderColor: "rgb(0, 216, 240,0.2)",
                        pointBackgroundColor: "rgb(19, 21, 51,0.1)",
                        pointBorderColor: "rgb(0, 216, 240,0)",
                        pointHoverBackgroundColor: "#fff",
                        pointHoverBorderColor: "rgb(0, 216, 240)",
                    },
                ],
            },
            options: {
                legend: {
                    display: false,
                },
                scale: {
                    ticks: {
                        min: 0,
                        max: 100,
                        stepSize: 20,
                    },
                    pointLabels: {
                        fontColor: "rgb(19, 21, 51)",
                        fontSize: 20,
                    },
                },
                elements: {
                    line: {
                        borderWidth: 3,
                    },
                },
                tooltips: {
                    callbacks: {
                        label: function (tooltipItem, data) {
                            var data = "";
                            switch (tooltipItem.index) {
                                case 0:
                                    data = apiSectionName[1].label + tooltipItem.yLabel + " %";
                                    break;
                                case 1:
                                    data = apiSectionName[2].label + tooltipItem.yLabel + " %";
                                    break;
                                case 2:
                                    data = apiSectionName[3].label + tooltipItem.yLabel + " %";
                                    break;
                                case 3:
                                    data = apiSectionName[4].label + tooltipItem.yLabel + " %";
                                    break;
                                case 4:
                                    data = apiSectionName[5].label + tooltipItem.yLabel + " %";
                                    break;

                                case 5:
                                    data = apiSectionName[6].label + tooltipItem.yLabel + " %";
                                    break;
                            }
                            return data;
                        },
                        title: function () {
                            return null;
                        },
                    },
                    // enabled: true,
                    // custom: this.mycustomtooltipfunction
                },
            },
        };
        let pngconfig = {
            type: "radar",
            data: {
                labels: graphlabel2,
                datasets: [
                    {
                        label: this.state.selected.label,
                        data: [
                            parseInt((sec.a / (roleCount * 24)) * 100),
                            parseInt((sec.b / (roleCount * 24)) * 100),
                            parseInt((sec.c / (roleCount * 24)) * 100),
                            parseInt((sec.d / (roleCount * 24)) * 100),
                            parseInt((sec.e / (roleCount * 24)) * 100),
                            parseInt((sec.f / (roleCount * 24)) * 100),
                        ],
                        fill: false,
                        backgroundColor: "rgb(0, 216, 240,0.2)",
                        borderColor: "rgb(0, 216, 240,0.2)",
                        pointBackgroundColor: "rgb(19, 21, 51,0.1)",
                        pointBorderColor: "rgb(0, 216, 240,0)",
                        pointHoverBackgroundColor: "#fff",
                        pointHoverBorderColor: "rgb(0, 216, 240)",
                    },
                ],
            },
            options: {
                legend: {
                    display: false,
                },
                scale: {
                    ticks: {
                        display: false,
                        min: 0,
                        max: 100,
                        stepSize: 20,
                    },
                    pointLabels: {
                        fontColor: "rgb(19, 21, 51)",
                        fontSize: 0,
                    },
                },
                elements: {
                    line: {
                        borderWidth: 3,
                    },
                },
                tooltips: {
                    callbacks: {
                        title: function () {
                            return null;
                        },
                    },
                    // enabled: true,
                    // custom: this.mycustomtooltipfunction
                },
            },
        };
        let data1 = {
            labels: secDatalabel.a,
            datasets: [
                {
                    label: this.state.questionlist.slice(0, secCut[0]),

                    data: [
                        parseInt((que.a0 / (4 * roleCount)) * 100),
                        parseInt((que.a1 / (4 * roleCount)) * 100),
                        parseInt((que.a2 / (4 * roleCount)) * 100),
                        parseInt((que.a3 / (4 * roleCount)) * 100),
                        parseInt((que.a4 / (4 * roleCount)) * 100),
                        parseInt((que.a5 / (4 * roleCount)) * 100),
                        parseInt((que.a6 / (4 * roleCount)) * 100),
                        parseInt((que.a7 / (4 * roleCount)) * 100),
                    ],
                    backgroundColor: [
                        this.state.chartcolors[0],
                        this.state.chartcolors[0],
                        this.state.chartcolors[0],
                        this.state.chartcolors[0],
                        this.state.chartcolors[0],
                        this.state.chartcolors[0],
                        this.state.chartcolors[0],
                        this.state.chartcolors[0],
                    ],
                },
            ],
        };
        let data2 = {
            labels: secDatalabel.b,
            datasets: [
                {
                    label: this.state.questionlist.slice(secCut[0], secCut[1]),
                    data: [
                        parseInt((que.b0 / (4 * roleCount)) * 100),
                        parseInt((que.b1 / (4 * roleCount)) * 100),
                        parseInt((que.b2 / (4 * roleCount)) * 100),
                        parseInt((que.b3 / (4 * roleCount)) * 100),
                        parseInt((que.b4 / (4 * roleCount)) * 100),
                        parseInt((que.b5 / (4 * roleCount)) * 100),
                        parseInt((que.b6 / (4 * roleCount)) * 100),
                        parseInt((que.b7 / (4 * roleCount)) * 100),
                    ],
                    backgroundColor: [
                        this.state.chartcolors[1],
                        this.state.chartcolors[1],
                        this.state.chartcolors[1],
                        this.state.chartcolors[1],
                        this.state.chartcolors[1],
                        this.state.chartcolors[1],
                        this.state.chartcolors[1],
                        this.state.chartcolors[1],
                    ],
                },
            ],
        };
        let data3 = {
            labels: secDatalabel.c,
            datasets: [
                {
                    label: this.state.questionlist.slice(secCut[2], secCut[3]),

                    data: [
                        parseInt((que.c0 / (4 * roleCount)) * 100),
                        parseInt((que.c1 / (4 * roleCount)) * 100),
                        parseInt((que.c2 / (4 * roleCount)) * 100),
                        parseInt((que.c3 / (4 * roleCount)) * 100),
                        parseInt((que.c4 / (4 * roleCount)) * 100),
                        parseInt((que.c5 / (4 * roleCount)) * 100),
                        parseInt((que.c6 / (4 * roleCount)) * 100),
                        parseInt((que.c7 / (4 * roleCount)) * 100),
                    ],
                    backgroundColor: [
                        this.state.chartcolors[2],
                        this.state.chartcolors[2],
                        this.state.chartcolors[2],
                        this.state.chartcolors[2],
                        this.state.chartcolors[2],
                        this.state.chartcolors[2],
                        this.state.chartcolors[2],
                        this.state.chartcolors[2],
                    ],

                    borderWidth: 1,
                },
            ],
        };

        let data4 = {
            labels: secDatalabel.d,
            datasets: [
                {
                    label: this.state.questionlist.slice(secCut[2], secCut[3]),

                    data: [
                        parseInt((que.d0 / (4 * roleCount)) * 100),
                        parseInt((que.d1 / (4 * roleCount)) * 100),
                        parseInt((que.d2 / (4 * roleCount)) * 100),
                        parseInt((que.d3 / (4 * roleCount)) * 100),
                        parseInt((que.d4 / (4 * roleCount)) * 100),
                        parseInt((que.d5 / (4 * roleCount)) * 100),
                        parseInt((que.d6 / (4 * roleCount)) * 100),
                        parseInt((que.d7 / (4 * roleCount)) * 100),
                    ],
                    backgroundColor: [
                        this.state.chartcolors[3],
                        this.state.chartcolors[3],
                        this.state.chartcolors[3],
                        this.state.chartcolors[3],
                        this.state.chartcolors[3],
                        this.state.chartcolors[3],
                        this.state.chartcolors[3],
                        this.state.chartcolors[3],
                    ],

                    borderWidth: 1,
                },
            ],
        };
        let data5 = {
            labels: secDatalabel.e,
            datasets: [
                {
                    label: this.state.questionlist.slice(secCut[3], secCut[4]),

                    data: [
                        parseInt((que.e0 / (4 * roleCount)) * 100),
                        parseInt((que.e1 / (4 * roleCount)) * 100),
                        parseInt((que.e2 / (4 * roleCount)) * 100),
                        parseInt((que.e3 / (4 * roleCount)) * 100),
                        parseInt((que.e4 / (4 * roleCount)) * 100),
                        parseInt((que.e5 / (4 * roleCount)) * 100),
                        parseInt((que.e6 / (4 * roleCount)) * 100),
                        parseInt((que.e7 / (4 * roleCount)) * 100),
                    ],
                    backgroundColor: [
                        this.state.chartcolors[4],
                        this.state.chartcolors[4],
                        this.state.chartcolors[4],
                        this.state.chartcolors[4],
                        this.state.chartcolors[4],
                        this.state.chartcolors[4],
                        this.state.chartcolors[4],
                        this.state.chartcolors[4],
                    ],

                    borderWidth: 1,
                },
            ],
        };
        let data6 = {
            labels: secDatalabel.f,
            datasets: [
                {
                    label: this.state.questionlist.slice(secCut[4], secCut[5]),

                    data: [
                        parseInt((que.f0 / (4 * roleCount)) * 100),
                        parseInt((que.f1 / (4 * roleCount)) * 100),
                        parseInt((que.f2 / (4 * roleCount)) * 100),
                        parseInt((que.f3 / (4 * roleCount)) * 100),
                        parseInt((que.f4 / (4 * roleCount)) * 100),
                        parseInt((que.f5 / (4 * roleCount)) * 100),
                        parseInt((que.f6 / (4 * roleCount)) * 100),
                        parseInt((que.f7 / (4 * roleCount)) * 100),
                    ],
                    backgroundColor: [
                        this.state.chartcolors[5],
                        this.state.chartcolors[5],
                        this.state.chartcolors[5],
                        this.state.chartcolors[5],
                        this.state.chartcolors[5],
                        this.state.chartcolors[5],
                        this.state.chartcolors[5],
                        this.state.chartcolors[5],
                    ],

                    borderWidth: 1,
                },
            ],
        };

        let secchart = this.state.sectionchart;
        secchart["a"] = data1;
        secchart["b"] = data2;
        secchart["c"] = data3;
        secchart["d"] = data4;
        secchart["e"] = data5;
        secchart["f"] = data6;
        let quechart = this.state.questionChart;

        quechart["a"] = s1Q;
        quechart["b"] = s2Q;
        quechart["c"] = s3Q;
        quechart["d"] = s4Q;
        quechart["e"] = s5Q;
        quechart["f"] = s6Q;
        this.setState({ questionChart: quechart });

        // let tierlist = tier1.filter(
        //     (value, index, self) =>
        //         index ===
        //         self.findIndex(
        //             (t) =>
        //                 t.topic1 === value.topic1 &&
        //                 t.topic2 === value.topic2 &&
        //                 t.topic3 === value.topic3 &&
        //                 t.topic4 === value.topic4
        //         )
        // );
        let selectedList = this.state.selectedtierlist;
        let tier1list = [{ label: "All", value: 0 }];
        // tierlist.forEach((i, j) => {
        //     if (
        //         tier1list.findIndex((a) => {
        //             return a.label === i.topic1;
        //         }) === -1
        //     ) {
        //         if (i.topic1 !== undefined) {
        //             tier1list.push({ label: i.topic1, value: j + 1 });
        //         }
        //     }
        // });
        selectedList.t1 = tier1list;

        this.quesyaxisstepsize = roleCount;
        let reset = this.state.selectedtier;
        reset.t1 = { label: "All" };
        reset.t2 = { label: "All" };
        reset.t3 = { label: "All" };

        let resetFilterList = this.state.selectedtierlist;
        resetFilterList.t2 = { label: "All" };
        resetFilterList.t3 = { label: "All" };
        this.setState({
            response: roleData,
            tiercountbytopic: {
                t1: roleCount,
                t2: roleCount,
                t3: roleCount,
                t4: roleCount,
            },
        });

        this.setState({
            totalSurvey: roleCount,
            selectedtierlist: resetFilterList,
            selectedtier: reset,
            tierresponsecount: tier1,
            section: sec,
            question: que,
            radargraph: config,
            radargraph2: pngconfig,
            sectionchart: secchart,
            tierlist: [],
            selectedList: tier1list,
        });


    }

    showDownloadChartOption(id) {
        this.setState({ activeDownload: id });
    }
    hideDownloadChartOption() {
        this.setState({ activeDownload: "" });
    }
    downloadChart(id) {

        exportAsImage(document.getElementById(id), id);

    }
    hideQuestionDownload() {
        this.setState({ activeDownload: "" });
        this.forceUpdate();
    }
    downloadChartasPNG(id, filename) {
        let parent = document.getElementById("radarchart2parent");
        parent.removeAttribute("hidden");
        setTimeout(() => {
            // parent.setAttribute('hidden', true)
            let canvas = document.getElementById(id);
            /// create an "off-screen" anchor tag
            var lnk = document.createElement("a"),
                e;

            /// the key here is to set the download attribute of the a tag
            lnk.download = filename;

            /// convert canvas content to data-uri for link. When download
            /// attribute is set the content pointed to by link will be
            /// pushed as "download" in HTML5 capable browsers
            lnk.href = canvas.toDataURL("image/png;base64");

            /// create a "fake" click-event to trigger the download
            if (document.createEvent) {
                e = document.createEvent("MouseEvents");
                e.initMouseEvent(
                    "click",
                    true,
                    true,
                    window,
                    0,
                    0,
                    0,
                    0,
                    0,
                    false,
                    false,
                    false,
                    false,
                    0,
                    null
                );

                lnk.dispatchEvent(e);
            } else if (lnk.fireEvent) {
                lnk.fireEvent("onclick");
            }

            parent.setAttribute("hidden", true);
        }, 1000);
    }
    hideDownloadBtn(div) {
        var element = "",
            element2 = "";
        if (div === "radarchart") {
            element = document.getElementById(div.replace(" ", "_")).children[0]
                .children[1];
            element2 = document.getElementById(div.replace(" ", "_")).children[0]
                .children[2];
            element2.setAttribute("hidden", true);
        } else {
            element = document.getElementById(div.replace(" ", "_")).children[0]
                .children[2];
        }

        element.setAttribute("hidden", true);
        setTimeout(() => {
            if (div === "radarchart") {
                element2.removeAttribute("hidden");
            }
            element.removeAttribute("hidden");
        }, 1000);
    }
    getSection(sec, ind) {
        ind = ind + 1;
        if (sec == 0) {
            return "G" + ind;
        } else if (sec == 1) {
            return "F" + ind;
        } else if (sec == 2) {
            return "S" + ind;
        } else if (sec == 3) {
            return "O" + ind;
        } else if (sec == 4) {
            return "R" + ind;
        } else if (sec == 5) {
            return "E" + ind;
        }
    }
    getLabel(ind) {
        switch (ind) {
            case 0:
                return 'Not True';
            case 1:
                return 'Seldom True';
            case 2:
                return 'Occasionally True';
            case 3:
                return 'Mostly True';
            case 4:
                return 'Always True';

        }
    }
    getCharacteristic(sec) {
        return this.state.characteristic[sec + 1].label;
    }
    export2Excel() {
        let loc = [];

        this.state.response.forEach((i) => {
            let obj = {};
            let avgobj = {};
            obj["Company"] = this.state.selected1.label;
            obj["Assessment"] = this.state.selected.label.split("(")[0];
            obj["Role"] = i.role;
            obj[this.state.topictitle[0]] = this.state.usertopic1.filter((k) => { if (k.id === i.topic1) { return k.name } })[0].name;
            obj[this.state.topictitle[1]] = this.state.usertopic2.filter((k) => { if (k.id === i.topic2) { return k.name } })[0].name;
            // obj[this.state.topictitle[2]] = i.topic3.name

            i.input_json.forEach((res, section) => {
                let vals = 0;

                res.questions[0].selectedValues.forEach((val, j) => {
                    console.log(val)
                    obj[`${this.getSection(section, j)}`] = this.getLabel(parseInt(val) - 1)

                    vals =
                        vals + (parseInt(val) - 1);
                });

                let x = (vals / (4 * 6)) * 100;
                avgobj = {
                    ...avgobj,
                    ...{
                        [` % of ${this.getCharacteristic(section)}`]:
                            Math.floor(x * 100) / 100,
                    },
                };
            });

            loc.push({ ...obj, ...avgobj });
        });

        const ws = XLSX.utils.json_to_sheet(loc);

        const wb = {
            Sheets: { ["Survey Report"]: ws },
            SheetNames: ["Survey Report"],
        };

        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

        const data = new Blob([excelBuffer], {
            type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        FileSaver.saveAs(data, this.state.selected.label.split("(")[0] + ".xlsx");
    }
    downloadAllCharacteristicQuestionChart(ids) {
        ids.forEach((i) => {
            this.downloadChart(i);
        });
    }
    downloadAllCharacteristicChart() {
        this.setState({ attributesValue: "All" });
        this.forceUpdate();

        setTimeout(() => {
            this.hideDownloadBtn(this.state.radargraph.data.labels[0]);
            this.downloadChart(
                this.state.radargraph.data.labels[0].replace(" ", "_")
            );

            this.hideDownloadBtn(this.state.radargraph.data.labels[1]);
            this.downloadChart(
                this.state.radargraph.data.labels[1].replace(" ", "_")
            );

            this.hideDownloadBtn(this.state.radargraph.data.labels[2]);
            this.downloadChart(
                this.state.radargraph.data.labels[2].replace(" ", "_")
            );

            this.hideDownloadBtn(this.state.radargraph.data.labels[3]);
            this.downloadChart(
                this.state.radargraph.data.labels[3].replace(" ", "_")
            );

            this.hideDownloadBtn(this.state.radargraph.data.labels[4]);
            this.downloadChart(
                this.state.radargraph.data.labels[4].replace(" ", "_")
            );

            this.hideDownloadBtn(this.state.radargraph.data.labels[5]);
            this.downloadChart(
                this.state.radargraph.data.labels[5].replace(" ", "_")
            );
        }, 1000);
    }

    render() {

        return (
            <div id="test">
                {this.state.Loader ? (
                    <div>
                        <div
                            className="row mb-5 primary-color1"
                            style={{ zIndex: 2, position: "relative" }}
                        >
                            <div className="col-md-6 mb-3">
                                <h4>Select Enterprise</h4>
                                <div className="card">
                                    <Select
                                        value={this.state.selected1}
                                        onChange={this.handleChange1}
                                        options={this.state.moss}
                                    />
                                </div>
                            </div>

                            {this.state.assessment.length !== 0 && (
                                <div className=" col-md-6 mb-3">
                                    <h4>Select Survey</h4>

                                    <div className="card">
                                        <Select
                                            //  className="basic-multi-select"
                                            //  isMulti
                                            //  classNamePrefix="select"
                                            value={this.state.selected}
                                            onChange={this.handleChange}
                                            options={this.state.assessment}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>

                        {this.state.totalSurvey !== 0 && (
                            <div
                                className="sticky-div"
                                style={{ position: "relative", borderRadius: 5 }}
                            >
                                <div className="row primary-color1" style={{ padding: 5 }}>

                                    <div className="col-md-4 mb-3">
                                        <h4>
                                            Select {this.state.topictitle[0]} (
                                            {this.state.tiercountbytopic.t1} Responses )
                                        </h4>
                                        <div className="card">
                                            <Select
                                                isDisabled={this.state.spinner}
                                                value={this.state.selectedtier.t1}
                                                onChange={(e) => this.handleTierChange("tier1", e)}
                                                options={this.state.selectedtierlist.t1}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-3">
                                        <h4>
                                            Select {this.state.topictitle[1]} (
                                            {this.state.tiercountbytopic.t2} Responses )
                                        </h4>
                                        <div className="card">
                                            <Select
                                                isDisabled={this.state.spinner}
                                                value={this.state.selectedtier.t2}
                                                onChange={(e) => this.handleTierChange("tier2", e)}
                                                options={this.state.selectedtierlist.t2}
                                            />
                                        </div>
                                    </div>
                                    {this.state.role.length !== 0 && (
                                        <div className="col-md-4 mb-3">
                                            <h4>Select Role  (
                                                {this.state.tiercountbytopic.t4} Responses )</h4>
                                            <div className="card">
                                                <Select
                                                    isDisabled={this.state.spinner}
                                                    value={this.state.selectedRole}
                                                    onChange={this.onSelectRole}
                                                    options={this.state.role}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {/* {this.state.selectedtierlist.t3.length !== 0 &&
                    <div className="col-md-4 mb-3">
                      <h4>Select {this.state.topictitle[2]}</h4>
                      <div className="card">
                        <Select
                          value={this.state.selectedtier.t3}
                          onChange={(e) => this.handleTierChange('tier3', e)}
                          options={this.state.selectedtierlist.t3}
                        /> </div> </div>
                  } */}
                                    {/* <div className="col-md-3 mb-3">
                    <h4>Select {this.state.topictitle[3]}</h4>
                    <div className="card">
                      <Select
                        value={this.state.selectedtier.t4}
                        onChange={(e) => this.handleTierChange('tier4', e)}
                        options={this.state.selectedtierlist.t4}
                      /> </div> 
                      </div> */}
                                </div>
                            </div>
                        )}

                        {this.state.selected1.label === undefined && (
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                Select Company from the list
                            </div>
                        )}
                        {this.state.selected1.label !== undefined &&
                            this.state.assessment.length === 0 && (
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    No survey configured for {this.state.selected1.label}
                                </div>
                            )}
                        {this.state.assessment.length !== 0 &&
                            this.state.selected.label === undefined && (
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    Select survey from the list
                                </div>
                            )}
                        {this.state.value !== undefined && this.state.totalSurvey === 0 && !this.state.spinner && (
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                {`No response found for ${this.state.assessment.filter(
                                    (i) => i.value === this.state.value
                                )[0].label
                                    }`}
                            </div>
                        )}
                        {!this.state.spinner ?
                            <>
                                {this.state.totalSurvey !== 0 && (
                                    <>

                                        <div style={{ textAlign: "end", marginBottom: 10 }}>
                                            <Button
                                                title="Export"
                                                onClick={() => {
                                                    this.export2Excel();
                                                }}
                                            >
                                                Export Report (xls)
                                            </Button>
                                        </div>
                                        <div
                                            className="d-sm-flex justify-content-center align-items-center mb-4"
                                            style={{
                                                background: "rgb(19, 21, 51)",
                                                borderRadius: 5,
                                                padding: 6,
                                            }}
                                        >
                                            <h2 className="text-white font-weight-bold m-0">
                                                Overall Average Score by Characteristic
                                            </h2>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 grid-margin stretch-card">
                                                <div className="card chartcard" id="radarchart">
                                                    <div
                                                        className="card-body"
                                                        style={{
                                                            padding: 10,
                                                            display: "flex",
                                                            alignItems: "flex-start",
                                                            flexDirection: "column",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        <div
                                                            style={{ position: "absolute", top: 0, right: 30 }}
                                                            onClick={() => {
                                                                this.downloadChartasPNG(
                                                                    "radarchart2",
                                                                    this.state.selected1.label +
                                                                    "_" +
                                                                    this.state.selected.label
                                                                );
                                                            }}
                                                        >
                                                            <text
                                                                style={{
                                                                    textDecoration: "underline",
                                                                    color: "black",
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                download png
                                                            </text>
                                                        </div>
                                                        <div
                                                            style={{ position: "absolute", top: 0, right: 0 }}
                                                            onClick={() => {
                                                                this.hideDownloadBtn("radarchart");
                                                                this.downloadChart("radarchart");
                                                            }}
                                                        >
                                                            <i className="material-icons" style={{ cursor: "pointer" }}>download_for_offline</i>
                                                        </div>
                                                        {this.state.section.length !== 0 && (
                                                            <Radar {...this.state.radargraph} />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            id="divsize"
                                            className="d-sm-flex justify-content-center align-items-center mb-4"
                                            style={{
                                                background: "rgb(19, 21, 51)",
                                                borderRadius: 5,
                                                padding: 6,
                                            }}
                                        >
                                            <h2 className="text-white font-weight-bold m-0">

                                                Responses by Characteristic
                                            </h2>
                                            <i
                                                onClick={() => {
                                                    this.downloadAllCharacteristicChart();
                                                }}
                                                className="material-icons"
                                                style={{ position: "absolute", right: 60, color: "white", cursor: 'pointer' }}
                                            >
                                                download
                                            </i>
                                        </div>
                                        <div
                                            className="row mb-5 filter "
                                            style={{ justifyContent: "space-between" }}
                                        >
                                            <div className="col-md-4 primary-color1">
                                                <h4>Select Characteristic</h4>
                                                <div className="card">
                                                    <Select
                                                        placeholder={"Characteristic"}
                                                        isSearchable
                                                        value={{ label: this.state.attributesValue }}
                                                        onChange={(value) => this.attributesValue(value.label)}
                                                        defaultValue={{ value: "All", label: "All" }}
                                                        options={this.state.characteristic}
                                                    />
                                                </div>
                                            </div>
                                            {/* <div className="col-xl-12  justify-content-center d-flex" >
                <button type="button" className="btn btn-primary btn-fw" onClick={() => this.search()}>GO</button>
              </div> */}
                                        </div>
                                    </>
                                )}
                                {this.state.attributesValue === "All" &&
                                    this.state.totalSurvey !== 0 ? (
                                    <>
                                        <div className="d-sm-flex justify-content-between align-items-start mb-4">
                                            <h2 className="text-dark font-weight-bold mb-2">
                                                Score by Characteristic
                                            </h2>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4 grid-margin stretch-card">
                                                <div
                                                    className="card chartcard"
                                                    id={this.state.radargraph.data.labels[0].replace(
                                                        " ",
                                                        "_"
                                                    )}
                                                >
                                                    <div className="card-body" style={{ padding: 10 }}>
                                                        <h2 className="card-title">
                                                            {this.state.radargraph.data.labels[0]}
                                                        </h2>
                                                        <div
                                                            style={{ position: "absolute", top: 0, right: 0 }}
                                                            onClick={() => {
                                                                this.hideDownloadBtn(
                                                                    this.state.radargraph.data.labels[0]
                                                                );
                                                                this.downloadChart(
                                                                    this.state.radargraph.data.labels[0].replace(
                                                                        " ",
                                                                        "_"
                                                                    )
                                                                );
                                                            }}
                                                        >
                                                            <i className="material-icons" style={{ cursor: "pointer" }}>download_for_offline</i>
                                                        </div>
                                                        <Bar
                                                            data={this.state.sectionchart.a}
                                                            options={this.options1}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-4 grid-margin stretch-card">
                                                <div
                                                    className="card chartcard"
                                                    id={this.state.radargraph.data.labels[1].replace(
                                                        " ",
                                                        "_"
                                                    )}
                                                >
                                                    <div className="card-body" style={{ padding: 10 }}>
                                                        <h2 className="card-title">
                                                            {this.state.radargraph.data.labels[1]}
                                                        </h2>
                                                        <div
                                                            style={{ position: "absolute", top: 0, right: 0 }}
                                                            onClick={() => {
                                                                this.hideDownloadBtn(
                                                                    this.state.radargraph.data.labels[1]
                                                                );
                                                                this.downloadChart(
                                                                    this.state.radargraph.data.labels[1].replace(
                                                                        " ",
                                                                        "_"
                                                                    )
                                                                );
                                                            }}
                                                        >
                                                            <i className="material-icons" style={{ cursor: "pointer" }}>download_for_offline</i>
                                                        </div>
                                                        <Bar
                                                            data={this.state.sectionchart.b}
                                                            options={this.options1}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-4 grid-margin stretch-card">
                                                <div
                                                    className="card chartcard"
                                                    id={this.state.radargraph.data.labels[2].replace(
                                                        " ",
                                                        "_"
                                                    )}
                                                >
                                                    <div className="card-body" style={{ padding: 10 }}>
                                                        <h4 className="card-title">
                                                            {this.state.radargraph.data.labels[2]}
                                                        </h4>
                                                        <div
                                                            style={{ position: "absolute", top: 0, right: 0 }}
                                                            onClick={() => {
                                                                this.hideDownloadBtn(
                                                                    this.state.radargraph.data.labels[2]
                                                                );
                                                                this.downloadChart(
                                                                    this.state.radargraph.data.labels[2].replace(
                                                                        " ",
                                                                        "_"
                                                                    )
                                                                );
                                                            }}
                                                        >
                                                            <i className="material-icons" style={{ cursor: "pointer" }}>download_for_offline</i>
                                                        </div>
                                                        <Bar
                                                            data={this.state.sectionchart.c}
                                                            options={this.options1}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4 grid-margin stretch-card">
                                                <div
                                                    className="card chartcard"
                                                    id={this.state.radargraph.data.labels[3].replace(
                                                        " ",
                                                        "_"
                                                    )}
                                                >
                                                    <div className="card-body" style={{ padding: 10 }}>
                                                        <h2 className="card-title">
                                                            {this.state.radargraph.data.labels[3]}
                                                        </h2>
                                                        <div
                                                            style={{ position: "absolute", top: 0, right: 0 }}
                                                            onClick={() => {
                                                                this.hideDownloadBtn(
                                                                    this.state.radargraph.data.labels[3]
                                                                );
                                                                this.downloadChart(
                                                                    this.state.radargraph.data.labels[3].replace(
                                                                        " ",
                                                                        "_"
                                                                    )
                                                                );
                                                            }}
                                                        >
                                                            <i className="material-icons" style={{ cursor: "pointer" }}>download_for_offline</i>
                                                        </div>
                                                        <Bar
                                                            data={this.state.sectionchart.d}
                                                            options={this.options1}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-4 grid-margin stretch-card">
                                                <div
                                                    className="card chartcard"
                                                    id={this.state.radargraph.data.labels[4].replace(
                                                        " ",
                                                        "_"
                                                    )}
                                                >
                                                    <div className="card-body" style={{ padding: 10 }}>
                                                        <h2 className="card-title">
                                                            {this.state.radargraph.data.labels[4]}
                                                        </h2>
                                                        <div
                                                            style={{ position: "absolute", top: 0, right: 0 }}
                                                            onClick={() => {
                                                                this.hideDownloadBtn(
                                                                    this.state.radargraph.data.labels[4]
                                                                );
                                                                this.downloadChart(
                                                                    this.state.radargraph.data.labels[4].replace(
                                                                        " ",
                                                                        "_"
                                                                    )
                                                                );
                                                            }}
                                                        >
                                                            <i className="material-icons" style={{ cursor: "pointer" }}>download_for_offline</i>
                                                        </div>
                                                        <Bar
                                                            data={this.state.sectionchart.e}
                                                            options={this.options1}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-4 grid-margin stretch-card">
                                                <div
                                                    className="card chartcard"
                                                    id={this.state.radargraph.data.labels[5].replace(
                                                        " ",
                                                        "_"
                                                    )}
                                                >
                                                    <div className="card-body" style={{ padding: 10 }}>
                                                        <h4 className="card-title">
                                                            {this.state.radargraph.data.labels[5]}
                                                        </h4>
                                                        <div
                                                            style={{ position: "absolute", top: 0, right: 0 }}
                                                            onClick={() => {
                                                                this.hideDownloadBtn(
                                                                    this.state.radargraph.data.labels[5]
                                                                );
                                                                this.downloadChart(
                                                                    this.state.radargraph.data.labels[5].replace(
                                                                        " ",
                                                                        "_"
                                                                    )
                                                                );
                                                            }}
                                                        >
                                                            <i className="material-icons" style={{ cursor: "pointer" }}>download_for_offline</i>
                                                        </div>
                                                        <Bar
                                                            data={this.state.sectionchart.f}
                                                            options={this.options1}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {this.state.response.length !== 0 &&
                                            this.state.radargraph.length !== 0 && (
                                                <>
                                                    {(this.state.attributesValue ===
                                                        this.state.characteristic[1].label ||
                                                        this.state.attributesValue ===
                                                        this.state.characteristic[2].label ||
                                                        this.state.attributesValue ===
                                                        this.state.characteristic[3].label) && (
                                                            <div className="row">
                                                                {this.state.attributesValue ===
                                                                    this.state.characteristic[1].label ? (
                                                                    <div className="col-md-4 grid-margin stretch-card">
                                                                        <div
                                                                            className="card chartcard"
                                                                            id={this.state.radargraph.data.labels[0].replace(
                                                                                " ",
                                                                                "_"
                                                                            )}
                                                                        >
                                                                            <div
                                                                                className="card-body"
                                                                                style={{ padding: 10 }}
                                                                            >
                                                                                <h2 className="card-title">
                                                                                    {this.state.radargraph.data.labels[0]}
                                                                                </h2>
                                                                                <div
                                                                                    style={{
                                                                                        position: "absolute",
                                                                                        top: 0,
                                                                                        right: 0,
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        this.hideDownloadBtn(
                                                                                            this.state.radargraph.data.labels[0]
                                                                                        );
                                                                                        this.downloadChart(
                                                                                            this.state.radargraph.data.labels[0].replace(
                                                                                                " ",
                                                                                                "_"
                                                                                            )
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    <i className="material-icons" style={{ cursor: "pointer" }}>
                                                                                        download_for_offline
                                                                                    </i>
                                                                                </div>
                                                                                <Bar
                                                                                    data={this.state.sectionchart.a}
                                                                                    options={this.options1}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : this.state.attributesValue ===
                                                                    this.state.characteristic[2].label ? (
                                                                    <div className="col-md-4 grid-margin stretch-card">
                                                                        <div
                                                                            className="card chartcard"
                                                                            id={this.state.radargraph.data.labels[1].replace(
                                                                                " ",
                                                                                "_"
                                                                            )}
                                                                        >
                                                                            <div
                                                                                className="card-body"
                                                                                style={{ padding: 10 }}
                                                                            >
                                                                                <h2 className="card-title">
                                                                                    {this.state.radargraph.data.labels[1]}
                                                                                </h2>
                                                                                <div
                                                                                    style={{
                                                                                        position: "absolute",
                                                                                        top: 0,
                                                                                        right: 0,
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        this.hideDownloadBtn(
                                                                                            this.state.radargraph.data.labels[1]
                                                                                        );
                                                                                        this.downloadChart(
                                                                                            this.state.radargraph.data.labels[1].replace(
                                                                                                " ",
                                                                                                "_"
                                                                                            )
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    <i className="material-icons" style={{ cursor: "pointer" }}>
                                                                                        download_for_offline
                                                                                    </i>
                                                                                </div>
                                                                                <Bar
                                                                                    data={this.state.sectionchart.b}
                                                                                    options={this.options1}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : this.state.attributesValue ===
                                                                    this.state.characteristic[3].label ? (
                                                                    <div className="col-md-4 grid-margin stretch-card">
                                                                        <div
                                                                            className="card chartcard"
                                                                            id={this.state.radargraph.data.labels[2].replace(
                                                                                " ",
                                                                                "_"
                                                                            )}
                                                                        >
                                                                            <div
                                                                                className="card-body"
                                                                                style={{ padding: 10 }}
                                                                            >
                                                                                <h2 className="card-title">
                                                                                    {this.state.radargraph.data.labels[2]}
                                                                                </h2>
                                                                                <div
                                                                                    style={{
                                                                                        position: "absolute",
                                                                                        top: 0,
                                                                                        right: 0,
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        this.hideDownloadBtn(
                                                                                            this.state.radargraph.data.labels[2]
                                                                                        );
                                                                                        this.downloadChart(
                                                                                            this.state.radargraph.data.labels[2].replace(
                                                                                                " ",
                                                                                                "_"
                                                                                            )
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    <i className="material-icons" style={{ cursor: "pointer" }}>
                                                                                        download_for_offline
                                                                                    </i>
                                                                                </div>
                                                                                <Bar
                                                                                    data={this.state.sectionchart.c}
                                                                                    options={this.options1}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>
                                                        )}

                                                    {(this.state.attributesValue ===
                                                        this.state.characteristic[4].label ||
                                                        this.state.characteristic[5].label ||
                                                        this.state.attributesValue ===
                                                        this.state.characteristic[6].label) && (
                                                            <div className="row">
                                                                {this.state.attributesValue ===
                                                                    this.state.characteristic[4].label ? (
                                                                    <div className="col-md-4 grid-margin stretch-card">
                                                                        <div
                                                                            className="card chartcard"
                                                                            id={this.state.radargraph.data.labels[3].replace(
                                                                                " ",
                                                                                "_"
                                                                            )}
                                                                        >
                                                                            <div
                                                                                className="card-body"
                                                                                style={{ padding: 10 }}
                                                                            >
                                                                                <h2 className="card-title">
                                                                                    {this.state.radargraph.data.labels[3]}
                                                                                </h2>
                                                                                <div
                                                                                    style={{
                                                                                        position: "absolute",
                                                                                        top: 0,
                                                                                        right: 0,
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        this.hideDownloadBtn(
                                                                                            this.state.radargraph.data.labels[3]
                                                                                        );
                                                                                        this.downloadChart(
                                                                                            this.state.radargraph.data.labels[3].replace(
                                                                                                " ",
                                                                                                "_"
                                                                                            )
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    <i className="material-icons" style={{ cursor: "pointer" }}>
                                                                                        download_for_offline
                                                                                    </i>
                                                                                </div>
                                                                                <Bar
                                                                                    data={this.state.sectionchart.d}
                                                                                    options={this.options1}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : this.state.attributesValue ===
                                                                    this.state.characteristic[5].label ? (
                                                                    <div className="col-md-4 grid-margin stretch-card">
                                                                        <div
                                                                            className="card chartcard"
                                                                            id={this.state.radargraph.data.labels[4].replace(
                                                                                " ",
                                                                                "_"
                                                                            )}
                                                                        >
                                                                            <div
                                                                                className="card-body"
                                                                                style={{ padding: 10 }}
                                                                            >
                                                                                <h2 className="card-title">
                                                                                    {this.state.radargraph.data.labels[4]}
                                                                                </h2>
                                                                                <div
                                                                                    style={{
                                                                                        position: "absolute",
                                                                                        top: 0,
                                                                                        right: 0,
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        this.hideDownloadBtn(
                                                                                            this.state.radargraph.data.labels[4]
                                                                                        );
                                                                                        this.downloadChart(
                                                                                            this.state.radargraph.data.labels[4].replace(
                                                                                                " ",
                                                                                                "_"
                                                                                            )
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    <i className="material-icons" style={{ cursor: "pointer" }}>
                                                                                        download_for_offline
                                                                                    </i>
                                                                                </div>
                                                                                <Bar
                                                                                    data={this.state.sectionchart.e}
                                                                                    options={this.options1}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : this.state.attributesValue ===
                                                                    this.state.characteristic[6].label ? (
                                                                    <div className="col-md-4 grid-margin stretch-card">
                                                                        <div
                                                                            className="card chartcard"
                                                                            id={this.state.radargraph.data.labels[5].replace(
                                                                                " ",
                                                                                "_"
                                                                            )}
                                                                        >
                                                                            <div
                                                                                className="card-body"
                                                                                style={{ padding: 10 }}
                                                                            >
                                                                                <h2 className="card-title">
                                                                                    {this.state.radargraph.data.labels[5]}
                                                                                </h2>
                                                                                <div
                                                                                    style={{
                                                                                        position: "absolute",
                                                                                        top: 0,
                                                                                        right: 0,
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        this.hideDownloadBtn(
                                                                                            this.state.radargraph.data.labels[5]
                                                                                        );
                                                                                        this.downloadChart(
                                                                                            this.state.radargraph.data.labels[5].replace(
                                                                                                " ",
                                                                                                "_"
                                                                                            )
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    <i className="material-icons" style={{ cursor: "pointer" }}>
                                                                                        download_for_offline
                                                                                    </i>
                                                                                </div>
                                                                                <Bar
                                                                                    data={this.state.sectionchart.f}
                                                                                    options={this.options1}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>
                                                        )}
                                                </>
                                            )}
                                    </>
                                )}

                                {this.state.totalSurvey !== 0 &&
                                    this.state.questionlist.length !== 0 &&
                                    this.state.characteristic.length !== 0 &&
                                    this.state.questionChart.a.length !== 0 && (
                                        <>
                                            <div className="d-sm-flex justify-content-between align-items-start mb-4">
                                                <h2 className="text-dark font-weight-bold mb-2">

                                                    Breakdown of responses by statement
                                                </h2>
                                            </div>
                                            {(this.state.attributesValue ===
                                                this.state.characteristic[1].label ||
                                                this.state.attributesValue === "All") && (
                                                    <>
                                                        <div
                                                            className="d-sm-flex justify-content-center align-items-center mb-4"
                                                            style={{
                                                                background: "rgb(19, 21, 51)",
                                                                borderRadius: 5,
                                                                padding: 6,
                                                            }}
                                                        >
                                                            <h2 className="text-white font-weight-bold m-0">
                                                                {this.state.radargraph.data.labels[0]}
                                                            </h2>
                                                            <i
                                                                onClick={() => {
                                                                    this.downloadAllCharacteristicQuestionChart([
                                                                        "A1D",
                                                                        "A2D",
                                                                        "A3D",
                                                                        "A4D",
                                                                        "A5D",
                                                                        "A6D",
                                                                    ]);
                                                                }}
                                                                className="material-icons"
                                                                style={{
                                                                    position: "absolute",
                                                                    right: 60,
                                                                    color: "white", cursor: 'pointer'
                                                                }}
                                                            >
                                                                download
                                                            </i>
                                                        </div>
                                                        <div className="row">
                                                            <div
                                                                className="col-md-6 grid-margin stretch-card"
                                                                style={{ position: "relative" }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        textAlign: "end",
                                                                        position: "absolute",
                                                                        right: 20,
                                                                        top: 50,
                                                                        zIndex: 1,
                                                                    }}
                                                                >
                                                                    <i
                                                                        onClick={() => {
                                                                            this.downloadChart("A1D");
                                                                        }}
                                                                        style={{ cursor: "pointer" }}
                                                                        className="material-icons"
                                                                    >
                                                                        download_for_offline
                                                                    </i>
                                                                </div>
                                                                <div
                                                                    className="card chartcard"
                                                                    id="A1D"

                                                                >
                                                                    <div className="card-body" style={{ padding: 10 }}>
                                                                        <h2 className="card-title  ques-title">
                                                                            {this.state.questionlist[0]}
                                                                        </h2>

                                                                        <Bar
                                                                            data={this.getQuestionChartDatasets(
                                                                                1,
                                                                                this.state.questionChart.a[0]
                                                                            )}
                                                                            options={this.questionOption()}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 grid-margin stretch-card" style={{ position: "relative" }}>
                                                                <div
                                                                    style={{
                                                                        textAlign: "end",
                                                                        position: "absolute",
                                                                        right: 20,
                                                                        top: 50,
                                                                        zIndex: 1,
                                                                    }}
                                                                >
                                                                    <i
                                                                        onClick={() => {
                                                                            this.downloadChart("A2D");
                                                                        }}
                                                                        style={{ cursor: "pointer" }}
                                                                        className="material-icons"
                                                                    >
                                                                        download_for_offline
                                                                    </i>
                                                                </div>
                                                                <div
                                                                    className="card chartcard"
                                                                    id="A2D"


                                                                >
                                                                    <div className="card-body" style={{ padding: 10 }}>
                                                                        <h2 className="card-title  ques-title">
                                                                            {this.state.questionlist[1]}
                                                                        </h2>

                                                                        <Bar
                                                                            data={this.getQuestionChartDatasets(
                                                                                1,
                                                                                this.state.questionChart.a[1]
                                                                            )}
                                                                            options={this.questionOption()}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6 grid-margin stretch-card" style={{ position: "relative" }}>
                                                                <div
                                                                    style={{
                                                                        textAlign: "end",
                                                                        position: "absolute",
                                                                        right: 20,
                                                                        top: 50,
                                                                        zIndex: 1,
                                                                    }}
                                                                >
                                                                    <i
                                                                        onClick={() => {
                                                                            this.downloadChart("A3D");
                                                                        }}
                                                                        style={{ cursor: "pointer" }}
                                                                        className="material-icons"
                                                                    >
                                                                        download_for_offline
                                                                    </i>
                                                                </div>
                                                                <div
                                                                    className="card chartcard"
                                                                    id="A3D"


                                                                >
                                                                    <div className="card-body" style={{ padding: 10 }}>
                                                                        <h2 className="card-title ques-title">
                                                                            {this.state.questionlist[2]}
                                                                        </h2>

                                                                        <Bar
                                                                            data={this.getQuestionChartDatasets(
                                                                                1,
                                                                                this.state.questionChart.a[2]
                                                                            )}
                                                                            options={this.questionOption()}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 grid-margin stretch-card" style={{ position: "relative" }}>
                                                                <div
                                                                    style={{
                                                                        textAlign: "end",
                                                                        position: "absolute",
                                                                        right: 20,
                                                                        top: 50,
                                                                        zIndex: 1,
                                                                    }}
                                                                >
                                                                    <i
                                                                        onClick={() => {
                                                                            this.downloadChart("A4D");
                                                                        }}
                                                                        style={{ cursor: "pointer" }}
                                                                        className="material-icons"
                                                                    >
                                                                        download_for_offline
                                                                    </i>
                                                                </div>
                                                                <div
                                                                    className="card chartcard"
                                                                    id="A4D"

                                                                >
                                                                    <div className="card-body" style={{ padding: 10 }}>
                                                                        <h2 className="card-title ques-title">
                                                                            {this.state.questionlist[3]}
                                                                        </h2>

                                                                        <Bar
                                                                            data={this.getQuestionChartDatasets(
                                                                                1,
                                                                                this.state.questionChart.a[3]
                                                                            )}
                                                                            options={this.questionOption()}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6 grid-margin stretch-card" style={{ position: "relative" }}>
                                                                <div
                                                                    style={{
                                                                        textAlign: "end",
                                                                        position: "absolute",
                                                                        right: 20,
                                                                        top: 50,
                                                                        zIndex: 1,
                                                                    }}
                                                                >
                                                                    <i
                                                                        onClick={() => {
                                                                            this.downloadChart("A5D");
                                                                        }}
                                                                        style={{ cursor: "pointer" }}
                                                                        className="material-icons"
                                                                    >
                                                                        download_for_offline
                                                                    </i>
                                                                </div>
                                                                <div
                                                                    className="card chartcard"
                                                                    id="A5D"


                                                                >
                                                                    <div className="card-body" style={{ padding: 10 }}>
                                                                        <h2 className="card-title ques-title">
                                                                            {this.state.questionlist[4]}
                                                                        </h2>

                                                                        <Bar
                                                                            data={this.getQuestionChartDatasets(
                                                                                1,
                                                                                this.state.questionChart.a[4]
                                                                            )}
                                                                            options={this.questionOption()}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 grid-margin stretch-card" style={{ position: "relative" }}>
                                                                <div
                                                                    style={{
                                                                        textAlign: "end",
                                                                        position: "absolute",
                                                                        right: 20,
                                                                        top: 50,
                                                                        zIndex: 1,
                                                                    }}
                                                                >
                                                                    <i
                                                                        onClick={() => {
                                                                            this.downloadChart("A6D");
                                                                        }}
                                                                        style={{ cursor: "pointer" }}
                                                                        className="material-icons"
                                                                    >
                                                                        download_for_offline
                                                                    </i>
                                                                </div>
                                                                <div
                                                                    className="card chartcard"
                                                                    id="A6D"

                                                                >
                                                                    <div className="card-body" style={{ padding: 10 }}>
                                                                        <h2 className="card-title ques-title">
                                                                            {this.state.questionlist[5]}
                                                                        </h2>

                                                                        <Bar
                                                                            data={this.getQuestionChartDatasets(
                                                                                1,
                                                                                this.state.questionChart.a[5]
                                                                            )}
                                                                            options={this.questionOption()}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            {(this.state.attributesValue ===
                                                this.state.characteristic[2].label ||
                                                this.state.attributesValue === "All") && (
                                                    <>
                                                        <div
                                                            className="d-sm-flex justify-content-center align-items-center mb-4"
                                                            style={{
                                                                background: "rgb(19, 21, 51)",
                                                                borderRadius: 5,
                                                                padding: 6,
                                                            }}
                                                        >
                                                            <h2 className="text-white font-weight-bold m-0">
                                                                {this.state.radargraph.data.labels[1]}
                                                            </h2>
                                                            <i
                                                                onClick={() => {
                                                                    this.downloadAllCharacteristicQuestionChart([
                                                                        "B1D",
                                                                        "B2D",
                                                                        "B3D",
                                                                        "B4D",
                                                                        "B5D",
                                                                        "B6D",
                                                                    ]);
                                                                }}
                                                                className="material-icons"
                                                                style={{
                                                                    position: "absolute",
                                                                    right: 60,
                                                                    color: "white", cursor: 'pointer'
                                                                }}
                                                            >
                                                                download
                                                            </i>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6 grid-margin stretch-card" style={{ position: "relative" }}>
                                                                <div
                                                                    style={{
                                                                        textAlign: "end",
                                                                        position: "absolute",
                                                                        right: 20,
                                                                        top: 50,
                                                                        zIndex: 1,
                                                                    }}
                                                                >
                                                                    <i
                                                                        onClick={() => {
                                                                            this.downloadChart("B1D");
                                                                        }}
                                                                        style={{ cursor: "pointer" }}
                                                                        className="material-icons"
                                                                    >
                                                                        download_for_offline
                                                                    </i>
                                                                </div>
                                                                <div
                                                                    className="card chartcard"
                                                                    id="B1D"


                                                                >
                                                                    <div className="card-body" style={{ padding: 10 }}>
                                                                        <h2 className="card-title ques-title">
                                                                            {this.state.questionlist[6]}
                                                                        </h2>

                                                                        <Bar
                                                                            data={this.getQuestionChartDatasets(
                                                                                2,
                                                                                this.state.questionChart.b[0]
                                                                            )}
                                                                            options={this.questionOption()}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 grid-margin stretch-card" style={{ position: "relative" }}>
                                                                <div
                                                                    style={{
                                                                        textAlign: "end",
                                                                        position: "absolute",
                                                                        right: 20,
                                                                        top: 50,
                                                                        zIndex: 1,
                                                                    }}
                                                                >
                                                                    <i
                                                                        onClick={() => {
                                                                            this.downloadChart("B2D");
                                                                        }}
                                                                        style={{ cursor: "pointer" }}
                                                                        className="material-icons"
                                                                    >
                                                                        download_for_offline
                                                                    </i>
                                                                </div>
                                                                <div
                                                                    className="card chartcard"
                                                                    id="B2D"

                                                                >
                                                                    <div className="card-body" style={{ padding: 10 }}>
                                                                        <h2 className="card-title ques-title">
                                                                            {this.state.questionlist[7]}
                                                                        </h2>

                                                                        <Bar
                                                                            data={this.getQuestionChartDatasets(
                                                                                2,
                                                                                this.state.questionChart.b[1]
                                                                            )}
                                                                            options={this.questionOption()}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6 grid-margin stretch-card" style={{ position: "relative" }}>
                                                                <div
                                                                    style={{
                                                                        textAlign: "end",
                                                                        position: "absolute",
                                                                        right: 20,
                                                                        top: 50,
                                                                        zIndex: 1,
                                                                    }}
                                                                >
                                                                    <i
                                                                        onClick={() => {
                                                                            this.downloadChart("B3D");
                                                                        }}
                                                                        style={{ cursor: "pointer" }}
                                                                        className="material-icons"
                                                                    >
                                                                        download_for_offline
                                                                    </i>
                                                                </div>
                                                                <div
                                                                    className="card chartcard"
                                                                    id="B3D"


                                                                >
                                                                    <div className="card-body" style={{ padding: 10 }}>
                                                                        <h2 className="card-title ques-title">
                                                                            {this.state.questionlist[8]}
                                                                        </h2>

                                                                        <Bar
                                                                            data={this.getQuestionChartDatasets(
                                                                                2,
                                                                                this.state.questionChart.b[2]
                                                                            )}
                                                                            options={this.questionOption()}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-6 grid-margin stretch-card" style={{ position: "relative" }}>
                                                                <div
                                                                    style={{
                                                                        textAlign: "end",
                                                                        position: "absolute",
                                                                        right: 20,
                                                                        top: 50,
                                                                        zIndex: 1,
                                                                    }}
                                                                >
                                                                    <i
                                                                        onClick={() => {
                                                                            this.downloadChart("B4D");
                                                                        }}
                                                                        style={{ cursor: "pointer" }}
                                                                        className="material-icons"
                                                                    >
                                                                        download_for_offline
                                                                    </i>
                                                                </div>
                                                                <div
                                                                    className="card chartcard"
                                                                    id="B4D"

                                                                >
                                                                    <div className="card-body" style={{ padding: 10 }}>
                                                                        <h2 className="card-title ques-title">
                                                                            {this.state.questionlist[9]}
                                                                        </h2>

                                                                        <Bar
                                                                            data={this.getQuestionChartDatasets(
                                                                                2,
                                                                                this.state.questionChart.b[3]
                                                                            )}
                                                                            options={this.questionOption()}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6 grid-margin stretch-card" style={{ position: "relative" }}>
                                                                <div
                                                                    style={{
                                                                        textAlign: "end",
                                                                        position: "absolute",
                                                                        right: 20,
                                                                        top: 50,
                                                                        zIndex: 1,
                                                                    }}
                                                                >
                                                                    <i
                                                                        onClick={() => {
                                                                            this.downloadChart("B5D");
                                                                        }}
                                                                        style={{ cursor: "pointer" }}
                                                                        className="material-icons"
                                                                    >
                                                                        download_for_offline
                                                                    </i>
                                                                </div>
                                                                <div
                                                                    className="card chartcard"
                                                                    id="B5D"

                                                                >
                                                                    <div className="card-body" style={{ padding: 10 }}>
                                                                        <h2 className="card-title ques-title">
                                                                            {this.state.questionlist[10]}
                                                                        </h2>

                                                                        <Bar
                                                                            data={this.getQuestionChartDatasets(
                                                                                2,
                                                                                this.state.questionChart.b[4]
                                                                            )}
                                                                            options={this.questionOption()}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 grid-margin stretch-card" style={{ position: "relative" }}>
                                                                <div
                                                                    style={{
                                                                        textAlign: "end",
                                                                        position: "absolute",
                                                                        right: 20,
                                                                        top: 50,
                                                                        zIndex: 1,
                                                                    }}
                                                                >
                                                                    <i
                                                                        onClick={() => {
                                                                            this.downloadChart("B6D");
                                                                        }}
                                                                        style={{ cursor: "pointer" }}
                                                                        className="material-icons"
                                                                    >
                                                                        download_for_offline
                                                                    </i>
                                                                </div>
                                                                <div
                                                                    className="card chartcard"
                                                                    id="B6D"

                                                                >
                                                                    <div className="card-body" style={{ padding: 10 }}>
                                                                        <h2 className="card-title ques-title">
                                                                            {this.state.questionlist[11]}
                                                                        </h2>

                                                                        <Bar
                                                                            data={this.getQuestionChartDatasets(
                                                                                2,
                                                                                this.state.questionChart.b[5]
                                                                            )}
                                                                            options={this.questionOption()}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            {(this.state.attributesValue ===
                                                this.state.characteristic[3].label ||
                                                this.state.attributesValue === "All") && (
                                                    <>
                                                        <div
                                                            className="d-sm-flex justify-content-center align-items-center mb-4"
                                                            style={{
                                                                background: "rgb(19, 21, 51)",
                                                                borderRadius: 5,
                                                                padding: 6,
                                                            }}
                                                        >
                                                            <h2 className="text-white font-weight-bold m-0">
                                                                {this.state.radargraph.data.labels[2]}
                                                            </h2>
                                                            <i
                                                                onClick={() => {
                                                                    this.downloadAllCharacteristicQuestionChart([
                                                                        "C1D",
                                                                        "C2D",
                                                                        "C3D",
                                                                        "C4D",
                                                                        "C5D",
                                                                        "C6D",
                                                                    ]);
                                                                }}
                                                                className="material-icons"
                                                                style={{
                                                                    position: "absolute",
                                                                    right: 60,
                                                                    color: "white", cursor: 'pointer'
                                                                }}
                                                            >
                                                                download
                                                            </i>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6 grid-margin stretch-card" style={{ position: "relative" }}>
                                                                <div
                                                                    style={{
                                                                        textAlign: "end",
                                                                        position: "absolute",
                                                                        right: 20,
                                                                        top: 50,
                                                                        zIndex: 1,
                                                                    }}
                                                                >
                                                                    <i
                                                                        onClick={() => {
                                                                            this.downloadChart("C1D");
                                                                        }}
                                                                        style={{ cursor: "pointer" }}
                                                                        className="material-icons"
                                                                    >
                                                                        download_for_offline
                                                                    </i>
                                                                </div>
                                                                <div
                                                                    className="card chartcard"
                                                                    id="C1D"

                                                                >
                                                                    <div className="card-body" style={{ padding: 10 }}>
                                                                        <h2 className="card-title ques-title">
                                                                            {this.state.questionlist[12]}
                                                                        </h2>

                                                                        <Bar
                                                                            data={this.getQuestionChartDatasets(
                                                                                3,
                                                                                this.state.questionChart.c[0]
                                                                            )}
                                                                            options={this.questionOption()}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 grid-margin stretch-card" style={{ position: "relative" }}>
                                                                <div
                                                                    style={{
                                                                        textAlign: "end",
                                                                        position: "absolute",
                                                                        right: 20,
                                                                        top: 50,
                                                                        zIndex: 1,
                                                                    }}
                                                                >
                                                                    <i
                                                                        onClick={() => {
                                                                            this.downloadChart("C2D");
                                                                        }}
                                                                        style={{ cursor: "pointer" }}
                                                                        className="material-icons"
                                                                    >
                                                                        download_for_offline
                                                                    </i>
                                                                </div>
                                                                <div
                                                                    className="card chartcard"
                                                                    id="C2D"

                                                                >
                                                                    <div className="card-body" style={{ padding: 10 }}>
                                                                        <h2 className="card-title ques-title">
                                                                            {this.state.questionlist[13]}
                                                                        </h2>

                                                                        <Bar
                                                                            data={this.getQuestionChartDatasets(
                                                                                3,
                                                                                this.state.questionChart.c[1]
                                                                            )}
                                                                            options={this.questionOption()}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6 grid-margin stretch-card" style={{ position: "relative" }}>
                                                                <div
                                                                    style={{
                                                                        textAlign: "end",
                                                                        position: "absolute",
                                                                        right: 20,
                                                                        top: 50,
                                                                        zIndex: 1,
                                                                    }}
                                                                >
                                                                    <i
                                                                        onClick={() => {
                                                                            this.downloadChart("C3D");
                                                                        }}
                                                                        style={{ cursor: "pointer" }}
                                                                        className="material-icons"
                                                                    >
                                                                        download_for_offline
                                                                    </i>
                                                                </div>
                                                                <div
                                                                    className="card chartcard"
                                                                    id="C3D"

                                                                >
                                                                    <div className="card-body" style={{ padding: 10 }}>
                                                                        <h2 className="card-title ques-title">
                                                                            {this.state.questionlist[14]}
                                                                        </h2>

                                                                        <Bar
                                                                            data={this.getQuestionChartDatasets(
                                                                                3,
                                                                                this.state.questionChart.c[2]
                                                                            )}
                                                                            options={this.questionOption()}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 grid-margin stretch-card" style={{ position: "relative" }}>
                                                                <div
                                                                    style={{
                                                                        textAlign: "end",
                                                                        position: "absolute",
                                                                        right: 20,
                                                                        top: 50,
                                                                        zIndex: 1,
                                                                    }}
                                                                >
                                                                    <i
                                                                        onClick={() => {
                                                                            this.downloadChart("C4D");
                                                                        }}
                                                                        style={{ cursor: "pointer" }}
                                                                        className="material-icons"
                                                                    >
                                                                        download_for_offline
                                                                    </i>
                                                                </div>
                                                                <div
                                                                    className="card chartcard"
                                                                    id="C4D"

                                                                >
                                                                    <div className="card-body" style={{ padding: 10 }}>
                                                                        <h2 className="card-title ques-title">
                                                                            {this.state.questionlist[15]}
                                                                        </h2>

                                                                        <Bar
                                                                            data={this.getQuestionChartDatasets(
                                                                                3,
                                                                                this.state.questionChart.c[3]
                                                                            )}
                                                                            options={this.questionOption()}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6 grid-margin stretch-card" style={{ position: "relative" }}>
                                                                <div
                                                                    style={{
                                                                        textAlign: "end",
                                                                        position: "absolute",
                                                                        right: 20,
                                                                        top: 50,
                                                                        zIndex: 1,
                                                                    }}
                                                                >
                                                                    <i
                                                                        onClick={() => {
                                                                            this.downloadChart("C5D");
                                                                        }}
                                                                        style={{ cursor: "pointer" }}
                                                                        className="material-icons"
                                                                    >
                                                                        download_for_offline
                                                                    </i>
                                                                </div>
                                                                <div
                                                                    className="card chartcard"
                                                                    id="C5D"

                                                                >
                                                                    <div className="card-body" style={{ padding: 10 }}>
                                                                        <h2 className="card-title ques-title">
                                                                            {this.state.questionlist[16]}
                                                                        </h2>

                                                                        <Bar
                                                                            data={this.getQuestionChartDatasets(
                                                                                3,
                                                                                this.state.questionChart.c[4]
                                                                            )}
                                                                            options={this.questionOption()}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 grid-margin stretch-card" style={{ position: "relative" }}>
                                                                <div
                                                                    style={{
                                                                        textAlign: "end",
                                                                        position: "absolute",
                                                                        right: 20,
                                                                        top: 50,
                                                                        zIndex: 1,
                                                                    }}
                                                                >
                                                                    <i
                                                                        onClick={() => {
                                                                            this.downloadChart("C6D");
                                                                        }}
                                                                        style={{ cursor: "pointer" }}
                                                                        className="material-icons"
                                                                    >
                                                                        download_for_offline
                                                                    </i>
                                                                </div>
                                                                <div
                                                                    className="card chartcard"
                                                                    id="C6D"

                                                                >
                                                                    <div className="card-body" style={{ padding: 10 }}>
                                                                        <h2 className="card-title ques-title">
                                                                            {this.state.questionlist[17]}
                                                                        </h2>

                                                                        <Bar
                                                                            data={this.getQuestionChartDatasets(
                                                                                3,
                                                                                this.state.questionChart.c[5]
                                                                            )}
                                                                            options={this.questionOption()}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            {(this.state.attributesValue ===
                                                this.state.characteristic[4].label ||
                                                this.state.attributesValue === "All") && (
                                                    <>
                                                        <div
                                                            className="d-sm-flex justify-content-center align-items-center mb-4"
                                                            style={{
                                                                background: "rgb(19, 21, 51)",
                                                                borderRadius: 5,
                                                                padding: 6,
                                                            }}
                                                        >
                                                            <h2 className="text-white font-weight-bold m-0">
                                                                {this.state.radargraph.data.labels[3]}
                                                            </h2>
                                                            <i
                                                                onClick={() => {
                                                                    this.downloadAllCharacteristicQuestionChart([
                                                                        "D1D",
                                                                        "D2D",
                                                                        "D3D",
                                                                        "D4D",
                                                                        "D5D",
                                                                        "D6D",
                                                                    ]);
                                                                }}
                                                                className="material-icons"
                                                                style={{
                                                                    position: "absolute",
                                                                    right: 60,
                                                                    color: "white", cursor: 'pointer'
                                                                }}
                                                            >
                                                                download
                                                            </i>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6 grid-margin stretch-card" style={{ position: "relative" }}>
                                                                <div
                                                                    style={{
                                                                        textAlign: "end",
                                                                        position: "absolute",
                                                                        right: 20,
                                                                        top: 50,
                                                                        zIndex: 1,
                                                                    }}
                                                                >
                                                                    <i
                                                                        onClick={() => {
                                                                            this.downloadChart("D1D");
                                                                        }}
                                                                        style={{ cursor: "pointer" }}
                                                                        className="material-icons"
                                                                    >
                                                                        download_for_offline
                                                                    </i>
                                                                </div>
                                                                <div
                                                                    className="card chartcard"
                                                                    id="D1D"

                                                                >
                                                                    <div className="card-body" style={{ padding: 10 }}>
                                                                        <h2 className="card-title ques-title">
                                                                            {this.state.questionlist[18]}
                                                                        </h2>

                                                                        <Bar
                                                                            data={this.getQuestionChartDatasets(
                                                                                4,
                                                                                this.state.questionChart.d[0]
                                                                            )}
                                                                            options={this.questionOption()}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 grid-margin stretch-card" style={{ position: "relative" }}>
                                                                <div
                                                                    style={{
                                                                        textAlign: "end",
                                                                        position: "absolute",
                                                                        right: 20,
                                                                        top: 50,
                                                                        zIndex: 1,
                                                                    }}
                                                                >
                                                                    <i
                                                                        onClick={() => {
                                                                            this.downloadChart("D2D");
                                                                        }}
                                                                        style={{ cursor: "pointer" }}
                                                                        className="material-icons"
                                                                    >
                                                                        download_for_offline
                                                                    </i>
                                                                </div>
                                                                <div
                                                                    className="card chartcard"
                                                                    id="D2D"

                                                                >
                                                                    <div className="card-body" style={{ padding: 10 }}>
                                                                        <h2 className="card-title ques-title">
                                                                            {this.state.questionlist[19]}
                                                                        </h2>

                                                                        <Bar
                                                                            data={this.getQuestionChartDatasets(
                                                                                4,
                                                                                this.state.questionChart.d[1]
                                                                            )}
                                                                            options={this.questionOption()}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6 grid-margin stretch-card" style={{ position: "relative" }}>
                                                                <div
                                                                    style={{
                                                                        textAlign: "end",
                                                                        position: "absolute",
                                                                        right: 20,
                                                                        top: 50,
                                                                        zIndex: 1,
                                                                    }}
                                                                >
                                                                    <i
                                                                        onClick={() => {
                                                                            this.downloadChart("D3D");
                                                                        }}
                                                                        style={{ cursor: "pointer" }}
                                                                        className="material-icons"
                                                                    >
                                                                        download_for_offline
                                                                    </i>
                                                                </div>
                                                                <div
                                                                    className="card chartcard"
                                                                    id="D3D"

                                                                >
                                                                    <div className="card-body" style={{ padding: 10 }}>
                                                                        <h2 className="card-title ques-title">
                                                                            {this.state.questionlist[20]}
                                                                        </h2>

                                                                        <Bar
                                                                            data={this.getQuestionChartDatasets(
                                                                                4,
                                                                                this.state.questionChart.d[2]
                                                                            )}
                                                                            options={this.questionOption()}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-6 grid-margin stretch-card" style={{ position: "relative" }}>
                                                                <div
                                                                    style={{
                                                                        textAlign: "end",
                                                                        position: "absolute",
                                                                        right: 20,
                                                                        top: 50,
                                                                        zIndex: 1,
                                                                    }}
                                                                >
                                                                    <i
                                                                        onClick={() => {
                                                                            this.downloadChart("D4D");
                                                                        }}
                                                                        style={{ cursor: "pointer" }}
                                                                        className="material-icons"
                                                                    >
                                                                        download_for_offline
                                                                    </i>
                                                                </div>
                                                                <div
                                                                    className="card chartcard"
                                                                    id="D4D"


                                                                >
                                                                    <div className="card-body" style={{ padding: 10 }}>
                                                                        <h2 className="card-title ques-title">
                                                                            {this.state.questionlist[21]}
                                                                        </h2>

                                                                        <Bar
                                                                            data={this.getQuestionChartDatasets(
                                                                                4,
                                                                                this.state.questionChart.d[3]
                                                                            )}
                                                                            options={this.questionOption()}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6 grid-margin stretch-card" style={{ position: "relative" }}>
                                                                <div
                                                                    style={{
                                                                        textAlign: "end",
                                                                        position: "absolute",
                                                                        right: 20,
                                                                        top: 50,
                                                                        zIndex: 1,
                                                                    }}
                                                                >
                                                                    <i
                                                                        onClick={() => {
                                                                            this.downloadChart("D5D");
                                                                        }}
                                                                        style={{ cursor: "pointer" }}
                                                                        className="material-icons"
                                                                    >
                                                                        download_for_offline
                                                                    </i>
                                                                </div>
                                                                <div
                                                                    className="card chartcard"
                                                                    id="D5D"

                                                                >
                                                                    <div className="card-body" style={{ padding: 10 }}>
                                                                        <h2 className="card-title ques-title">
                                                                            {this.state.questionlist[22]}
                                                                        </h2>

                                                                        <Bar
                                                                            data={this.getQuestionChartDatasets(
                                                                                4,
                                                                                this.state.questionChart.d[4]
                                                                            )}
                                                                            options={this.questionOption()}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-6 grid-margin stretch-card" style={{ position: "relative" }}>
                                                                <div
                                                                    style={{
                                                                        textAlign: "end",
                                                                        position: "absolute",
                                                                        right: 20,
                                                                        top: 50,
                                                                        zIndex: 1,
                                                                    }}
                                                                >
                                                                    <i
                                                                        onClick={() => {
                                                                            this.downloadChart("D6D");
                                                                        }}
                                                                        style={{ cursor: "pointer" }}
                                                                        className="material-icons"
                                                                    >
                                                                        download_for_offline
                                                                    </i>
                                                                </div>
                                                                <div
                                                                    className="card chartcard"
                                                                    id="D6D"


                                                                >
                                                                    <div className="card-body" style={{ padding: 10 }}>
                                                                        <h2 className="card-title ques-title">
                                                                            {this.state.questionlist[23]}
                                                                        </h2>

                                                                        <Bar
                                                                            data={this.getQuestionChartDatasets(
                                                                                4,
                                                                                this.state.questionChart.d[5]
                                                                            )}
                                                                            options={this.questionOption()}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            {(this.state.attributesValue ===
                                                this.state.characteristic[5].label ||
                                                this.state.attributesValue === "All") && (
                                                    <>
                                                        <div
                                                            className="d-sm-flex justify-content-center align-items-center mb-4"
                                                            style={{
                                                                background: "rgb(19, 21, 51)",
                                                                borderRadius: 5,
                                                                padding: 6,
                                                            }}
                                                        >
                                                            <h2 className="text-white font-weight-bold m-0">
                                                                {this.state.radargraph.data.labels[4]}
                                                            </h2>
                                                            <i
                                                                onClick={() => {
                                                                    this.downloadAllCharacteristicQuestionChart([
                                                                        "E1D",
                                                                        "E2D",
                                                                        "E3D",
                                                                        "E4D",
                                                                        "E5D",
                                                                        "E6D",
                                                                    ]);
                                                                }}
                                                                className="material-icons"
                                                                style={{
                                                                    position: "absolute",
                                                                    right: 60,
                                                                    color: "white",
                                                                }}
                                                            >
                                                                download
                                                            </i>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6 grid-margin stretch-card" style={{ position: "relative" }}>
                                                                <div
                                                                    style={{
                                                                        textAlign: "end",
                                                                        position: "absolute",
                                                                        right: 20,
                                                                        top: 50,
                                                                        zIndex: 1,
                                                                    }}
                                                                >
                                                                    <i
                                                                        onClick={() => {
                                                                            this.downloadChart("E1D");
                                                                        }}
                                                                        style={{ cursor: "pointer" }}
                                                                        className="material-icons"
                                                                    >
                                                                        download_for_offline
                                                                    </i>
                                                                </div>
                                                                <div
                                                                    className="card chartcard"
                                                                    id="E1D"

                                                                >
                                                                    <div className="card-body" style={{ padding: 10 }}>
                                                                        <h2 className="card-title ques-title">
                                                                            {this.state.questionlist[24]}
                                                                        </h2>

                                                                        <Bar
                                                                            data={this.getQuestionChartDatasets(
                                                                                5,
                                                                                this.state.questionChart.e[0]
                                                                            )}
                                                                            options={this.questionOption()}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 grid-margin stretch-card" style={{ position: "relative" }}>
                                                                <div
                                                                    style={{
                                                                        textAlign: "end",
                                                                        position: "absolute",
                                                                        right: 20,
                                                                        top: 50,
                                                                        zIndex: 1,
                                                                    }}
                                                                >
                                                                    <i
                                                                        onClick={() => {
                                                                            this.downloadChart("E2D");
                                                                        }}
                                                                        style={{ cursor: "pointer" }}
                                                                        className="material-icons"
                                                                    >
                                                                        download_for_offline
                                                                    </i>
                                                                </div>
                                                                <div
                                                                    className="card chartcard"
                                                                    id="E2D"

                                                                >
                                                                    <div className="card-body" style={{ padding: 10 }}>
                                                                        <h2 className="card-title ques-title">
                                                                            {this.state.questionlist[25]}
                                                                        </h2>

                                                                        <Bar
                                                                            data={this.getQuestionChartDatasets(
                                                                                5,
                                                                                this.state.questionChart.e[1]
                                                                            )}
                                                                            options={this.questionOption()}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6 grid-margin stretch-card" style={{ position: "relative" }}>
                                                                <div
                                                                    style={{
                                                                        textAlign: "end",
                                                                        position: "absolute",
                                                                        right: 20,
                                                                        top: 50,
                                                                        zIndex: 1,
                                                                    }}
                                                                >
                                                                    <i
                                                                        onClick={() => {
                                                                            this.downloadChart("E3D");
                                                                        }}
                                                                        style={{ cursor: "pointer" }}
                                                                        className="material-icons"
                                                                    >
                                                                        download_for_offline
                                                                    </i>
                                                                </div>
                                                                <div
                                                                    className="card chartcard"
                                                                    id="E3D"


                                                                >
                                                                    <div className="card-body" style={{ padding: 10 }}>
                                                                        <h2 className="card-title ques-title">
                                                                            {this.state.questionlist[26]}
                                                                        </h2>

                                                                        <Bar
                                                                            data={this.getQuestionChartDatasets(
                                                                                5,
                                                                                this.state.questionChart.e[2]
                                                                            )}
                                                                            options={this.questionOption()}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-6 grid-margin stretch-card" style={{ position: "relative" }}>
                                                                <div
                                                                    style={{
                                                                        textAlign: "end",
                                                                        position: "absolute",
                                                                        right: 20,
                                                                        top: 50,
                                                                        zIndex: 1,
                                                                    }}
                                                                >
                                                                    <i
                                                                        onClick={() => {
                                                                            this.downloadChart("E4D");
                                                                        }}
                                                                        style={{ cursor: "pointer" }}
                                                                        className="material-icons"
                                                                    >
                                                                        download_for_offline
                                                                    </i>
                                                                </div>
                                                                <div
                                                                    className="card chartcard"
                                                                    id="E4D"

                                                                >
                                                                    <div className="card-body" style={{ padding: 10 }}>
                                                                        <h2 className="card-title ques-title">
                                                                            {this.state.questionlist[27]}
                                                                        </h2>

                                                                        <Bar
                                                                            data={this.getQuestionChartDatasets(
                                                                                5,
                                                                                this.state.questionChart.e[3]
                                                                            )}
                                                                            options={this.questionOption()}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6 grid-margin stretch-card" style={{ position: "relative" }}>
                                                                <div
                                                                    style={{
                                                                        textAlign: "end",
                                                                        position: "absolute",
                                                                        right: 20,
                                                                        top: 50,
                                                                        zIndex: 1,
                                                                    }}
                                                                >
                                                                    <i
                                                                        onClick={() => {
                                                                            this.downloadChart("E5D");
                                                                        }}
                                                                        style={{ cursor: "pointer" }}
                                                                        className="material-icons"
                                                                    >
                                                                        download_for_offline
                                                                    </i>
                                                                </div>
                                                                <div
                                                                    className="card chartcard"
                                                                    id="E5D"

                                                                >
                                                                    <div className="card-body" style={{ padding: 10 }}>
                                                                        <h2 className="card-title ques-title">
                                                                            {this.state.questionlist[28]}
                                                                        </h2>

                                                                        <Bar
                                                                            data={this.getQuestionChartDatasets(
                                                                                5,
                                                                                this.state.questionChart.e[4]
                                                                            )}
                                                                            options={this.questionOption()}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 grid-margin stretch-card" style={{ position: "relative" }}>
                                                                <div
                                                                    style={{
                                                                        textAlign: "end",
                                                                        position: "absolute",
                                                                        right: 20,
                                                                        top: 50,
                                                                        zIndex: 1,
                                                                    }}
                                                                >
                                                                    <i
                                                                        onClick={() => {
                                                                            this.downloadChart("E6D");
                                                                        }}
                                                                        style={{ cursor: "pointer" }}
                                                                        className="material-icons"
                                                                    >
                                                                        download_for_offline
                                                                    </i>
                                                                </div>
                                                                <div
                                                                    className="card chartcard"
                                                                    id="E6D"

                                                                >
                                                                    <div className="card-body" style={{ padding: 10 }}>
                                                                        <h2 className="card-title ques-title">
                                                                            {this.state.questionlist[29]}
                                                                        </h2>

                                                                        <Bar
                                                                            data={this.getQuestionChartDatasets(
                                                                                5,
                                                                                this.state.questionChart.e[5]
                                                                            )}
                                                                            options={this.questionOption()}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            {(this.state.attributesValue ===
                                                this.state.characteristic[6].label ||
                                                this.state.attributesValue === "All") && (
                                                    <>
                                                        <div
                                                            className="d-sm-flex justify-content-center align-items-center mb-4"
                                                            style={{
                                                                background: "rgb(19, 21, 51)",
                                                                borderRadius: 5,
                                                                padding: 6,
                                                            }}
                                                        >
                                                            <h2 className="text-white font-weight-bold m-0">
                                                                {this.state.radargraph.data.labels[5]}
                                                            </h2>
                                                            <i
                                                                onClick={() => {
                                                                    this.downloadAllCharacteristicQuestionChart([
                                                                        "F1D",
                                                                        "F2D",
                                                                        "F3D",
                                                                        "F4D",
                                                                        "F5D",
                                                                        "F6D",
                                                                    ]);
                                                                }}
                                                                className="material-icons"
                                                                style={{
                                                                    position: "absolute",
                                                                    right: 60,
                                                                    color: "white",
                                                                }}
                                                            >
                                                                download
                                                            </i>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6 grid-margin stretch-card" style={{ position: "relative" }}>
                                                                <div
                                                                    style={{
                                                                        textAlign: "end",
                                                                        position: "absolute",
                                                                        right: 20,
                                                                        top: 50,
                                                                        zIndex: 1,
                                                                    }}
                                                                >
                                                                    <i
                                                                        onClick={() => {
                                                                            this.downloadChart("F1D");
                                                                        }}
                                                                        style={{ cursor: "pointer" }}
                                                                        className="material-icons"
                                                                    >
                                                                        download_for_offline
                                                                    </i>
                                                                </div>
                                                                <div
                                                                    className="card chartcard"
                                                                    id="F1D"

                                                                >
                                                                    <div className="card-body" style={{ padding: 10 }}>
                                                                        <h2 className="card-title ques-title">
                                                                            {this.state.questionlist[30]}
                                                                        </h2>

                                                                        <Bar
                                                                            data={this.getQuestionChartDatasets(
                                                                                6,
                                                                                this.state.questionChart.f[0]
                                                                            )}
                                                                            options={this.questionOption()}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 grid-margin stretch-card" style={{ position: "relative" }}>
                                                                <div
                                                                    style={{
                                                                        textAlign: "end",
                                                                        position: "absolute",
                                                                        right: 20,
                                                                        top: 50,
                                                                        zIndex: 1,
                                                                    }}
                                                                >
                                                                    <i
                                                                        onClick={() => {
                                                                            this.downloadChart("F2D");
                                                                        }}
                                                                        style={{ cursor: "pointer" }}
                                                                        className="material-icons"
                                                                    >
                                                                        download_for_offline
                                                                    </i>
                                                                </div>
                                                                <div
                                                                    className="card chartcard"
                                                                    id="F2D"

                                                                >
                                                                    <div className="card-body" style={{ padding: 10 }}>
                                                                        <h2 className="card-title ques-title">
                                                                            {this.state.questionlist[31]}
                                                                        </h2>

                                                                        <Bar
                                                                            data={this.getQuestionChartDatasets(
                                                                                6,
                                                                                this.state.questionChart.f[1]
                                                                            )}
                                                                            options={this.questionOption()}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6 grid-margin stretch-card" style={{ position: "relative" }}>
                                                                <div
                                                                    style={{
                                                                        textAlign: "end",
                                                                        position: "absolute",
                                                                        right: 20,
                                                                        top: 50,
                                                                        zIndex: 1,
                                                                    }}
                                                                >
                                                                    <i
                                                                        onClick={() => {
                                                                            this.downloadChart("F3D");
                                                                        }}
                                                                        style={{ cursor: "pointer" }}
                                                                        className="material-icons"
                                                                    >
                                                                        download_for_offline
                                                                    </i>
                                                                </div>
                                                                <div
                                                                    className="card chartcard"
                                                                    id="F3D"

                                                                >
                                                                    <div className="card-body" style={{ padding: 10 }}>
                                                                        <h2 className="card-title ques-title">
                                                                            {this.state.questionlist[32]}
                                                                        </h2>

                                                                        <Bar
                                                                            data={this.getQuestionChartDatasets(
                                                                                6,
                                                                                this.state.questionChart.f[2]
                                                                            )}
                                                                            options={this.questionOption()}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-6 grid-margin stretch-card" style={{ position: "relative" }}>
                                                                <div
                                                                    style={{
                                                                        textAlign: "end",
                                                                        position: "absolute",
                                                                        right: 20,
                                                                        top: 50,
                                                                        zIndex: 1,
                                                                    }}
                                                                >
                                                                    <i
                                                                        onClick={() => {
                                                                            this.downloadChart("F4D");
                                                                        }}
                                                                        style={{ cursor: "pointer" }}
                                                                        className="material-icons"
                                                                    >
                                                                        download_for_offline
                                                                    </i>
                                                                </div>
                                                                <div
                                                                    className="card chartcard"
                                                                    id="F4D"


                                                                >
                                                                    <div className="card-body" style={{ padding: 10 }}>
                                                                        <h2 className="card-title ques-title">
                                                                            {this.state.questionlist[33]}
                                                                        </h2>

                                                                        <Bar
                                                                            data={this.getQuestionChartDatasets(
                                                                                6,
                                                                                this.state.questionChart.f[3]
                                                                            )}
                                                                            options={this.questionOption()}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6 grid-margin stretch-card" style={{ position: "relative" }}>
                                                                <div
                                                                    style={{
                                                                        textAlign: "end",
                                                                        position: "absolute",
                                                                        right: 20,
                                                                        top: 50,
                                                                        zIndex: 1,
                                                                    }}
                                                                >
                                                                    <i
                                                                        onClick={() => {
                                                                            this.downloadChart("F5D");
                                                                        }}
                                                                        style={{ cursor: "pointer" }}
                                                                        className="material-icons"
                                                                    >
                                                                        download_for_offline
                                                                    </i>
                                                                </div>
                                                                <div
                                                                    className="card chartcard"
                                                                    id="F5D"


                                                                >
                                                                    <div className="card-body" style={{ padding: 10 }}>
                                                                        <h2 className="card-title ques-title">
                                                                            {this.state.questionlist[34]}
                                                                        </h2>



                                                                        <Bar
                                                                            id="F5"
                                                                            data={this.getQuestionChartDatasets(
                                                                                6,
                                                                                this.state.questionChart.f[4]
                                                                            )}
                                                                            options={this.questionOption()}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 grid-margin stretch-card" style={{ position: "relative" }}>
                                                                <div
                                                                    style={{
                                                                        textAlign: "end",
                                                                        position: "absolute",
                                                                        right: 20,
                                                                        top: 50,
                                                                        zIndex: 1,
                                                                    }}
                                                                >
                                                                    <i
                                                                        onClick={() => {
                                                                            this.downloadChart("F6D");
                                                                        }}
                                                                        style={{ cursor: "pointer" }}
                                                                        className="material-icons"
                                                                    >
                                                                        download_for_offline
                                                                    </i>
                                                                </div>
                                                                <div
                                                                    className="card chartcard"
                                                                    id="F6D"


                                                                >
                                                                    <div className="card-body" style={{ padding: 10 }}>
                                                                        <h2 className="card-title ques-title">
                                                                            {this.state.questionlist[35]}
                                                                        </h2>


                                                                        <Bar
                                                                            data={this.getQuestionChartDatasets(
                                                                                6,
                                                                                this.state.questionChart.f[5]
                                                                            )}
                                                                            options={this.questionOption()}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                        </>
                                    )}
                            </>
                            :
                            <MutatingDots
                                height="100"
                                width="100"
                                color="#00D8F0"
                                secondaryColor='rgb(19, 21, 51)'
                                radius='12.5'
                                ariaLabel="mutating-dots-loading"
                                wrapperStyle={{ justifyContent: 'center' }}
                                wrapperClass=""
                                visible={true}
                            />

                        }
                    </div>
                ) : (
                    <div >Check internet or contact admin team</div>
                )}
                <div
                    className="card chartcard"
                    id="radarchart2parent"
                    hidden
                    style={{ background: "transparent" }}
                >
                    <div
                        className="card-body"
                        style={{
                            padding: 10,
                            background: "transparent",
                            display: "flex",
                            alignItems: "flex-start",
                            flexDirection: "column",
                            justifyContent: "center",
                        }}
                    >
                        <Radar id="radarchart2" {...this.state.radargraph2} />
                    </div>
                </div>
            </div>
        );
    }
}
export default DashboardTwo;
