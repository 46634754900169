import React,{useState} from "react";


const CB = (props)=>{


    return (
        <input type={'checkbox'} checked={props.checked} onChange={(e)=>{props.onChange(e)}} />
    )
}
export default CB