export const responseData =   [ {
    "response": {
        "id": 5751,
        "moss_user": 19,
        "topic1": 44,
        "topic2": 85,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 44,
        "name": "Apron",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 85,
        "name": "Ramp Services",
        "order": null,
        "created": null,
        "topicOneId": 44
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5752,
        "moss_user": 19,
        "topic1": 44,
        "topic2": 85,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": true
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": true
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": true
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 44,
        "name": "Apron",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 85,
        "name": "Ramp Services",
        "order": null,
        "created": null,
        "topicOneId": 44
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5753,
        "moss_user": 19,
        "topic1": 44,
        "topic2": 85,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": true
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": true
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": true
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": true
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": true
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": true
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 44,
        "name": "Apron",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 85,
        "name": "Ramp Services",
        "order": null,
        "created": null,
        "topicOneId": 44
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5754,
        "moss_user": 19,
        "topic1": 44,
        "topic2": 85,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": true
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": true
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 44,
        "name": "Apron",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 85,
        "name": "Ramp Services",
        "order": null,
        "created": null,
        "topicOneId": 44
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5755,
        "moss_user": 19,
        "topic1": 44,
        "topic2": 85,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": true
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": true
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": true
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": true
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 44,
        "name": "Apron",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 85,
        "name": "Ramp Services",
        "order": null,
        "created": null,
        "topicOneId": 44
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5756,
        "moss_user": 19,
        "topic1": 44,
        "topic2": 85,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": true
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 44,
        "name": "Apron",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 85,
        "name": "Ramp Services",
        "order": null,
        "created": null,
        "topicOneId": 44
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5757,
        "moss_user": 19,
        "topic1": 44,
        "topic2": 85,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 44,
        "name": "Apron",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 85,
        "name": "Ramp Services",
        "order": null,
        "created": null,
        "topicOneId": 44
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5758,
        "moss_user": 19,
        "topic1": 44,
        "topic2": 79,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 7,
                "name": "治理意识与一致性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 8,
                        "name": "Governance awareness and alignment",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G1.&nbsp;&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队理解为什么需要在工作场所建立一套安全管理系统.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671095932530-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G2.&nbsp;&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我了解与我工作相关的健康与安全程序.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671096185180",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我认可与我工作相关的健康与安全程序.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671096202203",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我团队中的每个人都觉得我们需要对工作场所的安全负责.</span>",
                                "name": "radio-group-1671096237595",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们工作的风险评估准确的将潜在风险辨认和减低.</span>",
                                "name": "radio-group-1671096270071",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我所接受的安全就职为我提供了安全工作方面的知识.</span>",
                                "name": "radio-group-1671096294116",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 7
                    }
                ]
            },
            {
                "id": 8,
                "name": "专注失败事件",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 9,
                        "name": "Preoccupation with failure",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F1.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队认为较小的安全错误也非常重要，值得讨论.</span>",
                                "name": "radio-group-1671096413811-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">举报安全问题是我的个人职责.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">&nbsp;</span></p>",
                                "name": "radio-group-1671096503192",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们预料到较小的的工作错误有可能发生，且在这些错误发生时，不会刻意隐瞒.</span>",
                                "name": "radio-group-1671096524263",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的工作团队愿意停止工作来纠正较小的的安全错误.</span>",
                                "name": "radio-group-1671096539481",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们团队使用一个完善的流程来报告错误.（事故</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 0px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">/</span>险情报告）</p>",
                                "name": "radio-group-1671096557806",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会仔细检查工作方法描述和风险评估，以预测潜在的错误.</span>",
                                "name": "radio-group-1671096586371",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 8
                    }
                ]
            },
            {
                "id": 9,
                "name": "拒绝简化",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 10,
                        "name": "Reluctance to simplify",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S1.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会讨论工作计划，鼓励提出更好的建议.</span>",
                                "name": "radio-group-1671097013525-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队关注工作场所的变化并采取应对行动.</span>",
                                "name": "radio-group-1671097767313",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队认为，较小的的错误是意外事件和事故的源头</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">,</span><span class=\"s2\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Helvetica;\"> </span></p><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">可能会导致危害</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span>",
                                "name": "radio-group-1671097786481",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会试图改进工作方法，而不是按照他们一贯的方式做事</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span><br>",
                                "name": "radio-group-1671097829778",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们受到鼓励思考更好的做事方法</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span><br>",
                                "name": "radio-group-1671097848001",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们会与其他团队分享我们学到的知识.</span>",
                                "name": "radio-group-1671097862363",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 9
                    }
                ]
            },
            {
                "id": 10,
                "name": "对操作的敏感性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 11,
                        "name": "Sensitivity to operations",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">如果出现安全风险，我们的团队会花时间停止操作并重新开始</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我会从我们的工作计划中寻找微小的变化.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">经理会询问团队对工作计划可行性的意见.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">组织内有明确的流程来上报告更好的工作方式.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">管理层会倾听并按照工作团队的建议若实建议行事.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们会审查我们的工作，并找出任何需要改进的地方.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 10
                    }
                ]
            },
            {
                "id": 11,
                "name": "致力于维持韧性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 12,
                        "name": "Commitment to resilience",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">我们相信，作为一个团体，我们能通过接受和从安全错误中学习而变得更强大</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队有一套完善的安全错误分析流程.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队会从事件调查和调查发现中学习，并将这些发现应用到工作场所.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的所有团队成员都对安全错误的识别作出积极反应.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">共同修复与安全相关的错误是正常工作.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">停止并修复一个安全错误是被许可的.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 11
                    }
                ]
            },
            {
                "id": 12,
                "name": "重视专业人才",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 13,
                        "name": "Deference to Expertise",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">如果我的团队发现安全错误，同时也具备专业知识，我们会被授权采取行动</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">当发现高风险情况时，我们会立即采取行动，而不是报告和等待.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">如果我们的团队知道如何修复错误，他们有权进行修复.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我觉得在做安全决策时，我的专业知识有被考虑和受到重视.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的主管很擅长主持安全问题解决会议.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">如果我们需要专业协助，我们知道该联系谁.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 12
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 44,
        "name": "Apron",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 79,
        "name": "Baggage",
        "order": null,
        "created": null,
        "topicOneId": 44
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5759,
        "moss_user": 19,
        "topic1": 44,
        "topic2": 83,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 44,
        "name": "Apron",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 83,
        "name": "Lost & Found",
        "order": null,
        "created": null,
        "topicOneId": 44
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5760,
        "moss_user": 19,
        "topic1": 44,
        "topic2": 83,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": true
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 44,
        "name": "Apron",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 83,
        "name": "Lost & Found",
        "order": null,
        "created": null,
        "topicOneId": 44
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5761,
        "moss_user": 19,
        "topic1": 44,
        "topic2": 85,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": true
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": true
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 44,
        "name": "Apron",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 85,
        "name": "Ramp Services",
        "order": null,
        "created": null,
        "topicOneId": 44
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5762,
        "moss_user": 19,
        "topic1": 44,
        "topic2": 85,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 44,
        "name": "Apron",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 85,
        "name": "Ramp Services",
        "order": null,
        "created": null,
        "topicOneId": 44
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5763,
        "moss_user": 19,
        "topic1": 49,
        "topic2": 125,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 7,
                "name": "治理意识与一致性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 8,
                        "name": "Governance awareness and alignment",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G1.&nbsp;&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队理解为什么需要在工作场所建立一套安全管理系统.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671095932530-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G2.&nbsp;&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我了解与我工作相关的健康与安全程序.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671096185180",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我认可与我工作相关的健康与安全程序.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671096202203",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我团队中的每个人都觉得我们需要对工作场所的安全负责.</span>",
                                "name": "radio-group-1671096237595",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们工作的风险评估准确的将潜在风险辨认和减低.</span>",
                                "name": "radio-group-1671096270071",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我所接受的安全就职为我提供了安全工作方面的知识.</span>",
                                "name": "radio-group-1671096294116",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 7
                    }
                ]
            },
            {
                "id": 8,
                "name": "专注失败事件",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 9,
                        "name": "Preoccupation with failure",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F1.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队认为较小的安全错误也非常重要，值得讨论.</span>",
                                "name": "radio-group-1671096413811-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">举报安全问题是我的个人职责.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">&nbsp;</span></p>",
                                "name": "radio-group-1671096503192",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们预料到较小的的工作错误有可能发生，且在这些错误发生时，不会刻意隐瞒.</span>",
                                "name": "radio-group-1671096524263",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的工作团队愿意停止工作来纠正较小的的安全错误.</span>",
                                "name": "radio-group-1671096539481",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们团队使用一个完善的流程来报告错误.（事故</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 0px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">/</span>险情报告）</p>",
                                "name": "radio-group-1671096557806",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会仔细检查工作方法描述和风险评估，以预测潜在的错误.</span>",
                                "name": "radio-group-1671096586371",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 8
                    }
                ]
            },
            {
                "id": 9,
                "name": "拒绝简化",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 10,
                        "name": "Reluctance to simplify",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S1.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会讨论工作计划，鼓励提出更好的建议.</span>",
                                "name": "radio-group-1671097013525-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队关注工作场所的变化并采取应对行动.</span>",
                                "name": "radio-group-1671097767313",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队认为，较小的的错误是意外事件和事故的源头</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">,</span><span class=\"s2\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Helvetica;\"> </span></p><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">可能会导致危害</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span>",
                                "name": "radio-group-1671097786481",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": true
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会试图改进工作方法，而不是按照他们一贯的方式做事</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span><br>",
                                "name": "radio-group-1671097829778",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们受到鼓励思考更好的做事方法</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span><br>",
                                "name": "radio-group-1671097848001",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们会与其他团队分享我们学到的知识.</span>",
                                "name": "radio-group-1671097862363",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 9
                    }
                ]
            },
            {
                "id": 10,
                "name": "对操作的敏感性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 11,
                        "name": "Sensitivity to operations",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">如果出现安全风险，我们的团队会花时间停止操作并重新开始</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我会从我们的工作计划中寻找微小的变化.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">经理会询问团队对工作计划可行性的意见.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">组织内有明确的流程来上报告更好的工作方式.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">管理层会倾听并按照工作团队的建议若实建议行事.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们会审查我们的工作，并找出任何需要改进的地方.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 10
                    }
                ]
            },
            {
                "id": 11,
                "name": "致力于维持韧性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 12,
                        "name": "Commitment to resilience",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">我们相信，作为一个团体，我们能通过接受和从安全错误中学习而变得更强大</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队有一套完善的安全错误分析流程.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队会从事件调查和调查发现中学习，并将这些发现应用到工作场所.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的所有团队成员都对安全错误的识别作出积极反应.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">共同修复与安全相关的错误是正常工作.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">停止并修复一个安全错误是被许可的.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 11
                    }
                ]
            },
            {
                "id": 12,
                "name": "重视专业人才",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 13,
                        "name": "Deference to Expertise",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">如果我的团队发现安全错误，同时也具备专业知识，我们会被授权采取行动</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">当发现高风险情况时，我们会立即采取行动，而不是报告和等待.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">如果我们的团队知道如何修复错误，他们有权进行修复.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我觉得在做安全决策时，我的专业知识有被考虑和受到重视.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的主管很擅长主持安全问题解决会议.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">如果我们需要专业协助，我们知道该联系谁.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 12
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 49,
        "name": "SATS Food Services",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 125,
        "name": "MHA Catering – MCO HQ",
        "order": null,
        "created": null,
        "topicOneId": 49
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5764,
        "moss_user": 19,
        "topic1": 49,
        "topic2": 130,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 7,
                "name": "治理意识与一致性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 8,
                        "name": "Governance awareness and alignment",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G1.&nbsp;&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队理解为什么需要在工作场所建立一套安全管理系统.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671095932530-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G2.&nbsp;&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我了解与我工作相关的健康与安全程序.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671096185180",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我认可与我工作相关的健康与安全程序.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671096202203",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我团队中的每个人都觉得我们需要对工作场所的安全负责.</span>",
                                "name": "radio-group-1671096237595",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们工作的风险评估准确的将潜在风险辨认和减低.</span>",
                                "name": "radio-group-1671096270071",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我所接受的安全就职为我提供了安全工作方面的知识.</span>",
                                "name": "radio-group-1671096294116",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 7
                    }
                ]
            },
            {
                "id": 8,
                "name": "专注失败事件",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 9,
                        "name": "Preoccupation with failure",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F1.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队认为较小的安全错误也非常重要，值得讨论.</span>",
                                "name": "radio-group-1671096413811-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">举报安全问题是我的个人职责.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">&nbsp;</span></p>",
                                "name": "radio-group-1671096503192",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们预料到较小的的工作错误有可能发生，且在这些错误发生时，不会刻意隐瞒.</span>",
                                "name": "radio-group-1671096524263",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的工作团队愿意停止工作来纠正较小的的安全错误.</span>",
                                "name": "radio-group-1671096539481",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们团队使用一个完善的流程来报告错误.（事故</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 0px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">/</span>险情报告）</p>",
                                "name": "radio-group-1671096557806",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会仔细检查工作方法描述和风险评估，以预测潜在的错误.</span>",
                                "name": "radio-group-1671096586371",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 8
                    }
                ]
            },
            {
                "id": 9,
                "name": "拒绝简化",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 10,
                        "name": "Reluctance to simplify",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S1.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会讨论工作计划，鼓励提出更好的建议.</span>",
                                "name": "radio-group-1671097013525-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队关注工作场所的变化并采取应对行动.</span>",
                                "name": "radio-group-1671097767313",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队认为，较小的的错误是意外事件和事故的源头</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">,</span><span class=\"s2\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Helvetica;\"> </span></p><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">可能会导致危害</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span>",
                                "name": "radio-group-1671097786481",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会试图改进工作方法，而不是按照他们一贯的方式做事</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span><br>",
                                "name": "radio-group-1671097829778",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们受到鼓励思考更好的做事方法</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span><br>",
                                "name": "radio-group-1671097848001",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们会与其他团队分享我们学到的知识.</span>",
                                "name": "radio-group-1671097862363",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 9
                    }
                ]
            },
            {
                "id": 10,
                "name": "对操作的敏感性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 11,
                        "name": "Sensitivity to operations",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">如果出现安全风险，我们的团队会花时间停止操作并重新开始</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我会从我们的工作计划中寻找微小的变化.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">经理会询问团队对工作计划可行性的意见.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">组织内有明确的流程来上报告更好的工作方式.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">管理层会倾听并按照工作团队的建议若实建议行事.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们会审查我们的工作，并找出任何需要改进的地方.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 10
                    }
                ]
            },
            {
                "id": 11,
                "name": "致力于维持韧性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 12,
                        "name": "Commitment to resilience",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">我们相信，作为一个团体，我们能通过接受和从安全错误中学习而变得更强大</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队有一套完善的安全错误分析流程.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队会从事件调查和调查发现中学习，并将这些发现应用到工作场所.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的所有团队成员都对安全错误的识别作出积极反应.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">共同修复与安全相关的错误是正常工作.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">停止并修复一个安全错误是被许可的.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 11
                    }
                ]
            },
            {
                "id": 12,
                "name": "重视专业人才",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 13,
                        "name": "Deference to Expertise",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">如果我的团队发现安全错误，同时也具备专业知识，我们会被授权采取行动</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">当发现高风险情况时，我们会立即采取行动，而不是报告和等待.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">如果我们的团队知道如何修复错误，他们有权进行修复.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我觉得在做安全决策时，我的专业知识有被考虑和受到重视.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的主管很擅长主持安全问题解决会议.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">如果我们需要专业协助，我们知道该联系谁.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 12
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 49,
        "name": "SATS Food Services",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 130,
        "name": "SAF Catering Operations – Central Zone",
        "order": null,
        "created": null,
        "topicOneId": 49
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5765,
        "moss_user": 19,
        "topic1": 44,
        "topic2": 83,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": true
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": true
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": true
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": true
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": true
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 44,
        "name": "Apron",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 83,
        "name": "Lost & Found",
        "order": null,
        "created": null,
        "topicOneId": 44
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5766,
        "moss_user": 19,
        "topic1": 45,
        "topic2": 93,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 45,
        "name": "Cargo Services",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 93,
        "name": "ECC",
        "order": null,
        "created": null,
        "topicOneId": 45
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5767,
        "moss_user": 19,
        "topic1": 49,
        "topic2": 130,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 7,
                "name": "治理意识与一致性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 8,
                        "name": "Governance awareness and alignment",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G1.&nbsp;&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队理解为什么需要在工作场所建立一套安全管理系统.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671095932530-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G2.&nbsp;&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我了解与我工作相关的健康与安全程序.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671096185180",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我认可与我工作相关的健康与安全程序.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671096202203",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我团队中的每个人都觉得我们需要对工作场所的安全负责.</span>",
                                "name": "radio-group-1671096237595",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们工作的风险评估准确的将潜在风险辨认和减低.</span>",
                                "name": "radio-group-1671096270071",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我所接受的安全就职为我提供了安全工作方面的知识.</span>",
                                "name": "radio-group-1671096294116",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 7
                    }
                ]
            },
            {
                "id": 8,
                "name": "专注失败事件",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 9,
                        "name": "Preoccupation with failure",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F1.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队认为较小的安全错误也非常重要，值得讨论.</span>",
                                "name": "radio-group-1671096413811-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">举报安全问题是我的个人职责.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">&nbsp;</span></p>",
                                "name": "radio-group-1671096503192",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们预料到较小的的工作错误有可能发生，且在这些错误发生时，不会刻意隐瞒.</span>",
                                "name": "radio-group-1671096524263",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的工作团队愿意停止工作来纠正较小的的安全错误.</span>",
                                "name": "radio-group-1671096539481",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们团队使用一个完善的流程来报告错误.（事故</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 0px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">/</span>险情报告）</p>",
                                "name": "radio-group-1671096557806",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会仔细检查工作方法描述和风险评估，以预测潜在的错误.</span>",
                                "name": "radio-group-1671096586371",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 8
                    }
                ]
            },
            {
                "id": 9,
                "name": "拒绝简化",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 10,
                        "name": "Reluctance to simplify",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S1.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会讨论工作计划，鼓励提出更好的建议.</span>",
                                "name": "radio-group-1671097013525-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队关注工作场所的变化并采取应对行动.</span>",
                                "name": "radio-group-1671097767313",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队认为，较小的的错误是意外事件和事故的源头</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">,</span><span class=\"s2\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Helvetica;\"> </span></p><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">可能会导致危害</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span>",
                                "name": "radio-group-1671097786481",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会试图改进工作方法，而不是按照他们一贯的方式做事</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span><br>",
                                "name": "radio-group-1671097829778",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们受到鼓励思考更好的做事方法</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span><br>",
                                "name": "radio-group-1671097848001",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们会与其他团队分享我们学到的知识.</span>",
                                "name": "radio-group-1671097862363",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 9
                    }
                ]
            },
            {
                "id": 10,
                "name": "对操作的敏感性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 11,
                        "name": "Sensitivity to operations",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">如果出现安全风险，我们的团队会花时间停止操作并重新开始</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我会从我们的工作计划中寻找微小的变化.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">经理会询问团队对工作计划可行性的意见.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">组织内有明确的流程来上报告更好的工作方式.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">管理层会倾听并按照工作团队的建议若实建议行事.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们会审查我们的工作，并找出任何需要改进的地方.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 10
                    }
                ]
            },
            {
                "id": 11,
                "name": "致力于维持韧性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 12,
                        "name": "Commitment to resilience",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">我们相信，作为一个团体，我们能通过接受和从安全错误中学习而变得更强大</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队有一套完善的安全错误分析流程.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队会从事件调查和调查发现中学习，并将这些发现应用到工作场所.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的所有团队成员都对安全错误的识别作出积极反应.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">共同修复与安全相关的错误是正常工作.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">停止并修复一个安全错误是被许可的.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 11
                    }
                ]
            },
            {
                "id": 12,
                "name": "重视专业人才",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 13,
                        "name": "Deference to Expertise",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">如果我的团队发现安全错误，同时也具备专业知识，我们会被授权采取行动</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">当发现高风险情况时，我们会立即采取行动，而不是报告和等待.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">如果我们的团队知道如何修复错误，他们有权进行修复.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我觉得在做安全决策时，我的专业知识有被考虑和受到重视.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的主管很擅长主持安全问题解决会议.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">如果我们需要专业协助，我们知道该联系谁.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 12
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 49,
        "name": "SATS Food Services",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 130,
        "name": "SAF Catering Operations – Central Zone",
        "order": null,
        "created": null,
        "topicOneId": 49
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5768,
        "moss_user": 19,
        "topic1": 48,
        "topic2": 117,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 48,
        "name": "Catering",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 117,
        "name": "Cabin Services",
        "order": null,
        "created": null,
        "topicOneId": 48
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5769,
        "moss_user": 19,
        "topic1": 49,
        "topic2": 130,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 7,
                "name": "治理意识与一致性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 8,
                        "name": "Governance awareness and alignment",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G1.&nbsp;&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队理解为什么需要在工作场所建立一套安全管理系统.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671095932530-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G2.&nbsp;&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我了解与我工作相关的健康与安全程序.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671096185180",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我认可与我工作相关的健康与安全程序.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671096202203",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我团队中的每个人都觉得我们需要对工作场所的安全负责.</span>",
                                "name": "radio-group-1671096237595",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们工作的风险评估准确的将潜在风险辨认和减低.</span>",
                                "name": "radio-group-1671096270071",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我所接受的安全就职为我提供了安全工作方面的知识.</span>",
                                "name": "radio-group-1671096294116",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 7
                    }
                ]
            },
            {
                "id": 8,
                "name": "专注失败事件",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 9,
                        "name": "Preoccupation with failure",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F1.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队认为较小的安全错误也非常重要，值得讨论.</span>",
                                "name": "radio-group-1671096413811-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">举报安全问题是我的个人职责.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">&nbsp;</span></p>",
                                "name": "radio-group-1671096503192",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们预料到较小的的工作错误有可能发生，且在这些错误发生时，不会刻意隐瞒.</span>",
                                "name": "radio-group-1671096524263",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的工作团队愿意停止工作来纠正较小的的安全错误.</span>",
                                "name": "radio-group-1671096539481",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们团队使用一个完善的流程来报告错误.（事故</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 0px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">/</span>险情报告）</p>",
                                "name": "radio-group-1671096557806",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会仔细检查工作方法描述和风险评估，以预测潜在的错误.</span>",
                                "name": "radio-group-1671096586371",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 8
                    }
                ]
            },
            {
                "id": 9,
                "name": "拒绝简化",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 10,
                        "name": "Reluctance to simplify",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S1.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会讨论工作计划，鼓励提出更好的建议.</span>",
                                "name": "radio-group-1671097013525-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队关注工作场所的变化并采取应对行动.</span>",
                                "name": "radio-group-1671097767313",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队认为，较小的的错误是意外事件和事故的源头</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">,</span><span class=\"s2\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Helvetica;\"> </span></p><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">可能会导致危害</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span>",
                                "name": "radio-group-1671097786481",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会试图改进工作方法，而不是按照他们一贯的方式做事</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span><br>",
                                "name": "radio-group-1671097829778",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们受到鼓励思考更好的做事方法</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span><br>",
                                "name": "radio-group-1671097848001",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们会与其他团队分享我们学到的知识.</span>",
                                "name": "radio-group-1671097862363",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 9
                    }
                ]
            },
            {
                "id": 10,
                "name": "对操作的敏感性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 11,
                        "name": "Sensitivity to operations",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">如果出现安全风险，我们的团队会花时间停止操作并重新开始</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我会从我们的工作计划中寻找微小的变化.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">经理会询问团队对工作计划可行性的意见.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">组织内有明确的流程来上报告更好的工作方式.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">管理层会倾听并按照工作团队的建议若实建议行事.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们会审查我们的工作，并找出任何需要改进的地方.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 10
                    }
                ]
            },
            {
                "id": 11,
                "name": "致力于维持韧性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 12,
                        "name": "Commitment to resilience",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">我们相信，作为一个团体，我们能通过接受和从安全错误中学习而变得更强大</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队有一套完善的安全错误分析流程.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队会从事件调查和调查发现中学习，并将这些发现应用到工作场所.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的所有团队成员都对安全错误的识别作出积极反应.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">共同修复与安全相关的错误是正常工作.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">停止并修复一个安全错误是被许可的.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 11
                    }
                ]
            },
            {
                "id": 12,
                "name": "重视专业人才",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 13,
                        "name": "Deference to Expertise",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">如果我的团队发现安全错误，同时也具备专业知识，我们会被授权采取行动</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">当发现高风险情况时，我们会立即采取行动，而不是报告和等待.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">如果我们的团队知道如何修复错误，他们有权进行修复.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我觉得在做安全决策时，我的专业知识有被考虑和受到重视.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的主管很擅长主持安全问题解决会议.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">如果我们需要专业协助，我们知道该联系谁.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 12
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 49,
        "name": "SATS Food Services",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 130,
        "name": "SAF Catering Operations – Central Zone",
        "order": null,
        "created": null,
        "topicOneId": 49
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5770,
        "moss_user": 19,
        "topic1": 49,
        "topic2": 130,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 7,
                "name": "治理意识与一致性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 8,
                        "name": "Governance awareness and alignment",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G1.&nbsp;&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队理解为什么需要在工作场所建立一套安全管理系统.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671095932530-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G2.&nbsp;&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我了解与我工作相关的健康与安全程序.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671096185180",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我认可与我工作相关的健康与安全程序.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671096202203",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我团队中的每个人都觉得我们需要对工作场所的安全负责.</span>",
                                "name": "radio-group-1671096237595",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们工作的风险评估准确的将潜在风险辨认和减低.</span>",
                                "name": "radio-group-1671096270071",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我所接受的安全就职为我提供了安全工作方面的知识.</span>",
                                "name": "radio-group-1671096294116",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 7
                    }
                ]
            },
            {
                "id": 8,
                "name": "专注失败事件",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 9,
                        "name": "Preoccupation with failure",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F1.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队认为较小的安全错误也非常重要，值得讨论.</span>",
                                "name": "radio-group-1671096413811-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">举报安全问题是我的个人职责.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">&nbsp;</span></p>",
                                "name": "radio-group-1671096503192",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们预料到较小的的工作错误有可能发生，且在这些错误发生时，不会刻意隐瞒.</span>",
                                "name": "radio-group-1671096524263",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的工作团队愿意停止工作来纠正较小的的安全错误.</span>",
                                "name": "radio-group-1671096539481",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们团队使用一个完善的流程来报告错误.（事故</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 0px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">/</span>险情报告）</p>",
                                "name": "radio-group-1671096557806",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会仔细检查工作方法描述和风险评估，以预测潜在的错误.</span>",
                                "name": "radio-group-1671096586371",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 8
                    }
                ]
            },
            {
                "id": 9,
                "name": "拒绝简化",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 10,
                        "name": "Reluctance to simplify",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S1.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会讨论工作计划，鼓励提出更好的建议.</span>",
                                "name": "radio-group-1671097013525-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队关注工作场所的变化并采取应对行动.</span>",
                                "name": "radio-group-1671097767313",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队认为，较小的的错误是意外事件和事故的源头</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">,</span><span class=\"s2\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Helvetica;\"> </span></p><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">可能会导致危害</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span>",
                                "name": "radio-group-1671097786481",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会试图改进工作方法，而不是按照他们一贯的方式做事</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span><br>",
                                "name": "radio-group-1671097829778",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们受到鼓励思考更好的做事方法</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span><br>",
                                "name": "radio-group-1671097848001",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们会与其他团队分享我们学到的知识.</span>",
                                "name": "radio-group-1671097862363",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 9
                    }
                ]
            },
            {
                "id": 10,
                "name": "对操作的敏感性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 11,
                        "name": "Sensitivity to operations",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">如果出现安全风险，我们的团队会花时间停止操作并重新开始</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我会从我们的工作计划中寻找微小的变化.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">经理会询问团队对工作计划可行性的意见.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">组织内有明确的流程来上报告更好的工作方式.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">管理层会倾听并按照工作团队的建议若实建议行事.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们会审查我们的工作，并找出任何需要改进的地方.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 10
                    }
                ]
            },
            {
                "id": 11,
                "name": "致力于维持韧性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 12,
                        "name": "Commitment to resilience",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">我们相信，作为一个团体，我们能通过接受和从安全错误中学习而变得更强大</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队有一套完善的安全错误分析流程.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队会从事件调查和调查发现中学习，并将这些发现应用到工作场所.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的所有团队成员都对安全错误的识别作出积极反应.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">共同修复与安全相关的错误是正常工作.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">停止并修复一个安全错误是被许可的.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 11
                    }
                ]
            },
            {
                "id": 12,
                "name": "重视专业人才",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 13,
                        "name": "Deference to Expertise",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">如果我的团队发现安全错误，同时也具备专业知识，我们会被授权采取行动</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">当发现高风险情况时，我们会立即采取行动，而不是报告和等待.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">如果我们的团队知道如何修复错误，他们有权进行修复.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我觉得在做安全决策时，我的专业知识有被考虑和受到重视.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的主管很擅长主持安全问题解决会议.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">如果我们需要专业协助，我们知道该联系谁.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 12
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 49,
        "name": "SATS Food Services",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 130,
        "name": "SAF Catering Operations – Central Zone",
        "order": null,
        "created": null,
        "topicOneId": 49
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5771,
        "moss_user": 19,
        "topic1": 48,
        "topic2": 117,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 48,
        "name": "Catering",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 117,
        "name": "Cabin Services",
        "order": null,
        "created": null,
        "topicOneId": 48
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5772,
        "moss_user": 19,
        "topic1": 49,
        "topic2": 130,
        "topic3": 0,
        "topic4": 0,
        "role": "Supervisor",
        "input_json": [
            {
                "id": 7,
                "name": "治理意识与一致性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 8,
                        "name": "Governance awareness and alignment",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G1.&nbsp;&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队理解为什么需要在工作场所建立一套安全管理系统.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671095932530-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G2.&nbsp;&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我了解与我工作相关的健康与安全程序.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671096185180",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我认可与我工作相关的健康与安全程序.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671096202203",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我团队中的每个人都觉得我们需要对工作场所的安全负责.</span>",
                                "name": "radio-group-1671096237595",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们工作的风险评估准确的将潜在风险辨认和减低.</span>",
                                "name": "radio-group-1671096270071",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我所接受的安全就职为我提供了安全工作方面的知识.</span>",
                                "name": "radio-group-1671096294116",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 7
                    }
                ]
            },
            {
                "id": 8,
                "name": "专注失败事件",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 9,
                        "name": "Preoccupation with failure",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F1.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队认为较小的安全错误也非常重要，值得讨论.</span>",
                                "name": "radio-group-1671096413811-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">举报安全问题是我的个人职责.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">&nbsp;</span></p>",
                                "name": "radio-group-1671096503192",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们预料到较小的的工作错误有可能发生，且在这些错误发生时，不会刻意隐瞒.</span>",
                                "name": "radio-group-1671096524263",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的工作团队愿意停止工作来纠正较小的的安全错误.</span>",
                                "name": "radio-group-1671096539481",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们团队使用一个完善的流程来报告错误.（事故</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 0px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">/</span>险情报告）</p>",
                                "name": "radio-group-1671096557806",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会仔细检查工作方法描述和风险评估，以预测潜在的错误.</span>",
                                "name": "radio-group-1671096586371",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 8
                    }
                ]
            },
            {
                "id": 9,
                "name": "拒绝简化",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 10,
                        "name": "Reluctance to simplify",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S1.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会讨论工作计划，鼓励提出更好的建议.</span>",
                                "name": "radio-group-1671097013525-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队关注工作场所的变化并采取应对行动.</span>",
                                "name": "radio-group-1671097767313",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队认为，较小的的错误是意外事件和事故的源头</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">,</span><span class=\"s2\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Helvetica;\"> </span></p><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">可能会导致危害</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span>",
                                "name": "radio-group-1671097786481",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会试图改进工作方法，而不是按照他们一贯的方式做事</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span><br>",
                                "name": "radio-group-1671097829778",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们受到鼓励思考更好的做事方法</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span><br>",
                                "name": "radio-group-1671097848001",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们会与其他团队分享我们学到的知识.</span>",
                                "name": "radio-group-1671097862363",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 9
                    }
                ]
            },
            {
                "id": 10,
                "name": "对操作的敏感性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 11,
                        "name": "Sensitivity to operations",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">如果出现安全风险，我们的团队会花时间停止操作并重新开始</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我会从我们的工作计划中寻找微小的变化.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">经理会询问团队对工作计划可行性的意见.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">组织内有明确的流程来上报告更好的工作方式.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">管理层会倾听并按照工作团队的建议若实建议行事.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们会审查我们的工作，并找出任何需要改进的地方.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 10
                    }
                ]
            },
            {
                "id": 11,
                "name": "致力于维持韧性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 12,
                        "name": "Commitment to resilience",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">我们相信，作为一个团体，我们能通过接受和从安全错误中学习而变得更强大</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队有一套完善的安全错误分析流程.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队会从事件调查和调查发现中学习，并将这些发现应用到工作场所.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的所有团队成员都对安全错误的识别作出积极反应.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">共同修复与安全相关的错误是正常工作.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">停止并修复一个安全错误是被许可的.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 11
                    }
                ]
            },
            {
                "id": 12,
                "name": "重视专业人才",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 13,
                        "name": "Deference to Expertise",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">如果我的团队发现安全错误，同时也具备专业知识，我们会被授权采取行动</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">当发现高风险情况时，我们会立即采取行动，而不是报告和等待.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">如果我们的团队知道如何修复错误，他们有权进行修复.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我觉得在做安全决策时，我的专业知识有被考虑和受到重视.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的主管很擅长主持安全问题解决会议.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">如果我们需要专业协助，我们知道该联系谁.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 12
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 49,
        "name": "SATS Food Services",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 130,
        "name": "SAF Catering Operations – Central Zone",
        "order": null,
        "created": null,
        "topicOneId": 49
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5773,
        "moss_user": 19,
        "topic1": 44,
        "topic2": 83,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 44,
        "name": "Apron",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 83,
        "name": "Lost & Found",
        "order": null,
        "created": null,
        "topicOneId": 44
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5774,
        "moss_user": 19,
        "topic1": 48,
        "topic2": 117,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 48,
        "name": "Catering",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 117,
        "name": "Cabin Services",
        "order": null,
        "created": null,
        "topicOneId": 48
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5775,
        "moss_user": 19,
        "topic1": 49,
        "topic2": 130,
        "topic3": 0,
        "topic4": 0,
        "role": "Supervisor",
        "input_json": [
            {
                "id": 7,
                "name": "治理意识与一致性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 8,
                        "name": "Governance awareness and alignment",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G1.&nbsp;&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队理解为什么需要在工作场所建立一套安全管理系统.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671095932530-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G2.&nbsp;&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我了解与我工作相关的健康与安全程序.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671096185180",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我认可与我工作相关的健康与安全程序.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671096202203",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我团队中的每个人都觉得我们需要对工作场所的安全负责.</span>",
                                "name": "radio-group-1671096237595",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们工作的风险评估准确的将潜在风险辨认和减低.</span>",
                                "name": "radio-group-1671096270071",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我所接受的安全就职为我提供了安全工作方面的知识.</span>",
                                "name": "radio-group-1671096294116",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 7
                    }
                ]
            },
            {
                "id": 8,
                "name": "专注失败事件",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 9,
                        "name": "Preoccupation with failure",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F1.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队认为较小的安全错误也非常重要，值得讨论.</span>",
                                "name": "radio-group-1671096413811-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">举报安全问题是我的个人职责.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">&nbsp;</span></p>",
                                "name": "radio-group-1671096503192",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们预料到较小的的工作错误有可能发生，且在这些错误发生时，不会刻意隐瞒.</span>",
                                "name": "radio-group-1671096524263",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的工作团队愿意停止工作来纠正较小的的安全错误.</span>",
                                "name": "radio-group-1671096539481",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们团队使用一个完善的流程来报告错误.（事故</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 0px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">/</span>险情报告）</p>",
                                "name": "radio-group-1671096557806",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会仔细检查工作方法描述和风险评估，以预测潜在的错误.</span>",
                                "name": "radio-group-1671096586371",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 8
                    }
                ]
            },
            {
                "id": 9,
                "name": "拒绝简化",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 10,
                        "name": "Reluctance to simplify",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S1.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会讨论工作计划，鼓励提出更好的建议.</span>",
                                "name": "radio-group-1671097013525-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队关注工作场所的变化并采取应对行动.</span>",
                                "name": "radio-group-1671097767313",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队认为，较小的的错误是意外事件和事故的源头</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">,</span><span class=\"s2\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Helvetica;\"> </span></p><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">可能会导致危害</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span>",
                                "name": "radio-group-1671097786481",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会试图改进工作方法，而不是按照他们一贯的方式做事</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span><br>",
                                "name": "radio-group-1671097829778",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们受到鼓励思考更好的做事方法</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span><br>",
                                "name": "radio-group-1671097848001",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们会与其他团队分享我们学到的知识.</span>",
                                "name": "radio-group-1671097862363",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 9
                    }
                ]
            },
            {
                "id": 10,
                "name": "对操作的敏感性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 11,
                        "name": "Sensitivity to operations",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">如果出现安全风险，我们的团队会花时间停止操作并重新开始</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我会从我们的工作计划中寻找微小的变化.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">经理会询问团队对工作计划可行性的意见.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">组织内有明确的流程来上报告更好的工作方式.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">管理层会倾听并按照工作团队的建议若实建议行事.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们会审查我们的工作，并找出任何需要改进的地方.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 10
                    }
                ]
            },
            {
                "id": 11,
                "name": "致力于维持韧性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 12,
                        "name": "Commitment to resilience",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">我们相信，作为一个团体，我们能通过接受和从安全错误中学习而变得更强大</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队有一套完善的安全错误分析流程.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队会从事件调查和调查发现中学习，并将这些发现应用到工作场所.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的所有团队成员都对安全错误的识别作出积极反应.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">共同修复与安全相关的错误是正常工作.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">停止并修复一个安全错误是被许可的.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 11
                    }
                ]
            },
            {
                "id": 12,
                "name": "重视专业人才",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 13,
                        "name": "Deference to Expertise",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">如果我的团队发现安全错误，同时也具备专业知识，我们会被授权采取行动</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">当发现高风险情况时，我们会立即采取行动，而不是报告和等待.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">如果我们的团队知道如何修复错误，他们有权进行修复.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我觉得在做安全决策时，我的专业知识有被考虑和受到重视.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的主管很擅长主持安全问题解决会议.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">如果我们需要专业协助，我们知道该联系谁.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 12
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 49,
        "name": "SATS Food Services",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 130,
        "name": "SAF Catering Operations – Central Zone",
        "order": null,
        "created": null,
        "topicOneId": 49
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5776,
        "moss_user": 19,
        "topic1": 44,
        "topic2": 85,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 44,
        "name": "Apron",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 85,
        "name": "Ramp Services",
        "order": null,
        "created": null,
        "topicOneId": 44
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5777,
        "moss_user": 19,
        "topic1": 49,
        "topic2": 130,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 7,
                "name": "治理意识与一致性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 8,
                        "name": "Governance awareness and alignment",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G1.&nbsp;&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队理解为什么需要在工作场所建立一套安全管理系统.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671095932530-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G2.&nbsp;&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我了解与我工作相关的健康与安全程序.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671096185180",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我认可与我工作相关的健康与安全程序.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671096202203",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我团队中的每个人都觉得我们需要对工作场所的安全负责.</span>",
                                "name": "radio-group-1671096237595",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们工作的风险评估准确的将潜在风险辨认和减低.</span>",
                                "name": "radio-group-1671096270071",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我所接受的安全就职为我提供了安全工作方面的知识.</span>",
                                "name": "radio-group-1671096294116",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 7
                    }
                ]
            },
            {
                "id": 8,
                "name": "专注失败事件",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 9,
                        "name": "Preoccupation with failure",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F1.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队认为较小的安全错误也非常重要，值得讨论.</span>",
                                "name": "radio-group-1671096413811-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">举报安全问题是我的个人职责.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">&nbsp;</span></p>",
                                "name": "radio-group-1671096503192",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们预料到较小的的工作错误有可能发生，且在这些错误发生时，不会刻意隐瞒.</span>",
                                "name": "radio-group-1671096524263",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的工作团队愿意停止工作来纠正较小的的安全错误.</span>",
                                "name": "radio-group-1671096539481",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们团队使用一个完善的流程来报告错误.（事故</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 0px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">/</span>险情报告）</p>",
                                "name": "radio-group-1671096557806",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会仔细检查工作方法描述和风险评估，以预测潜在的错误.</span>",
                                "name": "radio-group-1671096586371",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 8
                    }
                ]
            },
            {
                "id": 9,
                "name": "拒绝简化",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 10,
                        "name": "Reluctance to simplify",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S1.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会讨论工作计划，鼓励提出更好的建议.</span>",
                                "name": "radio-group-1671097013525-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队关注工作场所的变化并采取应对行动.</span>",
                                "name": "radio-group-1671097767313",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队认为，较小的的错误是意外事件和事故的源头</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">,</span><span class=\"s2\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Helvetica;\"> </span></p><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">可能会导致危害</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span>",
                                "name": "radio-group-1671097786481",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会试图改进工作方法，而不是按照他们一贯的方式做事</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span><br>",
                                "name": "radio-group-1671097829778",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们受到鼓励思考更好的做事方法</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span><br>",
                                "name": "radio-group-1671097848001",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们会与其他团队分享我们学到的知识.</span>",
                                "name": "radio-group-1671097862363",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 9
                    }
                ]
            },
            {
                "id": 10,
                "name": "对操作的敏感性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 11,
                        "name": "Sensitivity to operations",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">如果出现安全风险，我们的团队会花时间停止操作并重新开始</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我会从我们的工作计划中寻找微小的变化.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">经理会询问团队对工作计划可行性的意见.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">组织内有明确的流程来上报告更好的工作方式.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">管理层会倾听并按照工作团队的建议若实建议行事.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们会审查我们的工作，并找出任何需要改进的地方.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 10
                    }
                ]
            },
            {
                "id": 11,
                "name": "致力于维持韧性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 12,
                        "name": "Commitment to resilience",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">我们相信，作为一个团体，我们能通过接受和从安全错误中学习而变得更强大</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队有一套完善的安全错误分析流程.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队会从事件调查和调查发现中学习，并将这些发现应用到工作场所.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的所有团队成员都对安全错误的识别作出积极反应.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">共同修复与安全相关的错误是正常工作.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">停止并修复一个安全错误是被许可的.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 11
                    }
                ]
            },
            {
                "id": 12,
                "name": "重视专业人才",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 13,
                        "name": "Deference to Expertise",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">如果我的团队发现安全错误，同时也具备专业知识，我们会被授权采取行动</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">当发现高风险情况时，我们会立即采取行动，而不是报告和等待.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">如果我们的团队知道如何修复错误，他们有权进行修复.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我觉得在做安全决策时，我的专业知识有被考虑和受到重视.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的主管很擅长主持安全问题解决会议.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">如果我们需要专业协助，我们知道该联系谁.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 12
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 49,
        "name": "SATS Food Services",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 130,
        "name": "SAF Catering Operations – Central Zone",
        "order": null,
        "created": null,
        "topicOneId": 49
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5778,
        "moss_user": 19,
        "topic1": 48,
        "topic2": 117,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 48,
        "name": "Catering",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 117,
        "name": "Cabin Services",
        "order": null,
        "created": null,
        "topicOneId": 48
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5779,
        "moss_user": 19,
        "topic1": 44,
        "topic2": 83,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": true
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": true
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": true
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": true
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 44,
        "name": "Apron",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 83,
        "name": "Lost & Found",
        "order": null,
        "created": null,
        "topicOneId": 44
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5780,
        "moss_user": 19,
        "topic1": 44,
        "topic2": 79,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 44,
        "name": "Apron",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 79,
        "name": "Baggage",
        "order": null,
        "created": null,
        "topicOneId": 44
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5781,
        "moss_user": 19,
        "topic1": 46,
        "topic2": 102,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 46,
        "name": "Passenger Services",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 102,
        "name": "Lounge",
        "order": null,
        "created": null,
        "topicOneId": 46
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5782,
        "moss_user": 19,
        "topic1": 44,
        "topic2": 83,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 44,
        "name": "Apron",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 83,
        "name": "Lost & Found",
        "order": null,
        "created": null,
        "topicOneId": 44
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5783,
        "moss_user": 19,
        "topic1": 45,
        "topic2": 98,
        "topic3": 0,
        "topic4": 0,
        "role": "Supervisor",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 45,
        "name": "Cargo Services",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 98,
        "name": "Building / Technical",
        "order": null,
        "created": null,
        "topicOneId": 45
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5784,
        "moss_user": 19,
        "topic1": 44,
        "topic2": 83,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": true
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": true
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 44,
        "name": "Apron",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 83,
        "name": "Lost & Found",
        "order": null,
        "created": null,
        "topicOneId": 44
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5785,
        "moss_user": 19,
        "topic1": 48,
        "topic2": 122,
        "topic3": 0,
        "topic4": 0,
        "role": "Manager",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 48,
        "name": "Catering",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 122,
        "name": "Catering Marketing / Catering Management",
        "order": null,
        "created": null,
        "topicOneId": 48
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5786,
        "moss_user": 19,
        "topic1": 49,
        "topic2": 130,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 7,
                "name": "治理意识与一致性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 8,
                        "name": "Governance awareness and alignment",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G1.&nbsp;&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队理解为什么需要在工作场所建立一套安全管理系统.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671095932530-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G2.&nbsp;&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我了解与我工作相关的健康与安全程序.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671096185180",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我认可与我工作相关的健康与安全程序.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671096202203",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我团队中的每个人都觉得我们需要对工作场所的安全负责.</span>",
                                "name": "radio-group-1671096237595",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们工作的风险评估准确的将潜在风险辨认和减低.</span>",
                                "name": "radio-group-1671096270071",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我所接受的安全就职为我提供了安全工作方面的知识.</span>",
                                "name": "radio-group-1671096294116",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 7
                    }
                ]
            },
            {
                "id": 8,
                "name": "专注失败事件",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 9,
                        "name": "Preoccupation with failure",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F1.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队认为较小的安全错误也非常重要，值得讨论.</span>",
                                "name": "radio-group-1671096413811-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">举报安全问题是我的个人职责.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">&nbsp;</span></p>",
                                "name": "radio-group-1671096503192",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们预料到较小的的工作错误有可能发生，且在这些错误发生时，不会刻意隐瞒.</span>",
                                "name": "radio-group-1671096524263",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的工作团队愿意停止工作来纠正较小的的安全错误.</span>",
                                "name": "radio-group-1671096539481",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们团队使用一个完善的流程来报告错误.（事故</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 0px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">/</span>险情报告）</p>",
                                "name": "radio-group-1671096557806",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会仔细检查工作方法描述和风险评估，以预测潜在的错误.</span>",
                                "name": "radio-group-1671096586371",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 8
                    }
                ]
            },
            {
                "id": 9,
                "name": "拒绝简化",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 10,
                        "name": "Reluctance to simplify",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S1.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会讨论工作计划，鼓励提出更好的建议.</span>",
                                "name": "radio-group-1671097013525-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队关注工作场所的变化并采取应对行动.</span>",
                                "name": "radio-group-1671097767313",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队认为，较小的的错误是意外事件和事故的源头</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">,</span><span class=\"s2\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Helvetica;\"> </span></p><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">可能会导致危害</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span>",
                                "name": "radio-group-1671097786481",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会试图改进工作方法，而不是按照他们一贯的方式做事</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span><br>",
                                "name": "radio-group-1671097829778",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们受到鼓励思考更好的做事方法</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span><br>",
                                "name": "radio-group-1671097848001",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们会与其他团队分享我们学到的知识.</span>",
                                "name": "radio-group-1671097862363",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 9
                    }
                ]
            },
            {
                "id": 10,
                "name": "对操作的敏感性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 11,
                        "name": "Sensitivity to operations",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">如果出现安全风险，我们的团队会花时间停止操作并重新开始</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我会从我们的工作计划中寻找微小的变化.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">经理会询问团队对工作计划可行性的意见.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">组织内有明确的流程来上报告更好的工作方式.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">管理层会倾听并按照工作团队的建议若实建议行事.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们会审查我们的工作，并找出任何需要改进的地方.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 10
                    }
                ]
            },
            {
                "id": 11,
                "name": "致力于维持韧性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 12,
                        "name": "Commitment to resilience",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">我们相信，作为一个团体，我们能通过接受和从安全错误中学习而变得更强大</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队有一套完善的安全错误分析流程.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队会从事件调查和调查发现中学习，并将这些发现应用到工作场所.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的所有团队成员都对安全错误的识别作出积极反应.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">共同修复与安全相关的错误是正常工作.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">停止并修复一个安全错误是被许可的.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 11
                    }
                ]
            },
            {
                "id": 12,
                "name": "重视专业人才",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 13,
                        "name": "Deference to Expertise",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">如果我的团队发现安全错误，同时也具备专业知识，我们会被授权采取行动</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">当发现高风险情况时，我们会立即采取行动，而不是报告和等待.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">如果我们的团队知道如何修复错误，他们有权进行修复.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我觉得在做安全决策时，我的专业知识有被考虑和受到重视.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的主管很擅长主持安全问题解决会议.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">如果我们需要专业协助，我们知道该联系谁.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 12
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 49,
        "name": "SATS Food Services",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 130,
        "name": "SAF Catering Operations – Central Zone",
        "order": null,
        "created": null,
        "topicOneId": 49
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5787,
        "moss_user": 19,
        "topic1": 48,
        "topic2": 114,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": true
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 48,
        "name": "Catering",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 114,
        "name": "Production / Kitchens / Bakery / Pastry / Butchery",
        "order": null,
        "created": null,
        "topicOneId": 48
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5788,
        "moss_user": 19,
        "topic1": 44,
        "topic2": 85,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": true
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": true
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 44,
        "name": "Apron",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 85,
        "name": "Ramp Services",
        "order": null,
        "created": null,
        "topicOneId": 44
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5789,
        "moss_user": 19,
        "topic1": 44,
        "topic2": 83,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": true
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 44,
        "name": "Apron",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 83,
        "name": "Lost & Found",
        "order": null,
        "created": null,
        "topicOneId": 44
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5790,
        "moss_user": 19,
        "topic1": 49,
        "topic2": 129,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 49,
        "name": "SATS Food Services",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 129,
        "name": "SAF Catering Operations – North Zone",
        "order": null,
        "created": null,
        "topicOneId": 49
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5791,
        "moss_user": 19,
        "topic1": 44,
        "topic2": 83,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": true
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": true
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": true
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 44,
        "name": "Apron",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 83,
        "name": "Lost & Found",
        "order": null,
        "created": null,
        "topicOneId": 44
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5792,
        "moss_user": 19,
        "topic1": 48,
        "topic2": 114,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": true
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": true
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 48,
        "name": "Catering",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 114,
        "name": "Production / Kitchens / Bakery / Pastry / Butchery",
        "order": null,
        "created": null,
        "topicOneId": 48
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5793,
        "moss_user": 19,
        "topic1": 49,
        "topic2": 130,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 7,
                "name": "治理意识与一致性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 8,
                        "name": "Governance awareness and alignment",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G1.&nbsp;&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队理解为什么需要在工作场所建立一套安全管理系统.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671095932530-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G2.&nbsp;&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我了解与我工作相关的健康与安全程序.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671096185180",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我认可与我工作相关的健康与安全程序.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671096202203",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我团队中的每个人都觉得我们需要对工作场所的安全负责.</span>",
                                "name": "radio-group-1671096237595",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们工作的风险评估准确的将潜在风险辨认和减低.</span>",
                                "name": "radio-group-1671096270071",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我所接受的安全就职为我提供了安全工作方面的知识.</span>",
                                "name": "radio-group-1671096294116",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 7
                    }
                ]
            },
            {
                "id": 8,
                "name": "专注失败事件",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 9,
                        "name": "Preoccupation with failure",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F1.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队认为较小的安全错误也非常重要，值得讨论.</span>",
                                "name": "radio-group-1671096413811-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">举报安全问题是我的个人职责.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">&nbsp;</span></p>",
                                "name": "radio-group-1671096503192",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们预料到较小的的工作错误有可能发生，且在这些错误发生时，不会刻意隐瞒.</span>",
                                "name": "radio-group-1671096524263",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的工作团队愿意停止工作来纠正较小的的安全错误.</span>",
                                "name": "radio-group-1671096539481",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们团队使用一个完善的流程来报告错误.（事故</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 0px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">/</span>险情报告）</p>",
                                "name": "radio-group-1671096557806",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会仔细检查工作方法描述和风险评估，以预测潜在的错误.</span>",
                                "name": "radio-group-1671096586371",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 8
                    }
                ]
            },
            {
                "id": 9,
                "name": "拒绝简化",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 10,
                        "name": "Reluctance to simplify",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S1.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会讨论工作计划，鼓励提出更好的建议.</span>",
                                "name": "radio-group-1671097013525-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队关注工作场所的变化并采取应对行动.</span>",
                                "name": "radio-group-1671097767313",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队认为，较小的的错误是意外事件和事故的源头</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">,</span><span class=\"s2\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Helvetica;\"> </span></p><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">可能会导致危害</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span>",
                                "name": "radio-group-1671097786481",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会试图改进工作方法，而不是按照他们一贯的方式做事</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span><br>",
                                "name": "radio-group-1671097829778",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们受到鼓励思考更好的做事方法</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span><br>",
                                "name": "radio-group-1671097848001",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们会与其他团队分享我们学到的知识.</span>",
                                "name": "radio-group-1671097862363",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 9
                    }
                ]
            },
            {
                "id": 10,
                "name": "对操作的敏感性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 11,
                        "name": "Sensitivity to operations",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">如果出现安全风险，我们的团队会花时间停止操作并重新开始</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我会从我们的工作计划中寻找微小的变化.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">经理会询问团队对工作计划可行性的意见.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">组织内有明确的流程来上报告更好的工作方式.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">管理层会倾听并按照工作团队的建议若实建议行事.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们会审查我们的工作，并找出任何需要改进的地方.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 10
                    }
                ]
            },
            {
                "id": 11,
                "name": "致力于维持韧性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 12,
                        "name": "Commitment to resilience",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">我们相信，作为一个团体，我们能通过接受和从安全错误中学习而变得更强大</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队有一套完善的安全错误分析流程.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队会从事件调查和调查发现中学习，并将这些发现应用到工作场所.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的所有团队成员都对安全错误的识别作出积极反应.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">共同修复与安全相关的错误是正常工作.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">停止并修复一个安全错误是被许可的.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 11
                    }
                ]
            },
            {
                "id": 12,
                "name": "重视专业人才",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 13,
                        "name": "Deference to Expertise",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">如果我的团队发现安全错误，同时也具备专业知识，我们会被授权采取行动</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">当发现高风险情况时，我们会立即采取行动，而不是报告和等待.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">如果我们的团队知道如何修复错误，他们有权进行修复.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我觉得在做安全决策时，我的专业知识有被考虑和受到重视.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的主管很擅长主持安全问题解决会议.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">如果我们需要专业协助，我们知道该联系谁.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 12
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 49,
        "name": "SATS Food Services",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 130,
        "name": "SAF Catering Operations – Central Zone",
        "order": null,
        "created": null,
        "topicOneId": 49
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5794,
        "moss_user": 19,
        "topic1": 49,
        "topic2": 126,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 49,
        "name": "SATS Food Services",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 126,
        "name": "MHA Catering – HTAC",
        "order": null,
        "created": null,
        "topicOneId": 49
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5795,
        "moss_user": 19,
        "topic1": 44,
        "topic2": 79,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": true
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": true
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": true
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 44,
        "name": "Apron",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 79,
        "name": "Baggage",
        "order": null,
        "created": null,
        "topicOneId": 44
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5796,
        "moss_user": 19,
        "topic1": 49,
        "topic2": 126,
        "topic3": 0,
        "topic4": 0,
        "role": "Supervisor",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 49,
        "name": "SATS Food Services",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 126,
        "name": "MHA Catering – HTAC",
        "order": null,
        "created": null,
        "topicOneId": 49
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5797,
        "moss_user": 19,
        "topic1": 44,
        "topic2": 85,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 7,
                "name": "治理意识与一致性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 8,
                        "name": "Governance awareness and alignment",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G1.&nbsp;&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队理解为什么需要在工作场所建立一套安全管理系统.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671095932530-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G2.&nbsp;&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我了解与我工作相关的健康与安全程序.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671096185180",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我认可与我工作相关的健康与安全程序.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671096202203",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我团队中的每个人都觉得我们需要对工作场所的安全负责.</span>",
                                "name": "radio-group-1671096237595",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们工作的风险评估准确的将潜在风险辨认和减低.</span>",
                                "name": "radio-group-1671096270071",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我所接受的安全就职为我提供了安全工作方面的知识.</span>",
                                "name": "radio-group-1671096294116",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 7
                    }
                ]
            },
            {
                "id": 8,
                "name": "专注失败事件",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 9,
                        "name": "Preoccupation with failure",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F1.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队认为较小的安全错误也非常重要，值得讨论.</span>",
                                "name": "radio-group-1671096413811-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">举报安全问题是我的个人职责.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">&nbsp;</span></p>",
                                "name": "radio-group-1671096503192",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们预料到较小的的工作错误有可能发生，且在这些错误发生时，不会刻意隐瞒.</span>",
                                "name": "radio-group-1671096524263",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的工作团队愿意停止工作来纠正较小的的安全错误.</span>",
                                "name": "radio-group-1671096539481",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们团队使用一个完善的流程来报告错误.（事故</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 0px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">/</span>险情报告）</p>",
                                "name": "radio-group-1671096557806",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会仔细检查工作方法描述和风险评估，以预测潜在的错误.</span>",
                                "name": "radio-group-1671096586371",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 8
                    }
                ]
            },
            {
                "id": 9,
                "name": "拒绝简化",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 10,
                        "name": "Reluctance to simplify",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S1.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会讨论工作计划，鼓励提出更好的建议.</span>",
                                "name": "radio-group-1671097013525-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队关注工作场所的变化并采取应对行动.</span>",
                                "name": "radio-group-1671097767313",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队认为，较小的的错误是意外事件和事故的源头</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">,</span><span class=\"s2\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Helvetica;\"> </span></p><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">可能会导致危害</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span>",
                                "name": "radio-group-1671097786481",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会试图改进工作方法，而不是按照他们一贯的方式做事</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span><br>",
                                "name": "radio-group-1671097829778",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们受到鼓励思考更好的做事方法</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span><br>",
                                "name": "radio-group-1671097848001",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们会与其他团队分享我们学到的知识.</span>",
                                "name": "radio-group-1671097862363",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 9
                    }
                ]
            },
            {
                "id": 10,
                "name": "对操作的敏感性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 11,
                        "name": "Sensitivity to operations",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">如果出现安全风险，我们的团队会花时间停止操作并重新开始</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我会从我们的工作计划中寻找微小的变化.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">经理会询问团队对工作计划可行性的意见.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">组织内有明确的流程来上报告更好的工作方式.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">管理层会倾听并按照工作团队的建议若实建议行事.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们会审查我们的工作，并找出任何需要改进的地方.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 10
                    }
                ]
            },
            {
                "id": 11,
                "name": "致力于维持韧性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 12,
                        "name": "Commitment to resilience",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">我们相信，作为一个团体，我们能通过接受和从安全错误中学习而变得更强大</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队有一套完善的安全错误分析流程.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队会从事件调查和调查发现中学习，并将这些发现应用到工作场所.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的所有团队成员都对安全错误的识别作出积极反应.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">共同修复与安全相关的错误是正常工作.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">停止并修复一个安全错误是被许可的.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 11
                    }
                ]
            },
            {
                "id": 12,
                "name": "重视专业人才",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 13,
                        "name": "Deference to Expertise",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">如果我的团队发现安全错误，同时也具备专业知识，我们会被授权采取行动</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">当发现高风险情况时，我们会立即采取行动，而不是报告和等待.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">如果我们的团队知道如何修复错误，他们有权进行修复.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我觉得在做安全决策时，我的专业知识有被考虑和受到重视.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的主管很擅长主持安全问题解决会议.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">如果我们需要专业协助，我们知道该联系谁.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 12
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 44,
        "name": "Apron",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 85,
        "name": "Ramp Services",
        "order": null,
        "created": null,
        "topicOneId": 44
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5798,
        "moss_user": 19,
        "topic1": 44,
        "topic2": 83,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": true
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 44,
        "name": "Apron",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 83,
        "name": "Lost & Found",
        "order": null,
        "created": null,
        "topicOneId": 44
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5799,
        "moss_user": 19,
        "topic1": 44,
        "topic2": 85,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 44,
        "name": "Apron",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 85,
        "name": "Ramp Services",
        "order": null,
        "created": null,
        "topicOneId": 44
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5800,
        "moss_user": 19,
        "topic1": 48,
        "topic2": 115,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 7,
                "name": "治理意识与一致性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 8,
                        "name": "Governance awareness and alignment",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G1.&nbsp;&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队理解为什么需要在工作场所建立一套安全管理系统.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671095932530-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G2.&nbsp;&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我了解与我工作相关的健康与安全程序.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671096185180",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我认可与我工作相关的健康与安全程序.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671096202203",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我团队中的每个人都觉得我们需要对工作场所的安全负责.</span>",
                                "name": "radio-group-1671096237595",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们工作的风险评估准确的将潜在风险辨认和减低.</span>",
                                "name": "radio-group-1671096270071",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我所接受的安全就职为我提供了安全工作方面的知识.</span>",
                                "name": "radio-group-1671096294116",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 7
                    }
                ]
            },
            {
                "id": 8,
                "name": "专注失败事件",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 9,
                        "name": "Preoccupation with failure",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F1.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队认为较小的安全错误也非常重要，值得讨论.</span>",
                                "name": "radio-group-1671096413811-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">举报安全问题是我的个人职责.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">&nbsp;</span></p>",
                                "name": "radio-group-1671096503192",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们预料到较小的的工作错误有可能发生，且在这些错误发生时，不会刻意隐瞒.</span>",
                                "name": "radio-group-1671096524263",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的工作团队愿意停止工作来纠正较小的的安全错误.</span>",
                                "name": "radio-group-1671096539481",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们团队使用一个完善的流程来报告错误.（事故</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 0px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">/</span>险情报告）</p>",
                                "name": "radio-group-1671096557806",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会仔细检查工作方法描述和风险评估，以预测潜在的错误.</span>",
                                "name": "radio-group-1671096586371",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 8
                    }
                ]
            },
            {
                "id": 9,
                "name": "拒绝简化",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 10,
                        "name": "Reluctance to simplify",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S1.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会讨论工作计划，鼓励提出更好的建议.</span>",
                                "name": "radio-group-1671097013525-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队关注工作场所的变化并采取应对行动.</span>",
                                "name": "radio-group-1671097767313",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队认为，较小的的错误是意外事件和事故的源头</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">,</span><span class=\"s2\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Helvetica;\"> </span></p><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">可能会导致危害</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span>",
                                "name": "radio-group-1671097786481",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会试图改进工作方法，而不是按照他们一贯的方式做事</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span><br>",
                                "name": "radio-group-1671097829778",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们受到鼓励思考更好的做事方法</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span><br>",
                                "name": "radio-group-1671097848001",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们会与其他团队分享我们学到的知识.</span>",
                                "name": "radio-group-1671097862363",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 9
                    }
                ]
            },
            {
                "id": 10,
                "name": "对操作的敏感性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 11,
                        "name": "Sensitivity to operations",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">如果出现安全风险，我们的团队会花时间停止操作并重新开始</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我会从我们的工作计划中寻找微小的变化.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">经理会询问团队对工作计划可行性的意见.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">组织内有明确的流程来上报告更好的工作方式.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">管理层会倾听并按照工作团队的建议若实建议行事.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们会审查我们的工作，并找出任何需要改进的地方.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 10
                    }
                ]
            },
            {
                "id": 11,
                "name": "致力于维持韧性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 12,
                        "name": "Commitment to resilience",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">我们相信，作为一个团体，我们能通过接受和从安全错误中学习而变得更强大</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队有一套完善的安全错误分析流程.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队会从事件调查和调查发现中学习，并将这些发现应用到工作场所.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的所有团队成员都对安全错误的识别作出积极反应.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">共同修复与安全相关的错误是正常工作.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">停止并修复一个安全错误是被许可的.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 11
                    }
                ]
            },
            {
                "id": 12,
                "name": "重视专业人才",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 13,
                        "name": "Deference to Expertise",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">如果我的团队发现安全错误，同时也具备专业知识，我们会被授权采取行动</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">当发现高风险情况时，我们会立即采取行动，而不是报告和等待.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">如果我们的团队知道如何修复错误，他们有权进行修复.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我觉得在做安全决策时，我的专业知识有被考虑和受到重视.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的主管很擅长主持安全问题解决会议.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">如果我们需要专业协助，我们知道该联系谁.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 12
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 48,
        "name": "Catering",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 115,
        "name": "Pre-Set",
        "order": null,
        "created": null,
        "topicOneId": 48
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5801,
        "moss_user": 19,
        "topic1": 48,
        "topic2": 122,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 48,
        "name": "Catering",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 122,
        "name": "Catering Marketing / Catering Management",
        "order": null,
        "created": null,
        "topicOneId": 48
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5802,
        "moss_user": 19,
        "topic1": 49,
        "topic2": 126,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": true
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 49,
        "name": "SATS Food Services",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 126,
        "name": "MHA Catering – HTAC",
        "order": null,
        "created": null,
        "topicOneId": 49
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5803,
        "moss_user": 19,
        "topic1": 48,
        "topic2": 117,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 48,
        "name": "Catering",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 117,
        "name": "Cabin Services",
        "order": null,
        "created": null,
        "topicOneId": 48
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5804,
        "moss_user": 19,
        "topic1": 49,
        "topic2": 130,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 7,
                "name": "治理意识与一致性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 8,
                        "name": "Governance awareness and alignment",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G1.&nbsp;&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队理解为什么需要在工作场所建立一套安全管理系统.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671095932530-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G2.&nbsp;&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我了解与我工作相关的健康与安全程序.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671096185180",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我认可与我工作相关的健康与安全程序.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671096202203",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我团队中的每个人都觉得我们需要对工作场所的安全负责.</span>",
                                "name": "radio-group-1671096237595",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们工作的风险评估准确的将潜在风险辨认和减低.</span>",
                                "name": "radio-group-1671096270071",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我所接受的安全就职为我提供了安全工作方面的知识.</span>",
                                "name": "radio-group-1671096294116",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 7
                    }
                ]
            },
            {
                "id": 8,
                "name": "专注失败事件",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 9,
                        "name": "Preoccupation with failure",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F1.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队认为较小的安全错误也非常重要，值得讨论.</span>",
                                "name": "radio-group-1671096413811-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">举报安全问题是我的个人职责.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">&nbsp;</span></p>",
                                "name": "radio-group-1671096503192",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们预料到较小的的工作错误有可能发生，且在这些错误发生时，不会刻意隐瞒.</span>",
                                "name": "radio-group-1671096524263",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的工作团队愿意停止工作来纠正较小的的安全错误.</span>",
                                "name": "radio-group-1671096539481",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们团队使用一个完善的流程来报告错误.（事故</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 0px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">/</span>险情报告）</p>",
                                "name": "radio-group-1671096557806",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会仔细检查工作方法描述和风险评估，以预测潜在的错误.</span>",
                                "name": "radio-group-1671096586371",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 8
                    }
                ]
            },
            {
                "id": 9,
                "name": "拒绝简化",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 10,
                        "name": "Reluctance to simplify",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S1.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会讨论工作计划，鼓励提出更好的建议.</span>",
                                "name": "radio-group-1671097013525-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队关注工作场所的变化并采取应对行动.</span>",
                                "name": "radio-group-1671097767313",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队认为，较小的的错误是意外事件和事故的源头</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">,</span><span class=\"s2\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Helvetica;\"> </span></p><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">可能会导致危害</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span>",
                                "name": "radio-group-1671097786481",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会试图改进工作方法，而不是按照他们一贯的方式做事</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span><br>",
                                "name": "radio-group-1671097829778",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们受到鼓励思考更好的做事方法</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span><br>",
                                "name": "radio-group-1671097848001",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们会与其他团队分享我们学到的知识.</span>",
                                "name": "radio-group-1671097862363",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 9
                    }
                ]
            },
            {
                "id": 10,
                "name": "对操作的敏感性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 11,
                        "name": "Sensitivity to operations",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">如果出现安全风险，我们的团队会花时间停止操作并重新开始</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我会从我们的工作计划中寻找微小的变化.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">经理会询问团队对工作计划可行性的意见.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">组织内有明确的流程来上报告更好的工作方式.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">管理层会倾听并按照工作团队的建议若实建议行事.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们会审查我们的工作，并找出任何需要改进的地方.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 10
                    }
                ]
            },
            {
                "id": 11,
                "name": "致力于维持韧性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 12,
                        "name": "Commitment to resilience",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">我们相信，作为一个团体，我们能通过接受和从安全错误中学习而变得更强大</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队有一套完善的安全错误分析流程.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队会从事件调查和调查发现中学习，并将这些发现应用到工作场所.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的所有团队成员都对安全错误的识别作出积极反应.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">共同修复与安全相关的错误是正常工作.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">停止并修复一个安全错误是被许可的.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 11
                    }
                ]
            },
            {
                "id": 12,
                "name": "重视专业人才",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 13,
                        "name": "Deference to Expertise",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">如果我的团队发现安全错误，同时也具备专业知识，我们会被授权采取行动</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">当发现高风险情况时，我们会立即采取行动，而不是报告和等待.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">如果我们的团队知道如何修复错误，他们有权进行修复.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我觉得在做安全决策时，我的专业知识有被考虑和受到重视.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的主管很擅长主持安全问题解决会议.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">如果我们需要专业协助，我们知道该联系谁.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 12
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 49,
        "name": "SATS Food Services",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 130,
        "name": "SAF Catering Operations – Central Zone",
        "order": null,
        "created": null,
        "topicOneId": 49
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5805,
        "moss_user": 19,
        "topic1": 49,
        "topic2": 130,
        "topic3": 0,
        "topic4": 0,
        "role": "Supervisor",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 49,
        "name": "SATS Food Services",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 130,
        "name": "SAF Catering Operations – Central Zone",
        "order": null,
        "created": null,
        "topicOneId": 49
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5806,
        "moss_user": 19,
        "topic1": 44,
        "topic2": 85,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": true
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": true
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": true
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": true
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": true
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": true
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": true
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 44,
        "name": "Apron",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 85,
        "name": "Ramp Services",
        "order": null,
        "created": null,
        "topicOneId": 44
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5807,
        "moss_user": 19,
        "topic1": 50,
        "topic2": 141,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 50,
        "name": "Others – Aviation ",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 141,
        "name": "GTRSG",
        "order": null,
        "created": null,
        "topicOneId": 50
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5808,
        "moss_user": 19,
        "topic1": 49,
        "topic2": 130,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 7,
                "name": "治理意识与一致性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 8,
                        "name": "Governance awareness and alignment",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G1.&nbsp;&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队理解为什么需要在工作场所建立一套安全管理系统.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671095932530-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G2.&nbsp;&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我了解与我工作相关的健康与安全程序.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671096185180",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我认可与我工作相关的健康与安全程序.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671096202203",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我团队中的每个人都觉得我们需要对工作场所的安全负责.</span>",
                                "name": "radio-group-1671096237595",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们工作的风险评估准确的将潜在风险辨认和减低.</span>",
                                "name": "radio-group-1671096270071",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我所接受的安全就职为我提供了安全工作方面的知识.</span>",
                                "name": "radio-group-1671096294116",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 7
                    }
                ]
            },
            {
                "id": 8,
                "name": "专注失败事件",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 9,
                        "name": "Preoccupation with failure",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F1.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队认为较小的安全错误也非常重要，值得讨论.</span>",
                                "name": "radio-group-1671096413811-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">举报安全问题是我的个人职责.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">&nbsp;</span></p>",
                                "name": "radio-group-1671096503192",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们预料到较小的的工作错误有可能发生，且在这些错误发生时，不会刻意隐瞒.</span>",
                                "name": "radio-group-1671096524263",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的工作团队愿意停止工作来纠正较小的的安全错误.</span>",
                                "name": "radio-group-1671096539481",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们团队使用一个完善的流程来报告错误.（事故</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 0px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">/</span>险情报告）</p>",
                                "name": "radio-group-1671096557806",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会仔细检查工作方法描述和风险评估，以预测潜在的错误.</span>",
                                "name": "radio-group-1671096586371",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 8
                    }
                ]
            },
            {
                "id": 9,
                "name": "拒绝简化",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 10,
                        "name": "Reluctance to simplify",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S1.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会讨论工作计划，鼓励提出更好的建议.</span>",
                                "name": "radio-group-1671097013525-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队关注工作场所的变化并采取应对行动.</span>",
                                "name": "radio-group-1671097767313",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队认为，较小的的错误是意外事件和事故的源头</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">,</span><span class=\"s2\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Helvetica;\"> </span></p><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">可能会导致危害</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span>",
                                "name": "radio-group-1671097786481",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会试图改进工作方法，而不是按照他们一贯的方式做事</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span><br>",
                                "name": "radio-group-1671097829778",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们受到鼓励思考更好的做事方法</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span><br>",
                                "name": "radio-group-1671097848001",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们会与其他团队分享我们学到的知识.</span>",
                                "name": "radio-group-1671097862363",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 9
                    }
                ]
            },
            {
                "id": 10,
                "name": "对操作的敏感性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 11,
                        "name": "Sensitivity to operations",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">如果出现安全风险，我们的团队会花时间停止操作并重新开始</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我会从我们的工作计划中寻找微小的变化.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">经理会询问团队对工作计划可行性的意见.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">组织内有明确的流程来上报告更好的工作方式.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">管理层会倾听并按照工作团队的建议若实建议行事.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们会审查我们的工作，并找出任何需要改进的地方.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 10
                    }
                ]
            },
            {
                "id": 11,
                "name": "致力于维持韧性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 12,
                        "name": "Commitment to resilience",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">我们相信，作为一个团体，我们能通过接受和从安全错误中学习而变得更强大</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队有一套完善的安全错误分析流程.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队会从事件调查和调查发现中学习，并将这些发现应用到工作场所.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的所有团队成员都对安全错误的识别作出积极反应.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">共同修复与安全相关的错误是正常工作.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">停止并修复一个安全错误是被许可的.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 11
                    }
                ]
            },
            {
                "id": 12,
                "name": "重视专业人才",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 13,
                        "name": "Deference to Expertise",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">如果我的团队发现安全错误，同时也具备专业知识，我们会被授权采取行动</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">当发现高风险情况时，我们会立即采取行动，而不是报告和等待.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">如果我们的团队知道如何修复错误，他们有权进行修复.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我觉得在做安全决策时，我的专业知识有被考虑和受到重视.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的主管很擅长主持安全问题解决会议.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">如果我们需要专业协助，我们知道该联系谁.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 12
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 49,
        "name": "SATS Food Services",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 130,
        "name": "SAF Catering Operations – Central Zone",
        "order": null,
        "created": null,
        "topicOneId": 49
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5809,
        "moss_user": 19,
        "topic1": 44,
        "topic2": 85,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 7,
                "name": "治理意识与一致性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 8,
                        "name": "Governance awareness and alignment",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G1.&nbsp;&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队理解为什么需要在工作场所建立一套安全管理系统.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671095932530-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G2.&nbsp;&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我了解与我工作相关的健康与安全程序.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671096185180",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我认可与我工作相关的健康与安全程序.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671096202203",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我团队中的每个人都觉得我们需要对工作场所的安全负责.</span>",
                                "name": "radio-group-1671096237595",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们工作的风险评估准确的将潜在风险辨认和减低.</span>",
                                "name": "radio-group-1671096270071",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我所接受的安全就职为我提供了安全工作方面的知识.</span>",
                                "name": "radio-group-1671096294116",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 7
                    }
                ]
            },
            {
                "id": 8,
                "name": "专注失败事件",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 9,
                        "name": "Preoccupation with failure",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F1.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队认为较小的安全错误也非常重要，值得讨论.</span>",
                                "name": "radio-group-1671096413811-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">举报安全问题是我的个人职责.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">&nbsp;</span></p>",
                                "name": "radio-group-1671096503192",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们预料到较小的的工作错误有可能发生，且在这些错误发生时，不会刻意隐瞒.</span>",
                                "name": "radio-group-1671096524263",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的工作团队愿意停止工作来纠正较小的的安全错误.</span>",
                                "name": "radio-group-1671096539481",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们团队使用一个完善的流程来报告错误.（事故</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 0px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">/</span>险情报告）</p>",
                                "name": "radio-group-1671096557806",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会仔细检查工作方法描述和风险评估，以预测潜在的错误.</span>",
                                "name": "radio-group-1671096586371",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 8
                    }
                ]
            },
            {
                "id": 9,
                "name": "拒绝简化",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 10,
                        "name": "Reluctance to simplify",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S1.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会讨论工作计划，鼓励提出更好的建议.</span>",
                                "name": "radio-group-1671097013525-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队关注工作场所的变化并采取应对行动.</span>",
                                "name": "radio-group-1671097767313",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队认为，较小的的错误是意外事件和事故的源头</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">,</span><span class=\"s2\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Helvetica;\"> </span></p><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">可能会导致危害</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span>",
                                "name": "radio-group-1671097786481",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会试图改进工作方法，而不是按照他们一贯的方式做事</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span><br>",
                                "name": "radio-group-1671097829778",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们受到鼓励思考更好的做事方法</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span><br>",
                                "name": "radio-group-1671097848001",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们会与其他团队分享我们学到的知识.</span>",
                                "name": "radio-group-1671097862363",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 9
                    }
                ]
            },
            {
                "id": 10,
                "name": "对操作的敏感性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 11,
                        "name": "Sensitivity to operations",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">如果出现安全风险，我们的团队会花时间停止操作并重新开始</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我会从我们的工作计划中寻找微小的变化.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">经理会询问团队对工作计划可行性的意见.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">组织内有明确的流程来上报告更好的工作方式.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">管理层会倾听并按照工作团队的建议若实建议行事.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们会审查我们的工作，并找出任何需要改进的地方.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 10
                    }
                ]
            },
            {
                "id": 11,
                "name": "致力于维持韧性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 12,
                        "name": "Commitment to resilience",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">我们相信，作为一个团体，我们能通过接受和从安全错误中学习而变得更强大</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队有一套完善的安全错误分析流程.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队会从事件调查和调查发现中学习，并将这些发现应用到工作场所.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的所有团队成员都对安全错误的识别作出积极反应.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">共同修复与安全相关的错误是正常工作.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">停止并修复一个安全错误是被许可的.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 11
                    }
                ]
            },
            {
                "id": 12,
                "name": "重视专业人才",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 13,
                        "name": "Deference to Expertise",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">如果我的团队发现安全错误，同时也具备专业知识，我们会被授权采取行动</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">当发现高风险情况时，我们会立即采取行动，而不是报告和等待.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">如果我们的团队知道如何修复错误，他们有权进行修复.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我觉得在做安全决策时，我的专业知识有被考虑和受到重视.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的主管很擅长主持安全问题解决会议.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">如果我们需要专业协助，我们知道该联系谁.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 12
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 44,
        "name": "Apron",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 85,
        "name": "Ramp Services",
        "order": null,
        "created": null,
        "topicOneId": 44
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5810,
        "moss_user": 19,
        "topic1": 49,
        "topic2": 130,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 7,
                "name": "治理意识与一致性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 8,
                        "name": "Governance awareness and alignment",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G1.&nbsp;&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队理解为什么需要在工作场所建立一套安全管理系统.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671095932530-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G2.&nbsp;&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我了解与我工作相关的健康与安全程序.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671096185180",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我认可与我工作相关的健康与安全程序.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671096202203",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我团队中的每个人都觉得我们需要对工作场所的安全负责.</span>",
                                "name": "radio-group-1671096237595",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们工作的风险评估准确的将潜在风险辨认和减低.</span>",
                                "name": "radio-group-1671096270071",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我所接受的安全就职为我提供了安全工作方面的知识.</span>",
                                "name": "radio-group-1671096294116",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 7
                    }
                ]
            },
            {
                "id": 8,
                "name": "专注失败事件",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 9,
                        "name": "Preoccupation with failure",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F1.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队认为较小的安全错误也非常重要，值得讨论.</span>",
                                "name": "radio-group-1671096413811-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">举报安全问题是我的个人职责.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">&nbsp;</span></p>",
                                "name": "radio-group-1671096503192",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们预料到较小的的工作错误有可能发生，且在这些错误发生时，不会刻意隐瞒.</span>",
                                "name": "radio-group-1671096524263",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的工作团队愿意停止工作来纠正较小的的安全错误.</span>",
                                "name": "radio-group-1671096539481",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们团队使用一个完善的流程来报告错误.（事故</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 0px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">/</span>险情报告）</p>",
                                "name": "radio-group-1671096557806",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会仔细检查工作方法描述和风险评估，以预测潜在的错误.</span>",
                                "name": "radio-group-1671096586371",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 8
                    }
                ]
            },
            {
                "id": 9,
                "name": "拒绝简化",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 10,
                        "name": "Reluctance to simplify",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S1.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会讨论工作计划，鼓励提出更好的建议.</span>",
                                "name": "radio-group-1671097013525-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队关注工作场所的变化并采取应对行动.</span>",
                                "name": "radio-group-1671097767313",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队认为，较小的的错误是意外事件和事故的源头</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">,</span><span class=\"s2\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Helvetica;\"> </span></p><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">可能会导致危害</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span>",
                                "name": "radio-group-1671097786481",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会试图改进工作方法，而不是按照他们一贯的方式做事</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span><br>",
                                "name": "radio-group-1671097829778",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们受到鼓励思考更好的做事方法</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span><br>",
                                "name": "radio-group-1671097848001",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们会与其他团队分享我们学到的知识.</span>",
                                "name": "radio-group-1671097862363",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 9
                    }
                ]
            },
            {
                "id": 10,
                "name": "对操作的敏感性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 11,
                        "name": "Sensitivity to operations",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">如果出现安全风险，我们的团队会花时间停止操作并重新开始</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我会从我们的工作计划中寻找微小的变化.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">经理会询问团队对工作计划可行性的意见.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">组织内有明确的流程来上报告更好的工作方式.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">管理层会倾听并按照工作团队的建议若实建议行事.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们会审查我们的工作，并找出任何需要改进的地方.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 10
                    }
                ]
            },
            {
                "id": 11,
                "name": "致力于维持韧性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 12,
                        "name": "Commitment to resilience",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">我们相信，作为一个团体，我们能通过接受和从安全错误中学习而变得更强大</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队有一套完善的安全错误分析流程.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队会从事件调查和调查发现中学习，并将这些发现应用到工作场所.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的所有团队成员都对安全错误的识别作出积极反应.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">共同修复与安全相关的错误是正常工作.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">停止并修复一个安全错误是被许可的.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 11
                    }
                ]
            },
            {
                "id": 12,
                "name": "重视专业人才",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 13,
                        "name": "Deference to Expertise",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">如果我的团队发现安全错误，同时也具备专业知识，我们会被授权采取行动</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">当发现高风险情况时，我们会立即采取行动，而不是报告和等待.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">如果我们的团队知道如何修复错误，他们有权进行修复.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我觉得在做安全决策时，我的专业知识有被考虑和受到重视.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的主管很擅长主持安全问题解决会议.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">如果我们需要专业协助，我们知道该联系谁.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 12
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 49,
        "name": "SATS Food Services",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 130,
        "name": "SAF Catering Operations – Central Zone",
        "order": null,
        "created": null,
        "topicOneId": 49
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5811,
        "moss_user": 19,
        "topic1": 49,
        "topic2": 129,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 7,
                "name": "治理意识与一致性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 8,
                        "name": "Governance awareness and alignment",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G1.&nbsp;&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队理解为什么需要在工作场所建立一套安全管理系统.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671095932530-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G2.&nbsp;&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我了解与我工作相关的健康与安全程序.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671096185180",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我认可与我工作相关的健康与安全程序.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671096202203",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我团队中的每个人都觉得我们需要对工作场所的安全负责.</span>",
                                "name": "radio-group-1671096237595",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们工作的风险评估准确的将潜在风险辨认和减低.</span>",
                                "name": "radio-group-1671096270071",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我所接受的安全就职为我提供了安全工作方面的知识.</span>",
                                "name": "radio-group-1671096294116",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 7
                    }
                ]
            },
            {
                "id": 8,
                "name": "专注失败事件",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 9,
                        "name": "Preoccupation with failure",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F1.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队认为较小的安全错误也非常重要，值得讨论.</span>",
                                "name": "radio-group-1671096413811-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">举报安全问题是我的个人职责.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">&nbsp;</span></p>",
                                "name": "radio-group-1671096503192",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们预料到较小的的工作错误有可能发生，且在这些错误发生时，不会刻意隐瞒.</span>",
                                "name": "radio-group-1671096524263",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的工作团队愿意停止工作来纠正较小的的安全错误.</span>",
                                "name": "radio-group-1671096539481",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们团队使用一个完善的流程来报告错误.（事故</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 0px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">/</span>险情报告）</p>",
                                "name": "radio-group-1671096557806",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会仔细检查工作方法描述和风险评估，以预测潜在的错误.</span>",
                                "name": "radio-group-1671096586371",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 8
                    }
                ]
            },
            {
                "id": 9,
                "name": "拒绝简化",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 10,
                        "name": "Reluctance to simplify",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S1.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会讨论工作计划，鼓励提出更好的建议.</span>",
                                "name": "radio-group-1671097013525-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队关注工作场所的变化并采取应对行动.</span>",
                                "name": "radio-group-1671097767313",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队认为，较小的的错误是意外事件和事故的源头</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">,</span><span class=\"s2\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Helvetica;\"> </span></p><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">可能会导致危害</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span>",
                                "name": "radio-group-1671097786481",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会试图改进工作方法，而不是按照他们一贯的方式做事</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span><br>",
                                "name": "radio-group-1671097829778",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们受到鼓励思考更好的做事方法</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span><br>",
                                "name": "radio-group-1671097848001",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们会与其他团队分享我们学到的知识.</span>",
                                "name": "radio-group-1671097862363",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 9
                    }
                ]
            },
            {
                "id": 10,
                "name": "对操作的敏感性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 11,
                        "name": "Sensitivity to operations",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">如果出现安全风险，我们的团队会花时间停止操作并重新开始</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我会从我们的工作计划中寻找微小的变化.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">经理会询问团队对工作计划可行性的意见.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">组织内有明确的流程来上报告更好的工作方式.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">管理层会倾听并按照工作团队的建议若实建议行事.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们会审查我们的工作，并找出任何需要改进的地方.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 10
                    }
                ]
            },
            {
                "id": 11,
                "name": "致力于维持韧性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 12,
                        "name": "Commitment to resilience",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">我们相信，作为一个团体，我们能通过接受和从安全错误中学习而变得更强大</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队有一套完善的安全错误分析流程.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队会从事件调查和调查发现中学习，并将这些发现应用到工作场所.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的所有团队成员都对安全错误的识别作出积极反应.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">共同修复与安全相关的错误是正常工作.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">停止并修复一个安全错误是被许可的.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 11
                    }
                ]
            },
            {
                "id": 12,
                "name": "重视专业人才",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 13,
                        "name": "Deference to Expertise",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">如果我的团队发现安全错误，同时也具备专业知识，我们会被授权采取行动</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">当发现高风险情况时，我们会立即采取行动，而不是报告和等待.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">如果我们的团队知道如何修复错误，他们有权进行修复.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我觉得在做安全决策时，我的专业知识有被考虑和受到重视.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的主管很擅长主持安全问题解决会议.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">如果我们需要专业协助，我们知道该联系谁.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 12
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 49,
        "name": "SATS Food Services",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 129,
        "name": "SAF Catering Operations – North Zone",
        "order": null,
        "created": null,
        "topicOneId": 49
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5812,
        "moss_user": 19,
        "topic1": 44,
        "topic2": 85,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 44,
        "name": "Apron",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 85,
        "name": "Ramp Services",
        "order": null,
        "created": null,
        "topicOneId": 44
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5813,
        "moss_user": 19,
        "topic1": 44,
        "topic2": 85,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 44,
        "name": "Apron",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 85,
        "name": "Ramp Services",
        "order": null,
        "created": null,
        "topicOneId": 44
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5814,
        "moss_user": 19,
        "topic1": 49,
        "topic2": 130,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 49,
        "name": "SATS Food Services",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 130,
        "name": "SAF Catering Operations – Central Zone",
        "order": null,
        "created": null,
        "topicOneId": 49
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5815,
        "moss_user": 19,
        "topic1": 44,
        "topic2": 85,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 44,
        "name": "Apron",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 85,
        "name": "Ramp Services",
        "order": null,
        "created": null,
        "topicOneId": 44
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5816,
        "moss_user": 19,
        "topic1": 48,
        "topic2": 116,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 48,
        "name": "Catering",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 116,
        "name": "Dishing / Equipment / Warewash",
        "order": null,
        "created": null,
        "topicOneId": 48
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5817,
        "moss_user": 19,
        "topic1": 44,
        "topic2": 84,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 44,
        "name": "Apron",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 84,
        "name": "Maintenance",
        "order": null,
        "created": null,
        "topicOneId": 44
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5818,
        "moss_user": 19,
        "topic1": 44,
        "topic2": 79,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": true
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": true
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": true
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": true
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": true
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": true
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": true
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": true
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": true
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": true
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": true
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": true
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 44,
        "name": "Apron",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 79,
        "name": "Baggage",
        "order": null,
        "created": null,
        "topicOneId": 44
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5819,
        "moss_user": 19,
        "topic1": 49,
        "topic2": 130,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 7,
                "name": "治理意识与一致性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 8,
                        "name": "Governance awareness and alignment",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G1.&nbsp;&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队理解为什么需要在工作场所建立一套安全管理系统.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671095932530-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G2.&nbsp;&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我了解与我工作相关的健康与安全程序.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671096185180",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我认可与我工作相关的健康与安全程序.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671096202203",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我团队中的每个人都觉得我们需要对工作场所的安全负责.</span>",
                                "name": "radio-group-1671096237595",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们工作的风险评估准确的将潜在风险辨认和减低.</span>",
                                "name": "radio-group-1671096270071",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我所接受的安全就职为我提供了安全工作方面的知识.</span>",
                                "name": "radio-group-1671096294116",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 7
                    }
                ]
            },
            {
                "id": 8,
                "name": "专注失败事件",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 9,
                        "name": "Preoccupation with failure",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F1.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队认为较小的安全错误也非常重要，值得讨论.</span>",
                                "name": "radio-group-1671096413811-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">举报安全问题是我的个人职责.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">&nbsp;</span></p>",
                                "name": "radio-group-1671096503192",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们预料到较小的的工作错误有可能发生，且在这些错误发生时，不会刻意隐瞒.</span>",
                                "name": "radio-group-1671096524263",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的工作团队愿意停止工作来纠正较小的的安全错误.</span>",
                                "name": "radio-group-1671096539481",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们团队使用一个完善的流程来报告错误.（事故</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 0px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">/</span>险情报告）</p>",
                                "name": "radio-group-1671096557806",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会仔细检查工作方法描述和风险评估，以预测潜在的错误.</span>",
                                "name": "radio-group-1671096586371",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 8
                    }
                ]
            },
            {
                "id": 9,
                "name": "拒绝简化",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 10,
                        "name": "Reluctance to simplify",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S1.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会讨论工作计划，鼓励提出更好的建议.</span>",
                                "name": "radio-group-1671097013525-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队关注工作场所的变化并采取应对行动.</span>",
                                "name": "radio-group-1671097767313",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队认为，较小的的错误是意外事件和事故的源头</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">,</span><span class=\"s2\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Helvetica;\"> </span></p><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">可能会导致危害</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span>",
                                "name": "radio-group-1671097786481",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会试图改进工作方法，而不是按照他们一贯的方式做事</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span><br>",
                                "name": "radio-group-1671097829778",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们受到鼓励思考更好的做事方法</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span><br>",
                                "name": "radio-group-1671097848001",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们会与其他团队分享我们学到的知识.</span>",
                                "name": "radio-group-1671097862363",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 9
                    }
                ]
            },
            {
                "id": 10,
                "name": "对操作的敏感性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 11,
                        "name": "Sensitivity to operations",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">如果出现安全风险，我们的团队会花时间停止操作并重新开始</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我会从我们的工作计划中寻找微小的变化.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">经理会询问团队对工作计划可行性的意见.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">组织内有明确的流程来上报告更好的工作方式.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">管理层会倾听并按照工作团队的建议若实建议行事.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们会审查我们的工作，并找出任何需要改进的地方.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 10
                    }
                ]
            },
            {
                "id": 11,
                "name": "致力于维持韧性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 12,
                        "name": "Commitment to resilience",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">我们相信，作为一个团体，我们能通过接受和从安全错误中学习而变得更强大</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队有一套完善的安全错误分析流程.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队会从事件调查和调查发现中学习，并将这些发现应用到工作场所.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的所有团队成员都对安全错误的识别作出积极反应.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">共同修复与安全相关的错误是正常工作.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">停止并修复一个安全错误是被许可的.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 11
                    }
                ]
            },
            {
                "id": 12,
                "name": "重视专业人才",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 13,
                        "name": "Deference to Expertise",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">如果我的团队发现安全错误，同时也具备专业知识，我们会被授权采取行动</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">当发现高风险情况时，我们会立即采取行动，而不是报告和等待.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">如果我们的团队知道如何修复错误，他们有权进行修复.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我觉得在做安全决策时，我的专业知识有被考虑和受到重视.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的主管很擅长主持安全问题解决会议.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">如果我们需要专业协助，我们知道该联系谁.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 12
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 49,
        "name": "SATS Food Services",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 130,
        "name": "SAF Catering Operations – Central Zone",
        "order": null,
        "created": null,
        "topicOneId": 49
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5820,
        "moss_user": 19,
        "topic1": 44,
        "topic2": 85,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 44,
        "name": "Apron",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 85,
        "name": "Ramp Services",
        "order": null,
        "created": null,
        "topicOneId": 44
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5821,
        "moss_user": 19,
        "topic1": 44,
        "topic2": 85,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 44,
        "name": "Apron",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 85,
        "name": "Ramp Services",
        "order": null,
        "created": null,
        "topicOneId": 44
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5822,
        "moss_user": 19,
        "topic1": 44,
        "topic2": 85,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": true
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": true
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 44,
        "name": "Apron",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 85,
        "name": "Ramp Services",
        "order": null,
        "created": null,
        "topicOneId": 44
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5823,
        "moss_user": 19,
        "topic1": 44,
        "topic2": 85,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 44,
        "name": "Apron",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 85,
        "name": "Ramp Services",
        "order": null,
        "created": null,
        "topicOneId": 44
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5824,
        "moss_user": 19,
        "topic1": 49,
        "topic2": 139,
        "topic3": 0,
        "topic4": 0,
        "role": "Manager",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 49,
        "name": "SATS Food Services",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 139,
        "name": "Quality Assurance / Order Desk / Halal Office / Culinary & Nutrition, BOM & Costing (CNBC)",
        "order": null,
        "created": null,
        "topicOneId": 49
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5825,
        "moss_user": 19,
        "topic1": 48,
        "topic2": 122,
        "topic3": 0,
        "topic4": 0,
        "role": "Manager",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": true
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": true
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 48,
        "name": "Catering",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 122,
        "name": "Catering Marketing / Catering Management",
        "order": null,
        "created": null,
        "topicOneId": 48
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5826,
        "moss_user": 19,
        "topic1": 44,
        "topic2": 85,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 44,
        "name": "Apron",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 85,
        "name": "Ramp Services",
        "order": null,
        "created": null,
        "topicOneId": 44
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5827,
        "moss_user": 19,
        "topic1": 49,
        "topic2": 130,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 7,
                "name": "治理意识与一致性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 8,
                        "name": "Governance awareness and alignment",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G1.&nbsp;&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队理解为什么需要在工作场所建立一套安全管理系统.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671095932530-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G2.&nbsp;&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我了解与我工作相关的健康与安全程序.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671096185180",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我认可与我工作相关的健康与安全程序.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\"><b>&nbsp;</b><b style=\"font-family: \" pingfang=\"\" sc\";\"=\"\">。</b></span></p>",
                                "name": "radio-group-1671096202203",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我团队中的每个人都觉得我们需要对工作场所的安全负责.</span>",
                                "name": "radio-group-1671096237595",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们工作的风险评估准确的将潜在风险辨认和减低.</span>",
                                "name": "radio-group-1671096270071",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "G6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我所接受的安全就职为我提供了安全工作方面的知识.</span>",
                                "name": "radio-group-1671096294116",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 7
                    }
                ]
            },
            {
                "id": 8,
                "name": "专注失败事件",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 9,
                        "name": "Preoccupation with failure",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F1.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队认为较小的安全错误也非常重要，值得讨论.</span>",
                                "name": "radio-group-1671096413811-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">举报安全问题是我的个人职责.</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">&nbsp;</span></p>",
                                "name": "radio-group-1671096503192",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们预料到较小的的工作错误有可能发生，且在这些错误发生时，不会刻意隐瞒.</span>",
                                "name": "radio-group-1671096524263",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的工作团队愿意停止工作来纠正较小的的安全错误.</span>",
                                "name": "radio-group-1671096539481",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们团队使用一个完善的流程来报告错误.（事故</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 0px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: \" pingfang=\"\" sc\";=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">/</span>险情报告）</p>",
                                "name": "radio-group-1671096557806",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "F6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会仔细检查工作方法描述和风险评估，以预测潜在的错误.</span>",
                                "name": "radio-group-1671096586371",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 8
                    }
                ]
            },
            {
                "id": 9,
                "name": "拒绝简化",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 10,
                        "name": "Reluctance to simplify",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S1.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会讨论工作计划，鼓励提出更好的建议.</span>",
                                "name": "radio-group-1671097013525-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队关注工作场所的变化并采取应对行动.</span>",
                                "name": "radio-group-1671097767313",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队认为，较小的的错误是意外事件和事故的源头</span><p class=\"p1\" style=\"margin-right: 0px; margin-bottom: 0px; margin-left: 54px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";\"=\"\"><span class=\"s1\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Arial;\">,</span><span class=\"s2\" style=\"font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal; font-family: Helvetica;\"> </span></p><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">可能会导致危害</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span>",
                                "name": "radio-group-1671097786481",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的团队会试图改进工作方法，而不是按照他们一贯的方式做事</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span><br>",
                                "name": "radio-group-1671097829778",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们受到鼓励思考更好的做事方法</span><span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">.</span><br>",
                                "name": "radio-group-1671097848001",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "S6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们会与其他团队分享我们学到的知识.</span>",
                                "name": "radio-group-1671097862363",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 9
                    }
                ]
            },
            {
                "id": 10,
                "name": "对操作的敏感性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 11,
                        "name": "Sensitivity to operations",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">如果出现安全风险，我们的团队会花时间停止操作并重新开始</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我会从我们的工作计划中寻找微小的变化.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">经理会询问团队对工作计划可行性的意见.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">组织内有明确的流程来上报告更好的工作方式.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">管理层会倾听并按照工作团队的建议若实建议行事.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "O6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们会审查我们的工作，并找出任何需要改进的地方.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 10
                    }
                ]
            },
            {
                "id": 11,
                "name": "致力于维持韧性",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 12,
                        "name": "Commitment to resilience",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">我们相信，作为一个团体，我们能通过接受和从安全错误中学习而变得更强大</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队有一套完善的安全错误分析流程.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我们的团队会从事件调查和调查发现中学习，并将这些发现应用到工作场所.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的所有团队成员都对安全错误的识别作出积极反应.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">共同修复与安全相关的错误是正常工作.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "R6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">停止并修复一个安全错误是被许可的.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 11
                    }
                ]
            },
            {
                "id": 12,
                "name": "重视专业人才",
                "lang": "mandarin",
                "questions": [
                    {
                        "id": 13,
                        "name": "Deference to Expertise",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E1.&nbsp;<span style=\"font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;=\"\" color:=\"\" rgb(0,=\"\" 0,=\"\" 0);\"=\"\">如果我的团队发现安全错误，同时也具备专业知识，我们会被授权采取行动</span><span style=\"color: rgb(0, 0, 0);\">.</span><br>",
                                "name": "radio-group-1671097919320-0",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E2.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">当发现高风险情况时，我们会立即采取行动，而不是报告和等待.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E3.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">如果我们的团队知道如何修复错误，他们有权进行修复.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E4.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我觉得在做安全决策时，我的专业知识有被考虑和受到重视.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E5.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">我的主管很擅长主持安全问题解决会议.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "E6.&nbsp;<span style=\"color: rgb(0, 0, 0); font-family: \" pingfang=\"\" sc\";=\"\" font-size:=\"\" 12px;\"=\"\">如果我们需要专业协助，我们知道该联系谁.</span>",
                                "name": "radio-group-1671098201634",
                                "values": [
                                    {
                                        "label": "不符合",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "很少符合",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "偶尔符合",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "基本符合",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "总是符合",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 12
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 49,
        "name": "SATS Food Services",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 130,
        "name": "SAF Catering Operations – Central Zone",
        "order": null,
        "created": null,
        "topicOneId": 49
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5828,
        "moss_user": 19,
        "topic1": 49,
        "topic2": 139,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 49,
        "name": "SATS Food Services",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 139,
        "name": "Quality Assurance / Order Desk / Halal Office / Culinary & Nutrition, BOM & Costing (CNBC)",
        "order": null,
        "created": null,
        "topicOneId": 49
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5829,
        "moss_user": 19,
        "topic1": 48,
        "topic2": 115,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 48,
        "name": "Catering",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 115,
        "name": "Pre-Set",
        "order": null,
        "created": null,
        "topicOneId": 48
    },
    "topic3": {},
    "topic4": {}
},
{
    "response": {
        "id": 5830,
        "moss_user": 19,
        "topic1": 49,
        "topic2": 130,
        "topic3": 0,
        "topic4": 0,
        "role": "Worker",
        "input_json": [
            {
                "id": 1,
                "name": "Governance awareness and alignment",
                "lang": "english",
                "questions": [
                    {
                        "id": 1,
                        "name": "Organisations have systems, processes and procedures for safe work practices, usually based on and reflected in an espoused set of values about safety.  HRO’s ensure that these important governance systems, processes and procedures are well understood by all workers and that their personal values regarding safety are aligned to the espoused organisational safety values.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e71f307-7fff-9ab3-126e-f386ab1be700\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G1. My team understands why we have a Safety Management system at this workplace.&nbsp; </span></span><br>",
                                "name": "radio-group-1663691462214-0",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-052ca504-7fff-1cf1-25b7-a5c9d5a0651c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G2. I understand the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691695803",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-720b5c5b-7fff-6533-aa33-5f506138cdd8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G3. I agree with the Health and Safety procedures related to my work. </span></span><br>",
                                "name": "radio-group-1663691832646",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">G4. Everyone in my team feels responsible for our safety in this workplace.</span><br>",
                                "name": "radio-group-1663691924179",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-b6936a14-7fff-865c-3029-49cce4047e0f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G5. The risk assessment for the work we do are an accurate indication of the potential risks.</span></span><br>",
                                "name": "radio-group-1668160760969",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d1670ab3-7fff-eca2-1d41-491c83a2700a\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">G6. The safety induction I received, provided me with the knowledge to work safely</span></span><br>",
                                "name": "radio-group-1668160768699",
                                "values": [
                                    {
                                        "label": "Not True ",
                                        "value": "1  ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True   ",
                                        "value": "4    ",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 1
                    }
                ]
            },
            {
                "id": 2,
                "name": "Preoccupation with  failure",
                "lang": "english",
                "questions": [
                    {
                        "id": 2,
                        "name": "Preoccupation with failure is the active consideration and ongoing wariness of the possibility of failure that treats any failure or near miss as an indicator of potentially larger problems (LaPorte & Consolini 1991).  Preoccupation with failure is the process of operating with much concern about the  possibility of unexpected events that may hinder safety by engaging in proactive analysis and discussions and after-action reviews.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e458a014-7fff-382c-1748-68b7c921fd01\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F1. My team considers small safety errors important enough to talk about.</span></span><br>",
                                "name": "radio-group-1663729457864-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": true
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2a5aa96c-7fff-c3df-5d82-34dc878630d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F2. I bring small safety errors to the attention of my team members.</span></span><br>",
                                "name": "radio-group-1663729580215",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8e2f9620-7fff-0f00-29da-aefeccd4231f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F3. We expect small work errors to occur and don’t hide them when they happen.</span></span><br>",
                                "name": "radio-group-1663729620771",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-51190fe6-7fff-3f7f-a6ce-254d50f39699\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F4. My work team is comfortable to stop work to address small safety errors.</span></span><br>",
                                "name": "radio-group-1663729676531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5c1a8d76-7fff-7bc8-6d69-d71292960266\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F5. Our team uses a well-established process to report errors. (Incident / near miss reporting)</span></span><br>",
                                "name": "radio-group-1663729720417",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": true
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-9672a6bf-7fff-b0d6-905d-0acc705839d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">F6. My team examines work method statements and risk assessments carefully to predict potential errors.</span></span><br>",
                                "name": "radio-group-1663729740048",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": true
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 2
                    }
                ]
            },
            {
                "id": 3,
                "name": "Reluctance to  simplify",
                "lang": "english",
                "questions": [
                    {
                        "id": 3,
                        "name": "Another way HROs manage the unexpected is by being reluctant to accept simplifications. Less simplification allows you to see in more detail what might be causing the unexpected. HROs take deliberate steps to create more complete and nuanced pictures of what they face. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e303cfaa-7fff-d427-d0f0-79806193b7f8\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S1. My team discusses work plans, and its ok to suggest a better way.</span></span><br>",
                                "name": "radio-group-1663729847845-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-48194cfa-7fff-1bf9-7409-64ffe99d79d1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S2. My team pays attention to and acts on changes in the workplace.</span></span><br>",
                                "name": "radio-group-1663730126227",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e688fbde-7fff-8210-1324-200418cdaea6\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S3. Our team believe small safety errors are the start of incidents and accidents that may lead to harm.</span></span><br>",
                                "name": "radio-group-1663730148202",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-269210d5-7fff-bce6-0679-91ca65199b83\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S4. My team tries to improve work methods rather than doing them the way they always have been done.</span></span><br>",
                                "name": "radio-group-1663730176213",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-a37b14af-7fff-f9b7-3297-9bf004b07fc4\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S5. We are encouraged to think about better ways of doing things.</span></span><br>",
                                "name": "radio-group-1663730194997",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-627b04c4-7fff-470a-ee96-f3b977241db9\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">S6. We share knowledge we develop with other teams.</span></span><br>",
                                "name": "radio-group-1663730216933",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 3
                    }
                ]
            },
            {
                "id": 4,
                "name": "Sensitivity  to  operations",
                "lang": "english",
                "questions": [
                    {
                        "id": 4,
                        "name": "Sensitivity to operations is about the work itself, about seeing what we are actually doing regardless of intentions, designs, and plans. Differences in sensitivity are evident, for example, in interpretations of close calls. When people see a near miss as success, this reinforces their beliefs that current operations are sufficient to forestall unintended consequences. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-07c1658f-7fff-30db-bfac-553fa89a28a3\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O1. Our team takes time to stop and reset if safety risks appear.</span></span><br>",
                                "name": "radio-group-1663730300185-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": true
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-c42b41c4-7fff-6934-3860-95eafb0bc911\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O2. I look for small changes in our work from what we had planned.</span></span><br>",
                                "name": "radio-group-1663730591892",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": true
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7ee5d442-7fff-96a1-db18-f5cca5e3e6d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O3. Managers ask team opinions about the practicality of work plans.</span></span><br>",
                                "name": "radio-group-1663730608635",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-d0c257e3-7fff-db5b-c4f5-d9bef2335fac\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O4. There are clear processes to report better ways of doing work.</span></span><br>",
                                "name": "radio-group-1663730639379",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-682d608e-7fff-0c8b-34e6-798b049b0334\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O5. Management listens to, and act on suggestions from work teams.</span></span><br>",
                                "name": "radio-group-1663730680131",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-ffcbd50c-7fff-3e0b-0375-559f0f97890c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">O6. We&nbsp;review our work to identify any areas for improvement.</span></span><br>",
                                "name": "radio-group-1663730707531",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 4
                    }
                ]
            },
            {
                "id": 5,
                "name": "Commitment to  resilience",
                "lang": "english",
                "questions": [
                    {
                        "id": 5,
                        "name": "No system is perfect. The essence of resilience is that HROs develop capabilities to detect, contain, and bounce back from inevitable errors. The hallmark of an HRO is not that it is error-free but that errors don't disable it. ",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7afdcf8f-7fff-610a-c5eb-1c819de6cc4e\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R1. We believe we grow stronger as a group by embracing and learning from safety errors.</span></span><br>",
                                "name": "radio-group-1663731322646-0",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-2fd92146-7fff-9c07-62d9-6e68da2d2d86\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R2. Our team has a well-developed process for analysing safety errors.</span></span><br>",
                                "name": "radio-group-1663731420181",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-85b4ec0d-7fff-c554-5e0a-e1c6f092845c\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R3. Our team learns from incident investigations and their findings and apply those findings into the workplace.</span></span><br>",
                                "name": "radio-group-1663731440395",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-5019dc7f-7fff-038d-94d4-ee49fe7d4dee\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R4. All my team members respond positively to the identification of safety errors.</span></span><br>",
                                "name": "radio-group-1663731453482",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-4c04f4e8-7fff-2e14-b7f5-10de2acba2f1\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R5. Collectively fixing safety related errors is considered normal work.</span></span><br>",
                                "name": "radio-group-1663731473330",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-40b84f26-7fff-5dc8-b01f-2c1de20d4e85\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">R6. Its ok to stop and fix a safety error.</span></span><br>",
                                "name": "radio-group-1663731486627",
                                "values": [
                                    {
                                        "label": "Not True",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 5
                    }
                ]
            },
            {
                "id": 6,
                "name": "Deference  to  Expertise",
                "lang": "english",
                "questions": [
                    {
                        "id": 6,
                        "name": "The final distinctive feature of HROs is their deference to expertise. HROs push decision making down and around. Decisions are made on the front line, and authority migrates to the people with the most expertise, regardless of their rank.",
                        "value": [
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-e77c43f8-7fff-c9d4-4a69-18997f8c76d0\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E1. My team is empowered to act on safety errors if we identify them and if we have the expertise to do so.</span></span><br>",
                                "name": "radio-group-1663731558034-0",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span style=\"color: rgba(0, 0, 0, 0.87); font-family: &quot;Nunito Sans rev=2&quot;; font-size: 18px;\">E2. When a high risk situation is identified, we act rather than report and wait.</span><br>",
                                "name": "radio-group-1663731640613",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-34999b70-7fff-3350-1e1b-632c39a0b456\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E3. If our team knows how to fix an error, they are permitted to do so.</span></span><br>",
                                "name": "radio-group-1663731658044",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-df7af48c-7fff-badb-c057-335f3f5ff68f\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E4. I feel my expertise is considered and respected during safety decision making.</span></span><br>",
                                "name": "radio-group-1663731686754",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": true
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": false
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-7df30fb6-7fff-f47a-4cb6-9f7ee9050616\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E5. My supervisor is good at leading meetings for safety problem solving.</span></span><br>",
                                "name": "radio-group-1663731698714",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            },
                            {
                                "type": "radio-group",
                                "required": false,
                                "label": "<span id=\"docs-internal-guid-8f257ebe-7fff-1e4d-2513-c7791bbab11b\"><span style=\"font-size: 12pt; color: rgb(32, 33, 36); font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;\">E6. We know who to contact if and when we need expertise.</span></span><br>",
                                "name": "radio-group-1663731710722",
                                "values": [
                                    {
                                        "label": "Not True    ",
                                        "value": "1",
                                        "selected": false
                                    },
                                    {
                                        "label": "Seldom True ",
                                        "value": "2",
                                        "selected": false
                                    },
                                    {
                                        "label": "Occasionally True     ",
                                        "value": "3",
                                        "selected": false
                                    },
                                    {
                                        "label": "Mostly True      ",
                                        "value": "4",
                                        "selected": false
                                    },
                                    {
                                        "label": "Always True  ",
                                        "value": "5",
                                        "selected": true
                                    }
                                ]
                            }
                        ],
                        "questionTitleId": 6
                    }
                ]
            }
        ],
        "ip_address": null
    },
    "topic1": {
        "id": 49,
        "name": "SATS Food Services",
        "order": null,
        "active": 1,
        "role": null,
        "created": null,
        "mossUserId": 19
    },
    "topic2": {
        "id": 130,
        "name": "SAF Catering Operations – Central Zone",
        "order": null,
        "created": null,
        "topicOneId": 49
    },
    "topic3": {},
    "topic4": {}
}
]