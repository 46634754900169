import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import { Trans } from 'react-i18next';

class Sidebar extends Component {
  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach(i => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(this.state).forEach(i => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: '/apps', state: 'appsMenuOpen' },
      { path: '/basic-ui', state: 'basicUiMenuOpen' },
      { path: '/advanced-ui', state: 'advancedUiMenuOpen' },
      { path: '/form-elements', state: 'formElementsMenuOpen' },
      { path: '/tables', state: 'tablesMenuOpen' },
      { path: '/maps', state: 'mapsMenuOpen' },
      { path: '/icons', state: 'iconsMenuOpen' },
      { path: '/charts', state: 'chartsMenuOpen' },
      { path: '/user-pages', state: 'userPagesMenuOpen' },
      { path: '/error-pages', state: 'errorPagesMenuOpen' },
      { path: '/general-pages', state: 'generalPagesMenuOpen' },
      { path: '/ecommerce', state: 'ecommercePagesMenuOpen' },
    ];

    dropdownPaths.forEach((obj => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true })
      }
    }));

  }
  render() {
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar" style={{ position: 'fixed', top: 60, background: '#131533' }}>
        <ul className="nav pt-4">

          <li className={this.isPathActive('/dashboard') ? 'nav-item active' : 'nav-item'} style={{ padding: '0 20px' }}>
            <Link className="nav-link" to="/dashboard">
              <span className="icon-bg"><i className="mdi mdi-monitor-dashboard menu-icon"></i></span>
              <span className="menu-title"><Trans>Dashboard</Trans></span>
            </Link>
          </li>
          {/* <li className={ this.isPathActive('/manage') ? 'nav-item active' : 'nav-item' } style={{padding:'0 20px'}}>
            <Link className="nav-link" to="/manage">
              <span className="icon-bg"><i className="mdi mdi-account menu-icon"></i></span>
              <span className="menu-title"><Trans>Manage Assessment</Trans></span>
            </Link>
          </li> */}
          <li className={this.isPathActive('/topic') ? 'nav-item active' : 'nav-item'} style={{ padding: '0 20px' }}>
            <Link className="nav-link" to="/topic">
              <span className="icon-bg"><i className="mdi mdi-file  menu-icon"></i></span>
              <span className="menu-title"><Trans>Configure Survey</Trans></span>
            </Link>
          </li>
          <li className={this.isPathActive('/tables/data-table') ? 'nav-item active' : 'nav-item'} style={{ padding: '0 20px' }}>
            <Link className="nav-link" to="/tables/data-table">
              <span className="icon-bg"><i className="mdi mdi-qrcode menu-icon"></i></span>
              <span className="menu-title"><Trans>Survey QR Code / Link</Trans></span>
            </Link>
          </li>
          <li className={this.isPathActive('/tables/data-repo') ? 'nav-item active' : 'nav-item'} style={{ padding: '0 20px' }}>
            <Link className="nav-link" to="/tables/data-repo">
              <span className="icon-bg"><i className="mdi mdi-widgets menu-icon"></i></span>
              <span className="menu-title"><Trans>Data Repository </Trans></span>
            </Link>
          </li>
          <li className={this.isPathActive('/other_report') ? 'nav-item active' : 'nav-item'} style={{ padding: '0 20px' }}>
            <Link className="nav-link" to="/other_report">
              <span className="icon-bg"><i className="mdi mdi-clipboard menu-icon"></i></span>
              <span className="menu-title"><Trans>Other Report</Trans></span>
            </Link>
          </li>
          <li className={this.isPathActive('/comparison') ? 'nav-item active' : 'nav-item'} style={{ padding: '0 20px' }}>
            <Link className="nav-link" to="/comparison">
              <span className="icon-bg"><i className="mdi mdi-set-center menu-icon"></i></span>
              <span className="menu-title"><Trans>Comparison</Trans></span>
            </Link>
          </li>
          <li className="nav-item " style={{ padding: '0 20px' }}>
            <Link className="nav-link" to="/logout">
              <span className="icon-bg"><i className="mdi mdi-logout menu-icon"></i></span>
              <span className="menu-title"><Trans>Log Out</Trans></span>
            </Link>
          </li>
        </ul>
        <div style={{ padding: 20, display: 'flex', justifyContent: 'center', position: 'absolute', width: '100%', bottom: -15 }}>
          <img src={require('../../assets/images/fusion_sidebar.png')} width={'50%'} height={'auto'} />
        </div>

      </nav>

    );
  }

  isPathActive(path) {
    console.log(this.props.location.pathname)
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add className 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {

      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }

}

export default withRouter(Sidebar);