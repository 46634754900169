export const API_URL = 'https://api.acuizen.com';
export const MOSS_USER = API_URL + '/moss-users';
export const MOSS_TITLE = API_URL + '/moss-titles';
export const TOPICS_URL = API_URL + '/topic-ones';
export const SUBTOPICS_URL = API_URL + '/topic-twos';
export const NESTED_SUBTOPIC_URL = '/topic-twos';
export const TIER_THREE_URL = API_URL + '/topic-threes';
export const NESTED_TIER_THREE_URL = '/topic-threes';
export const TIER_FOUR_URL = API_URL + '/topic-fours';
export const NESTED_TIER_FOUR_URL = '/topic-fours';

export const STANDARDS_URL = API_URL + '/standards';

export const QUESTIONS_URL = API_URL + '/questions';
export const SUBQUESTIONS_URL = API_URL + '/sub-questions';
export const NESTED_SUBQUESTIONS_URL = '/sub-questions';