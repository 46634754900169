import React, { Component, Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Spinner from '../app/shared/Spinner';
import DashboardTwo from './dashboard/Dashboard2';
import addittionalReport from './public/AdditionalReport';
const lazyWithRetry = (componentImport) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem(
        'page-has-been-force-refreshed'
      ) || 'false'
    );

    try {
      const component = await componentImport();

      window.localStorage.setItem(
        'page-has-been-force-refreshed',
        'false'
      );

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        window.localStorage.setItem(
          'page-has-been-force-refreshed',
          'true'
        );
        return window.location.reload();
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error;
    }
  });

const Dashboard = lazyWithRetry(() => import('./dashboard/Dashboard'));
const Logout = lazyWithRetry(() => import('./Logout'));
const RtlLayout = lazyWithRetry(() => import('./layout/RtlLayout'));

const Widgets = lazyWithRetry(() => import('./widgets/Widgets'));

const KanbanBoard = lazyWithRetry(() => import('./apps/KanbanBoard'));
const Tickets = lazyWithRetry(() => import('./apps/Tickets'));
const Chats = lazyWithRetry(() => import('./apps/Chats'));
const TodoList = lazyWithRetry(() => import('./apps/TodoList'));

const Accordions = lazyWithRetry(() => import('./basic-ui/Accordions'));
const Buttons = lazyWithRetry(() => import('./basic-ui/Buttons'));
const Badges = lazyWithRetry(() => import('./basic-ui/Badges'));
const Breadcrumbs = lazyWithRetry(() => import('./basic-ui/Breadcrumbs'));
const Dropdowns = lazyWithRetry(() => import('./basic-ui/Dropdowns'));
const Modals = lazyWithRetry(() => import('./basic-ui/Modals'));
const Progress = lazyWithRetry(() => import('./basic-ui/Progress'));
const Paginations = lazyWithRetry(() => import('./basic-ui/Paginations'));
const TabsPage = lazyWithRetry(() => import('./basic-ui/TabsPage'));
const Typography = lazyWithRetry(() => import('./basic-ui/Typography'));
const Tooltips = lazyWithRetry(() => import('./basic-ui/Tooltips'));
const Popups = lazyWithRetry(() => import('./basic-ui/Popups'));

const Dragula = lazyWithRetry(() => import('./advanced-ui/Dragula'));
const Clipboard = lazyWithRetry(() => import('./advanced-ui/Clipboards'));
const ContextMenu = lazyWithRetry(() => import('./advanced-ui/ContextMenus'));
const Sliders = lazyWithRetry(() => import('./advanced-ui/Sliders'));
const Carousel = lazyWithRetry(() => import('./advanced-ui/Carousel'));
const Loaders = lazyWithRetry(() => import('./advanced-ui/Loaders'));
const TreeView = lazyWithRetry(() => import('./advanced-ui/TreeView'));

const BasicElements = lazyWithRetry(() => import('./form-elements/BasicElements'));
const AdvancedElements = lazyWithRetry(() => import('./form-elements/AdvancedElements'));
const Validation = lazyWithRetry(() => import('./form-elements/Validation'));
const Wizard = lazyWithRetry(() => import('./form-elements/Wizard'));

const BasicTable = lazyWithRetry(() => import('./tables/BasicTable'));
const DataTable = lazyWithRetry(() => import('./tables/DataTables'));
const ReactTable = lazyWithRetry(() => import('./tables/ReactTable'));
const SortableTable = lazyWithRetry(() => import('./tables/SortableTable'));
const DataRepo = lazyWithRetry(() => import('./tables/DataRepo'));
const Response = lazyWithRetry(() => import('./tables/Response'));
const VectorMap = lazyWithRetry(() => import('./maps/VectorMap'));
const SimpleMap = lazyWithRetry(() => import('./maps/SimpleMap'));
const ManageTopic = lazy(() => import('./topics/ManageTopic'))
const Notifications = lazyWithRetry(() => import('./notifications/Notifications'));

const Mdi = lazyWithRetry(() => import('./icons/Mdi'));
const FlagIcons = lazyWithRetry(() => import('./icons/FlagIcons'));
const FontAwesome = lazyWithRetry(() => import('./icons/FontAwesome'));
const SimpleLine = lazyWithRetry(() => import('./icons/SimpleLine'));
const Themify = lazyWithRetry(() => import('./icons/Themify'));
const TypIcons = lazyWithRetry(() => import('./icons/TypIcons'));

const TextEditors = lazyWithRetry(() => import('./text-editors/TextEditors'));
const CodeEditor = lazyWithRetry(() => import('./code-editor/CodeEditor'));

const ChartJs = lazyWithRetry(() => import('./charts/ChartJs'));
const C3Charts = lazyWithRetry(() => import('./charts/C3Charts'));
const Chartist = lazyWithRetry(() => import('./charts/Chartist'));
const GoogleCharts = lazyWithRetry(() => import('./charts/GoogleCharts'));
const SparkLineCharts = lazyWithRetry(() => import('./charts/SparkLineCharts'));
const GuageChart = lazyWithRetry(() => import('./charts/GuageChart'));

const Error404 = lazyWithRetry(() => import('./error-pages/Error404'));
const Error500 = lazyWithRetry(() => import('./error-pages/Error500'));

const Login = lazyWithRetry(() => import('./user-pages/Login'));
const Login2 = lazyWithRetry(() => import('./user-pages/Login2'));
const Register1 = lazyWithRetry(() => import('./user-pages/Register'));
const Register2 = lazyWithRetry(() => import('./user-pages/Register2'));
const Lockscreen = lazyWithRetry(() => import('./user-pages/Lockscreen'));
const ManageAssessment = lazyWithRetry(() => import('./tables/Manage_Assessment'))
const LandingPage = lazyWithRetry(() => import('./general-pages/LandingPage'));
const BlankPage = lazyWithRetry(() => import('./general-pages/BlankPage'));
const Profile = lazyWithRetry(() => import('./general-pages/Profile'));
const Faq = lazyWithRetry(() => import('./general-pages/Faq'));
const Faq2 = lazyWithRetry(() => import('./general-pages/Faq2'));
const NewsGrid = lazyWithRetry(() => import('./general-pages/NewsGrid'));
const Timeline = lazyWithRetry(() => import('./general-pages/Timeline'));
const SearchResults = lazyWithRetry(() => import('./general-pages/SearchResults'));
const Portfolio = lazyWithRetry(() => import('./general-pages/Portfolio'));
const UserListing = lazyWithRetry(() => import('./general-pages/UserListing'));

const Invoice = lazyWithRetry(() => import('./ecommerce/Invoice'));
const Pricing = lazyWithRetry(() => import('./ecommerce/Pricing'));
const ProductCatalogue = lazyWithRetry(() => import('./ecommerce/ProductCatalogue'));
const ProjectList = lazyWithRetry(() => import('./ecommerce/ProjectList'));
const Orders = lazyWithRetry(() => import('./ecommerce/Orders'));
const AddUser = lazyWithRetry(() => import('./user/AddUser'));
const EditUser = lazyWithRetry(() => import('./user/EditUser'));
const Email = lazyWithRetry(() => import('./apps/Email'));
const Calendar = lazyWithRetry(() => import('./apps/Calendar'));
const Gallery = lazyWithRetry(() => import('./apps/Gallery'));
const compare = lazyWithRetry(() => import('./public/comparision'));
const Details = lazyWithRetry(() => import('./public/Details'))
const Overview = lazyWithRetry(() => import('./public/Overview'))
const Public = lazyWithRetry(() => import('./public/FetchSteps'))
const Questions = lazyWithRetry(() => import('./questions/ManageQuestions'))
class AppRoutes extends Component {
  render() {
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>

          <Route exact path="/dashboard_new" component={Dashboard} />
          <Route exact path="/dashboard" component={DashboardTwo} />

          <Route exact path="/layout/RtlLayout" component={RtlLayout} />

          <Route exact path="/widgets" component={Widgets} />
          <Route exact path="/logout" component={Logout} />

          <Route exact path="/apps/kanban-board" component={KanbanBoard} />
          <Route exact path="/apps/todo-list" component={TodoList} />
          <Route exact path="/apps/tickets" component={Tickets} />
          <Route exact path="/apps/chats" component={Chats} />
          <Route path="/topic" component={ManageTopic} />
          <Route path="/manage" component={ManageAssessment} />
          <Route path="/basic-ui/accordions" component={Accordions} />
          <Route path="/basic-ui/buttons" component={Buttons} />
          <Route path="/basic-ui/badges" component={Badges} />
          <Route path="/basic-ui/breadcrumbs" component={Breadcrumbs} />
          <Route path="/basic-ui/dropdowns" component={Dropdowns} />
          <Route path="/basic-ui/modals" component={Modals} />
          <Route path="/basic-ui/progressbar" component={Progress} />
          <Route path="/basic-ui/pagination" component={Paginations} />
          <Route path="/basic-ui/tabs" component={TabsPage} />
          <Route path="/basic-ui/typography" component={Typography} />
          <Route path="/basic-ui/tooltips" component={Tooltips} />
          <Route path="/basic-ui/popups" component={Popups} />
          <Route path="/ques" component={Questions} />
          <Route path="/advanced-ui/dragula" component={Dragula} />
          <Route path="/advanced-ui/clipboard" component={Clipboard} />
          <Route path="/advanced-ui/context-menu" component={ContextMenu} />
          <Route path="/advanced-ui/sliders" component={Sliders} />
          <Route path="/advanced-ui/carousel" component={Carousel} />
          <Route path="/advanced-ui/loaders" component={Loaders} />
          <Route path="/advanced-ui/tree-view" component={TreeView} />

          <Route path="/form-Elements/basic-elements" component={BasicElements} />
          <Route path="/form-Elements/advanced-elements" component={AdvancedElements} />
          <Route path="/form-Elements/validation" component={Validation} />
          <Route path="/form-Elements/wizard" component={Wizard} />

          <Route path="/tables/basic-table" component={BasicTable} />
          <Route path="/tables/data-table" component={DataTable} />
          <Route path="/tables/react-table" component={ReactTable} />
          <Route path="/tables/data-repo" component={DataRepo} />
          <Route path="/response/:id" component={Response} />
          <Route path="/tables/sortable-table" component={SortableTable} />

          <Route path="/maps/vector-map" component={VectorMap} />
          <Route path="/maps/simple-map" component={SimpleMap} />

          <Route path="/notifications" component={Notifications} />
          <Route path="/adduser" component={AddUser} />
          <Route path="/edituser" component={EditUser} />

          <Route path="/icons/mdi" component={Mdi} />
          <Route path="/icons/flag-icons" component={FlagIcons} />
          <Route path="/icons/font-awesome" component={FontAwesome} />
          <Route path="/icons/simple-line" component={SimpleLine} />
          <Route path="/icons/themify" component={Themify} />
          <Route path="/icons/typicons" component={TypIcons} />

          <Route path="/text-editors" component={TextEditors} />
          <Route path="/code-editor" component={CodeEditor} />

          <Route path="/icons/themify" component={Themify} />

          <Route path="/charts/chart-js" component={ChartJs} />
          <Route path="/charts/c3-chart" component={C3Charts} />
          <Route path="/charts/chartist" component={Chartist} />
          <Route path="/charts/google-charts" component={GoogleCharts} />
          <Route path="/charts/sparkline-charts" component={SparkLineCharts} />
          <Route path="/charts/guage-chart" component={GuageChart} />


          <Route exact path="/" component={Login} />
          <Route path="/overview/:id/:id1/:id2" component={Overview} />
          <Route path="/details/:id/:id1/:id2/:id3" component={Details} />
          <Route path="/units/:id" component={Public} />
          <Route path="/user-pages/login-2" component={Login2} />
          <Route path="/user-pages/register-1" component={Register1} />
          <Route path="/user-pages/register-2" component={Register2} />
          <Route path="/user-pages/lockscreen" component={Lockscreen} />

          <Route path="/error-pages/error-404" component={Error404} />
          <Route path="/error-pages/error-500" component={Error500} />

          <Route path="/general-pages/blank-page" component={BlankPage} />
          <Route path="/general-pages/landing-page" component={LandingPage} />
          <Route path="/general-pages/profile" component={Profile} />
          <Route path="/general-pages/faq-1" component={Faq} />
          <Route path="/general-pages/faq-2" component={Faq2} />
          <Route path="/general-pages/news-grid" component={NewsGrid} />
          <Route path="/general-pages/timeline" component={Timeline} />
          <Route path="/general-pages/search-results" component={SearchResults} />
          <Route path="/general-pages/portfolio" component={Portfolio} />
          <Route path="/general-pages/user-listing" component={UserListing} />

          <Route path="/ecommerce/invoice" component={Invoice} />
          <Route path="/ecommerce/pricing" component={Pricing} />
          <Route path="/ecommerce/product-catalogue" component={ProductCatalogue} />
          <Route path="/ecommerce/project-list" component={ProjectList} />
          <Route path="/ecommerce/orders" component={Orders} />

          <Route path="/apps/email" component={Email} />
          <Route path="/apps/calendar" component={Calendar} />
          <Route path="/apps/gallery" component={Gallery} />
          <Route path="/other_report" component={addittionalReport} />
          <Route path="/comparison" component={compare} />
          <Redirect to="/dashboard" />
        </Switch>
      </Suspense>
    );
  }
}


export default AppRoutes;