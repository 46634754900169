export default {
    public: 'https://app.acuizen.com/public',
    Fetch: 'https://app.acuizen.com/fetch_public_microstep_beta',
    Login: 'https://app.acuizen.com/userlogin_beta',
    CheckParallel: 'https://app.acuizen.com/check_device_token_beta',
    Enterprise: 'https://app.acuizen.com/company_screens/',
    UserAdd: 'https://app.acuizen.com/user_add_nike',
    UserList: 'https://app.acuizen.com/user_add_list',
    SubmitUnit: 'https://app.acuizen.com/submit_public_url',
    Filter: 'https://app.acuizen.com/filter_for_nike',
    Response: 'https://app.acuizen.com/nike_response_view',
    QuestionWithTitle:`https://api.acuizen.com/question-titles?filter={"include":[{"relation":"questions"}]}`
}