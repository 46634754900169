import { combineReducers } from 'redux'
import DataReducer from './microData/MicroReducer'
import AssignReducer from './assignedData/AssignReducer'
import LoginReducer from './loginData/LoginReducer'
const rootReducer = combineReducers({
    data: DataReducer,
    assign: AssignReducer,
    login: LoginReducer

})
export default rootReducer;