import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './app/App';
import { Provider } from 'react-redux'
import store from './app/redux/store'
import "./i18n";
import * as serviceWorker from './serviceWorker';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
ReactDOM.render(
  <Provider store={store}>
  <BrowserRouter >
    <App />
  </BrowserRouter>
  </Provider>
, document.getElementById('root'));
defineCustomElements(window);
serviceWorker.unregister();