import { FETCH_MICRO_DATA_REQUEST, FETCH_MICRO_DATA_SUCCESS, FETCH_MICRO_DATA_FAILURE } from './MicroTypes'
const initialState = {
    loading: false,
    data: [],
    session: '',
    title: '',
    error: ''
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_MICRO_DATA_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_MICRO_DATA_SUCCESS:
            return {
                loading: false,
                data: action.payload,
                // session: action.payload.program_id,
                // title: action.payload.program_title,
                // status: action.payload.status
            }
        case FETCH_MICRO_DATA_FAILURE:
            return {
                loading: false,
                data: action.payload,
                // session: action.payload.program_id,
                // title: action.payload.program_title,
                // status: action.payload.status
            }

        default:
            return state;
    }
}
export default reducer;